import { NavLink } from "react-router-dom";
import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../common/AuthContext";
import { DataContext } from "../../common/DataContext";
const Nav_Link = (props) => {
  return <h6 class="headerlink">{props.text}</h6>;
};

export const UserSidebar = () => {
  const { mobile } = useContext(DataContext);

  const {
    user,
    setUser,
    logout,
    logInUser,
    loginRedirect,
    setLoginRedirect,
    redirect,
    toggleRedirect,
  } = useContext(AuthContext);

  const handleLogout = () => {
    setUser(null);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: mobile ? "100vw" : "10vw",
          height: mobile ? "10vh" : "100%",
          backgroundColor: "#5E0516",
          alignItems: "center",

          boxShadow: "2px 2px 9px #000000",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: mobile ? "5vh" : "8vh",
            marginTop: "1vh",
            backgroundColor: "#5E0516",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {mobile ? (
            <>
              <label
                style={{
                  color: "#eed77a",
                  fontWeight: 700,
                  marginBottom: "1vh",
                }}
              >{`Welcome ${user && user.callsign}`}</label>
            </>
          ) : (
            <>
              <label
                style={{
                  color: "#eed77a",
                  fontWeight: 700,
                }}
              >{`Welcome`}</label>
              <label
                style={{
                  color: "#eed77a",
                  fontWeight: 700,
                  marginBottom: "1vh",
                }}
              >{` ${user && user.callsign}`}</label>
            </>
          )}

          <div
            style={{
              width: "100%",
              height: ".25vh",
              backgroundColor: "#eed77a",
            }}
          ></div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: mobile ? "row" : "column",
            width: "100%",
            height: mobile ? "3vh" : "auto",

            backgroundColor: "#5E0516",
            alignItems: "center",
            justifyContent: "center",
            overflowx: mobile ? "scroll" : "",
          }}
        >
          <NavLink
            to="/userHome"
            style={{ textDecoration: "none", marginRight: mobile ? "3vw" : "" }}
          >
            <Nav_Link text={"Announcements"} />
          </NavLink>
          <NavLink
            to="/userProfile"
            style={{ textDecoration: "none", marginRight: mobile ? "3vw" : "" }}
          >
            <Nav_Link text={"Profile"} />
          </NavLink>

          <NavLink
            to="/Medals"
            style={{ textDecoration: "none", marginRight: mobile ? "3vw" : "" }}
          >
            <Nav_Link text={"CEF Medals"} />
          </NavLink>

          <NavLink
            to="/Badges"
            style={{ textDecoration: "none", marginRight: mobile ? "3vw" : "" }}
          >
            <Nav_Link text={"Specialist Badges"} />
          </NavLink>

          <a
            href={`https://www.discord.gg/msfShq56Zz`}
            style={{
              textDecoration: "none",
              fontSize: ".75rem",
              marginRight: mobile ? "3vw" : "",
            }}
            target="_blank"
          >
            <Nav_Link text={"Discord Channel"} />
          </a>

          <NavLink
            to="/login"
            style={{ textDecoration: "none", marginRight: mobile ? "3vw" : "" }}
            onClick={() => handleLogout()}
          >
            <Nav_Link text={"Logout"} />
          </NavLink>
        </div>
      </div>
    </>
  );
};

export const ConfirmModal = (props) => {
  return (
    <>
      {props.modalState === true ? (
        <>
          <div
            style={{
              position: "fixed",
              top: "40%",
              left: "40rem",
              width: "40%",
              height: "auto",
              backgroundColor: "ghostwhite",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              zIndex: "9999",
              padding: "1vw",
              boxShadow: "1px 1px 6px 2px black",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "auto",
                // backgroundColor:'lightgreen',
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <label
                style={{
                  fontSize: "1.5rem",
                  color: "black",
                  fontWeight: 600,
                  marginRight: "2vw",
                  marginLeft: "2vw",
                  marginBottom: "2vh",
                }}
              >
                {props.question}
              </label>
            </div>

            <div
              style={{
                width: "100%",
                height: "auto",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                style={{ marginRight: "2vw" }}
                onClick={(e) => props.cancelAction(e)}
              >
                Cancel
              </button>

              <button
                style={{ marginRight: "2vw" }}
                onClick={(e) => props.confirmAction(e)}
              >
                Confirm
              </button>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export const StyledButton = (props) => {
  return (
    <>
      <div
        onClick={() => props.buttonAction()}
        style={{
          width: "8vw",
          height: "5vh",
          backgroundColor: "black",
          marginRight: "1vw",
          marginLeft: "1vw",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h2 style={{ color: "white" }}>{props.buttonText}</h2>
      </div>
    </>
  );
};

export const countryList = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bonaire, Sint Eustatius and Saba",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo (the Democratic Republic of the)",
  "Congo",
  "Cook Islands",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czechia",
  "Côte d'Ivoire",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Falkland Islands [Malvinas]",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran (Islamic Republic of)",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "South Korea",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Republic of North Macedonia",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Réunion",
  "Saint Barthélemy",
  "Saint Helena, Ascension and Tristan da Cunha",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom of Great Britain and Northern Ireland",
  "United States Minor Outlying Islands",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Viet Nam",
  "Virgin Islands (British)",
  "Virgin Islands (U.S.)",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
  "Åland Islands",
];
