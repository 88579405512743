import React, { useContext } from "react";
import "./styles.css";
import { DataContext } from "../../common/DataContext";
export const Footer = () => {
  const { mobile } = useContext(DataContext);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: !mobile ? "left" : "center",
          height: "6vh",
          width: "100vw",
          backgroundColor: "#5E0516",
          zIndex: "999",
          boxShadow: "10px 7px 9px 9px #000000",
        }}
      >
        <h2 class="footerlink">
          &copy;{new Date().getFullYear()} CEF PAINTBALL - MMFCYDI?!
        </h2>
      </div>
    </>
  );
};
