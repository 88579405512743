import React, { useState, useContext, useEffect } from "react";
import { DataContext } from "../../common/DataContext";
import { AuthContext } from "../../common/AuthContext";
import { Navigate } from "react-router-dom";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { poster, fetcher } from "../../calls/calls";
import { Register_page_1 } from "./register_components";
import { Register_page_2 } from "./register_components";
import { Register_page_3 } from "./register_components";
import { Register_page_4 } from "./register_components";
import { Register_page_5 } from "./register_components";
import { Register_page_6 } from "./register_components";
import { Register_page_7 } from "./register_components";

export const Register = () => {
  const [page, setPage] = useState(1);

  const namePattern = /^[A-Za-z]+,\s[A-Za-z]+,\s[A-Za-z]+$/;
  const addressPattern = /^[A-Za-z0-9\s]+,\s[A-Za-z0-9\s]+,\s[A-Za-z0-9\s]*$/;

  const { redirect } = useContext(AuthContext);

  const {
    firstName,
    setFirstName,
    middleName,
    setMiddleName,
    lastName,
    setLastName,
    address,
    setAddress,
    city,
    setCity,
    state,
    setState,
    country,
    setCountry,
    zip,
    setZip,
    phone,
    setPhone,
    EmergencyContact,
    setEmergencyContact,
    age,
    setAge,
    email,
    setEmail,
    callsign,
    setCallsign,
    homeTeam,
    setHomeTeam,
    rank,
    setRank,
    unit,
    setUnit,
    company,
    setCompany,
    section,
    setSection,
    years,
    setYears,
    playTypes,
    setPlayTypes,
    registerMedals,
    setRegisterMedals,
    registerBadges,
    setRegisterBadges,
    pastEvents,
    setPastEvents,
    fetchPastEvents,
    eventYears,
    setEventYears,
    skills,
    setSkills,
    otherInfo,
    setOtherInfo,
    password,
    setPassword,
    checkPassword,
    setCheckPassword,
    loginEmail,
    setLoginEmail,
    code,
    setCode,
    registerUser,
    unitNumber,
    setUnitNumber,
    mobile,
    showName,
    toggleShowName,
    show_location,
    toggleShowLocation,
  } = useContext(DataContext);

  const handleSetFirstName = (e) => {
    setFirstName(e.target.value);
  };

  const handleSetMiddleName = (e) => {
    setMiddleName(e.target.value);
  };

  const handleSetLastName = (e) => {
    setLastName(e.target.value);
  };

  const handleSetAddress = (e) => {
    setAddress(e.target.value);
  };

  const handleSetUnitNumber = (e) => {
    setUnitNumber();
  };

  const handleSetCity = (e) => {
    setCity(e.target.value);
  };

  const handleSetState = (e) => {
    setState(e.target.value);
  };

  const handleSetCountry = (e) => {
    setCountry(e.target.value);
  };

  const handleSetZip = (e) => {
    setZip(e.target.value);
  };

  const handleSetPhone = (e) => {
    setPhone(e);
  };

  const handleSetEmergencyContact = (e) => {
    setEmergencyContact(e.target.value);
  };

  const handleSetAge = (e) => {
    setAge(e.target.value);
  };

  const handleSetEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleSetCallsign = (e) => {
    setCallsign(e.target.value);
  };

  const handleSetHomeTeam = (e) => {
    setHomeTeam(e.target.value);
  };

  const handleSetRank = (e) => {
    setRank(e.target.value);
  };

  const handleSetUnit = (e) => {
    setUnit(e.target.value);
  };

  const handleSetCompany = (e) => {
    setCompany(e.target.value);
  };

  const handleSetSection = (e) => {
    setSection(e.target.value);
  };

  const handleSetYears = (e) => {
    setYears(e.target.value);
  };

  const handleSetSkills = (e) => {
    setSkills(e.target.value);
  };

  const handleSetOtherInfo = (e) => {
    setOtherInfo(e.target.value);
  };

  const handleSetPassword = (e) => {
    setPassword(e.target.value);
  };

  const handleSetCheckPassword = (e) => {
    setCheckPassword(e.target.value);
  };

  const handleSetLoginEmail = (e) => {
    setLoginEmail(e.target.value);
  };

  const handleSetCode = (e) => {
    setCode(e.target.value);
  };

  const handleSetPlayTypes = (e) => {
    if (playTypes && playTypes.includes(e.target.value)) {
      setPlayTypes(playTypes.filter((type) => type !== e.target.value));
    } else {
      setPlayTypes([...playTypes, e.target.value]);
    }
  };

  const handleSetRegisterMedals = (e) => {
    let updatedRegisterMedals = { ...registerMedals };

    if (updatedRegisterMedals.hasOwnProperty(parseInt(e.target.value))) {
      delete updatedRegisterMedals[parseInt(e.target.value)];
    } else {
      updatedRegisterMedals[parseInt(e.target.value)] = null;
    }
    setRegisterMedals(updatedRegisterMedals);
  };

  const handleSetMedalDetails = (e, id) => {
    let updatedRegisterMedals = registerMedals;
    updatedRegisterMedals[id] = e.target.value;
    setRegisterMedals(updatedRegisterMedals);
  };

  const handleSetBadgeDetails = (e, id) => {
    let updatedRegisterBadges = { ...registerBadges };
    if (updatedRegisterBadges.hasOwnProperty(id)) {
      updatedRegisterBadges[id] = { ...updatedRegisterBadges[id] };
      updatedRegisterBadges[id].details = e.target.value;
    } else {
      updatedRegisterBadges[id] = {
        details: e.target.value,
      };
    }
    setRegisterBadges(updatedRegisterBadges);
  };

  const handleSetRegisterBadges = (e, badgeId) => {
    let updatedRegisterBadges = { ...registerBadges };
    if (updatedRegisterBadges.hasOwnProperty(badgeId)) {
      updatedRegisterBadges[badgeId] = {
        level: e.target.value,
      };
    } else {
      updatedRegisterBadges[badgeId] = {
        level: e.target.value,
      };
    }
    setRegisterBadges(updatedRegisterBadges);
  };

  const handleClearRegisterBadges = (e) => {
    let updatedRegisterBadges = {};
    setRegisterBadges(updatedRegisterBadges);
  };

  const handleEventYearsChange = (e, eventId) => {
    let updatedEventYears = { ...eventYears };
    if (updatedEventYears.hasOwnProperty(eventId)) {
      if (e.target.checked) {
        if (!updatedEventYears[eventId].includes(e.target.value)) {
          updatedEventYears[eventId] = [
            ...updatedEventYears[eventId],
            e.target.value,
          ];
        }
      } else {
        updatedEventYears[eventId] = updatedEventYears[eventId].filter(
          (year) => year !== e.target.value,
        );
        if (updatedEventYears[eventId].length === 0) {
          delete updatedEventYears[eventId];
        }
      }
    } else {
      updatedEventYears[eventId] = [e.target.value];
    }
    setEventYears(updatedEventYears);
  };

  const handleClearEventYears = (e) => {
    let updatedevents = {};
    setEventYears(updatedevents);
  };

  const handlePageForward = () => {
    if (page !== 7) {
      console.log(phone, country);
      if (
        page === 1 &&
        address &&
        city &&
        state &&
        country &&
        zip &&
        phone &&
        email &&
        EmergencyContact
      ) {
        setPage((prevCount) => prevCount + 1);
      } else if (page === 2 && callsign) {
        setPage((prevCount) => prevCount + 1);
      } else if (page > 2) {
        setPage((prevCount) => prevCount + 1);
      } else {
        alert("You must fill in all of the required fields to proceed.");
      }
    }
  };

  const handlePageBack = () => {
    if (page !== 1) {
      setPage((prevCount) => prevCount - 1);
    }
  };

  return (
    <>
      <div
        style={
          mobile && mobile === false
            ? {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "#adc8e6",
                height: "auto",
              }
            : {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                // justifyContent:'center',
                backgroundColor: "#adc8e6",
                minHeight: "100vh",
                height: "100%",
              }
        }
      >
        <Header />
        <div
          // style={{

          // }}
          style={
            mobile && mobile === false
              ? {
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  height: "88vh",
                  width: "100%",
                }
              : {
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  backgroundColor: "#adc8e6",
                  height: "auto",
                  WebkitOverflowScrolling: "touch",
                  flexGrow: 1,
                }
          }
        >
          {mobile && mobile == false ? (
            <>
              <h1>New Player Registration</h1>
            </>
          ) : (
            <>
              <h3>New Player Registration</h3>
            </>
          )}

          {page && page === 1 ? (
            <>
              <div
                // style={{

                // }}

                style={
                  mobile && mobile === false
                    ? {
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }
                    : {
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",

                        flexGrow: 1, // Allow the content to take remaining space
                      }
                }
              >
                <Register_page_1
                  firstName={firstName}
                  handleSetFirstName={handleSetFirstName}
                  middleName={middleName}
                  handleSetMiddleName={handleSetMiddleName}
                  lastName={lastName}
                  handleSetLastName={handleSetLastName}
                  address={address}
                  handleSetAddress={handleSetAddress}
                  unitNumber={unitNumber}
                  handleSetUnitNumber={handleSetUnitNumber}
                  city={city}
                  handleSetCity={handleSetCity}
                  state={state}
                  handleSetState={handleSetState}
                  country={country}
                  handleSetCountry={handleSetCountry}
                  zip={zip}
                  handleSetZip={handleSetZip}
                  phone={phone}
                  handleSetPhone={handleSetPhone}
                  emergencyContact={EmergencyContact}
                  handleSetEmergencyContact={handleSetEmergencyContact}
                  age={age}
                  handleSetAge={handleSetAge}
                  email={email}
                  handleSetEmail={handleSetEmail}
                />
                <button type="button" onClick={() => handlePageForward()}>
                  Next
                </button>
              </div>
            </>
          ) : page && page === 2 ? (
            <>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Register_page_2
                  callsign={callsign}
                  handleSetCallsign={handleSetCallsign}
                  age={age}
                  handleSetAge={handleSetAge}
                  homeTeam={homeTeam}
                  handleSetHomeTeam={handleSetHomeTeam}
                  rank={rank}
                  handleSetRank={handleSetRank}
                  unit={unit}
                  handleSetUnit={handleSetUnit}
                  company={company}
                  handleSetCompany={handleSetCompany}
                  section={section}
                  handleSetSection={handleSetSection}
                  years={years}
                  handleSetYears={handleSetYears}
                  playTypes={playTypes}
                  handleSetPlayTypes={handleSetPlayTypes}
                />

                <div
                  style={{
                    width: "100%",
                    height: "auto",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <button
                    style={{ marginRight: "1vw" }}
                    type="button"
                    onClick={() => handlePageBack()}
                  >
                    Back
                  </button>
                  <button type="button" onClick={() => handlePageForward()}>
                    Next
                  </button>
                </div>
              </div>
            </>
          ) : page && page === 3 ? (
            <>
              <div
                style={{
                  width: "100%",
                  height: "96%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Register_page_3
                  registerMedals={registerMedals}
                  handleSetMedalDetails={handleSetMedalDetails}
                  handleSetRegisterMedals={handleSetRegisterMedals}
                />

                <div
                  style={{
                    width: "100%",
                    height: "auto",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "1vh",
                  }}
                >
                  <button
                    style={{ marginRight: "1vw" }}
                    type="button"
                    onClick={() => handlePageBack()}
                  >
                    Back
                  </button>
                  <button type="button" onClick={() => handlePageForward()}>
                    Next
                  </button>
                </div>
              </div>
            </>
          ) : page && page === 4 ? (
            <>
              <div
                style={{
                  width: "100%",
                  height: "96%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Register_page_4
                  handleSetRegisterBadges={handleSetRegisterBadges}
                  registerBadges={registerBadges}
                  handleClearRegisterBadges={handleClearRegisterBadges}
                  handleSetBadgeDetails={handleSetBadgeDetails}
                />

                <div
                  style={{
                    width: "100%",
                    height: "auto",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <button
                    style={{ marginRight: "1vw" }}
                    type="button"
                    onClick={() => handlePageBack()}
                  >
                    Back
                  </button>
                  <button type="button" onClick={() => handlePageForward()}>
                    Next
                  </button>
                </div>
              </div>
            </>
          ) : page && page === 5 ? (
            <>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Register_page_5
                  pastEvents={pastEvents}
                  fetchPastEvents={fetchPastEvents}
                  eventYears={eventYears}
                  handleEventYearsChange={handleEventYearsChange}
                  handleClearEventYears={handleClearEventYears}
                />

                <div
                  style={{
                    width: "100%",
                    height: "auto",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <button
                    style={{ marginRight: "1vw" }}
                    type="button"
                    onClick={() => handlePageBack()}
                  >
                    Back
                  </button>
                  <button type="button" onClick={() => handlePageForward()}>
                    Next
                  </button>
                </div>
              </div>
            </>
          ) : page && page === 6 ? (
            <>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Register_page_6
                  skills={skills}
                  otherInfo={otherInfo}
                  handleSetOtherInfo={handleSetOtherInfo}
                  handleSetSkills={handleSetSkills}
                />
                <div
                  style={{
                    width: "100%",
                    height: "auto",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <button
                    style={{ marginRight: "1vw" }}
                    type="button"
                    onClick={() => handlePageBack()}
                  >
                    Back
                  </button>
                  <button type="button" onClick={() => handlePageForward()}>
                    Next
                  </button>
                </div>
              </div>
            </>
          ) : page && page === 7 ? (
            <>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Register_page_7
                  loginEmail={loginEmail}
                  handleSetLoginEmail={handleSetLoginEmail}
                  password={password}
                  handleSetPassword={handleSetPassword}
                  checkPassword={checkPassword}
                  handleSetCheckPassword={handleSetCheckPassword}
                  code={code}
                  handleSetCode={handleSetCode}
                />

                <div
                  style={{
                    width: "100%",
                    height: "auto",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <button
                    style={{ marginRight: "1vw" }}
                    type="button"
                    onClick={() => handlePageBack()}
                  >
                    Back
                  </button>
                  <button type="button" onClick={(e) => registerUser(e)}>
                    Submit
                  </button>
                </div>
              </div>
            </>
          ) : null}
        </div>

        <Footer />
      </div>
      {/* {!redirect ? null : <Navigate push to="/login" />} */}
    </>
  );
};
