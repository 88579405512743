import React, { createContext, useState, useEffect } from "react";
import { useLocalStorageState } from "../useLocalStorage";
import { poster, fetcher } from "../../calls/calls";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../constants";

export const AuthContext = createContext({});
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorageState("viewer.user", null);
  const [loginRedirect, setLoginRedirect] = useState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [redirect, toggleRedirect] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (user || !user == null) {
      toggleRedirect(true);
    }
    // eslint-disable-next-line
  }, [user]);

  const logInUser = async () => {
    let url = API_URL + "/login";
    let outpack = {
      email: email,
      password: password,
    };
    poster(outpack, url).then((response) => {
      if (response.status === 200) {
        setUser(response.user_info);
        // toggleRedirect(true)
      } else {
        alert(response.message);
      }
    });
  };

  const logout = () => {
    setUser(null);
    // toggleRedirect(true);
    setEmail(null);
    setPassword(null);
    fetcher("logout").then((response) => {});
  };

  // const SSO_Refresh = () => {
  //   let outpack = {
  //     user_id: user.id,
  //   };
  // };

  const value = {
    logout,
    user,
    setUser,
    logInUser,
    loginRedirect,
    setLoginRedirect,
    email,
    setEmail,
    password,
    setPassword,
    code,
    setCode,
    redirect,
    toggleRedirect,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
