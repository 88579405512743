// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navImg {
  margin-left: 3.25vw;
  margin-top: 1vh;
  margin-bottom: 1vh;
  width: 2.75%;
}

.headerlink {
  color: #eed77a;
  font-size: 1rem;
  font-weight: 700;
  padding-left: 0%;
  padding-right: 0%;
  /* margin-left: 2vw;
  margin-right: 3vw; */
}

@media only screen and (max-height: 950px) {
  .headerlink {
    color: #eed77a;
    font-size: 1rem;
    font-weight: 700;
    padding-left: 0%;
    padding-right: 0%;
    /* margin-left: 2vw;
    margin-right: 3vw; */
  }
  .navImg {
    width: 45px;
  }
}

@media only screen and (max-width: 384px) {
  .navImg {
    margin-top: 1vh;
    margin-bottom: 1vh;
  }

  .headerlink {
    color: #eed77a;
    font-size: 0.71rem;
    font-weight: 700;
    padding-left: 0%;
    padding-right: 0%;
    /* margin-left: 2vw;
    margin-right: 3vw; */
  }
}

@media only screen and (max-height: 384px) {
  .headerlink {
    color: #eed77a;
    font-size: 1rem;
    font-weight: 700;
    padding-left: 0%;
    padding-right: 0%;
    /* margin-left: 2vw;
    margin-right: 3vw; */
  }
  .navImg {
    width: 4vw;

    /* height:auto */
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Header/styles.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,iBAAiB;EACjB;sBACoB;AACtB;;AAEA;EACE;IACE,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,iBAAiB;IACjB;wBACoB;EACtB;EACA;IACE,WAAW;EACb;AACF;;AAEA;EACE;IACE,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,cAAc;IACd,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;IAChB,iBAAiB;IACjB;wBACoB;EACtB;AACF;;AAEA;EACE;IACE,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,iBAAiB;IACjB;wBACoB;EACtB;EACA;IACE,UAAU;;IAEV,gBAAgB;EAClB;AACF","sourcesContent":[".navImg {\n  margin-left: 3.25vw;\n  margin-top: 1vh;\n  margin-bottom: 1vh;\n  width: 2.75%;\n}\n\n.headerlink {\n  color: #eed77a;\n  font-size: 1rem;\n  font-weight: 700;\n  padding-left: 0%;\n  padding-right: 0%;\n  /* margin-left: 2vw;\n  margin-right: 3vw; */\n}\n\n@media only screen and (max-height: 950px) {\n  .headerlink {\n    color: #eed77a;\n    font-size: 1rem;\n    font-weight: 700;\n    padding-left: 0%;\n    padding-right: 0%;\n    /* margin-left: 2vw;\n    margin-right: 3vw; */\n  }\n  .navImg {\n    width: 45px;\n  }\n}\n\n@media only screen and (max-width: 384px) {\n  .navImg {\n    margin-top: 1vh;\n    margin-bottom: 1vh;\n  }\n\n  .headerlink {\n    color: #eed77a;\n    font-size: 0.71rem;\n    font-weight: 700;\n    padding-left: 0%;\n    padding-right: 0%;\n    /* margin-left: 2vw;\n    margin-right: 3vw; */\n  }\n}\n\n@media only screen and (max-height: 384px) {\n  .headerlink {\n    color: #eed77a;\n    font-size: 1rem;\n    font-weight: 700;\n    padding-left: 0%;\n    padding-right: 0%;\n    /* margin-left: 2vw;\n    margin-right: 3vw; */\n  }\n  .navImg {\n    width: 4vw;\n\n    /* height:auto */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
