import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../common/DataContext/index";
import { AuthContext } from "../../common/AuthContext";
import { Navigate } from "react-router-dom";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { UserSidebar } from "../../components/common";
import { Tooltip } from "@mui/material";
import logo from "../../static/NavIcon.png";
import useToggle from "../../common/hooks/useToggle";

const ParagraphComponent = ({ text }) => {
  return (
    <div
      style={{
        whiteSpace: "pre-line",
        color: "#eed77a",
        textDecoration: "none",
        textAlign: "center",
        marginBottom: "1vh",
        fontWeight: 500,
      }}
    >
      {text}
    </div>
  );
};

export const Badges = () => {
  var gold_image;
  var silver_image;
  var bronze_image;
  var new_gold_image;
  var new_silver_image;
  var new_bronze_image;
  const { mobile, badges, fetchBadges, updateBadges, deleteBadge } =
    useContext(DataContext);

  const { user } = useContext(AuthContext);
  const [badgeModalOpen, toggleBadgeModalOpen] = useToggle();
  const [modalMode, setModalMode] = useState(null);
  const [selectedBadge, setSelectedBadge] = useState(null);

  const handleSetModalMode = (e) => {
    setModalMode(e);
  };

  const handleOpenBadgeModal = (e, mode, selected_badge = null) => {
    console.log(mode);
    handleSetModalMode(mode);
    if (selected_badge) {
      setSelectedBadge(selected_badge);
    }
    toggleBadgeModalOpen();
  };

  useEffect(() => {
    fetchBadges();
  }, []);

  const EditBadgeModal = (props) => {
    const { mobile } = useContext(DataContext);
    const [tempGoldUrl, setTempGoldUrl] = useState(null);
    const [tempSilverUrl, setTempSilverUrl] = useState(null);
    const [tempBronzeUrl, setTempBronzeUrl] = useState(null);

    const [tempGoldRequirements, setTempGoldRequirements] = useState(null);
    const [tempSilverRequirements, setTempSilverRequirements] = useState(null);
    const [tempBronzeRequirements, setTempBronzeRequirements] = useState(null);
    const [tempId, setTempId] = useState(null);
    const [tempName, setTempName] = useState(null);
    const [tempDescription, setTempDescription] = useState(null);

    useEffect(() => {
      if (props.selectedBadge && props.selectedBadge !== null) {
        setTempId(props.selectedBadge.id);
        setTempName(props.selectedBadge.name);
        setTempDescription(props.selectedBadge.description);
        setTempGoldRequirements(props.selectedBadge.gold_requirements);
        setTempSilverRequirements(props.selectedBadge.silver_requirements);
        setTempBronzeRequirements(props.selectedBadge.bronze_requirements);
        setTempGoldUrl(props.selectedBadge.gold_url);
        setTempSilverUrl(props.selectedBadge.silver_url);
        setTempBronzeUrl(props.selectedBadge.bronze_url);
      }
    }, [props.selectedBadge]);

    useEffect(() => {
      console.log(tempId);
    }, [tempId]);

    const handleSetTempBronzeRequirements = (e) => {
      setTempBronzeRequirements(e.target.value);
    };
    const handleSetTempSilverRequirements = (e) => {
      setTempSilverRequirements(e.target.value);
    };
    const handleSetTempGoldRequirements = (e) => {
      setTempGoldRequirements(e.target.value);
    };

    const handleSetTempName = (e) => {
      setTempName(e.target.value);
    };

    const handleSetTempDescription = (e) => {
      setTempDescription(e.target.value);
    };

    const handleSetTempGoldUrl = (e) => {
      gold_image = e.target.files;
      new_gold_image = true;
      console.log(e.target.files);
      const reader = new FileReader();
      const selectedFile = e.target.files[0];
      reader.onload = (event) => {
        const fileString = event.target.result;
        setTempGoldUrl(fileString);
      };
      reader.readAsDataURL(selectedFile);
    };

    const handleSetTempSilverUrl = (e) => {
      silver_image = e.target.files;
      new_silver_image = true;
      console.log(e.target.files);
      const reader = new FileReader();
      const selectedFile = e.target.files[0];
      reader.onload = (event) => {
        const fileString = event.target.result;
        setTempSilverUrl(fileString);
      };
      reader.readAsDataURL(selectedFile);
    };

    const handleSetTempBronzeUrl = (e) => {
      bronze_image = e.target.files;
      new_bronze_image = true;
      console.log(e.target.files);
      const reader = new FileReader();
      const selectedFile = e.target.files[0];
      reader.onload = (event) => {
        const fileString = event.target.result;
        setTempBronzeUrl(fileString);
      };
      reader.readAsDataURL(selectedFile);
    };

    const handleUpdateBadgeDetails = (e) => {
      updateBadges(
        tempName,
        tempDescription,
        tempGoldRequirements,
        tempSilverRequirements,
        tempBronzeRequirements,
        tempId,
        new_gold_image ? gold_image : null,
        new_silver_image ? silver_image : null,
        new_bronze_image ? bronze_image : null,
      );
      new_gold_image = false;
      new_silver_image = false;
      new_bronze_image = false;
      toggleBadgeModalOpen();
      fetchBadges();
    };

    const handleDeleteBadge = (id) => {
      console.log(id);
      deleteBadge(tempId);
      toggleBadgeModalOpen();
    };

    return (
      <>
        {props.badgeModalOpen && props.badgeModalOpen === true ? (
          <>
            <div
              style={{
                position: "fixed",
                top: "6vh",
                left: mobile ? "4vw" : "32vw",
                height: mobile ? "70vh" : "auto",
                width: mobile ? "92vw" : "50vw",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "left",
                marginTop: "1vh",
                backgroundColor: "ghostwhite",
                boxShadow: "2px 2px 9px #000000",
                zIndex: "999",
                flexWrap: "wrap",
                paddingBottom: "2vh",
              }}
            >
              <div
                style={{
                  height: "5vh",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#5E0516",
                  boxShadow: "2px 2px 9px #000000",
                }}
              >
                <h3
                  style={{ color: "#eed77a" }}
                >{`${props.modalMode} Badge:`}</h3>
              </div>

              <div
                style={{
                  height: "auto",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  overflowY: "scroll",
                  // backgroundColor: "lightgrey",
                  paddingTop: "4vh",
                }}
              >
                <div
                  style={{
                    height: "auto",
                    width: "70%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    // backgroundColor:'grey',
                    marginBottom: "1vh",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      width: "auto",
                      height: "20vh",
                      boxShadow: "2px 2px 9px #000000",
                      padding: "2px",
                      marginRight: "1vw",
                    }}
                  >
                    <label
                      style={{
                        color: "black",
                        fontSize: "1remm",
                        fontWeight: 400,
                      }}
                    >
                      {"Gold Image"}
                    </label>
                    <img
                      style={{
                        width: mobile ? "25vw" : "6.6vw",
                        height: "auto",
                        padding: mobile ? "" : "1vh",
                      }}
                      src={
                        tempGoldUrl && tempGoldUrl !== null ? tempGoldUrl : logo
                      }
                      alt={"gold badge image"}
                    />
                    <input
                      type="file"
                      accept=".jpg, .png, .jpeg,"
                      style={{
                        // backgroundColor:'grey',
                        width: "auto",
                        color: "black",
                      }}
                      onChange={(e) => handleSetTempGoldUrl(e)}
                      multiple={true}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      width: "auto",
                      height: "20vh",
                      boxShadow: "2px 2px 9px #000000",
                      marginRight: "1vw",
                      padding: "2px",
                    }}
                  >
                    <label
                      style={{
                        color: "black",
                        fontSize: "1remm",
                        fontWeight: 400,
                      }}
                    >
                      {"Silver Image"}
                    </label>
                    <img
                      style={{
                        width: mobile ? "25vw" : "6.6vw",
                        height: "auto",
                        padding: mobile ? "" : "1vh",
                      }}
                      src={
                        tempSilverUrl && tempSilverUrl !== null
                          ? tempSilverUrl
                          : logo
                      }
                      alt={"silver badge image"}
                    />
                    <input
                      type="file"
                      accept=".jpg, .png, .jpeg,"
                      style={{
                        // backgroundColor:'grey',
                        width: "auto",
                        color: "black",
                      }}
                      onChange={(e) => handleSetTempSilverUrl(e)}
                      multiple={false}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      width: "auto",
                      height: "20vh",
                      boxShadow: "2px 2px 9px #000000",
                      padding: "2px",
                    }}
                  >
                    <label
                      style={{
                        color: "black",
                        fontSize: "1remm",
                        fontWeight: 400,
                      }}
                    >
                      {"Bronze Image"}
                    </label>
                    <img
                      style={{
                        width: mobile ? "25vw" : "6.6vw",
                        height: "auto",
                        padding: mobile ? "" : "1vh",
                      }}
                      src={
                        tempBronzeUrl && tempBronzeUrl !== null
                          ? tempBronzeUrl
                          : logo
                      }
                      alt={"bronze badge image"}
                    />
                    <input
                      type="file"
                      accept=".jpg, .png, .jpeg,"
                      style={{
                        // backgroundColor:'grey',
                        width: "auto",
                        color: "black",
                      }}
                      onChange={(e) => handleSetTempBronzeUrl(e)}
                      multiple={false}
                    />
                  </div>
                </div>

                <div
                  style={{
                    height: "auto",
                    width: "70%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "right",
                    // backgroundColor:'grey',
                    marginBottom: "1vh",
                    marginTop: "1vh",
                    paddingRight: "8vw",
                  }}
                >
                  <label
                    style={{
                      color: "black",
                      fontSize: "1remm",
                      fontWeight: 400,
                      marginRight: "2vw",
                    }}
                  >
                    {"Name:"}
                  </label>

                  <input
                    type="text"
                    style={{
                      width: "20vw",
                    }}
                    value={tempName}
                    onChange={(e) => handleSetTempName(e)}
                  ></input>
                </div>

                <div
                  style={{
                    height: "auto",
                    width: "70%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "right",
                    // backgroundColor:'grey',
                    marginBottom: "1vh",
                    paddingRight: "8vw",
                  }}
                >
                  <label
                    style={{
                      color: "black",
                      fontSize: "1remm",
                      fontWeight: 400,
                      marginRight: "2vw",
                    }}
                  >
                    {"Description:"}
                  </label>

                  <textarea
                    style={{
                      width: "20vw",
                      height: "10vh",
                    }}
                    value={tempDescription}
                    onChange={(e) => handleSetTempDescription(e)}
                  ></textarea>
                </div>

                <div
                  style={{
                    height: "auto",
                    width: "70%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "right",
                    // backgroundColor:'grey',
                    marginBottom: "1vh",
                    paddingRight: "8vw",
                  }}
                >
                  <label
                    style={{
                      color: "black",
                      fontSize: "1remm",
                      fontWeight: 400,
                      marginRight: "2vw",
                    }}
                  >
                    {"Gold Requirements:"}
                  </label>

                  <textarea
                    style={{
                      width: "20vw",
                      height: "10vh",
                    }}
                    value={tempGoldRequirements}
                    onChange={(e) => handleSetTempGoldRequirements(e)}
                  ></textarea>
                </div>

                <div
                  style={{
                    height: "auto",
                    width: "70%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "right",
                    // backgroundColor:'grey',
                    marginBottom: "1vh",
                    paddingRight: "8vw",
                  }}
                >
                  <label
                    style={{
                      color: "black",
                      fontSize: "1remm",
                      fontWeight: 400,
                      marginRight: "2vw",
                    }}
                  >
                    {"Silver Requirements:"}
                  </label>

                  <textarea
                    style={{
                      width: "20vw",
                      height: "10vh",
                    }}
                    value={tempSilverRequirements}
                    onChange={(e) => handleSetTempSilverRequirements(e)}
                  ></textarea>
                </div>

                <div
                  style={{
                    height: "auto",
                    width: "70%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "right",
                    // backgroundColor:'grey',
                    marginBottom: "1vh",
                    paddingRight: "8vw",
                  }}
                >
                  <label
                    style={{
                      color: "black",
                      fontSize: "1remm",
                      fontWeight: 400,
                      marginRight: "2vw",
                    }}
                  >
                    {"Bronze Requirements:"}
                  </label>

                  <textarea
                    style={{
                      width: "20vw",
                      height: "10vh",
                    }}
                    value={tempBronzeRequirements}
                    onChange={(e) => handleSetTempBronzeRequirements(e)}
                  ></textarea>
                </div>

                <div
                  style={{
                    height: "10%",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <button
                    style={{ marginRight: "2vw" }}
                    onClick={(e) => handleOpenBadgeModal(e)}
                  >
                    Cancel
                  </button>
                  {props.modalMode === "Edit" ? (
                    <>
                      <button
                        style={{ marginRight: "2vw" }}
                        onClick={(e) => handleDeleteBadge(tempId)}
                      >
                        Delete
                      </button>
                    </>
                  ) : null}

                  <button onClick={(e) => handleUpdateBadgeDetails(e)}>
                    {props.modalMode}
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </>
    );
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          backgroundColor: "#adc8e6",
          minHeight: mobile ? "100vh" : "",
        }}
      >
        <Header />
        <div
          style={{
            height: "88vh",
            width: "100%",
            display: "flex",
            flexDirection: mobile ? "column" : "row",
            alignItems: "center",
            flexGrow: mobile ? 1 : 0,
          }}
        >
          {user && user !== null ? (
            <>
              <UserSidebar />
            </>
          ) : null}
          {user && user.role === "admin" ? (
            <>
              <button
                style={{
                  position: "fixed",
                  top: "8vh",
                  left: "10vw",
                }}
                value={"Add"}
                onClick={(e) => handleOpenBadgeModal(e, "Add")}
              >
                Add Badge
              </button>
            </>
          ) : null}
          <EditBadgeModal
            badgeModalOpen={badgeModalOpen}
            modalMode={modalMode}
            updateBadges={updateBadges}
            selectedBadge={selectedBadge}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: mobile ? "70vh" : "80vh",
              alignItems: "center",
              overflowY: "scroll",
              paddingTop: "2vh",
              paddingBottom: "6vh",
            }}
          >
            {badges && badges.length > 0 ? (
              badges.slice().map((row, index) => {
                const {
                  id,
                  name,
                  description,
                  gold_url,
                  silver_url,
                  bronze_url,
                  bronze_requirements,
                  silver_requirements,
                  gold_requirements,
                } = row;
                return (
                  <>
                    <div
                      key={index}
                      style={{
                        height: "Auto",
                        width: mobile ? "80vw" : "50%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "1vh",

                        backgroundColor: "#5E0516",
                        boxShadow: "1px 1px 6px 2px black",
                        border: "solid",
                        borderWidth: "4px",
                        borderColor: "#eed77a",
                      }}
                    >
                      <label
                        style={{
                          color: "#eed77a",
                          fontSize: "1.2rem",
                          fontWeight: 800,
                          marginTop: "1vh",
                        }}
                        value={"Add"}
                        onClick={
                          user.role === "admin"
                            ? (e) => handleOpenBadgeModal(e, "Edit", row)
                            : null
                        }
                      >
                        {name}
                      </label>
                      <p
                        style={{
                          height: "2vh",
                          color: "#eed77a",
                          fontSize: ".5remm",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          fontWeight: 500,
                          marginTop: "1vh",
                          textAlign: "center",
                        }}
                      >{`Requirements:`}</p>
                      <ParagraphComponent text={description} />

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            marginBottom: "1vh",
                            marginRight: "2vw",
                          }}
                        >
                          <Tooltip
                            placement="top"
                            title={bronze_requirements}
                            arrow
                          >
                            <img
                              style={{
                                width: mobile ? "25vw" : "6.6vw",
                                height: "auto",
                                padding: mobile ? "" : "1vh",
                              }}
                              src={bronze_url}
                              alt={name}
                            />
                          </Tooltip>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginBottom: "1vh",
                            }}
                          >
                            <label
                              style={{
                                color: "#eed77a",
                                fontSize: "1remm",
                                fontWeight: 700,
                              }}
                            >{`Bronze`}</label>
                            {/* <input
                          value="Bronze"
                          checked={
                            props.registerBadges &&
                            props.registerBadges[`${id}`]?.level == "Bronze"
                          }
                          onChange={(e) => props.handleSetRegisterBadges(e, id)}
                          type="radio"
                        /> */}
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            marginBottom: "1vh",
                            marginRight: "2vw",
                          }}
                        >
                          <Tooltip
                            placement="top"
                            title={silver_requirements}
                            arrow
                          >
                            <img
                              style={{
                                width: mobile ? "25vw" : "6.6vw",
                                height: "auto",
                                padding: mobile ? "" : "1vh",
                              }}
                              src={silver_url}
                              alt={name} // Add alt text for accessibility
                            />
                          </Tooltip>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginBottom: "1vh",
                            }}
                          >
                            <label
                              style={{
                                color: "#eed77a",
                                fontSize: "1remm",
                                fontWeight: 700,
                              }}
                            >{`Silver`}</label>
                            {/* <input
                          value="Silver"
                          checked={
                            props.registerBadges &&
                            props.registerBadges[`${id}`]?.level == "Silver"
                          }
                          onChange={(e) => props.handleSetRegisterBadges(e, id)}
                          type="radio"
                        /> */}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            marginBottom: "1vh",
                          }}
                        >
                          <Tooltip
                            placement="top"
                            title={gold_requirements}
                            arrow
                          >
                            <img
                              style={{
                                width: mobile ? "25vw" : "6.6vw",
                                height: "auto",
                                padding: mobile ? "" : "1vh",
                              }}
                              src={gold_url}
                              alt={name} // Add alt text for accessibility
                            />
                          </Tooltip>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginBottom: "1vh",
                            }}
                          >
                            <label
                              style={{
                                color: "#eed77a",
                                fontSize: "1remm",
                                fontWeight: 700,
                              }}
                            >{`Gold`}</label>
                            {/* <input
                          value="Gold"
                          checked={
                            props.registerBadges &&
                            props.registerBadges[`${id}`]?.level == "Gold"
                          }
                          onChange={(e) => props.handleSetRegisterBadges(e, id)}
                          type="radio"
                        /> */}
                          </div>
                        </div>
                      </div>

                      {/* {props.registerBadges &&
                  props.registerBadges.hasOwnProperty(id) ? (
                    <textArea
                      style={{
                        width: "80%",
                        height: "10vh",
                        marginBottom: "1vh",
                      }}
                      placeholder={
                        "Tell us where, when, and how your Badge was earned"
                      }
                      onChange={(e) => props.handleSetBadgeDetails(e, id)}
                    />
                  ) : null} */}
                    </div>
                  </>
                );
              })
            ) : (
              <>
                <div
                  style={{
                    width: "auto",
                    height: "50vh",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{ width: "50vh", height: "auto" }}
                    src={
                      "https://printsmiths.nyc3.cdn.digitaloceanspaces.com/CEF/Badges/badges_meme.png"
                    }
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <Footer />
        {/* {!redirect ? null : <Navigate push to="/login" />} */}
      </div>
    </>
  );
};
