import "./App.css";
import React, { useEffect, useContext } from "react";
import { BrowserRouter, Link, Route, Routes, redirect } from "react-router-dom";
import { DataProvider } from "./common/DataContext/index";
import { AuthProvider } from "./common/AuthContext";
import { DataContext } from "./common/DataContext/index";
import { Main } from "./pages/Main";
import { About } from "./pages/About";

import { Contact } from "./pages/contact";
import { Shop } from "./pages/shop";
import { Login } from "./pages/login";
import { Register } from "./pages/Register";

import { History } from "./pages/History";
import { Media } from "./pages/Media";
import { Events } from "./pages/Events";
import { Memorials } from "./pages/Memorials";
import { fetcher, poster } from "./calls/calls";
import { Members } from "./pages/Members";
import { Recruiting } from "./pages/Recruiting";
import { MapPage } from "./pages/Map";
import { UserHome } from "./pages/UserHome";
import { UserProfile } from "./pages/UserProfile";
import { EventProfile } from "./pages/EventProfile";
import { Badges } from "./pages/Badges";
import { Medals } from "./pages/Medals";
function App() {
  return (
    // <div class= 'appDiv'>
    <>
      <BrowserRouter>
        <AuthProvider>
          <DataProvider>
            <Routes>
              <Route exact path="/" element={<Main />} />
              <Route exact path="/userHome" element={<UserHome />} />
              <Route exact path="/userProfile" element={<UserProfile />} />
              <Route exact path="/register" element={<Register />} />
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/about" element={<About />} />
              <Route exact path="/history" element={<History />} />
              <Route exact path="/Media" element={<Media />} />
              <Route exact path="/Events" element={<Events />} />
              <Route exact path="/EventProfile" element={<EventProfile />} />
              <Route exact path="/Members" element={<Members />} />
              <Route exact path="/Recruiting" element={<Recruiting />} />
              <Route exact path="/Memorials" element={<Memorials />} />
              <Route exact path="/Map" element={<MapPage />} />
              <Route exact path="/contact" element={<Contact />} />
              <Route exact path="/shop" element={<Shop />} />
              <Route exact path="/Badges" element={<Badges />} />
              <Route exact path="/Medals" element={<Medals />} />
            </Routes>
          </DataProvider>
        </AuthProvider>
      </BrowserRouter>
    </>
    // </div>
  );
}

export default App;
