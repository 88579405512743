import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../common/DataContext/index";
import { AuthContext } from "../../common/AuthContext";
import { Navigate } from "react-router-dom";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { UserSidebar } from "../../components/common";
import logo from "../../static/NavIcon.png";
export const Members = () => {
  const { mobile, members, fetchMembers, editPlayerInfo } =
    useContext(DataContext);
  const { user, redirect } = useContext(AuthContext);
  const [editMemberOpen, toggleEditMemberOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [tempRank, setTempRank] = useState(null);
  const [tempUnit, setTempUnit] = useState(null);
  const [tempCompany, setTempCompany] = useState(null);
  const [tempSection, setTempSection] = useState(null);
  useEffect(() => {
    fetchMembers();
  }, []);

  const handleEditMemberModal = (e, id, rank, unit, company, section) => {
    setSelectedMember(id);
    setTempRank(rank);
    setTempUnit(unit);
    setTempCompany(company);
    setTempSection(section);
    if (editMemberOpen) {
      toggleEditMemberOpen(false);
    } else {
      toggleEditMemberOpen(true);
    }
  };

  const EditMemberModal = (props) => {
    const { mobile } = useContext(DataContext);

    const handlEditUser = () => {
      editPlayerInfo(
        selectedMember,
        tempRank,
        tempUnit,
        tempCompany,
        tempSection,
      );

      fetchMembers();
      toggleEditMemberOpen(false);
    };

    return (
      <>
        {props.editMemberOpen && props.editMemberOpen === true ? (
          <>
            <div
              style={{
                position: "fixed",
                top: "20vh",
                left: mobile ? "4vw" : "32vw",
                height: mobile ? "70vh" : "auto",
                width: mobile ? "92vw" : "40vw",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "left",
                marginTop: "1vh",
                backgroundColor: "ghostwhite",
                boxShadow: "2px 2px 9px #000000",
                zIndex: "999",
                flexWrap: "wrap",
                paddingBottom: "2vh",
              }}
            >
              <div
                style={{
                  height: "5vh",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#5E0516",
                  boxShadow: "2px 2px 9px #000000",
                }}
              >
                <h3 style={{ color: "#eed77a" }}>{`Edit CEF Member Info:`}</h3>
              </div>

              <div
                style={{
                  height: "auto",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  // backgroundColor: "lightgrey",
                  paddingTop: "4vh",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    height: "3vh",
                    width: mobile ? "90%" : "30vw",
                    alignItems: "center",
                    justifyContent: "right",
                    marginBottom: "2vh",
                    // backgroundColor:'red',
                    marginRight: mobile ? "0vw" : "2vw",
                  }}
                >
                  <label
                    style={{
                      height: mobile ? "3vh" : "2vh",
                      marginRight: "2vw",
                      textJustify: "auto",
                      paddingBottom: ".5vh",
                    }}
                  >
                    Rank:
                  </label>
                  <select
                    type="text"
                    style={{
                      width: mobile ? "47vw" : "15.5vw",
                      height: "2.5vh",
                      marginRight: mobile ? "4vw" : "",
                    }}
                    onChange={(e) => setTempRank(e.target.value)}
                  >
                    <option
                      value="Unknown"
                      selected={tempRank == "Unknown"}
                      id="Unknown"
                    >
                      Unknown
                    </option>
                    <option
                      value="Recruit"
                      selected={tempRank == "Recruit"}
                      id="Recruit"
                    >
                      Recruit
                    </option>
                    <option
                      value="Private"
                      selected={tempRank == "Private"}
                      id="Private"
                    >
                      Private
                    </option>
                    <option
                      value="Lance Corporal"
                      selected={tempRank == "Lance Corporal"}
                      id="Lance Corporal"
                    >
                      Lance Corporal
                    </option>
                    <option
                      value="Corporal"
                      selected={tempRank == "Corporal"}
                      id="Corporal"
                    >
                      Corporal
                    </option>
                    <option
                      value="Sergeant"
                      selected={tempRank == "Sergeant"}
                      id="Sergeant"
                    >
                      Sergeant
                    </option>
                    <option
                      value="Warrant Officer"
                      selected={tempRank == "Warrant Officer"}
                      id="Force Sergeant Major"
                    >
                      Force Sergeant Major
                    </option>
                    <option
                      value="Warrant Officer"
                      selected={tempRank == "Warrant Officer"}
                      id="Warrant Officer"
                    >
                      Warrant Officer
                    </option>
                    <option
                      value="Chief Warrant Officer"
                      selected={tempRank == "Chief Warrant Officer"}
                      id="Cheif Warrant Officer"
                    >
                      Chief Warrant Officer
                    </option>
                    <option
                      value="2nd Lieutenant"
                      selected={tempRank == "2nd Lieutenant"}
                      id="2nd Lieutenant"
                    >
                      2nd Lieutenant
                    </option>
                    <option
                      value="Lieutenant"
                      selected={tempRank == "Lieutenant"}
                      id="Lieutenant"
                    >
                      Lieutenant
                    </option>
                    <option
                      value="Captain"
                      selected={tempRank == "Captain"}
                      id="Captain"
                    >
                      Captain
                    </option>
                    <option
                      value="Major"
                      selected={tempRank == "Major"}
                      id="Major"
                    >
                      Major
                    </option>
                    <option
                      value="Lieutenant Colonel"
                      selected={tempRank == "Lieutenant Colonel"}
                      id="Lieutenant Colonel"
                    >
                      Lieutenant Colonel
                    </option>
                    <option
                      value="Colonel"
                      selected={tempRank == "Colonel"}
                      id="Colonel"
                    >
                      Colonel
                    </option>
                    <option
                      value="Brigadier"
                      selected={tempRank == "Brigadier"}
                      id="Brigadier"
                    >
                      Brigadier
                    </option>
                    <option
                      value="Major General"
                      selected={tempRank == "Major General"}
                      id="Major General"
                    >
                      Major General
                    </option>
                    <option
                      value="Lieutenant General"
                      selected={tempRank == "Lieutenant General"}
                      id="Lieutenant General"
                    >
                      Lieutenant General
                    </option>
                    <option
                      value="General"
                      selected={tempRank == "General"}
                      id="General"
                    >
                      General
                    </option>
                  </select>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    height: "3vh",
                    width: mobile ? "90%" : "30vw",
                    alignItems: "center",
                    justifyContent: "right",
                    marginBottom: "2vh",
                    // backgroundColor:'red',
                    marginRight: mobile ? "0vw" : "2vw",
                  }}
                >
                  <label
                    style={{
                      height: mobile ? "3vh" : "2vh",
                      marginRight: "2vw",
                      textJustify: "auto",
                      paddingBottom: ".5vh",
                    }}
                  >
                    Unit:
                  </label>
                  <select
                    type="text"
                    style={{
                      width: mobile ? "47vw" : "15.5vw",
                      height: "2.5vh",
                      marginRight: mobile ? "4vw" : "",
                    }}
                    onChange={(e) => setTempUnit(e.target.value)}
                  >
                    <option
                      value="Unknown"
                      selected={tempUnit == "Unknown"}
                      id="Unknown"
                    >
                      Unknown
                    </option>
                    <option
                      value="13/18 Hussars"
                      selected={tempUnit == "13/18 Hussars"}
                      id="13/18 Hussars"
                    >
                      13/18 Hussars (D-Day)
                    </option>
                    <option
                      value="13/18 Light Dragoons"
                      id="13/18 Light Dragoons"
                      selected={tempUnit == "13/18 Light Dragoons"}
                    >
                      13/18 Light Dragoons
                    </option>
                    <option
                      value="East Yorkshire Regiment"
                      id="East Yorkshire Regiment"
                      selected={tempUnit == "East Yorkshire Regiment"}
                    >
                      East Yorkshire Regiment
                    </option>
                    <option
                      value="41 Royal Marines Commando"
                      id="41 Royal Marines Commando"
                      selected={tempUnit == "41 Royal Marines Commando"}
                    >
                      41 Royal Marines Commando
                    </option>
                    <option
                      value="Royal Winnipeg Rifles"
                      id="Royal Winnipeg Rifles"
                      selected={tempUnit == "Royal Winnipeg Rifles"}
                    >
                      Royal Winnipeg Rifles
                    </option>
                    <option
                      value="Canadian Scottish Regiment"
                      id="Canadian Scottish Regiment"
                      selected={tempUnit == "Canadian Scottish Regiment"}
                    >
                      Canadian Scottish Regiment
                    </option>
                    <option
                      value="9th British Parachute Regiment"
                      id="9th British Parachute Regiment"
                      selected={tempUnit == "9th British Parachute Regiment"}
                    >
                      9th British Parachute Regiment
                    </option>
                    <option
                      value="1st Canadian Parachute Battalion"
                      id="1st Canadian Parachute Battalion"
                      selected={tempUnit == "1st Canadian Parachute Battalion"}
                    >
                      1st Canadian Parachute Battalion
                    </option>
                    <option
                      value="Royal Air Force"
                      id="Royal Air Force"
                      selected={tempUnit == "Royal Air Force"}
                    >
                      Royal Air Force
                    </option>
                    <option
                      value="6th Airbourne Division"
                      id="6th Airbourne Division"
                      selected={tempUnit == "6th Airbourne Division"}
                    >
                      6th Airbourne Division
                    </option>
                    <option
                      value="3rd Infantry Division"
                      id="3rd Infantry Division"
                      selected={tempUnit == "3rd Infantry Division"}
                    >
                      3rd Infantry Division
                    </option>
                    <option
                      value="Royal Scots Dragoon Guards (Fulda Gap)"
                      id="Royal Scots Dragoon Guards (Fulda Gap)"
                      selected={
                        tempUnit == "Royal Scots Dragoon Guards (Fulda Gap)"
                      }
                    >
                      Royal Scots Dragoon Guards (Fulda Gap)
                    </option>
                    <option
                      value="CEF High Command"
                      selected={tempUnit == "CEF High Command"}
                      id="CEF High Command"
                    >
                      CEF High Command
                    </option>
                  </select>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    height: "3vh",
                    width: mobile ? "90%" : "30vw",
                    alignItems: "center",
                    justifyContent: "right",
                    marginBottom: "2vh",
                    // backgroundColor:'red',
                    marginRight: mobile ? "0vw" : "2vw",
                  }}
                >
                  <label
                    style={{
                      height: mobile ? "3vh" : "2vh",
                      marginRight: "2vw",
                      textJustify: "auto",
                      paddingBottom: ".5vh",
                    }}
                  >
                    Company:
                  </label>
                  <select
                    type="text"
                    style={{
                      width: mobile ? "47vw" : "15.5vw",
                      height: "2.5vh",
                      marginRight: mobile ? "4vw" : "",
                    }}
                    onChange={(e) => setTempCompany(e.target.value)}
                  >
                    <option
                      value="N/A"
                      selected={tempCompany == "N/A"}
                      id="Unknown"
                    >
                      N/A
                    </option>
                    <option
                      value="ABLE"
                      selected={tempCompany == "ABLE"}
                      id="ABLE"
                    >
                      ABLE
                    </option>
                    <option
                      value="BAKER"
                      selected={tempCompany == "BAKER"}
                      id="BAKER"
                    >
                      BAKER
                    </option>
                    <option
                      value="CHARLES"
                      selected={tempCompany == "CHARLES"}
                      id="CHARLES"
                    >
                      CHARLES
                    </option>
                    <option
                      value="DOG"
                      selected={tempCompany == "DOG"}
                      id="DOG"
                    >
                      DOG
                    </option>
                    <option
                      value="ZULU"
                      selected={tempCompany == "ZULU"}
                      id="ZULU"
                    >
                      ZULU
                    </option>
                    <option value="HQ" selected={tempCompany == "HQ"} id="HQ">
                      HQ
                    </option>
                  </select>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    height: "3vh",
                    width: mobile ? "90%" : "30vw",
                    alignItems: "center",
                    justifyContent: "right",
                    marginBottom: "2vh",
                    // backgroundColor:'red',
                    marginRight: mobile ? "0vw" : "2vw",
                  }}
                >
                  <label
                    style={{
                      height: mobile ? "3vh" : "2vh",
                      marginRight: "2vw",
                      textJustify: "auto",
                      paddingBottom: ".5vh",
                    }}
                  >
                    Section:
                  </label>
                  <select
                    type="text"
                    style={{
                      width: mobile ? "47vw" : "15.5vw",
                      height: "2.5vh",
                      marginRight: mobile ? "4vw" : "",
                    }}
                    onChange={(e) => setTempSection(e.target.value)}
                  >
                    <option value="N/A" id="Unknown">
                      N/A
                    </option>
                    <option
                      value="S1 - Personnel"
                      selected={tempSection == "S1 - Personnel"}
                      id="S1 - Personnel"
                    >
                      S1- Personnel
                    </option>
                    <option
                      value="S2 - Intellegence"
                      selected={tempSection == "S2 - Intellegence"}
                      id="S2 - Intellegence"
                    >
                      S2 - Intellegence
                    </option>
                    <option
                      value="S3 - Operations"
                      selected={tempSection == "S3 - Operations"}
                      id="S3 - Operations"
                    >
                      S3 - Operations
                    </option>
                    <option
                      value="S4 - Logistics"
                      selected={tempSection == "S4 - Logistics"}
                      id="S4 - Logistics"
                    >
                      S4 - Logistics
                    </option>
                    <option
                      value="S5 - Plans"
                      selected={tempSection == "S5 - Plans"}
                      id="S5 - Plans"
                    >
                      S5 - Plans
                    </option>
                    <option
                      value="S6 - Communications"
                      selected={tempSection == "S6 - Communications"}
                      id="S6 - Communications"
                    >
                      S6 - Communications
                    </option>
                    <option
                      value="S7 - Training"
                      selected={tempSection == "S7 - Training"}
                      id="S7 - Training"
                    >
                      S7 - Training
                    </option>
                    <option
                      value="S8 - Finance"
                      selected={tempSection == "S8 - Finance"}
                      id="S8 - Finance"
                    >
                      S8 - Finance
                    </option>
                    <option
                      value="S9 - Media"
                      selected={tempSection == "S9 - Media"}
                      id="S9 - Media"
                    >
                      S9 - Media
                    </option>
                  </select>
                </div>
                <div
                  style={{
                    height: "10%",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <button
                    style={{ marginRight: "2vw" }}
                    onClick={(e) => props.handleEditMemberModal(e.target.value)}
                  >
                    Cancel
                  </button>
                  <button onClick={(e) => handlEditUser(e)}>Confirm</button>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </>
    );
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          backgroundColor: "#adc8e6",
          minHeight: mobile ? "100vh" : "",
        }}
      >
        <Header />
        <div
          style={{
            height: "88vh",
            width: "100%",
            display: "flex",
            flexDirection: mobile ? "column" : "row",
            alignItems: "center",
            flexGrow: mobile ? 1 : 0,
          }}
        >
          {user && user !== null ? (
            <>
              <UserSidebar />
            </>
          ) : null}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: mobile ? "70vh" : "80vh",
              alignItems: "center",
              overflowY: "scroll",
              paddingTop: "2vh",
              paddingBottom: "6vh",
            }}
          >
            <EditMemberModal
              editMemberOpen={editMemberOpen}
              handleEditMemberModal={handleEditMemberModal}
              // rank={rank}
              // unit={unit}
              // company={company}
              // section={section}
            />
            {members && members
              ? members.slice().map((row, index) => {
                  const {
                    id,
                    callsign,
                    name,
                    rank,
                    section,
                    state,
                    unit,
                    country,
                    city,
                    company,
                    profilePicUrl,
                    showName,
                    showLocation,
                  } = row;
                  return (
                    <>
                      <div
                        key={index}
                        style={{
                          height: mobile ? "18vh" : "20vh",
                          width: mobile ? "90vw" : "55vw",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "left",
                          marginTop: "1vh",
                          backgroundColor: "#5E0516",
                          boxShadow: "1px 1px 6px 2px black",
                          border: "solid",
                          borderWidth: "4px",
                          borderColor: "#eed77a",
                        }}
                      >
                        <div
                          style={{
                            width: mobile ? "28vw" : "15vw",
                            height: mobile ? "auto" : "18vh",
                            // backgroundColor:'grey',
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "self-start",
                            justifyContent: "center",
                            paddingTop: "1vh",
                            paddingBottom: "1vh",
                            paddingLeft: "1vw",
                            // marginLeft:'1vw'

                            // border: "solid",
                            // borderWidth: "4px",
                            // borderColor: "#5E0516",
                          }}
                        >
                          {profilePicUrl && profilePicUrl ? (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: mobile ? "20vw" : "auto",
                                  height: mobile ? "10vh" : "15vh",
                                  boxShadow: "2px 2px 9px #000000",
                                  border: "solid",
                                  borderWidth: "4px",
                                  borderColor: "#eed77a",
                                  overflow: "hidden",
                                  marginLeft: "1vw",
                                  alignItems: "center",
                                  // justifyContent:'center',
                                }}
                              >
                                <img
                                  style={{
                                    width: mobile ? "25vw" : "auto",
                                    height: mobile ? "auto" : "15vh",
                                    minHeight: "10vh",
                                    // boxShadow: "2px 2px 9px #000000",
                                    // border: "solid",
                                    // borderWidth: "4px",
                                    // borderColor: "#eed77a",
                                    // marginLeft: "1vw",
                                  }}
                                  src={profilePicUrl}
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              <img
                                style={{
                                  width: mobile ? "20vw" : "auto",
                                  height: mobile ? "auto" : "15vh",
                                  boxShadow: "2px 2px 9px #000000",
                                  border: "solid",
                                  borderWidth: "4px",
                                  borderColor: "#eed77a",
                                  marginLeft: "1vw",
                                }}
                                src={logo}
                              />
                            </>
                          )}
                        </div>

                        {mobile ? (
                          <>
                            <div
                              style={{
                                width: "auto",
                                height: mobile ? "auto" : "18vh",
                                // backgroundColor:'black',
                                display: "flex",
                                flexDirection: "column",
                                // marginLeft:'1vw'
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  height: "auto",
                                  // width: "15vw",
                                  alignItems: "center",
                                  justifyContent: "left",

                                  // backgroundColor:'lightsalmon',
                                  marginRight: mobile ? "0vw" : "20vw",
                                  // marginTop:'2vh'
                                }}
                              >
                                <label
                                  style={{
                                    fontSize: mobile ? ".7rem" : ".8rem",
                                    color: "#eed77a",
                                    fontWeight: 700,
                                    marginRight: "1vw",
                                  }}
                                >
                                  Name:
                                </label>
                                <label
                                  style={{
                                    fontSize: mobile ? ".7rem" : ".8rem",
                                    color: "#eed77a",
                                    fontWeight: 600,
                                  }}
                                >
                                  {`${name}`}
                                </label>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  height: "auto",
                                  // width: "15vw",
                                  alignItems: "center",
                                  justifyContent: "left",

                                  // backgroundColor:'lightsalmon',
                                  marginRight: mobile ? "0vw" : "20vw",
                                  // marginTop:'2vh'
                                }}
                              >
                                <label
                                  style={{
                                    fontSize: mobile ? ".7rem" : ".8rem",
                                    color: "#eed77a",
                                    fontWeight: 700,
                                    marginRight: "1vw",
                                  }}
                                >
                                  Callsign:
                                </label>
                                <label
                                  style={{
                                    fontSize: mobile ? ".7rem" : ".8rem",
                                    color: "#eed77a",
                                    fontWeight: 600,
                                  }}
                                >
                                  {`${callsign}`}
                                </label>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  height: "auto",
                                  alignItems: "center",
                                  justifyContent: "left",
                                  marginRight: mobile ? "0vw" : "20vw",
                                }}
                              >
                                <label
                                  style={{
                                    fontSize: mobile ? ".7rem" : ".8rem",
                                    color: "#eed77a",
                                    fontWeight: 700,
                                    marginRight: "1vw",
                                  }}
                                >
                                  Rank:
                                </label>
                                <label
                                  style={{
                                    fontSize: mobile ? ".7rem" : ".8rem",
                                    color: "#eed77a",
                                    fontWeight: 600,
                                  }}
                                >
                                  {`${rank}`}
                                </label>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  height: "3vh",
                                  // width: "15vw",
                                  alignItems: "center",
                                  justifyContent: "left",

                                  // backgroundColor:'lightsalmon',
                                  marginRight: mobile ? "0vw" : "20vw",
                                }}
                              >
                                <label
                                  style={{
                                    fontSize: mobile ? ".7rem" : ".8rem",
                                    color: "#eed77a",
                                    fontWeight: 700,
                                    marginRight: "1vw",
                                  }}
                                >
                                  Unit:
                                </label>
                                <label
                                  style={{
                                    fontSize: mobile ? ".6rem" : ".8rem",
                                    color: "#eed77a",
                                    fontWeight: 600,
                                  }}
                                >
                                  {`${unit}`}
                                </label>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  height: "2vh",
                                  // width: "15vw",
                                  alignItems: "center",
                                  justifyContent: "left",

                                  // backgroundColor:'lightsalmon',
                                  marginRight: mobile ? "0vw" : "20vw",
                                }}
                              >
                                <label
                                  style={{
                                    fontSize: mobile ? ".7rem" : ".8rem",
                                    color: "#eed77a",
                                    fontWeight: 700,
                                    marginRight: "1vw",
                                  }}
                                >
                                  Section:
                                </label>
                                <label
                                  style={{
                                    fontSize: mobile ? ".7rem" : ".8rem",
                                    color: "#eed77a",
                                    fontWeight: 600,
                                  }}
                                >
                                  {`${section}`}
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              style={{
                                width: mobile ? "32vw" : "15vw",
                                height: "18vh",
                                // backgroundColor:'black',
                                display: "flex",
                                flexDirection: "column",
                                marginLeft: "1vw",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  height: "3vh",
                                  width: "15vw",
                                  alignItems: "center",
                                  justifyContent: "left",

                                  // backgroundColor:'lightsalmon',
                                  marginRight: mobile ? "0vw" : "20vw",
                                  marginTop: "2vh",
                                }}
                              >
                                <label
                                  style={{
                                    fontSize: ".8rem",
                                    fontWeight: 700,
                                    marginRight: "1vw",
                                    color: "#eed77a",
                                  }}
                                >
                                  Name:
                                </label>
                                <label
                                  style={{
                                    fontSize: ".8rem",
                                    color: "#eed77a",
                                    fontWeight: 600,
                                  }}
                                >
                                  {showName ? name : "Hidden"}
                                </label>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  height: "3vh",
                                  width: "15vw",
                                  alignItems: "center",
                                  justifyContent: "left",

                                  // backgroundColor:'lightsalmon',
                                  marginRight: mobile ? "0vw" : "20vw",
                                  marginTop: "2vh",
                                }}
                              >
                                <label
                                  style={{
                                    fontSize: ".8rem",
                                    fontWeight: 700,
                                    color: "#eed77a",
                                    marginRight: "1vw",
                                  }}
                                >
                                  Callsign:
                                </label>
                                <label
                                  style={{
                                    fontSize: ".8rem",
                                    color: "#eed77a",
                                    fontWeight: 600,
                                  }}
                                >
                                  {`${callsign}`}
                                </label>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  height: "3vh",
                                  width: "15vw",
                                  alignItems: "center",
                                  justifyContent: "left",

                                  // backgroundColor:'lightsalmon',
                                  marginRight: mobile ? "0vw" : "20vw",
                                  marginTop: "2vh",
                                }}
                              >
                                <label
                                  style={{
                                    fontSize: ".8rem",
                                    color: "#eed77a",
                                    fontWeight: 700,
                                    marginRight: "1vw",
                                  }}
                                >
                                  Location:
                                </label>
                                <label
                                  style={{
                                    fontSize: ".8rem",
                                    color: "#eed77a",
                                    fontWeight: 600,
                                  }}
                                >
                                  {showLocation
                                    ? `${state}, ${country}`
                                    : "Parts Unknown"}
                                </label>
                              </div>
                            </div>

                            <div
                              style={{
                                width: "18vw",
                                height: "18vh",
                                // backgroundColor:'black',
                                display: "flex",
                                flexDirection: "column",
                                marginLeft: "1vw",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  height: "3vh",
                                  width: "18vw",
                                  alignItems: "center",
                                  justifyContent: "left",

                                  // backgroundColor:'lightsalmon',
                                  marginRight: mobile ? "0vw" : "20vw",
                                  marginTop: "2vh",
                                }}
                              >
                                <label
                                  style={{
                                    fontSize: ".8rem",
                                    color: "#eed77a",
                                    fontWeight: 700,
                                    marginRight: "1vw",
                                  }}
                                >
                                  Rank:
                                </label>
                                <label
                                  style={{
                                    fontSize: ".8rem",
                                    color: "#eed77a",
                                    fontWeight: 600,
                                  }}
                                >
                                  {`${rank}`}
                                </label>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  height: "3vh",
                                  width: "18vw",
                                  alignItems: "center",
                                  justifyContent: "left",

                                  // backgroundColor:'lightsalmon',
                                  marginRight: mobile ? "0vw" : "20vw",
                                  marginTop: "2vh",
                                }}
                              >
                                <label
                                  style={{
                                    fontSize: ".8rem",
                                    color: "#eed77a",
                                    fontWeight: 700,
                                    marginRight: "1vw",
                                  }}
                                >
                                  Unit:
                                </label>
                                <label
                                  style={{
                                    width: "auto",
                                    fontSize: ".8rem",
                                    color: "#eed77a",
                                    fontWeight: 600,
                                  }}
                                >
                                  {`${unit}`}
                                </label>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  height: "3vh",
                                  width: "15vw",
                                  alignItems: "center",
                                  justifyContent: "left",

                                  // backgroundColor:'lightsalmon',
                                  marginRight: mobile ? "0vw" : "20vw",
                                  marginTop: "2vh",
                                }}
                              >
                                <label
                                  style={{
                                    fontSize: ".8rem",
                                    color: "#eed77a",
                                    fontWeight: 700,
                                    marginRight: "1vw",
                                  }}
                                >
                                  Section:
                                </label>
                                <label
                                  style={{
                                    fontSize: ".8rem",
                                    color: "#eed77a",
                                    fontWeight: 600,
                                  }}
                                >
                                  {`${section}`}
                                </label>
                              </div>
                            </div>
                          </>
                        )}
                        {user && user.role === "admin" && !mobile ? (
                          <>
                            <div
                              style={{
                                width: "3vw",
                                height: "18vh",
                                display: "flex",
                                flexDirection: "column",
                                // backgroundColor:'grey',
                                marginLeft: "1.5vw",
                              }}
                            >
                              <button
                                style={{
                                  marginRight: "1vh",
                                }}
                                onClick={(e) =>
                                  handleEditMemberModal(
                                    e,
                                    id,
                                    rank,
                                    unit,
                                    company,
                                    section,
                                  )
                                }
                              >
                                Edit
                              </button>
                            </div>
                          </>
                        ) : null}
                      </div>
                    </>
                  );
                })
              : null}
          </div>
        </div>
        <Footer />
        {/* {!redirect ? null : <Navigate push to="/login" />} */}
      </div>
    </>
  );
};
