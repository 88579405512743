import React, { useState, useContext, useEffect } from "react";
import { DataContext } from "../../common/DataContext";

export const ContactText = () => {
  const { fetchContactInfo, address, phone, email } = useContext(DataContext);

  useEffect(() => {
    fetchContactInfo();
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "80vh",
        }}
      >
        <h1 style={{ paddingTop: "8vh", textAlign: "center", color: "black" }}>
          Contact
        </h1>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
            width: "90%",
            height: "90%",
            backgroundColor: "ghostwhite",
            boxShadow: "1px 1px 6px 2px black",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "top",
              width: "100%",
            }}
          >
            <h2
              style={{
                width: "40%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "right",
                marginRight: "2vw",
              }}
            >
              <strong>Address:</strong>
            </h2>

            <h2>{address}</h2>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "top",
              width: "100%",
            }}
          >
            <h2
              style={{
                width: "40%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "right",
                marginRight: "2vw",
              }}
            >
              <strong>Phone:</strong>
            </h2>

            <h2>{phone}</h2>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "top",
              width: "100%",
            }}
          >
            <h2
              style={{
                width: "40%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "right",
                marginRight: "2vw",
              }}
            >
              <strong>Email:</strong>
            </h2>

            <h2>{email}</h2>
          </div>
        </div>
      </div>
    </>
  );
};
