import React from "react";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { ContactText } from "./contactText";
import "./styles.css";

export const Contact = () => {
  return (
    <>
      <Header />
      <div className="contactPage">
        <div>
          <ContactText />
        </div>
      </div>
      <Footer />
    </>
  );
};
