import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../common/DataContext";
import badges_clip from "../../static/badges.mp3";
import { Tooltip } from "@mui/material";
import { countryList } from "../../components/common";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

export const Register_page_1 = (props) => {
  const {
    mobile,
    showName,
    toggleShowName,
    show_location,
    toggleShowLocation,
  } = useContext(DataContext);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleToggleShowName = () => {
    toggleShowName();
  };

  const handleToggleShowLocation = () => {
    toggleShowLocation();
  };

  return (
    <>
      <div
        style={
          mobile && mobile === false
            ? {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                height: "2vh",
                width: "100%",
                marginBottom: "3vh",
              }
            : {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "2vh",
                width: "100vw",
                marginBottom: "5vh",
              }
        }
      >
        {mobile && mobile == false ? (
          <>
            <h2>Page 1 - Contact Information</h2>
          </>
        ) : (
          <>
            <h4 style={{ marginBottom: "0vh" }}>
              Page 1 - Contact Information
            </h4>
          </>
        )}
        <label style={{ marginBottom: "2vh" }}>
          <strong>* Required Field</strong>
        </label>
      </div>

      <div
        style={
          mobile && mobile === false
            ? {
                display: "flex",
                flexDirection: "row",
                height: "auto",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }
            : {
                display: "flex",
                flexDirection: "row",
                height: "65%",
                width: "100vw",
                alignItems: "center",
              }
        }
      >
        <div
          style={
            mobile && mobile === false
              ? {
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  width: "auto",
                  alignItems: "self-end",
                  // backgroundColor:'blue'
                }
              : {
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  width: "100vw",
                  alignItems: "self-end",
                  // backgroundColor:'blue'
                }
          }
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "3vh",
              width: "100vw",
              alignItems: "center",
              justifyContent: "right",
              marginBottom: "2vh",
              marginRight: mobile ? "0vw" : "20vw",
            }}
          >
            <label
              style={{
                height: mobile ? "3vh" : "2vh",
                marginRight: "2vw",
                textJustify: "auto",
                paddingBottom: ".5vh",
              }}
            >
              First Name*:
            </label>

            <input
              type="text"
              style={{ width: "45vw", height: "2vh", marginRight: "4vw" }}
              value={props.firstName}
              onChange={(e) => props.handleSetFirstName(e)}
              id=""
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "3vh",
              width: "100vw",
              alignItems: "center",
              justifyContent: "right",
              marginBottom: "2vh",
              marginRight: mobile ? "0vw" : "20vw",
            }}
          >
            <label
              style={{
                height: "3vh",
                marginRight: "2vw",
                textJustify: "auto",
                paddingBottom: ".5vh",
              }}
            >
              Last Name*:
            </label>

            <input
              type="text"
              style={{ width: "45vw", height: "2vh", marginRight: "4vw" }}
              value={props.lastName}
              onChange={(e) => props.handleSetLastName(e)}
              id=""
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "3vh",
              width: "100vw",
              alignItems: "center",
              justifyContent: "right",
              marginBottom: "2vh",
              marginRight: mobile ? "0vw" : "20vw",
            }}
          >
            <label
              style={{
                height: "3vh",
                marginRight: "2vw",
                textJustify: "auto",
                paddingBottom: ".5vh",
              }}
            >
              Middle Inital:
            </label>

            <input
              type="text"
              style={{ width: "45vw", height: "2vh", marginRight: "4vw" }}
              value={props.middleName}
              onChange={(e) => props.handleSetMiddleName(e)}
              id=""
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "3vh",
              width: "100vw",
              alignItems: "center",
              justifyContent: "right",
              marginBottom: "2vh",
              marginRight: mobile ? "0vw" : "20vw",
            }}
          >
            <label
              style={{
                height: "3vh",
                marginRight: "2vw",
                textJustify: "auto",
                paddingBottom: ".5vh",
              }}
            >
              Street Address*:
            </label>

            <input
              type="text"
              style={{ width: "45vw", height: "2vh", marginRight: "4vw" }}
              value={props.address}
              onChange={(e) => props.handleSetAddress(e)}
              id=""
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "3vh",
              width: "100vw",
              alignItems: "center",
              justifyContent: "right",
              marginBottom: "2vh",
              marginRight: mobile ? "0vw" : "20vw",
            }}
          >
            <label
              style={{
                height: "3vh",
                marginRight: "2vw",
                textJustify: "auto",
                paddingBottom: ".5vh",
              }}
            >
              Apt/Unit:
            </label>

            <input
              type="text"
              style={{ width: "45vw", height: "2vh", marginRight: "4vw" }}
              value={props.apartmentNumber}
              onChange={(e) => props.handleSetUnitNumber(e)}
              id=""
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "3vh",
              width: "100vw",
              alignItems: "center",
              justifyContent: "right",
              marginBottom: "2vh",
              marginRight: mobile ? "0vw" : "20vw",
            }}
          >
            <label
              style={{
                height: "3vh",
                marginRight: "2vw",
                textJustify: "auto",
                paddingBottom: ".5vh",
              }}
            >
              City*:
            </label>

            <input
              type="text"
              style={{ width: "45vw", height: "2vh", marginRight: "4vw" }}
              value={props.city}
              onChange={(e) => props.handleSetCity(e)}
              id=""
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "3vh",
              width: "100vw",
              alignItems: "center",
              justifyContent: "right",
              marginBottom: "2vh",
              marginRight: mobile ? "0vw" : "20vw",
            }}
          >
            <label
              style={{
                height: "3vh",
                marginRight: "2vw",
                textJustify: "auto",
                paddingBottom: ".5vh",
              }}
            >
              Province/State*:
            </label>

            <input
              type="text"
              style={{ width: "45vw", height: "2vh", marginRight: "4vw" }}
              value={props.state}
              placeholder="Spelled out i.e., California"
              onChange={(e) => props.handleSetState(e)}
              id=""
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "3vh",
              width: "100vw",
              alignItems: "center",
              justifyContent: "right",
              marginBottom: "2vh",
              marginRight: mobile ? "0vw" : "20vw",
            }}
          >
            <label
              style={{
                height: "3vh",
                marginRight: "2vw",
                textJustify: "auto",
                paddingBottom: ".5vh",
              }}
            >
              Country*:
            </label>

            {/* <input
              type="text"
              style={{ width: "45vw", height: "2vh", marginRight: "4vw" }}
              value={props.country}
              onChange={(e) => props.handleSetCountry(e)}
              id=""
            /> */}
            <select
              id="countrySelect"
              style={{ width: "45.5vw", height: "2.5vh", marginRight: "4vw" }}
              value={props.country}
              onChange={(e) => props.handleSetCountry(e)}
            >
              {countryList.map((countryoption) => (
                <option
                  key={countryoption}
                  value={countryoption}
                  selected={countryoption === props.country}
                >
                  {countryoption}
                </option>
              ))}
            </select>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "3vh",
              width: "100vw",
              alignItems: "center",
              justifyContent: "right",
              marginBottom: "2vh",
              marginRight: mobile ? "0vw" : "20vw",
            }}
          >
            <label
              style={{
                height: "3vh",
                marginRight: "2vw",
                textJustify: "auto",
                paddingBottom: ".5vh",
              }}
            >
              ZIP/Postal Code*
            </label>

            <input
              type="text"
              style={{ width: "45vw", height: "2vh", marginRight: "4vw" }}
              value={props.zip}
              onChange={(e) => props.handleSetZip(e)}
              id=""
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "3vh",
              width: "100vw",
              alignItems: "center",
              justifyContent: "right",
              marginBottom: "2vh",
              marginRight: mobile ? "0vw" : "20vw",
            }}
          >
            <label
              style={{
                height: "3vh",
                marginRight: "2vw",
                textJustify: "auto",
                paddingBottom: ".5vh",
              }}
            >
              Email*:
            </label>

            <input
              type="text"
              style={{ width: "45vw", height: "2vh", marginRight: "4vw" }}
              value={props.email}
              onChange={(e) => props.handleSetEmail(e)}
              id=""
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "3vh",
              width: "100vw",
              alignItems: "center",
              justifyContent: "right",
              marginBottom: "2vh",
              marginRight: mobile ? "0vw" : "20vw",
            }}
          >
            <label
              style={{
                height: "3vh",
                marginRight: "2vw",
                textJustify: "auto",
                paddingBottom: ".5vh",
              }}
            >
              Emergency Contact*:
            </label>

            <input
              type="text"
              style={{ width: "45vw", height: "2vh", marginRight: "4vw" }}
              placeholder="Name & Email or Phone*"
              onChange={(e) => props.handleSetEmergencyContact(e)}
              id=""
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "3vh",
              width: "100vw",
              alignItems: "center",
              justifyContent: "right",
              marginBottom: "2vh",
              marginRight: mobile ? "0vw" : "20vw",
            }}
          >
            <label
              style={{
                height: "3vh",
                marginRight: "2vw",
                textJustify: "auto",
                paddingBottom: ".5vh",
              }}
            >
              Phone Number*:
            </label>

            <PhoneInput
              style={{ width: "45.5vw", marginRight: "4vw" }}
              placeholder="Enter phone number"
              value={props.phone}
              onChange={(e) => props.handleSetPhone(e)}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "auto",
              width: "auto",
              alignItems: "center",
              justifyContent: "left",
              marginBottom: "2vh",
              marginRight: mobile ? "0vw" : "47.25vw",
              // backgroundColor:'lightgray'
            }}
          >
            <label
              style={{
                marginRight: "2vw",
                textJustify: "auto",
              }}
            >
              Privacy*:
            </label>
            <label
              style={{
                textJustify: "auto",
                marginRight: "1vw",
              }}
            >
              Name:
            </label>

            <label
              style={{
                textJustify: "auto",
              }}
            >
              Hide
            </label>
            <input
              onChange={(e) => handleToggleShowName(e)}
              checked={showName === false}
              type="radio"
            ></input>

            <label
              style={{
                textJustify: "auto",
              }}
            >
              Show
            </label>
            <input
              onChange={(e) => handleToggleShowName(e)}
              checked={showName === true}
              style={{
                marginRight: "3vw",
              }}
              type="radio"
            ></input>

            <label
              style={{
                textJustify: "auto",
                marginRight: "1vw",
              }}
            >
              Location:
            </label>

            <label
              style={{
                textJustify: "auto",
              }}
            >
              Hide
            </label>
            <input
              onChange={(e) => handleToggleShowLocation(e)}
              checked={show_location === false}
              type="radio"
            ></input>

            <label
              style={{
                textJustify: "auto",
              }}
            >
              Show
            </label>
            <input
              onChange={(e) => handleToggleShowLocation(e)}
              checked={show_location === true}
              type="radio"
            ></input>
          </div>
        </div>
      </div>
    </>
  );
};

export const Register_page_2 = (props) => {
  const { mobile } = useContext(DataContext);

  return (
    <>
      <div
        style={
          mobile && mobile === false
            ? {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "2vh",
                width: "100%",
                marginBottom: "3vh",
              }
            : {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "top",
                height: "100%",
                width: "100vw",
                marginBottom: "1vh",
                // backgroundColor:'grey'
              }
        }
      >
        <div
          style={
            mobile && mobile === false
              ? {
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "2vh",
                  width: "100%",
                  marginBottom: "3vh",
                }
              : {
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "auto",
                  width: "100vw",
                  marginBottom: "1vh",
                  // backgroundColor:'lightgray'
                }
          }
        >
          {mobile && mobile == false ? (
            <>
              <h2>Page 2 - Player & Unit Info</h2>
            </>
          ) : (
            <>
              <h4 style={{ marginBottom: "0vh", marginTop: "0vh" }}>
                Page 2 - Player & Unit Info
              </h4>
            </>
          )}
          <label style={{ marginBottom: "1vh" }}>
            <strong>* Required Field</strong>
          </label>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            height: "3vh",
            width: "100vw",
            alignItems: "center",
            justifyContent: "right",
            marginBottom: "2vh",
            marginRight: mobile ? "0vw" : "45vw",
          }}
        >
          <label
            style={{
              height: "3vh",
              marginRight: "2vw",
              textJustify: "auto",
              paddingBottom: ".5vh",
            }}
          >
            Callsign*:
          </label>

          <input
            type="text"
            style={{ width: "45vw", height: "2vh", marginRight: "4vw" }}
            value={props.callsign}
            onChange={(e) => props.handleSetCallsign(e)}
            id=""
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            height: "3vh",
            width: "100vw",
            alignItems: "center",
            justifyContent: "right",
            marginBottom: "2vh",
            marginRight: mobile ? "0vw" : "45vw",
          }}
        >
          <label
            style={{
              height: "3vh",
              marginRight: "2vw",
              textJustify: "auto",
              paddingBottom: ".5vh",
            }}
          >
            Age:
          </label>

          <input
            type="number"
            style={{ width: "45vw", height: "2vh", marginRight: "4vw" }}
            value={props.age}
            onChange={(e) => props.handleSetAge(e)}
            id=""
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            height: "3vh",
            width: "100vw",
            alignItems: "center",
            justifyContent: "right",
            marginBottom: "2vh",
            marginRight: mobile ? "0vw" : "45vw",
          }}
        >
          <label
            style={{
              height: "3vh",
              marginRight: "2vw",
              textJustify: "auto",
              paddingBottom: ".5vh",
            }}
          >
            Home Team:
          </label>

          <input
            type="text"
            style={{ width: "45vw", height: "2vh", marginRight: "4vw" }}
            value={props.homeTeam}
            onChange={(e) => props.handleSetHomeTeam(e)}
            id=""
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            height: "3vh",
            width: "100vw",
            alignItems: "center",
            justifyContent: "right",
            marginBottom: "2vh",
            marginRight: mobile ? "0vw" : "45vw",
          }}
        >
          <label
            style={{
              height: "3vh",
              marginRight: "2vw",
              textJustify: "auto",
              paddingBottom: ".5vh",
            }}
          >
            Rank:
          </label>
          <select
            type="text"
            style={{
              width: mobile ? "47vw" : "45.5vw",
              height: "3vh",
              marginRight: "4vw",
            }}
            onChange={(e) => props.handleSetRank(e)}
          >
            <option value="Unknown" id="Unknown">
              Unknown
            </option>
            <option value="Recruit" id="Recruit">
              Recruit
            </option>
            <option value="Private" id="Private">
              Private
            </option>
            <option value="Lance Corporal" id="Lance Corporal">
              Lance Corporal
            </option>
            <option value="Corporal" id="Corporal">
              Corporal
            </option>
            <option value="Sergeant" id="Sergeant">
              Sergeant
            </option>
            <option value="Force Sergeant Major" id="Force Sergeant Major">
              Force Sergeant Major
            </option>
            <option value="Warrant Officer" id="Warrant Officer">
              Warrant Officer
            </option>
            <option value="Chief Warrant Officer" id="Chief Warrant Officer">
              Chief Warrant Officer
            </option>
            <option value="2nd Lieutenant" id="2nd Lieutenant">
              2nd Lieutenant
            </option>
            <option value="Lieutenant" id="Lieutenant">
              Lieutenant
            </option>
            <option value="Captain" id="Captain">
              Captain
            </option>
            <option value="Major" id="Major">
              Major
            </option>
            <option value="Lieutenant Colonel" id="Lieutenant Colonel">
              Lieutenant Colonel
            </option>
            <option value="Colonel" id="Colonel">
              Colonel
            </option>
            <option value="Brigadier" id="Brigadier">
              Brigadier
            </option>
            <option value="Major General" id="Major General">
              Major General
            </option>
            <option value="Lieutenant General" id="Lieutenant General">
              Lieutenant General
            </option>
            <option value="General" id="General">
              General
            </option>
          </select>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            height: "3vh",
            width: "100vw",
            alignItems: "center",
            justifyContent: "right",
            marginBottom: "2vh",
            marginRight: mobile ? "0vw" : "45vw",
          }}
        >
          <label
            style={{
              height: "3vh",
              marginRight: "2vw",
              textJustify: "auto",
              paddingBottom: ".5vh",
            }}
          >
            Unit:
          </label>
          <select
            type="text"
            style={{
              width: mobile ? "47vw" : "45.5vw",
              height: "3vh",
              marginRight: "4vw",
            }}
            onChange={(e) => props.handleSetUnit(e)}
          >
            <option value="Unknown" id="Unknown">
              Unknown
            </option>
            <option value="13/18 Hussars" id="13/18 Hussars">
              13/18 Hussars (D-Day)
            </option>
            <option value="13/18 Light Dragoons" id="13/18 Light Dragoons">
              13/18 Light Dragoons
            </option>
            <option
              value="East Yorkshire Regiment"
              id="East Yorkshire Regiment"
            >
              East Yorkshire Regiment
            </option>
            <option
              value="41 Royal Marines Commando"
              id="41 Royal Marines Commando"
            >
              41 Royal Marines Commando
            </option>
            <option value="Royal Winnipeg Rifles" id="Royal Winnipeg Rifles">
              Royal Winnipeg Rifles
            </option>
            <option
              value="Canadian Scottish Regiment"
              id="Canadian Scottish Regiment"
            >
              Canadian Scottish Regiment
            </option>
            <option
              value="9th British Parachute Regiment"
              id="9th British Parachute Regiment"
            >
              9th British Parachute Regiment
            </option>
            <option
              value="1st Canadian Parachute Battalion"
              id="1st Canadian Parachute Battalion"
            >
              1st Canadian Parachute Battalion
            </option>
            <option value="Royal Air Force" id="Royal Air Force">
              Royal Air Force
            </option>
            <option value="6th Airbourne Division" id="6th Airbourne Division">
              6th Airbourne Division
            </option>
            <option value="3rd Infantry Division" id="3rd Infantry Division">
              3rd Infantry Division
            </option>
            <option
              value="Royal Scots Dragoon Guards (Fulda Gap)"
              id="Royal Scots Dragoon Guards (Fulda Gap)"
            >
              Royal Scots Dragoon Guards (Fulda Gap)
            </option>
            <option value="CEF High Command" id="CEF High Command">
              CEF High Command
            </option>
          </select>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            height: "3vh",
            width: "100vw",
            alignItems: "center",
            justifyContent: "right",
            marginBottom: "2vh",
            marginRight: mobile ? "0vw" : "45vw",
          }}
        >
          <label
            style={{
              height: "3vh",
              marginRight: "2vw",
              textJustify: "auto",
              paddingBottom: ".5vh",
            }}
          >
            Company(COY):
          </label>

          <select
            type="text"
            style={{
              width: mobile ? "47vw" : "45.5vw",
              height: "3vh",
              marginRight: "4vw",
            }}
            onChange={(e) => props.handleSetCompany(e)}
          >
            <option value="N/A" id="Unknown">
              N/A
            </option>
            <option value="ABLE" id="ABLE">
              ABLE
            </option>
            <option value="BAKER" id="BAKER">
              BAKER
            </option>
            <option value="CHARLES" id="CHARLES">
              CHARLES
            </option>
            <option value="DOG" id="DOG">
              DOG
            </option>
            <option value="ZULU" id="ZULU">
              ZULU
            </option>
            <option value="HQ" id="HQ">
              HQ
            </option>
          </select>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            height: "3vh",
            width: "100vw",
            alignItems: "center",
            justifyContent: "right",
            marginBottom: "2vh",
            marginRight: mobile ? "0vw" : "45vw",
          }}
        >
          <label
            style={{
              height: "3vh",
              marginRight: "2vw",
              textJustify: "auto",
              paddingBottom: ".5vh",
            }}
          >
            Section:
          </label>

          <select
            type="text"
            style={{
              width: mobile ? "47vw" : "45.5vw",
              height: "3vh",
              marginRight: "4vw",
            }}
            onChange={(e) => props.handleSetSection(e)}
          >
            <option value="N/A" id="Unknown">
              N/A
            </option>
            <option value="S1 - Personnel" id="S1 - Personnel">
              S1- Personnel
            </option>
            <option value="S2 - Intellegence" id="S2 - Intellegence">
              S2 - Intellegence
            </option>
            <option value="S3 - Operations" id="S3 - Operations">
              S3 - Operations
            </option>
            <option value="S4 - Logistics" id="S4 - Logistics">
              S4 - Logistics
            </option>
            <option value="S5 - Plans" id="S5 - Plans">
              S5 - Plans
            </option>
            <option value="S6 - Communications" id="S6 - Communications">
              S6 - Communications
            </option>
            <option value="S7 - Training" id="S7 - Training">
              S7 - Training
            </option>
            <option value="S8 - Finance" id="S8 - Finance">
              S8 - Finance
            </option>
            <option value="S9 - Media" id="S9 - Media">
              S9 - Media
            </option>
          </select>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            height: "3vh",
            width: "100vw",
            alignItems: "center",
            justifyContent: "right",
            marginBottom: "2vh",
            marginRight: mobile ? "0vw" : "45vw",
          }}
        >
          <label
            style={{
              height: "3vh",
              marginRight: "2vw",
              textJustify: "auto",
              paddingBottom: ".5vh",
            }}
          >
            Years experience:
          </label>

          <input
            type="number"
            style={{ width: "45vw", height: "2vh", marginRight: "4vw" }}
            value={props.years}
            onChange={(e) => props.handleSetYears(e)}
            id=""
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            height: "3vh",
            width: "100vw",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "1vh",
          }}
        >
          <label
            style={{
              height: "3vh",
              marginRight: "2vw",
              textJustify: "auto",
              paddingBottom: ".5vh",
            }}
          >
            Game Styles:
          </label>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "left",
            // backgroundColor:'green',
            justifyContent: "center",
            width: "80vw",
            height: "3vh",
            paddingLeft: "5vw",
            paddingRight: "5vw",
            marginBottom: "1vh",
          }}
        >
          <label>Speedball:</label>
          <input
            type="checkbox"
            value="Speedball"
            onChange={(e) => props.handleSetPlayTypes(e)}
            id=""
          />

          <label style={{ marginLeft: "1vh" }}>Woodsball:</label>
          <input
            type="checkbox"
            value="Woodsball"
            onChange={(e) => props.handleSetPlayTypes(e)}
            id=""
          />

          <label style={{ marginLeft: "1vh" }}>Scenario:</label>
          <input
            type="checkbox"
            value="Scenario"
            onChange={(e) => props.handleSetPlayTypes(e)}
            id=""
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "left",
            // backgroundColor:'green',
            justifyContent: "center",
            width: "80vw",
            height: "3vh",
            paddingLeft: "5vw",
            paddingRight: "5vw",

            paddingBottom: "2vw",

            marginBottom: "2vh",
          }}
        >
          <label style={{ marginLeft: "1vh" }}>Magfed:</label>
          <input
            type="checkbox"
            value="Magfed"
            onChange={(e) => props.handleSetPlayTypes(e)}
            id=""
          />
          <label style={{ marginLeft: "1vh" }}>Pump:</label>
          <input
            type="checkbox"
            value="Pump"
            onChange={(e) => props.handleSetPlayTypes(e)}
            id=""
          />
        </div>
      </div>
    </>
  );
};

export const Register_page_3 = (props) => {
  const { medals, fetchMedals, mobile } = useContext(DataContext);

  useEffect(() => {
    fetchMedals();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // eslint-disable-next-line
  }, [props.registerMedals]);

  return (
    <>
      <div
        style={
          mobile && mobile === false
            ? {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                height: "2vh",
                width: "100%",
                marginBottom: "3vh",
              }
            : {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "2vh",
                width: "100vw",
                marginBottom: "5vh",
              }
        }
      >
        {!mobile ? (
          <>
            <h2>Page 3 - Medals and Awards</h2>
          </>
        ) : (
          <>
            <h4 style={{ marginBottom: "0vh" }}>Page 3 - Medals and Awards</h4>
          </>
        )}

        <label style={{ marginBottom: "2vh" }}>Select all that apply</label>
      </div>

      <div
        style={{
          width: mobile ? "80vw" : "20vw",
          height: "62vh",
          display: "flex",
          flexDirection: "column",
          overflowY: "scroll",
          // backgroundColor:'#adc8e6',
          alignItems: mobile ? "center" : "",
          boxShadow: "1px 1px 6px 2px black",
          paddingLeft: "1vw",
          paddingRight: "1vw",
          paddingBottom: "1vh",
        }}
      >
        {medals && medals.length > 0
          ? medals.slice().map((row, index) => {
              const { id, name, url } = row;

              return (
                <>
                  <div
                    key={index}
                    style={{
                      height: "auto",
                      width: mobile ? "70vw" : "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "1vh",

                      backgroundColor: "#5E0516",
                      boxShadow: "1px 1px 6px 2px black",
                      border: "solid",
                      borderWidth: "4px",
                      borderColor: "#eed77a",
                    }}
                  >
                    <img
                      style={{
                        width: mobile ? "15vw" : "4.6vw",
                        height: "auto",
                        marginTop: "1vh",
                        paddingTop: "1vh",
                      }}
                      src={url}
                      alt={name} // Add alt text for accessibility
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <label
                        style={{
                          color: "#eed77a",
                          fontSize: "1remm",
                          fontWeight: 700,
                          padding: "1vw",
                        }}
                      >
                        {name}
                      </label>
                      <input
                        type="checkbox"
                        checked={
                          props.registerMedals &&
                          props.registerMedals.hasOwnProperty(id)
                        }
                        onChange={(e) => props.handleSetRegisterMedals(e)}
                        value={id}
                        style={{ marginRight: "1vw" }}
                      />
                    </div>
                    {props.registerMedals &&
                    props.registerMedals.hasOwnProperty(id) ? (
                      <textArea
                        style={{
                          width: "80%",
                          height: "10vh",
                          marginBottom: "1vh",
                        }}
                        placeholder={
                          "Tell us where, when, and how your medal was earned"
                        }
                        onChange={(e) => props.handleSetMedalDetails(e, id)}
                      />
                    ) : null}
                  </div>
                </>
              );
            })
          : null}
      </div>
    </>
  );
};

export const Register_page_4 = (props) => {
  const { badges, fetchBadges, mobile } = useContext(DataContext);

  useEffect(() => {
    if (badges === null) {
      if (!mobile) {
        var audio = new Audio(badges_clip);
        audio.play();
      }

      setTimeout(fetchBadges, 4000);
    }
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div
        style={
          mobile && mobile === false
            ? {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                height: "2vh",
                width: "100%",
                marginBottom: "3vh",
              }
            : {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "2vh",
                width: "100vw",
                marginBottom: "5vh",
              }
        }
      >
        {!mobile ? (
          <>
            <h2>Page 4 - CEF Badges</h2>
          </>
        ) : (
          <>
            <h4 style={{ marginBottom: "0vh" }}>Page 4 - CEF Badges</h4>
          </>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            width: mobile ? "100vw" : "30vw",
            height: "4vh",
            marginBottom: "1vh",
            // backgroundColor:'red'
          }}
        >
          <label>Select all that apply</label>
          <button
            onClick={() => props.handleClearRegisterBadges()}
            style={{
              marginLeft: "2vw",
              height: "3vh",
            }}
          >
            Clear Selections
          </button>
        </div>
      </div>

      <div
        style={{
          width: mobile ? "90vw" : "25vw",
          height: mobile ? "62vh" : "60vh",
          display: "flex",
          flexDirection: "column",
          alignItems: mobile ? "center" : "",
          overflowY: "scroll",
          backgroundColor: "#adc8e6",
          boxShadow: "1px 1px 6px 2px black",
          paddingLeft: "1vw",
          paddingRight: "1vw",
          paddingBottom: "1vh",
          marginBottom: "2vh",
        }}
      >
        {badges && badges.length > 0 ? (
          badges.slice().map((row, index) => {
            const {
              id,
              name,
              description,
              gold_url,
              silver_url,
              bronze_url,
              bronze_requirements,
              silver_requirements,
              gold_requirements,
            } = row;
            return (
              <>
                <div
                  key={index}
                  style={{
                    height: "Auto",
                    width: mobile ? "80vw" : "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "1vh",

                    backgroundColor: "#5E0516",
                    boxShadow: "1px 1px 6px 2px black",
                    border: "solid",
                    borderWidth: "4px",
                    borderColor: "#eed77a",
                  }}
                >
                  <label
                    style={{
                      color: "#eed77a",
                      fontSize: "1remm",
                      fontWeight: 700,
                      marginTop: "1vh",
                    }}
                  >
                    {name}
                  </label>
                  <p
                    style={{
                      height: "2vh",
                      color: "#eed77a",
                      fontSize: ".5remm",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                      fontWeight: 500,
                      marginTop: "1vh",
                      textAlign: "center",
                    }}
                  >{`Requirements:`}</p>
                  <p
                    style={{
                      marginTop: "0%",
                      height: "auto",
                      width: "95%",
                      color: "#eed77a",
                      fontSize: ".5remm",
                      fontWeight: 400,
                      textAlign: "center",
                    }}
                  >
                    {description}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "1vh",
                      }}
                    >
                      <Tooltip
                        placement="top"
                        title={bronze_requirements}
                        arrow
                      >
                        <img
                          style={{
                            width: mobile ? "25vw" : "6.6vw",
                            height: "auto",
                            padding: mobile ? "" : "1vh",
                          }}
                          src={bronze_url}
                          alt={name}
                        />
                      </Tooltip>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginBottom: "1vh",
                        }}
                      >
                        <label
                          style={{
                            color: "#eed77a",
                            fontSize: "1remm",
                            fontWeight: 700,
                          }}
                        >{`Bronze`}</label>
                        <input
                          value="Bronze"
                          checked={
                            props.registerBadges &&
                            props.registerBadges[`${id}`]?.level == "Bronze"
                          }
                          onChange={(e) => props.handleSetRegisterBadges(e, id)}
                          type="radio"
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "1vh",
                      }}
                    >
                      <Tooltip
                        placement="top"
                        title={silver_requirements}
                        arrow
                      >
                        <img
                          style={{
                            width: mobile ? "25vw" : "6.6vw",
                            height: "auto",
                            padding: mobile ? "" : "1vh",
                          }}
                          src={silver_url}
                          alt={name} // Add alt text for accessibility
                        />
                      </Tooltip>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginBottom: "1vh",
                        }}
                      >
                        <label
                          style={{
                            color: "#eed77a",
                            fontSize: "1remm",
                            fontWeight: 700,
                          }}
                        >{`Silver`}</label>
                        <input
                          value="Silver"
                          checked={
                            props.registerBadges &&
                            props.registerBadges[`${id}`]?.level == "Silver"
                          }
                          onChange={(e) => props.handleSetRegisterBadges(e, id)}
                          type="radio"
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "1vh",
                      }}
                    >
                      <Tooltip placement="top" title={gold_requirements} arrow>
                        <img
                          style={{
                            width: mobile ? "25vw" : "6.6vw",
                            height: "auto",
                            padding: mobile ? "" : "1vh",
                          }}
                          src={gold_url}
                          alt={name} // Add alt text for accessibility
                        />
                      </Tooltip>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginBottom: "1vh",
                        }}
                      >
                        <label
                          style={{
                            color: "#eed77a",
                            fontSize: "1remm",
                            fontWeight: 700,
                          }}
                        >{`Gold`}</label>
                        <input
                          value="Gold"
                          checked={
                            props.registerBadges &&
                            props.registerBadges[`${id}`]?.level == "Gold"
                          }
                          onChange={(e) => props.handleSetRegisterBadges(e, id)}
                          type="radio"
                        />
                      </div>
                    </div>
                  </div>

                  {props.registerBadges &&
                  props.registerBadges.hasOwnProperty(id) ? (
                    <textArea
                      style={{
                        width: "80%",
                        height: "10vh",
                        marginBottom: "1vh",
                      }}
                      placeholder={
                        "Tell us where, when, and how your Badge was earned"
                      }
                      onChange={(e) => props.handleSetBadgeDetails(e, id)}
                    />
                  ) : null}
                </div>
              </>
            );
          })
        ) : (
          <>
            <div
              style={{
                width: "auto",
                height: "50vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{ width: "30vh", height: "auto" }}
                src={
                  "https://printsmiths.nyc3.cdn.digitaloceanspaces.com/CEF/Badges/badges_meme.png"
                }
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export const Register_page_5 = (props) => {
  const { pastEvents, fetchPastEvents, mobile } = useContext(DataContext);

  useEffect(() => {
    fetchPastEvents();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // eslint-disable-next-line
  }, [props]);

  const chunkArray = (array, chunkSize) => {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  };
  return (
    <>
      <div
        style={
          mobile && mobile === false
            ? {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                height: "2vh",
                width: "100%",
                marginBottom: "3vh",
              }
            : {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "2vh",
                width: "100vw",
                marginBottom: "5vh",
              }
        }
      >
        {!mobile ? (
          <>
            <h2>Page 5 - CEF Sanctioned Events Attended</h2>
          </>
        ) : (
          <>
            <h4 style={{ marginBottom: "0vh" }}>
              Page 5 - CEF Sanctioned Events Attended
            </h4>
          </>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            width: mobile ? "100vw" : "30vw",
            height: "4vh",
            marginBottom: "1vh",
            // backgroundColor:'red'
          }}
        >
          <label>Select all that apply</label>
          <button
            onClick={() => props.handleClearRegisterBadges()}
            style={{
              marginLeft: "2vw",
              height: "3vh",
            }}
          >
            Clear Selections
          </button>
        </div>
      </div>

      <div
        style={{
          height: mobile ? "60vh" : "62vh",
          width: mobile ? "80vw" : "60%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          // justifyContent: "center",
          marginTop: "1vh",

          backgroundColor: "#5E0516",
          boxShadow: "1px 1px 6px 2px black",
          border: "solid",
          borderWidth: "4px",
          borderColor: "#eed77a",
          overflowY: "scroll",
          marginBottom: "2vh",
          paddingBottom: "2vh",
        }}
      >
        {pastEvents && pastEvents.length > 0 ? (
          pastEvents.slice().map((row, index) => {
            const { id, name, image_url, event_years } = row;

            return (
              <>
                <div
                  key={index}
                  style={{
                    height: "Auto",
                    width: mobile ? "60vw" : "50%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "1vh",

                    backgroundColor: "#5E0516",
                    boxShadow: "1px 1px 6px 2px black",
                    border: "solid",
                    borderWidth: "4px",
                    borderColor: "#eed77a",
                  }}
                >
                  <label
                    style={{
                      color: "#eed77a",
                      fontSize: "1remm",
                      fontWeight: 700,
                      marginTop: "1vh",
                    }}
                  >
                    {name}
                  </label>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {image_url && image_url ? (
                        <img
                          style={{
                            width: mobile ? "35vw" : "15vw",
                            height: "auto",
                            padding: "1vh",
                          }}
                          src={image_url}
                          alt={name} // Add alt text for accessibility
                        />
                      ) : (
                        <div
                          style={{
                            height: "2vh",
                          }}
                        ></div>
                      )}

                      <div>
                        {chunkArray(event_years, mobile ? 3 : 4).map(
                          (row, rowIndex) => (
                            <div
                              key={rowIndex}
                              style={{ display: "flex", marginBottom: "10px" }}
                            >
                              {row.map((year) => (
                                <div
                                  key={year}
                                  style={{
                                    marginRight: "10px",
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <label
                                    style={{
                                      color: "#eed77a",
                                      fontSize: "1remm",
                                      fontWeight: 700,
                                    }}
                                  >
                                    {year}
                                  </label>

                                  <input
                                    type="checkbox"
                                    value={year}
                                    checked={
                                      props.eventYears &&
                                      props.eventYears.hasOwnProperty(id) &&
                                      props.eventYears[`${id}`].includes(year)
                                    }
                                    // checked={props.registerBadges&&props.registerBadges[`${id}`]=='Gold'}
                                    onChange={(e) =>
                                      props.handleEventYearsChange(e, id)
                                    }
                                    style={{ marginLeft: "5px" }}
                                  />
                                </div>
                              ))}
                            </div>
                          ),
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* <input type="checkbox" onChange={(e)=>props.handleSetRegisterMedals(e)} value={id} style={{marginRight:'1vw'}}/> */}
              </>
            );
          })
        ) : (
          <>{/* <h1>BADGES!? WE DON'T NEED NO STINKING BADGES!</h1> */}</>
        )}
      </div>
    </>
  );
};

export const Register_page_6 = (props) => {
  const { mobile } = useContext(DataContext);
  return (
    <>
      <div
        style={
          mobile && mobile === false
            ? {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                height: "2vh",
                width: "100%",
                marginBottom: "3vh",
              }
            : {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "2vh",
                width: "100vw",
                marginBottom: "5vh",
              }
        }
      >
        {!mobile ? (
          <>
            <h2>Page 6 - Other Info</h2>
          </>
        ) : (
          <>
            <h4 style={{ marginBottom: "0vh", marginTop: "0vh" }}>
              Page 6 - Other Info
            </h4>
          </>
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "auto",
          alignItems: "center",
        }}
      >
        <h4>Real World Skills</h4>
        <textarea
          value={props.skills}
          onChange={() => props.handleSetSkills}
          style={{
            height: "20vh",
            width: "60%",
          }}
          Placeholder="Real world skills or qualifications of interest. Carpenter, electrician, doctor, lawyer, pilot, etc."
        />
        <h4>Other Information</h4>
        <textarea
          value={props.otherInfo}
          onChange={() => props.handleSetOtherInfo}
          style={{
            height: "20vh",
            width: "60%",
            marginBottom: "2vh",
          }}
          Placeholder="Any other details you would like to add to the record"
        />
      </div>
    </>
  );
};

export const Register_page_7 = (props) => {
  const { mobile } = useContext(DataContext);
  return (
    <>
      <div
        style={
          mobile && mobile === false
            ? {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                height: "2vh",
                width: "100%",
                marginBottom: "3vh",
              }
            : {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "2vh",
                width: "100vw",
                marginBottom: "5vh",
              }
        }
      >
        {!mobile ? (
          <>
            <h2>Page 7 - Login Info</h2>
          </>
        ) : (
          <>
            <h4 style={{ marginBottom: "0vh", marginTop: "0vh" }}>
              {" "}
              Page 7 - Login Info
            </h4>
          </>
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "auto",
          alignItems: "center",
        }}
      >
        <label style={{ marginBottom: "1vh" }}>{`${`Login Username:`}`} </label>
        <label style={{ marginBottom: "1vh" }}>
          {`${`(callsign or email recommended):`}`}{" "}
        </label>
        <input
          value={props.loginEmail}
          onChange={(e) => props.handleSetLoginEmail(e)}
          type="text"
          style={{
            height: "2vh",
            width: mobile ? "60vw" : "20%",
            marginBottom: "2vh",
          }}
        />
        <label>Password</label>

        <input
          value={props.password}
          onChange={(e) => props.handleSetPassword(e)}
          type="password"
          style={{
            height: "2vh",
            width: mobile ? "60vw" : "20%",
            marginBottom: "2vh",
          }}
        />
        <label>Confirm Password</label>
        <input
          value={props.checkPassword}
          onChange={(e) => props.handleSetCheckPassword(e)}
          type="password"
          style={{
            height: "2vh",
            width: mobile ? "60vw" : "20%",
            marginBottom: "2vh",
          }}
        />
        <label>Recruiter Code</label>
        <input
          value={props.code}
          onChange={(e) => props.handleSetCode(e)}
          type="text"
          style={{
            height: "2vh",
            width: mobile ? "60vw" : "20%",
            marginBottom: "2vh",
          }}
        />
      </div>
    </>
  );
};

// const BadgeDetailsModal=(props)=>{
//   return(
//     <>
//     {props&&props.open?
//         <>
//           <div
//             style={{
//               position:'fixed',

//               display:'flex',
//               flexDirection:"column",
//               width:'40vw',
//               height:'40vh',
//               backgroundColor:'grey',
//               zIndex:'9999'

//             }}
//           >
//               MODAL
//           </div>
//         </>
//         :
//         null
//     }

//     </>
//   )
// }
