import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { AuthContext } from "../../common/AuthContext";
import { DataContext } from "../../common/DataContext";
import logo from "../../static/NavIcon.png";
import { Tooltip } from "@mui/material";
import {
  EditPlayerInfoModal,
  MedalComponent,
  EventComponent,
  BadgeComponent,
  EditContactInfoModal,
  EditExperienceInfoModal,
  EditOtherInfoModal,
} from "./profileComponents";
import React, { useEffect, useContext, useState } from "react";
import { UserSidebar } from "../../components/common";
import { Navigate } from "react-router-dom";
import useToggle from "../../common/hooks/useToggle";
var newImage = false;
var editImage;
const ParagraphComponent = ({ text }) => {
  return (
    <div
      style={{
        whiteSpace: "pre-line",
        color: "#eed77a",
        textDecoration: "none",
        textAlign: "center",
        marginBottom: "1vh",
        fontWeight: 500,
      }}
    >
      {text}
    </div>
  );
};

export const UserProfile = () => {
  const { user, redirect, toggleRedirect } = useContext(AuthContext);

  const {
    callsign,
    rank,
    unit,
    fetchUser,
    city,
    state,
    country,
    address,
    email,
    phone,
    EmergencyContact,
    badgeInfo,
    formattedName,
    company,
    medalInfo,
    years,
    homeTeam,
    playTypes,
    playerEvents,
    setPlayerEvents,
    setBadgeInfo,
    otherInfo,
    skills,
    profilePicUrl,
    updateProfilePic,
    age,
    setAge,
    section,
    firstName,
    middleName,
    lastName,
    showName,
    showLocation,
    setMiddleName,
    setLastName,
    setFirstName,
    setCallsign,
    setRank,
    setUnit,
    setCompany,
    setSection,
    updatePlayerInfo,
    updateContactInfo,
    updateExperienceInfo,
    mobile,
    updateOtherInfo,
    handleSetNewProfilePic,

  } = useContext(DataContext);

  const [editPlayerInfoOpen, toggleEditPlayerInfoOpen] = useState(null);
  const [editContactInfoOpen, toggleEditContactInfoOpen] = useState(null);
  const [editExperienceInfoOpen, toggleEditExperienceInfoOpen] = useState(null);
  const [editOtherInfoOpen, toggleEditOtherInfoOpen] = useState(null);
  const [tempProfilePicUrl,setTempProfilePicUrl] = useState(null);

   const[editPlayerBadgesModalOpen,toggleEditPlayerBadgesModalOpen]=useToggle()

  useEffect(() => {
    toggleRedirect(false);
    if (!user || user === null) {
      toggleRedirect(true);
    } else {
      fetchUser();
      if(user&&user.profile_pic!==null){
        setTempProfilePicUrl(user.profile_pic)
      }
    }

    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if(user&&profilePicUrl!==null){
      setTempProfilePicUrl(profilePicUrl)
    }
    // eslint-disable-next-line
  }, [profilePicUrl]);

  const handleEditPlayerBadgesModal = (e) => {
      toggleEditPlayerBadgesModalOpen()
  };

  const handleEditPlayerInfoModal = () => {
    if (editPlayerInfoOpen) {
      toggleEditPlayerInfoOpen(false);
    } else {
      toggleEditPlayerInfoOpen(true);
    }
  };

  const handleEditContactInfoModal = () => {
    if (editContactInfoOpen) {
      toggleEditContactInfoOpen(false);
    } else {
      toggleEditContactInfoOpen(true);
    }
  };

  const handleEditExperienceInfoModal = () => {
    if (editExperienceInfoOpen) {
      toggleEditExperienceInfoOpen(false);
    } else {
      toggleEditExperienceInfoOpen(true);
    }
  };

  const handleEditOtherInfoModal = () => {
    if (editOtherInfoOpen) {
      toggleEditOtherInfoOpen(false);
    } else {
      toggleEditOtherInfoOpen(true);
    }
  };


  const handleSetProfileImage = (e) => {
    editImage = e.target.files;
    handleSetNewProfilePic(e)
    newImage = true;
    const selectedFile = e.target.files[0];
    console.log(e.target.files);

    const reader = new FileReader();
    reader.onload = (event) => {
      const fileString = event.target.result;
      setTempProfilePicUrl(fileString);
    };
    reader.readAsDataURL(selectedFile);
  };
  


const EditPlayerBadgesModal=()=>{

  const {
    fetchBadges,
    badges,
    badgeInfo,
    updateUserBadges,
    removeUserBadge
  } = useContext(DataContext);

  const [showAllBadges,toggleShowAllBadges]=useToggle(false)
  const [showSelectedBadge,toggleShowSelectedBadge]=useToggle(false)
  const [selectedBadges,setSelectedBadges] = useState(null)
  const [filteredBadges,setFilteredBadges] = useState(null)
  const [newBadges,setNewBadges] = useState(null)
  const [addButtonText,setAddButtonText] = useState('Add')

  const [selectedBadgeName,setSelectedBadgeName] = useState(null)
  const [selectedBadgeUrl,setSelectedBadgeUrl] = useState(null)
  const [selectedBadgeLevel,setSelectedBadgeLevel] = useState(null)
  const [selectedBadgeDescription,setSelectedBadgeDescription] = useState(null)
  const [selectedBadgeId,setSelectedBadgeId] = useState(null)

  const [tempBronzeUrl,setTempBronzeUrl]=useState(null)
  const [tempSilverUrl,setTempSilverUrl]=useState(null)
  const [tempGoldUrl,setTempGoldUrl]=useState(null)


  const handleToggleShowAllBadges=(e)=>{
    toggleShowAllBadges()
    setAddButtonText('Update')
  }

  useEffect(() => {
    if(editPlayerBadgesModalOpen===true && badges ===null){
      fetchBadges()
    }
    // eslint-disable-next-line
  }, [editPlayerBadgesModalOpen]);

  useEffect(() => {
    if (badgeInfo!==null && badgeInfo.length>0){
      if(badges !== null ){
        let tempfilteredBadges = badges.filter(badge => badgeInfo&&!badgeInfo.find(cb => cb.id === badge.id));
        setFilteredBadges(tempfilteredBadges)
    }
  }
    else{
      setFilteredBadges(badges)
    }
    

    // eslint-disable-next-line
  }, [badges,badgeInfo]);


  const handleToggleEditPlayerBadgesModalOpen = (e)=>{
    if(showAllBadges){
      toggleShowAllBadges(false)
      setAddButtonText('Add')
    }
    else{
      toggleEditPlayerBadgesModalOpen()
    }

  }

  const handleSetSelectedBadges = (name, url, description, level, id) => {
    setSelectedBadgeName(name)
    setSelectedBadgeUrl(url)
    setSelectedBadgeLevel(level)
    setSelectedBadgeDescription(description)
    setSelectedBadgeId(id)
    generateBadgeUrls(url)
  };

  const generateBadgeUrls=(originalUrl) =>{
    console.log('og',originalUrl)

    const lastUnderscoreIndex = originalUrl.lastIndexOf('_');
    const badgeLevel = originalUrl.substring(lastUnderscoreIndex + 1, originalUrl.lastIndexOf('.'));
    
    // Construct the base URL by removing the badge level from the original URL
    const baseUrl = originalUrl.substring(0, lastUnderscoreIndex + 1);
  

    let bronzeUrl, silverUrl, goldUrl;
    switch (badgeLevel) {
      case 'bronze':
        silverUrl = `${baseUrl}silver.jpg`;
        goldUrl = `${baseUrl}gold.jpg`;
        bronzeUrl=originalUrl
        break;
      case 'silver':
        bronzeUrl = `${baseUrl}bronze.jpg`.replace(/\s/g, '_');
        goldUrl = `${baseUrl}gold.jpg`;
        silverUrl=originalUrl
        break;
      case 'gold':
        bronzeUrl = `${baseUrl}bronze.jpg`.replace(/\s/g, '_');
        silverUrl = `${baseUrl}silver.jpg`;
        goldUrl=originalUrl
        break;
      default:
        break;
    }
    console.log(bronzeUrl)
    console.log(silverUrl)
    console.log(goldUrl)
    setTempBronzeUrl(bronzeUrl)
    setTempSilverUrl(silverUrl)
    setTempGoldUrl(goldUrl)
  }

  const handleSetNewBadges = (e, badgeId,name,url) => {
    let updatedRegisterBadges = { ...newBadges };
    if (updatedRegisterBadges.hasOwnProperty(badgeId)) {
      updatedRegisterBadges[badgeId] = {
        level: e.target.value,
        name:name,
        id:badgeId,
        url:url
      };
    } else {
      updatedRegisterBadges[badgeId] = {
        level: e.target.value,
        name:name,
        id:badgeId,
        url:url
      };
    }
    setNewBadges(updatedRegisterBadges);
  };

  const handleSetBadgeDetails = (e, id) => {
    let updatedRegisterBadges = { ...newBadges };
    if (updatedRegisterBadges.hasOwnProperty(id)) {
      updatedRegisterBadges[id] = { ...updatedRegisterBadges[id] };
      updatedRegisterBadges[id].details = e.target.value;
    } else {
      updatedRegisterBadges[id] = {
        details: e.target.value,
      };
    }
    setNewBadges(updatedRegisterBadges);
    console.log(updatedRegisterBadges)
  };

  const handleUpdatePlayerBadges=(e)=>{
    console.log(badgeInfo)
    let updatedBadgeInfo
    if (typeof badgeInfo === 'object'){
      updatedBadgeInfo=[]
    }
    else{
      updatedBadgeInfo = [...badgeInfo];
    }

    Object.values(newBadges).forEach(badge => {

      updatedBadgeInfo.push(badge);
    });
  
    console.log(updatedBadgeInfo)
    setBadgeInfo(updatedBadgeInfo);
    updateUserBadges(user.id,newBadges)
    // let updatedBadgeInfo = { ...badgeInfo };


  
    // // Update the badgeInfo state with the updatedBadgeInfo
    // setBadgeInfo(updatedBadgeInfo);
    // toggleShowAllBadges() 

  }

  const handleRemovePlayerBadge=(e)=>{
    removeUserBadge(user.id,selectedBadgeId)
    setBadgeInfo(prevState => {
      // Filter out items with id equal to check_id
      return prevState.filter(item => item.id !== selectedBadgeId);
    });
  }

  return(
    <>
    {editPlayerBadgesModalOpen&&editPlayerBadgesModalOpen===true?
    <>
                <div
              style={{
                position: "fixed",
                top: "6vh",
                left: mobile ? "4vw" : "32vw",
                height: mobile ? "70vh" : "auto",
                width: mobile ? "92vw" : "40vw",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "left",
                marginTop: "1vh",
                backgroundColor: "ghostwhite",
                boxShadow: "2px 2px 9px #000000",
                zIndex: "9999",
                flexWrap: "wrap",
                paddingBottom: "2vh",
              }}
            >
              <div
                style={{
                  height: "5vh",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#5E0516",
                  boxShadow: "2px 2px 9px #000000",
                }}
              >
                <h3 style={{ color: "#eed77a" }}>{`Edit Specialist Badges:`}</h3>
              </div>

              <div
                style={{
                  height: "auto",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  // backgroundColor: "lightgrey",
                  paddingTop: "2vh",
                }}
              >
                  <label
                    style={{
                      fontSize: ".8rem",
                      fontWeight: 700,
                    }}
                  >{`Current Specialist Badges:`}</label>
                <BadgeComponent badgeInfo={badgeInfo} mode={'edit'} handleSetSelectedBadges={handleSetSelectedBadges}/>

                {showSelectedBadge&&showSelectedBadge?
                <>
                                <div
                  key={selectedBadgeId}
                  style={{
                    height: "Auto",
                    width: mobile ? "80vw" : "90%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "1vh",

                    backgroundColor: "#5E0516",
                    boxShadow: "1px 1px 6px 2px black",
                    border: "solid",
                    borderWidth: "4px",
                    borderColor: "#eed77a",
                  }}
                >
                  <label
                    style={{
                      color: "#eed77a",
                      fontSize: "1remm",
                      fontWeight: 700,
                      marginTop: "1vh",
                    }}
                  >
                    {selectedBadgeName}
                  </label>
                  <p
                    style={{
                      height: "2vh",
                      color: "#eed77a",
                      fontSize: ".5remm",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                      fontWeight: 500,
                      marginTop: "1vh",
                      textAlign: "center",
                    }}
                  >{`Requirements:`}</p>
                  <p
                    style={{
                      marginTop: "0%",
                      height: "auto",
                      width: "95%",
                      color: "#eed77a",
                      fontSize: ".5remm",
                      fontWeight: 400,
                      textAlign: "center",
                    }}
                  >
                    {selectedBadgeDescription}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "1vh",
                      }}
                    >
                      <Tooltip
                        placement="top"
                        // title={bronze_requirements}
                        arrow
                      >
                        <img
                          style={{
                            width: mobile ? "25vw" : "6.6vw",
                            height: "auto",
                            padding: mobile ? "" : "1vh",
                          }}
                          src={tempBronzeUrl}
                          alt={selectedBadgeName}
                        />
                      </Tooltip>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginBottom: "1vh",
                        }}
                      >
                        <label
                          style={{
                            color: "#eed77a",
                            fontSize: "1remm",
                            fontWeight: 700,
                          }}
                        >{`Bronze`}</label>
                        <input
                          value="Bronze"
                          checked={
                            selectedBadgeLevel &&
                            selectedBadgeLevel === "Bronze"
                          }
                          // onChange={(e) =>  handleSetNewBadges(e, id, name,bronze_url)}
                          type="radio"
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "1vh",
                      }}
                    >
                      <Tooltip
                        placement="top"
                        // title={silver_requirements}
                        arrow
                      >
                        <img
                          style={{
                            width: mobile ? "25vw" : "6.6vw",
                            height: "auto",
                            padding: mobile ? "" : "1vh",
                          }}
                          src={tempSilverUrl}
                          // alt={name} // Add alt text for accessibility
                        />
                      </Tooltip>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginBottom: "1vh",
                        }}
                      >
                        <label
                          style={{
                            color: "#eed77a",
                            fontSize: "1remm",
                            fontWeight: 700,
                          }}
                        >{`Silver`}</label>
                        <input
                          value="Silver"
                          checked={
                            selectedBadgeLevel &&
                            selectedBadgeLevel === "Silver"
                          }
                          // onChange={(e) => handleSetNewBadges(e, id, name, silver_url)}
                          type="radio"
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "1vh",
                      }}
                    >
                      <Tooltip placement="top" 
                      // title={gold_requirements} 
                      >
                        <img
                          style={{
                            width: mobile ? "25vw" : "6.6vw",
                            height: "auto",
                            padding: mobile ? "" : "1vh",
                          }}
                          src={tempGoldUrl}
                        />
                      </Tooltip>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginBottom: "1vh",
                        }}
                      >
                        <label
                          style={{
                            color: "#eed77a",
                            fontSize: "1remm",
                            fontWeight: 700,
                          }}
                        >{`Gold`}</label>
                        <input
                          value="Gold"
                          checked={
                            selectedBadgeLevel &&
                            selectedBadgeLevel === "Gold"
                          }
                          // onChange={(e) =>  handleSetNewBadges(e, id, name , gold_url)}
                          type="radio"
                        />
                      </div>

                    </div>
                    
                  </div>
                  <textArea
                      style={{
                        width: "80%",
                        height: "10vh",
                        marginBottom: "1vh",
                      }}
                      placeholder={
                        "Tell us where, when, and how your Badge was earned"
                      }

                      // onChange={(e) => handleSetBadgeDetails(e, id)}
                    />
 
                </div>
                </>
                :
                null
                }













                {showAllBadges?
                <>

<div
        style={{
          width: mobile ? "90vw" : "100%",
          height: mobile ? "62vh" : "60vh",
          display: "flex",
          flexDirection: "column",
          alignItems: mobile ? "center" : "center",
          overflowY: "scroll",

          paddingBottom: "1vh",
          marginBottom: "2vh",
          marginTop:'1vh'
        }}
      >
         
        {filteredBadges && filteredBadges.length > 0 ? (
          filteredBadges.slice().map((row, index) => {
            const {
              id,
              name,
              description,
              gold_url,
              silver_url,
              bronze_url,
              bronze_requirements,
              silver_requirements,
              gold_requirements,
            } = row;

            return (
              <>
              {
                
              }
                <div
                  key={index}
                  style={{
                    height: "Auto",
                    width: mobile ? "80vw" : "90%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "1vh",

                    backgroundColor: "#5E0516",
                    boxShadow: "1px 1px 6px 2px black",
                    border: "solid",
                    borderWidth: "4px",
                    borderColor: "#eed77a",
                  }}
                >
                  <label
                    style={{
                      color: "#eed77a",
                      fontSize: "1remm",
                      fontWeight: 700,
                      marginTop: "1vh",
                    }}
                  >
                    {name}
                  </label>
                  <p
                    style={{
                      height: "2vh",
                      color: "#eed77a",
                      fontSize: ".5remm",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                      fontWeight: 500,
                      marginTop: "1vh",
                      textAlign: "center",
                    }}
                  >{`Requirements:`}</p>
                  <p
                    style={{
                      marginTop: "0%",
                      height: "auto",
                      width: "95%",
                      color: "#eed77a",
                      fontSize: ".5remm",
                      fontWeight: 400,
                      textAlign: "center",
                    }}
                  >
                    {description}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "1vh",
                      }}
                    >
                      <Tooltip
                        placement="top"
                        title={bronze_requirements}
                        arrow
                      >
                        <img
                          style={{
                            width: mobile ? "25vw" : "6.6vw",
                            height: "auto",
                            padding: mobile ? "" : "1vh",
                          }}
                          src={bronze_url}
                          alt={name}
                        />
                      </Tooltip>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginBottom: "1vh",
                        }}
                      >
                        <label
                          style={{
                            color: "#eed77a",
                            fontSize: "1remm",
                            fontWeight: 700,
                          }}
                        >{`Bronze`}</label>
                        <input
                          value="Bronze"
                          checked={
                            selectedBadges &&
                            selectedBadges[`${id}`]?.level === "Bronze"
                          }
                          onChange={(e) =>  handleSetNewBadges(e, id, name,bronze_url)}
                          type="radio"
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "1vh",
                      }}
                    >
                      <Tooltip
                        placement="top"
                        title={silver_requirements}
                        arrow
                      >
                        <img
                          style={{
                            width: mobile ? "25vw" : "6.6vw",
                            height: "auto",
                            padding: mobile ? "" : "1vh",
                          }}
                          src={silver_url}
                          alt={name} // Add alt text for accessibility
                        />
                      </Tooltip>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginBottom: "1vh",
                        }}
                      >
                        <label
                          style={{
                            color: "#eed77a",
                            fontSize: "1remm",
                            fontWeight: 700,
                          }}
                        >{`Silver`}</label>
                        <input
                          value="Silver"
                          checked={
                            selectedBadges &&
                            selectedBadges[`${id}`]?.level === "Silver"
                          }
                          onChange={(e) => handleSetNewBadges(e, id, name, silver_url)}
                          type="radio"
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "1vh",
                      }}
                    >
                      <Tooltip placement="top" title={gold_requirements} arrow>
                        <img
                          style={{
                            width: mobile ? "25vw" : "6.6vw",
                            height: "auto",
                            padding: mobile ? "" : "1vh",
                          }}
                          src={gold_url}
                          alt={name} // Add alt text for accessibility
                        />
                      </Tooltip>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginBottom: "1vh",
                        }}
                      >
                        <label
                          style={{
                            color: "#eed77a",
                            fontSize: "1remm",
                            fontWeight: 700,
                          }}
                        >{`Gold`}</label>
                        <input
                          value="Gold"
                          checked={
                            selectedBadges &&
                            selectedBadges[`${id}`]?.level === "Gold"
                          }
                          onChange={(e) =>  handleSetNewBadges(e, id, name , gold_url)}
                          type="radio"
                        />
                      </div>
                    </div>
                  </div>

                  {newBadges &&
                  newBadges.hasOwnProperty(id) ? (
                    <textArea
                      style={{
                        width: "80%",
                        height: "10vh",
                        marginBottom: "1vh",
                      }}
                      placeholder={
                        "Tell us where, when, and how your Badge was earned"
                      }

                      onChange={(e) => handleSetBadgeDetails(e, id)}
                    />
                  ) : null}
                </div>
              </>
            );
          })
        ) : (
          <>
            <div
              style={{
                width: "auto",
                height: "50vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{ width: "30vh", height: "auto" }}
                src={
                  "https://printsmiths.nyc3.cdn.digitaloceanspaces.com/CEF/Badges/badges_meme.png"
                }
              />
            </div>
          </>
        )}
      </div>


                </>
                :
                null

                }
                <div
                style={{
                  height: "auto",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent:'center',
                  backgroundColor: "lightgrey",

                }}
              >
                                <button
                  style={{
                    width:'4vw',
                    marginRight:'1vw'
                  }}
                  onClick={(e)=>handleToggleEditPlayerBadgesModalOpen(e)}
                >
                  Cancel
                </button>
                <button
                  style={{
                    width:'4vw',
                    marginRight:'1vw'
                  }}
                  onClick={(e)=>addButtonText==='Add'?handleToggleShowAllBadges(e) : handleUpdatePlayerBadges(e)}
                >
                 {addButtonText}
                </button>
                <button
                  style={{
                    width:'4vw',
                    marginRight:'1vw'
                  }}
                  onClick={(e)=>toggleShowSelectedBadge()}
                >
                  Edit
                </button>
                <button
                  style={{
                    width:'4vw',

                  }}
                  onClick={(e)=>handleRemovePlayerBadge(e)}
                >
                  Remove
                </button>
                </div>



              </div>
             </div>

    </>
    :
    null
    }
    </>
  )
}






















  const DesktopFormat = () => {
    return (
      <>
      <EditPlayerBadgesModal/>
        <div
          style={{
            height: "88vh",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            // alignItems: "center",
          }}
        >
          <div
            style={{
              height: "100%",
              width: "25%",
              display: "flex",
              flexDirection: "column",
              alignItems: "top",
              // backgroundColor:'ghostwhite',
              msrginRight: "1vw",
              marginLeft: "1vw",
            }}
          >
            <div
              style={{
                height: "30%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "grey",
                boxShadow: "2px 2px 9px #000000",
                marginBottom: "2vh",
                marginTop: "2vh",
              }}
            >
              <div
                style={{
                  height: "3.1vh",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "left",
                  backgroundColor: "#5E0516",
                }}
              >

                <h3 style={{ color: "#eed77a",marginLeft:'.5vw' }}>{`Profile Picture`}</h3>
              </div>
              <div
                style={{
                  height: "80%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "ghostwhite",
                  boxShadow: "2px 2px 9px #000000",
                }}
              >
                <img
                  style={{
                    width: "auto",
                    height: "92%",
                    boxShadow: "2px 2px 9px #000000",
                    border: "solid",
                    borderWidth: "4px",
                    borderColor: "#5E0516",
                  }}
                  src={tempProfilePicUrl&&tempProfilePicUrl?tempProfilePicUrl:logo}
                />
              </div>
              <div
                style={{
                  height: "10%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  backgroundColor: "#5E0516",
                  boxShadow: "2px 2px 9px #000000",
                }}
              >
                <input
                  type="file"
                  accept=".jpg, .png, .jpeg,"
                  style={{
                    marginLeft: ".5vw",
                    width: "15.25vw",
                    color: "#eed77a",
                  }}
                  onChange={(e) => handleSetProfileImage(e)}
                  multiple={true}
                />
                <button onClick={(e) => updateProfilePic(e)} style={{}}>
                  Submit
                </button>
              </div>
            </div>

            <div
              style={{
                height: "3.1vh",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                // justifyContent: "center",
                backgroundColor: "#5E0516",
                boxShadow: "2px 2px 9px #000000",
              }}
            >
              <button 
              style={{
                marginLeft:'.5vw',marginRight:'.5vw'
              }}
              onClick={(e) => handleEditPlayerInfoModal(e)}
              >
                Edit
              </button>
              <h3
                style={{
                  color: "#eed77a",

                }}
              >{`Player Info`}</h3>

            </div>

            <div
              style={{
                height: "22vh",
                width: "100%",
                display: "flex",
                backgroundColor: "ghostwhite",
                boxShadow: "2px 2px 9px #000000",
              }}
            >
              <div
                style={{
                  height: "auto",
                  width: "5vw",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "self-end",
                  marginRight: ".5vw",
                  marginLeft: ".5vw",
                  // boxShadow: '2px 2px 9px #000000',
                }}
              >
                <div
                  style={{
                    height: "3vh",
                  }}
                >
                  <label
                    style={{
                      fontSize: ".8rem",
                      fontWeight: 700,
                    }}
                  >{`Name:`}</label>
                </div>
                <div
                  style={{
                    height: "3vh",
                  }}
                >
                  <label
                    style={{
                      fontSize: ".8rem",
                      fontWeight: 700,
                    }}
                  >{`Age:`}</label>
                </div>
                <div
                  style={{
                    height: "3vh",
                  }}
                >
                  <label
                    style={{
                      fontSize: ".8rem",
                      fontWeight: 700,
                    }}
                  >{`Callsign:`}</label>
                </div>

                <div
                  style={{
                    height: "3vh",
                  }}
                >
                  <label
                    style={{
                      fontSize: ".8rem",
                      fontWeight: 700,
                    }}
                  >{`Rank:`}</label>
                </div>

                <div
                  style={{
                    height: "3vh",
                  }}
                >
                  <label
                    style={{
                      fontSize: ".8rem",
                      fontWeight: 700,
                    }}
                  >{`Unit:`}</label>
                </div>
                <div
                  style={{
                    height: "3vh",
                  }}
                >
                  <label
                    style={{
                      fontSize: ".8rem",
                      fontWeight: 700,
                    }}
                  >{`Company:`}</label>
                </div>
                <div
                  style={{
                    height: "3vh",
                  }}
                >
                  <label
                    style={{
                      fontSize: ".8rem",
                      fontWeight: 700,
                    }}
                  >{`Section:`}</label>
                </div>
              </div>
              <div
                style={{
                  height: "100%",
                  width: "auto",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "self-start",

                  // boxShadow: '2px 2px 9px #000000',
                }}
              >
                <div
                  style={{
                    height: "3vh",
                  }}
                >
                  <label
                    style={{
                      fontSize: ".8rem",
                      fontWeight: 700,
                    }}
                  >
                    {`${formattedName}`}
                  </label>
                </div>
                <div
                  style={{
                    height: "3vh",
                  }}
                >
                  <label
                    style={{
                      fontSize: ".8rem",
                      fontWeight: 700,
                    }}
                  >
                    {age}
                  </label>
                </div>
                <div
                  style={{
                    height: "3vh",
                  }}
                >
                  <label
                    style={{
                      fontSize: ".8rem",
                      fontWeight: 700,
                    }}
                  >
                    {`${callsign}`}
                  </label>
                </div>

                <div
                  style={{
                    height: "3vh",
                  }}
                >
                  <label
                    style={{
                      fontSize: ".8rem",
                      fontWeight: 700,
                    }}
                  >
                    {`${rank}`}
                  </label>
                </div>
                <div
                  style={{
                    height: "3vh",
                  }}
                >
                  <label
                    style={{
                      fontSize: ".8rem",
                      fontWeight: 700,
                    }}
                  >
                    {`${unit}`}
                  </label>
                </div>
                <div
                  style={{
                    height: "3vh",
                  }}
                >
                  <label
                    style={{
                      fontSize: ".8rem",
                      fontWeight: 700,
                    }}
                  >
                    {`${company}`}
                  </label>
                </div>
                <div
                  style={{
                    height: "3vh",
                  }}
                >
                  <label
                    style={{
                      fontSize: ".8rem",
                      fontWeight: 700,
                    }}
                  >
                    {`${section}`}
                  </label>
                </div>
              </div>
            </div>

            <div
              style={{
                height: "3vh",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "left",
                backgroundColor: "#5E0516",
                boxShadow: "2px 2px 9px #000000",
                marginTop: "2vh",
              }}
            >
              <button 
              onClick={(e) => handleEditContactInfoModal(e)}
              style={{
                marginLeft:'.5vw',marginRight:'.5vw'
              }}
              >
                Edit
              </button>
              <h3
                style={{
                  color: "#eed77a",
                }}
              >{`Contact Info`}</h3>

            </div>

            <div
              style={{
                height: "22vh",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                // justifyContent: "center",
                backgroundColor: "ghostwhite",
                boxShadow: "2px 2px 9px #000000",
                overflowX: "scroll",
              }}
            >
              <div
                style={{
                  height: "100%",
                  width: "5vw",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "self-end",
                  marginLeft: ".5vw",
                  marginRight: ".5vw",
                }}
              >
                <div
                  style={{
                    height: "3vh",
                    width: "auto",
                    // backgroundColor:'blue',
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "right",
                  }}
                >
                  <label
                    style={{
                      fontSize: ".8rem",
                      fontWeight: 700,
                    }}
                  >{`Address:`}</label>
                </div>
                <div
                  style={{
                    height: "2vh",
                    width: "auto",
                    // backgroundColor:'red',
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "right",
                  }}
                >
                  <label
                    style={{
                      fontSize: ".8rem",
                      fontWeight: 700,
                    }}
                  >{`City:`}</label>
                </div>
                <div
                  style={{
                    height: "3vh",
                    width: "auto",
                    // backgroundColor:'red',
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "right",
                  }}
                >
                  <label
                    style={{
                      fontSize: ".8rem",
                      fontWeight: 700,
                    }}
                  >{`State/Province:`}</label>
                </div>
                <div
                  style={{
                    height: "3vh",
                    width: "auto",
                    // backgroundColor:'red',
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "right",
                  }}
                >
                  <label
                    style={{
                      fontSize: ".8rem",
                      fontWeight: 700,
                    }}
                  >{`Country:`}</label>
                </div>
                <div
                  style={{
                    height: "3vh",
                    width: "auto",
                    // backgroundColor:'red',
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "right",
                  }}
                >
                  <label
                    style={{
                      fontSize: ".8rem",
                      fontWeight: 700,
                    }}
                  >{`Contact Email:`}</label>
                </div>
                <div
                  style={{
                    height: "3vh",
                    width: "auto",
                    // backgroundColor:'red',
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "right",
                  }}
                >
                  <label
                    style={{
                      fontSize: ".8rem",
                      fontWeight: 700,
                    }}
                  >{`Phone:`}</label>
                </div>

                <div
                  style={{
                    height: "3vh",
                    width: "5vw",
                    // backgroundColor:'red',
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "right",
                  }}
                >
                  <label
                    style={{
                      fontSize: ".8rem",
                      fontWeight: 700,
                    }}
                  >{`Emergency:`}</label>
                </div>
              </div>
              <div
                style={{
                  height: "100%",
                  width: "auto",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "self-start",
                }}
              >
                <div
                  style={{
                    height: "3vh",
                    width: "auto",
                    // backgroundColor:'red',
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "right",
                  }}
                >
                  <label
                    style={{
                      fontSize: ".8rem",
                      fontWeight: 700,
                    }}
                  >
                    {/* {user.show_location? address:'Private'} */}
                    {address}
                  </label>
                </div>
                <div
                  style={{
                    height: "2vh",
                    width: "auto",
                    // backgroundColor:'red',
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "right",
                  }}
                >
                  <label
                    style={{
                      fontSize: ".8rem",
                      fontWeight: 700,
                    }}
                  >
                    {city}
                  </label>
                </div>
                <div
                  style={{
                    height: "3vh",
                    width: "10vw",
                    // backgroundColor:'red',
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "left",
                  }}
                >
                  <label
                    style={{
                      fontSize: ".8rem",
                      fontWeight: 700,
                    }}
                  >
                    {state}
                  </label>
                </div>

                <div
                  style={{
                    height: "3vh",
                    width: "10vw",
                    // backgroundColor:'red',
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "left",
                  }}
                >
                  <label
                    style={{
                      fontSize: ".8rem",
                      fontWeight: 700,
                    }}
                  >
                    {country}
                  </label>
                </div>

                <div
                  style={{
                    height: "3vh",
                    width: "10vw",
                    // backgroundColor:'red',
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "left",
                  }}
                >
                  <label
                    style={{
                      fontSize: ".8rem",
                      fontWeight: 700,
                    }}
                  >
                    {email}
                  </label>
                </div>
                <div
                  style={{
                    height: "3vh",
                    width: "10vw",
                    // backgroundColor:'red',
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "left",
                  }}
                >
                  <label
                    style={{
                      fontSize: ".8rem",
                      fontWeight: 700,
                    }}
                  >
                    {phone}
                  </label>
                </div>
                <div
                  style={{
                    height: "3vh",
                    width: "10vw",
                    // backgroundColor:'red',
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "left",
                  }}
                >
                  <label
                    style={{
                      fontSize: ".8rem",
                      fontWeight: 700,
                    }}
                  >
                    {EmergencyContact}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              height: "100%",
              width: "57%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginLeft: "1vw",
              // backgroundColor:'ghostwhite'
            }}
          >
            <div
              style={{
                height: "30%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "ghostwhite",
                boxShadow: "2px 2px 9px #000000",
                // marginBottom:'2vh',
                marginTop: "2vh",
              }}
            >
              <div
                style={{
                  height: "3.1vh",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "left",
                  backgroundColor: "#5E0516",
                }}
              >
              <button
              onClick={(e)=>handleEditPlayerBadgesModal(e)}
              style={{
                marginLeft:'.5vw',
                marginRight:'.5vw'
              }} >
                Edit
              </button>
                <h3 style={{ color: "#eed77a" }}>{`Badges`}</h3>
              </div>
              <BadgeComponent badgeInfo={badgeInfo} />
              {/* <div
          style={{
            height: "10vh",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "1vh",
            marginLeft: "2vh",
            // backgroundColor:'lightgrey',
          }}
        >
    
        </div> */}
            </div>

            <div
              style={{
                height: "25vh",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "ghostwhite",
                boxShadow: "2px 2px 9px #000000",
                marginBottom: "2vh",
                marginTop: "2vh",
                // overflowY:'scroll'
              }}
            >
              <div
                style={{
                  height: "3.1vh",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "left",
                  backgroundColor: "#5E0516",
                }}
              >
              <button
              style={{
                marginLeft:'.5vw',
                marginRight:'.5vw'
              }} >
                Edit
              </button>
                <h3 style={{ color: "#eed77a" }}>{`Medals`}</h3>
              </div>
              <MedalComponent medalInfo={medalInfo} />
            </div>

            <div
              style={{
                height: "28.25%",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                // boxShadow: "2px 2px 9px #000000",
              }}
            >
              <div
                style={{
                  height: "100%",
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  // alignItems: "center",
                  backgroundColor: "ghostwhite",
                  boxShadow: "2px 2px 9px #000000",
                  marginRight: "1vw",
                }}
              >
                <div
                  style={{
                    height: "3vh",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "left",
                    backgroundColor: "#5E0516",
                  }}
                >
                  <button 
                  onClick={(e) => handleEditExperienceInfoModal(e)}
                  style={{
                    marginLeft:'.5vw',marginRight:'.5vw'
                  }}
                  >
                    Edit
                  </button>
                  <h3
                    style={{
                      color: "#eed77a",

                    }}
                  >{`Experience`}</h3>

                </div>

                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    // justifyContent: "center",
                    overflowY: "scroll",
                  }}
                >
                  <div
                    style={{
                      height: "100%",
                      width: "auto",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "self-end",
                      marginRight: ".5vw",
                      marginLeft: ".5vw",
                      // backgroundColor:'grey',
                    }}
                  >
                    <label
                      style={{
                        fontSize: ".8rem",
                        fontWeight: 700,
                        marginBottom: "1vh",
                        marginTop: "1vh",
                      }}
                    >{`Experience:`}</label>

                    <label
                      style={{
                        fontSize: ".8rem",
                        fontWeight: 700,
                        marginBottom: "1vh",
                      }}
                    >{`Home Team:`}</label>

                    <label
                      style={{
                        fontSize: ".8rem",
                        fontWeight: 700,
                        marginBottom: "1vh",
                      }}
                    >{`Game Styles:`}</label>
                  </div>

                  <div
                    style={{
                      height: "100%",
                      width: "auto",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "self-start",

                      // backgroundColor:'lightgrey'
                    }}
                  >
                    <label
                      style={{
                        fontSize: ".8rem",
                        fontWeight: 700,
                        marginBottom: "1vh",
                        marginTop: "1vh",
                      }}
                    >
                      {years && years ? `${years} Years` : "Unknown"}
                    </label>

                    <label
                      style={{
                        fontSize: ".8rem",
                        fontWeight: 700,
                        marginBottom: "1vh",
                      }}
                    >
                      {homeTeam && homeTeam ? homeTeam : "Unknown"}
                    </label>

                    <label
                      style={{
                        fontSize: ".8rem",
                        fontWeight: 700,
                        marginBottom: "1vh",
                      }}
                    >
                      {`${
                        playTypes && playTypes
                          ? playTypes.toString().replace(/[{}]/g, "")
                          : "Unknown"
                      }`}
                    </label>
                  </div>
                </div>
              </div>

              <div
                style={{
                  height: "100%",
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  // alignItems: "center",
                  backgroundColor: "ghostwhite",
                  boxShadow: "2px 2px 9px #000000",
                }}
              >
                <div
                  style={{
                    height: "3vh",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "left",
                    backgroundColor: "#5E0516",
                  }}
                >
                  <button 
                  onClick={(e) => handleEditOtherInfoModal(e)}
                  style={{
                    marginLeft:'.5vw',marginRight:'.5vw'
                  }}
                  >
                    Edit
                  </button>
                  <h3
                    style={{
                      color: "#eed77a",
                    }}
                  >{`Other Info`}</h3>

                </div>

                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      height: "100%",
                      width: "auto",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "self-end",
                      marginRight: ".5vw",
                      marginLeft: ".5vw",
                    }}
                  >
                    <label
                      style={{
                        fontSize: ".8rem",
                        fontWeight: 700,
                        marginBottom: "1vh",
                        marginTop: "1vh",
                      }}
                    >{`Skills:`}</label>
                    <label
                      style={{
                        fontSize: ".8rem",
                        fontWeight: 700,
                        marginBottom: "1vh",
                      }}
                    >{`Additional Info:`}</label>
                  </div>

                  <div
                    style={{
                      height: "100%",
                      width: "auto",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "self-start",
                    }}
                  >
                    <label
                      style={{
                        fontSize: ".8rem",
                        fontWeight: 700,
                        marginBottom: "1vh",
                        marginTop: "1vh",
                      }}
                    >
                      {skills && skills ? skills : "Unknown"}
                    </label>
                    <label
                      style={{
                        fontSize: ".8rem",
                        fontWeight: 700,
                        marginBottom: "1vh",
                      }}
                    >
                      {otherInfo && otherInfo ? otherInfo : "Unknown"}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              height: "80.5vh",
              width: "30%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "ghostwhite",
              marginLeft: "1vw",
              marginRight: "1vw",
              boxShadow: "2px 2px 9px #000000",
              marginTop: "2vh",
              // overflowY:'scroll'
            }}
          >
            <div
              style={{
                height: "3.1vh",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "left",
                backgroundColor: "#5E0516",
              }}
            >
              <button
                style={{
                  marginLeft:'.5vw',marginRight:'.5vw'
                }}
              >
                Edit
              </button>
              <h3 style={{ color: "#eed77a" }}>{`CEF Events Attended`}</h3>
            </div>
            <EventComponent playerEvents={playerEvents} />
          </div>
        </div>
      </>
    );
  };

  //////////////////////////////////////////////////////////////////////////

  const MobileFormat = () => {
    return (
      <div
        style={{
          height: mobile ? "75vh" : "88vh",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          // backgroundColor: "grey",
          // boxShadow: "2px 2px 9px #000000",
          // marginBottom: "2vh",
          paddingBottom: "2vh",
          marginTop: "2vh",
          overflowY: "scroll",
        }}
      >
        <div
          style={{
            height: "5vh",
            width: "90%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#5E0516",
          }}
        >
          <h3 style={{ color: "#eed77a" }}>{`Profile Picture`}</h3>
        </div>
        <div
          style={{
            height: "40vh",
            width: "90%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "ghostwhite",
            boxShadow: "2px 2px 9px #000000",
          }}
        >
          <img
            style={{
              width: "auto",
              height: "92%",
              boxShadow: "2px 2px 9px #000000",
              border: "solid",
              borderWidth: "4px",
              borderColor: "#5E0516",
            }}
            src={profilePicUrl}
          />
        </div>
        <div
          style={{
            height: "5vh",
            width: "90%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            backgroundColor: "#5E0516",
            boxShadow: "2px 2px 9px #000000",
          }}
        >
          <input
            type="file"
            accept=".jpg, .png, .jpeg,"
            style={{
              marginLeft: ".5vw",
              width: "auto",
              color: "#eed77a",
            }}
            onChange={(e) => handleSetNewProfilePic(e)}
            multiple={true}
          />
          <button onClick={(e) => updateProfilePic(e)} style={{}}>
            Submit
          </button>
        </div>

        <div
          style={{
            height: "5vh",
            width: "90%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#5E0516",
            boxShadow: "2px 2px 9px #000000",
            marginTop: "2vh",
          }}
        >
          <h3
            style={{
              color: "#eed77a",

              marginLeft: "10vw",
            }}
          >{`Player Info`}</h3>
          <button
            style={{
              position: "relative",
              top: "0vh",
              left: "20vw",
            }}
            onClick={(e) => handleEditPlayerInfoModal(e)}
          >
            Edit
          </button>
        </div>

        <div
          style={{
            height: "90vh",
            width: "90%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "ghostwhite",
            boxShadow: "2px 2px 9px #000000",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "3vh",
              width: "90%",
              alignItems: "center",
              justifyContent: "left",
              marginBottom: "2vh",
              backgroundColor: "lightgrey",
              marginRight: mobile ? "0vw" : "20vw",
              marginTop: "2vh",
            }}
          >
            <label
              style={{
                fontSize: ".8rem",
                fontWeight: 700,
                marginRight: "2vw",
              }}
            >
              Name:
            </label>
            <label
              style={{
                fontSize: ".8rem",
                fontWeight: 600,
              }}
            >
              {`${formattedName}`}
            </label>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "3vh",
              width: "90%",
              alignItems: "center",
              justifyContent: "left",
              marginBottom: "2vh",
              backgroundColor: "lightgrey",
              marginRight: mobile ? "0vw" : "20vw",
            }}
          >
            <label
              style={{
                fontSize: ".8rem",
                fontWeight: 700,
                marginRight: "2vw",
              }}
            >
              Callsign:
            </label>
            <label
              style={{
                fontSize: ".8rem",
                fontWeight: 600,
              }}
            >
              {`${callsign}`}
            </label>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "3vh",
              width: "90%",
              alignItems: "center",
              justifyContent: "left",
              marginBottom: "2vh",
              backgroundColor: "lightgrey",
              marginRight: mobile ? "0vw" : "20vw",
            }}
          >
            <label
              style={{
                fontSize: ".8rem",
                fontWeight: 700,
                marginRight: "2vw",
              }}
            >
              Age:
            </label>
            <label
              style={{
                fontSize: ".8rem",
                fontWeight: 600,
              }}
            >
              {`${age}`}
            </label>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "3vh",
              width: "90%",
              alignItems: "center",
              justifyContent: "left",
              marginBottom: "2vh",
              backgroundColor: "lightgrey",
              marginRight: mobile ? "0vw" : "20vw",
            }}
          >
            <label
              style={{
                fontSize: ".8rem",
                fontWeight: 700,
                marginRight: "2vw",
              }}
            >
              Rank:
            </label>
            <label
              style={{
                fontSize: ".8rem",
                fontWeight: 600,
              }}
            >
              {`${rank}`}
            </label>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "3vh",
              width: "90%",
              alignItems: "center",
              justifyContent: "left",
              marginBottom: "2vh",
              backgroundColor: "lightgrey",
              marginRight: mobile ? "0vw" : "20vw",
            }}
          >
            <label
              style={{
                fontSize: ".8rem",
                fontWeight: 700,
                marginRight: "2vw",
              }}
            >
              Unit:
            </label>
            <label
              style={{
                fontSize: ".8rem",
                fontWeight: 600,
              }}
            >
              {`${unit}`}
            </label>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "3vh",
              width: "90%",
              alignItems: "center",
              justifyContent: "left",
              marginBottom: "2vh",
              backgroundColor: "lightgrey",
              marginRight: mobile ? "0vw" : "20vw",
            }}
          >
            <label
              style={{
                fontSize: ".8rem",
                fontWeight: 700,
                marginRight: "2vw",
              }}
            >
              Company:
            </label>
            <label
              style={{
                fontSize: ".8rem",
                fontWeight: 600,
              }}
            >
              {`${company}`}
            </label>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "3vh",
              width: "90%",
              alignItems: "center",
              justifyContent: "left",
              marginBottom: "2vh",
              backgroundColor: "lightgrey",
              marginRight: mobile ? "0vw" : "20vw",
            }}
          >
            <label
              style={{
                fontSize: ".8rem",
                fontWeight: 700,
                marginRight: "2vw",
              }}
            >
              Section:
            </label>
            <label
              style={{
                fontSize: ".8rem",
                fontWeight: 600,
              }}
            >
              {`${section}`}
            </label>
          </div>
        </div>

        <div
          style={{
            height: "5vh",
            width: "90%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#5E0516",
            boxShadow: "2px 2px 9px #000000",
            marginTop: "2vh",
          }}
        >
          <h3
            style={{
              color: "#eed77a",

              marginLeft: "10vw",
            }}
          >{`Contact Info`}</h3>
          <button
            style={{
              position: "relative",
              top: "0vh",
              left: "18vw",
            }}
            onClick={(e) => handleEditContactInfoModal(e)}
          >
            Edit
          </button>
        </div>

        <div
          style={{
            height: "90vh",
            width: "90%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "ghostwhite",
            boxShadow: "2px 2px 9px #000000",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "3vh",
              width: "90%",
              alignItems: "center",
              justifyContent: "left",
              marginBottom: "2vh",
              backgroundColor: "lightgrey",
              marginRight: mobile ? "0vw" : "20vw",
              marginTop: "2vh",
            }}
          >
            <label
              style={{
                fontSize: ".8rem",
                fontWeight: 700,
                marginRight: "2vw",
              }}
            >
              Address:
            </label>
            <label
              style={{
                fontSize: ".8rem",
                fontWeight: 600,
              }}
            >
              {`${address}`}
            </label>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "3vh",
              width: "90%",
              alignItems: "center",
              justifyContent: "left",
              marginBottom: "2vh",
              backgroundColor: "lightgrey",
              marginRight: mobile ? "0vw" : "20vw",
            }}
          >
            <label
              style={{
                fontSize: ".8rem",
                fontWeight: 700,
                marginRight: "2vw",
              }}
            >
              City:
            </label>
            <label
              style={{
                fontSize: ".8rem",
                fontWeight: 600,
              }}
            >
              {`${city}`}
            </label>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "3vh",
              width: "90%",
              alignItems: "center",
              justifyContent: "left",
              marginBottom: "2vh",
              backgroundColor: "lightgrey",
              marginRight: mobile ? "0vw" : "20vw",
            }}
          >
            <label
              style={{
                fontSize: ".8rem",
                fontWeight: 700,
                marginRight: "2vw",
              }}
            >
              State:
            </label>
            <label
              style={{
                fontSize: ".8rem",
                fontWeight: 600,
              }}
            >
              {`${state}`}
            </label>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "3vh",
              width: "90%",
              alignItems: "center",
              justifyContent: "left",
              marginBottom: "2vh",
              backgroundColor: "lightgrey",
              marginRight: mobile ? "0vw" : "20vw",
            }}
          >
            <label
              style={{
                fontSize: ".8rem",
                fontWeight: 700,
                marginRight: "2vw",
              }}
            >
              Country:
            </label>
            <label
              style={{
                fontSize: ".8rem",
                fontWeight: 600,
              }}
            >
              {`${country}`}
            </label>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "3vh",
              width: "90%",
              alignItems: "center",
              justifyContent: "left",
              marginBottom: "2vh",
              backgroundColor: "lightgrey",
              marginRight: mobile ? "0vw" : "20vw",
            }}
          >
            <label
              style={{
                fontSize: ".8rem",
                fontWeight: 700,
                marginRight: "2vw",
              }}
            >
              Email:
            </label>
            <label
              style={{
                fontSize: ".8rem",
                fontWeight: 600,
              }}
            >
              {`${email}`}
            </label>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "3vh",
              width: "90%",
              alignItems: "center",
              justifyContent: "left",
              marginBottom: "2vh",
              backgroundColor: "lightgrey",
              marginRight: mobile ? "0vw" : "20vw",
            }}
          >
            <label
              style={{
                fontSize: ".8rem",
                fontWeight: 700,
                marginRight: "2vw",
              }}
            >
              Phone:
            </label>
            <label
              style={{
                fontSize: ".8rem",
                fontWeight: 600,
              }}
            >
              {`${phone}`}
            </label>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "3vh",
              width: "90%",
              alignItems: "center",
              justifyContent: "left",
              marginBottom: "2vh",
              backgroundColor: "lightgrey",
              marginRight: mobile ? "0vw" : "20vw",
            }}
          >
            <label
              style={{
                fontSize: ".8rem",
                fontWeight: 700,
                marginRight: "2vw",
              }}
            >
              Emergency:
            </label>
            <label
              style={{
                fontSize: ".8rem",
                fontWeight: 600,
              }}
            >
              {`${EmergencyContact}`}
            </label>
          </div>
        </div>

        <div
          style={{
            height: "5vh",
            width: "90%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#5E0516",
            boxShadow: "2px 2px 9px #000000",
            marginTop: "2vh",
          }}
        >
          <h3
            style={{
              color: "#eed77a",

              marginLeft: "10vw",
            }}
          >{`Experience Info:`}</h3>
          <button
            style={{
              position: "relative",
              top: "0vh",
              left: "14vw",
            }}
            onClick={(e) => handleEditExperienceInfoModal(e)}
          >
            Edit
          </button>
        </div>

        <div
          style={{
            height: "90vh",
            width: "90%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "ghostwhite",
            boxShadow: "2px 2px 9px #000000",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "3vh",
              width: "90%",
              alignItems: "center",
              justifyContent: "left",
              marginBottom: "2vh",
              backgroundColor: "lightgrey",
              marginRight: mobile ? "0vw" : "20vw",
              marginTop: "2vh",
            }}
          >
            <label
              style={{
                fontSize: ".8rem",
                fontWeight: 700,
                marginRight: "2vw",
              }}
            >
              Years Experience:
            </label>
            <label
              style={{
                fontSize: ".8rem",
                fontWeight: 600,
              }}
            >
              {`${years}`}
            </label>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "3vh",
              width: "90%",
              alignItems: "center",
              justifyContent: "left",
              marginBottom: "2vh",
              backgroundColor: "lightgrey",
              marginRight: mobile ? "0vw" : "20vw",
            }}
          >
            <label
              style={{
                fontSize: ".8rem",
                fontWeight: 700,
                marginRight: "2vw",
              }}
            >
              Home Team:
            </label>
            <label
              style={{
                fontSize: ".8rem",
                fontWeight: 600,
              }}
            >
              {`${homeTeam}`}
            </label>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "3vh",
              width: "90%",
              alignItems: "center",
              justifyContent: "left",
              marginBottom: "2vh",
              backgroundColor: "lightgrey",
              marginRight: mobile ? "0vw" : "20vw",
              overflowY: "auto",
            }}
          >
            <label
              style={{
                fontSize: ".8rem",
                fontWeight: 700,
                marginRight: "2vw",
              }}
            >
              Same Styles:
            </label>
            <label
              style={{
                fontSize: ".8rem",
                fontWeight: 600,
              }}
            >
              {`${playTypes}`}
            </label>
          </div>
        </div>

        <div
          style={{
            height: "5vh",
            width: "90%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#5E0516",
            boxShadow: "2px 2px 9px #000000",
            marginTop: "2vh",
          }}
        >
          <h3
            style={{
              color: "#eed77a",

              marginLeft: "10vw",
            }}
          >{`Other Info:`}</h3>
          <button
            style={{
              position: "relative",
              top: "0vh",
              left: "20vw",
            }}
            onClick={(e) => handleEditOtherInfoModal(e)}
          >
            Edit
          </button>
        </div>

        <div
          style={{
            height: "90vh",
            width: "90%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "ghostwhite",
            boxShadow: "2px 2px 9px #000000",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "3vh",
              width: "90%",
              alignItems: "center",
              justifyContent: "left",
              marginBottom: "2vh",
              backgroundColor: "lightgrey",
              marginRight: mobile ? "0vw" : "20vw",
              marginTop: "2vh",
            }}
          >
            <label
              style={{
                fontSize: ".8rem",
                fontWeight: 700,
                marginRight: "2vw",
              }}
            >
              Skills & trades:
            </label>
            <label
              style={{
                fontSize: ".8rem",
                fontWeight: 600,
              }}
            >
              {`${skills}`}
            </label>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "3vh",
              width: "90%",
              alignItems: "center",
              justifyContent: "left",
              marginBottom: "2vh",
              backgroundColor: "lightgrey",
              marginRight: mobile ? "0vw" : "20vw",
            }}
          >
            <label
              style={{
                fontSize: ".8rem",
                fontWeight: 700,
                marginRight: "2vw",
              }}
            >
              Other info:
            </label>
            <label
              style={{
                fontSize: ".8rem",
                fontWeight: 600,
              }}
            >
              {`${otherInfo}`}
            </label>
          </div>
        </div>

        <div
          style={{
            height: "5vh",
            width: "90%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#5E0516",
            boxShadow: "2px 2px 9px #000000",
            marginTop: "2vh",
          }}
        >
              <button >
                Edit
              </button>
          <h3
            style={{
              color: "#eed77a",

              marginLeft: "10vw",
            }}
          >{`Badges`}</h3>
          <button
            style={{
              position: "relative",
              top: "0vh",
              left: "24vw",
            }}
            onClick={(e) => handleEditPlayerInfoModal(e)}
          >
            Edit
          </button>
        </div>

        <div
          style={{
            height: "90vh",
            width: "90%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "ghostwhite",
            boxShadow: "2px 2px 9px #000000",
          }}
        >
          <BadgeComponent badgeInfo={badgeInfo} />
        </div>

        <div
          style={{
            height: "5vh",
            width: "90%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#5E0516",
            boxShadow: "2px 2px 9px #000000",
            marginTop: "2vh",
          }}
        >
          <h3
            style={{
              color: "#eed77a",

              marginLeft: "10vw",
            }}
          >{`Medals & Awards:`}</h3>
          <button
            style={{
              position: "relative",
              top: "0vh",
              left: "11vw",
            }}
            onClick={(e) => handleEditPlayerInfoModal(e)}
          >
            Edit
          </button>
        </div>

        <div
          style={{
            height: "90vh",
            width: "90%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "ghostwhite",
            boxShadow: "2px 2px 9px #000000",
            paddingTop: "4vh",
            paddingBottom: "4vh",
          }}
        >
          <MedalComponent medalInfo={medalInfo} />
        </div>

        <div
          style={{
            height: "5vh",
            width: "90%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#5E0516",
            boxShadow: "2px 2px 9px #000000",
            marginTop: "2vh",
          }}
        >
          <h3
            style={{
              color: "#eed77a",

              marginLeft: "10vw",
            }}
          >{`CEF Events Attended:`}</h3>
          <button
            style={{
              position: "relative",
              top: "0vh",
              left: "8vw",
            }}
            onClick={(e) => handleEditPlayerInfoModal(e)}
          >
            Edit
          </button>
        </div>

        <div
          style={{
            height: "auto",
            width: "90%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "ghostwhite",
            boxShadow: "2px 2px 9px #000000",
            paddingTop: "4vh",
            paddingBottom: "4vh",
          }}
        >
          <EventComponent playerEvents={playerEvents} />
        </div>
      </div>
    );
  };

  return (
    <>
      <EditExperienceInfoModal
        fetchUser={fetchUser}
        editExperienceInfoOpen={editExperienceInfoOpen}
        handleEditExperienceInfoModal={handleEditExperienceInfoModal}
        updateExperienceInfo={updateExperienceInfo}
        years={years}
        team={homeTeam}
        styles={playTypes}
      />

      <EditContactInfoModal
        editContactInfoOpen={editContactInfoOpen}
        handleEditContactInfoModal={handleEditContactInfoModal}
        fetchUser={fetchUser}
        updateContactInfo={updateContactInfo}
        address={address}
        city={city}
        country={country}
        state={state}
        email={email}
        phone={phone}
        showLocation={showLocation}
        emergency={EmergencyContact}
      />
      <EditPlayerInfoModal
        fetchUser={fetchUser}
        updatePlayerInfo={updatePlayerInfo}
        editPlayerInfoOpen={editPlayerInfoOpen}
        handleEditPlayerInfoModal={handleEditPlayerInfoModal}
        middleName={middleName}
        setMiddleName={setMiddleName}
        lastName={lastName}
        setLastName={setLastName}
        firstName={firstName}
        setFirstName={setFirstName}
        callsign={callsign}
        setCallsign={setCallsign}
        rank={rank}
        setRank={setRank}
        unit={unit}
        setUnit={setUnit}
        company={company}
        setCompany={setCompany}
        showName={showName}
        section={section}
        setSection={setSection}
        age={age}
        setAge={setAge}
      />
      <EditOtherInfoModal
        fetchUser={fetchUser}
        updatePlayerInfo={updatePlayerInfo}
        editOtherInfoOpen={editOtherInfoOpen}
        handleEditOtherInfoModal={handleEditOtherInfoModal}
        skills={skills}
        otherInfo={otherInfo}
        updateOtherInfo={updateOtherInfo}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          backgroundColor: "#adc8e6",
          minHeight: mobile ? "100vh" : "",
        }}
      >
        <Header />
        <div
          style={{
            height: "88vh",
            width: "100%",
            display: "flex",
            flexDirection: mobile ? "column" : "row",
            alignItems: "center",
            flexGrow: mobile ? 1 : 0,
          }}
        >
          <UserSidebar />

          {mobile ? <MobileFormat /> : <DesktopFormat />}
        </div>
        <Footer />
        {/* {redirect && !user ? <Navigate push to="/login" /> : null} */}
      </div>
    </>
  );
};
