// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../static/hexmap_background.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contactsmain {
  position: fixed;
  height: 100%;
  width: 100%;
  margin-top: 0vh;
  margin: 0%;
  padding: 0px;
  overflow: auto;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat;
  background-size: cover;
}
.textDiv {
  margin-left: 20%;
  margin-top: 10vh;
  margin-bottom: 20vh;
  height: 65%;
  background-color: rgba(0, 0, 0, 0.65);
  width: 60%;
}

@media only screen and (max-width: 384px) {
  .textDiv {
    margin: auto;
    width: 80%;
    background-color: rgba(0, 0, 0, 0.65);
    margin-bottom: 2vh;
  }
}

.contactPage {
  position: fixed;
  width: 100%;
  height: 100%;
  margin-top: 0vh;
  margin: 0%;
  padding: 0px;
  overflow: auto;
  background-color: rgb(78, 171, 218);
  /* background-color: rgba(233, 239, 243, 0.29); */
  background-size: cover;
}
`, "",{"version":3,"sources":["webpack://./src/pages/contact/styles.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,WAAW;EACX,eAAe;EACf,UAAU;EACV,YAAY;EACZ,cAAc;EACd,6DAA+D;EAC/D,mEAAqE;EAIrE,sBAAsB;AACxB;AACA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;EACnB,WAAW;EACX,qCAAqC;EACrC,UAAU;AACZ;;AAEA;EACE;IACE,YAAY;IACZ,UAAU;IACV,qCAAqC;IACrC,kBAAkB;EACpB;AACF;;AAEA;EACE,eAAe;EACf,WAAW;EACX,YAAY;EACZ,eAAe;EACf,UAAU;EACV,YAAY;EACZ,cAAc;EACd,mCAAmC;EACnC,iDAAiD;EAIjD,sBAAsB;AACxB","sourcesContent":[".contactsmain {\n  position: fixed;\n  height: 100%;\n  width: 100%;\n  margin-top: 0vh;\n  margin: 0%;\n  padding: 0px;\n  overflow: auto;\n  background: url(\"../../static/hexmap_background.png\") no-repeat;\n  background-image: url(\"../../static/hexmap_background.png\") no-repeat;\n  -webkit-background-size: cover;\n  -moz-background-size: cover;\n  -o-background-size: cover;\n  background-size: cover;\n}\n.textDiv {\n  margin-left: 20%;\n  margin-top: 10vh;\n  margin-bottom: 20vh;\n  height: 65%;\n  background-color: rgba(0, 0, 0, 0.65);\n  width: 60%;\n}\n\n@media only screen and (max-width: 384px) {\n  .textDiv {\n    margin: auto;\n    width: 80%;\n    background-color: rgba(0, 0, 0, 0.65);\n    margin-bottom: 2vh;\n  }\n}\n\n.contactPage {\n  position: fixed;\n  width: 100%;\n  height: 100%;\n  margin-top: 0vh;\n  margin: 0%;\n  padding: 0px;\n  overflow: auto;\n  background-color: rgb(78, 171, 218);\n  /* background-color: rgba(233, 239, 243, 0.29); */\n  -webkit-background-size: cover;\n  -moz-background-size: cover;\n  -o-background-size: cover;\n  background-size: cover;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
