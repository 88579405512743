import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../common/AuthContext";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { DataContext } from "../../common/DataContext";
import { Navigate } from "react-router-dom";

export const Login = () => {
  const { logInUser, email, setEmail, password, setPassword, redirect } =
    useContext(AuthContext);

  const { mobile } = useContext(DataContext);

  const handleLogin = () => {
    logInUser();
  };

  // useEffect(() => {
  //   const handleKeyPress = (event) => {
  //     if (event.key === 'Enter') {
  //       logInUser();
  //     }
  //   };
  //   document.addEventListener('keydown', handleKeyPress);
  //   return () => {
  //     document.removeEventListener('keydown', handleKeyPress);
  //   };
  // }, []);

  return (
    <>
      {mobile && mobile == false ? (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "#adc8e6",
              height: "100vh",
            }}
          >
            <Header />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "88vh",
              }}
            >
              <h1>Login</h1>
              <form>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: "1vh",
                  }}
                >
                  <div style={{ width: "5vw" }}>
                    <label>Login Username: </label>
                  </div>

                  <input
                    type="text"
                    value={email && email}
                    onChange={(e) => setEmail(e.target.value)}
                    id=""
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "5vw" }}>
                    <label>Password: </label>
                  </div>
                  <input
                    type="password"
                    value={password && password}
                    onChange={(e) => setPassword(e.target.value)}
                    id=""
                  />
                </div>
              </form>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: "2vh",
                }}
              >
                <button type="button" onClick={(e) => handleLogin(e)}>
                  Submit
                </button>
              </div>
            </div>

            <Footer />
          </div>
        </>
      ) : (
        <>
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                minHeight: "99.9vh", // Ensure the container takes at least the full viewport height
                backgroundColor: "#adc8e6",
              }}
            >
              <Header />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  flexGrow: 1, // Allow the content to take remaining space
                  padding: "1em",
                }}
              >
                <h1>Login</h1>
                <form style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: "1vh",
                    }}
                  >
                    <div style={{ width: "20%" }}>
                      <label>Login Username: </label>
                    </div>

                    <input
                      style={{ width: "80%" }}
                      type="text"
                      value={email && email}
                      placeholder="Case Sensitive"
                      onChange={(e) => setEmail(e.target.value)}
                      id=""
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: "1vh",
                    }}
                  >
                    <div style={{ width: "20%" }}>
                      <label>Password: </label>
                    </div>
                    <input
                      style={{ width: "80%" }}
                      type="password"
                      placeholder="Case Sensitive"
                      value={password && password}
                      onChange={(e) => setPassword(e.target.value)}
                      id=""
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: "2vh",
                    }}
                  >
                    <button type="button" onClick={() => handleLogin()}>
                      Submit
                    </button>
                  </div>
                </form>
              </div>

              <Footer />
            </div>
            {!redirect ? null : <Navigate push to="/userHome" />}
          </>
        </>
      )}

      {!redirect ? null : <Navigate push to="/userHome" />}
    </>
  );
};
