import React, { useState, useContext, useEffect } from "react";
import { DataContext } from "../../common/DataContext/index";
export const MediaText = () => {
  const { mobile } = useContext(DataContext);

  return (
    <>
      <div
        style={{
          height: mobile ? "60vh" : "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          // justifyContent: "center",
        }}
      >
        <div
          style={{
            height: mobile ? "25vh" : "20vh",
            width: mobile ? "90vw" : "60%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#5E0516",
            boxShadow: "1px 1px 6px 2px black",
            border: "solid",
            borderWidth: "4px",
            borderColor: "#eed77a",
            marginTop: "3vh",
          }}
        >
          <h3
            style={{ color: "#eed77a" }}
          >{`CEF Official Youtube Channel:`}</h3>
          <a
            href={`http://www.youtube.com/@CommonwealthForces`}
            style={{ textDecoration: "none", fontSize: ".75rem" }}
            target="_blank"
          >
            <h4 style={{ color: "#eed77a", textDecoration: "none" }}>
              {`http://www.youtube.com/@CommonwealthForces`}
            </h4>
          </a>
        </div>

        <div
          style={{
            height: mobile ? "25vh" : "20vh",
            width: mobile ? "90vw" : "60%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#5E0516",
            boxShadow: "1px 1px 6px 2px black",
            border: "solid",
            borderWidth: "4px",
            borderColor: "#eed77a",
            marginTop: "2vh",
            overflowY: "scroll",
          }}
        >
          <h3
            style={{ color: "#eed77a", textDecoration: "none" }}
          >{`CEF Members Youtube Channels:`}</h3>
          <a
            href={`http://www.youtube.com/@bakercompany123`}
            style={{ textDecoration: "none", fontSize: ".75rem" }}
            target="_blank"
          >
            <h4 style={{ color: "#eed77a" }}>
              {`http://www.youtube.com/@bakercompany123`}
            </h4>
          </a>

          <a
            href={`http://www.youtube.com/@Commonwealthgoose`}
            style={{ textDecoration: "none", fontSize: ".75rem" }}
            target="_blank"
          >
            <h4 style={{ color: "#eed77a", textDecoration: "none" }}>
              {`http://www.youtube.com/@Commonwealthgoose`}
            </h4>
          </a>
        </div>
      </div>
    </>
  );
};
