import React, { useState, useContext, useEffect } from "react";
import { DataContext } from "../../common/DataContext/index";
import { AuthContext } from "../../common/AuthContext";
import "./styles.css";
import { Navigate } from "react-router-dom";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { poster } from "../../calls/calls";
import { UserSidebar } from "../../components/common";

import { MapWidget } from "./mapWidget";
export const MapPage = () => {
  useEffect(() => {
    let outpack = {};
    poster(
      outpack,
      "https://octopus-app-88oau.ondigitalocean.app" + "/api/time",
    ).then((response) => console.log(response));
  }, []);

  const { mobile } = useContext(DataContext);

  const { user, redirect } = useContext(AuthContext);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          backgroundColor: "black",
          minHeight: mobile ? "100vh" : "",
        }}
      >
        <Header />
        <div
          style={{
            height: "88vh",
            width: "100%",
            display: "flex",
            flexDirection: mobile ? "column" : "row",
            alignItems: "center",
            flexGrow: mobile ? 1 : 0,
          }}
        >
          {user && user !== null ? (
            <>
              <UserSidebar />
            </>
          ) : null}
          <div
            style={{
              height: mobile ? "78vh" : "88vh",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              // alignItems: "center",
            }}
          >
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                // justifyContent:'top',
                // backgroundColor:'ghostwhite',
                msrginRight: "1vw",
              }}
            >
              <MapWidget />
            </div>
          </div>
        </div>
        <Footer />
        {/* {!redirect ? null : <Navigate push to="/login" />} */}
      </div>
    </>
  );
};
