import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../common/DataContext/index";
import { AuthContext } from "../../common/AuthContext";
import { Navigate } from "react-router-dom";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { UserSidebar } from "../../components/common";
import { Tooltip } from "@mui/material";
import logo from "../../static/NavIcon.png";
import useToggle from "../../common/hooks/useToggle";

const ParagraphComponent = ({ text }) => {
  return (
    <div
      style={{
        whiteSpace: "pre-line",
        color: "#eed77a",
        textDecoration: "none",
        textAlign: "center",
        marginBottom: "1vh",
        fontWeight: 500,
      }}
    >
      {text}
    </div>
  );
};

export const Medals = () => {
  var medal_image;
  var new_image;

  const { mobile, medals, fetchMedals, updateMedals, deleteMedal } =
    useContext(DataContext);

  const { user } = useContext(AuthContext);
  const [medalModalOpen, toggleMedalModalOpen] = useToggle();
  const [modalMode, setModalMode] = useState(null);
  const [selectedMedal, setSelectedMedal] = useState(null);

  const handleSetModalMode = (e) => {
    setModalMode(e);
  };

  const handleOpenMedalModal = (e, mode, selected_medal = null) => {
    console.log(mode);
    handleSetModalMode(mode);
    if (selected_medal) {
      setSelectedMedal(selected_medal);
    }
    toggleMedalModalOpen();
  };

  useEffect(() => {
    fetchMedals();
  }, []);

  const EditMedalModal = (props) => {
    const { mobile } = useContext(DataContext);
    const [tempImageUrl, setTempImageUrl] = useState(null);
    const [tempDescription, setTempDescription] = useState(null);
    const [tempId, setTempId] = useState(null);
    const [tempName, setTempName] = useState(null);


    useEffect(() => {
      console.log(props.selectedMedal)
      if (props.selectedMedal && props.selectedMedal !== null) {
        setTempId(props.selectedMedal.id);
        setTempName(props.selectedMedal.name);
        setTempDescription(props.selectedMedal.description);

        setTempImageUrl(props.selectedMedal.url);

      }
    }, [props.selectedMedal]);

    useEffect(() => {
      console.log(tempId);
    }, [tempId]);



    const handleSetTempName = (e) => {
      setTempName(e.target.value);
    };

    const handleSetTempDescription = (e) => {
      setTempDescription(e.target.value);
    };

    const handleSetTempImageUrl = (e) => {
      medal_image = e.target.files;
      new_image = true;
      console.log(e.target.files);
      const reader = new FileReader();
      const selectedFile = e.target.files[0];
      reader.onload = (event) => {
        const fileString = event.target.result;
        setTempImageUrl(fileString);
      };
      reader.readAsDataURL(selectedFile);
    };


    const handleUpdateMedalDetails = (e) => {
      updateMedals(
        tempName,
        tempDescription,
        tempId,
        new_image ? medal_image : null,
      );
      new_image = false;
      toggleMedalModalOpen();
      fetchMedals();
    };

    const handleDeleteMedal= (id) => {
      console.log(id);
      deleteMedal(tempId);
      toggleMedalModalOpen();
    };

    return (
      <>
        {props.medalModalOpen && props.medalModalOpen === true ? (
          <>
            <div
              style={{
                position: "fixed",
                top: "6vh",
                left: mobile ? "4vw" : "32vw",
                height: mobile ? "70vh" : "auto",
                width: mobile ? "92vw" : "50vw",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "left",
                marginTop: "1vh",
                backgroundColor: "ghostwhite",
                boxShadow: "2px 2px 9px #000000",
                zIndex: "999",
                flexWrap: "wrap",
                paddingBottom: "2vh",
              }}
            >
              <div
                style={{
                  height: "5vh",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#5E0516",
                  boxShadow: "2px 2px 9px #000000",
                }}
              >
                <h3
                  style={{ color: "#eed77a" }}
                >{`${props.modalMode} Medal:`}</h3>
              </div>

              <div
                style={{
                  height: "auto",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent:'center',
                  overflowY: "scroll",
                  // backgroundColor: "lightgrey",
                  paddingTop: "2vh",
                }}
              >

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          width: "auto",
                          height: "25vh",
                          boxShadow: "2px 2px 9px #000000",
                          padding: "2px",
                          marginBottom: "1vw",
                        }}
                      >
                        <label
                          style={{
                            color: "black",
                            fontSize: "1remm",
                            fontWeight: 400,
                          }}
                        >
                          {"Medal Image"}
                        </label>
                        <img
                          style={{
                            width: mobile ? "25vw" : "6.6vw",
                            height: "auto",
                            padding: mobile ? "" : "1vh",
                          }}
                          src={
                            tempImageUrl && tempImageUrl !== null ? tempImageUrl : logo
                          }
                          alt={" medal image"}
                        />
                        <input
                          type="file"
                          accept=".jpg, .png, .jpeg,"
                          style={{
                            // backgroundColor:'grey',
                            width: "auto",
                            color: "black",
                          }}
                          onChange={(e) => handleSetTempImageUrl(e)}
                          multiple={true}
                        />
                      </div>





                <div
                  style={{
                    height: "auto",
                    width: "70%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "right",
                    // backgroundColor:'grey',
                    marginBottom: "1vh",
                    marginTop: "1vh",
                    paddingRight: "14vw",
                  }}
                >
                  <label
                    style={{
                      color: "black",
                      fontSize: "1remm",
                      fontWeight: 400,
                      marginRight: "2vw",
                    }}
                  >
                    {"Name:"}
                  </label>

                  <input
                    type="text"
                    style={{
                      width: "20vw",
                    }}
                    value={tempName}
                    onChange={(e) => handleSetTempName(e)}
                  ></input>
                </div>

                <div
                  style={{
                    height: "auto",
                    width: "70%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "right",
                    // backgroundColor:'grey',
                    marginBottom: "1vh",
                    paddingRight: "14vw",
                  }}
                >
                  <label
                    style={{
                      color: "black",
                      fontSize: "1remm",
                      fontWeight: 400,
                      marginRight: "2vw",
                    }}
                  >
                    {"Description:"}
                  </label>

                  <textarea
                    style={{
                      width: "20vw",
                      height: "10vh",
                    }}
                    value={tempDescription}
                    onChange={(e) => handleSetTempDescription(e)}
                  ></textarea>
                </div>

               

              

              

                <div
                  style={{
                    height: "10%",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom:'2vh'
                  }}
                >
                  <button
                    style={{ marginRight: "2vw" }}
                    onClick={(e) => handleOpenMedalModal(e)}
                  >
                    Cancel
                  </button>
                  {props.modalMode === "Edit" ? (
                    <>
                      <button
                        style={{ marginRight: "2vw" }}
                        onClick={(e) => handleDeleteMedal(tempId)}
                      >
                        Delete
                      </button>
                    </>
                  ) : null}

                  <button 
                  onClick={(e) => handleUpdateMedalDetails(e)}
                  >
                    {props.modalMode}
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </>
    );
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          backgroundColor: "#adc8e6",
          minHeight: mobile ? "100vh" : "",
        }}
      >
        <Header />
        <div
          style={{
            height: "88vh",
            width: "100%",
            display: "flex",
            flexDirection: mobile ? "column" : "row",
            alignItems: "center",
            flexGrow: mobile ? 1 : 0,
          }}
        >
          {user && user !== null ? (
            <>
              <UserSidebar />
            </>
          ) : null}
          {user && user.role === "admin" ? (
            <>
              <button
                style={{
                  position: "fixed",
                  top: "8vh",
                  left: "10vw",
                }}
                value={"Add"}
                onClick={(e) => handleOpenMedalModal(e, "Add")}
              >
                Add Medal
              </button>
            </>
          ) : null}
          <EditMedalModal
            medalModalOpen={medalModalOpen}
            modalMode={modalMode}
            updateMedals={updateMedals}
            selectedMedal={selectedMedal}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: mobile ? "70vh" : "80vh",
              alignItems: "center",
              overflowY: "scroll",
              paddingTop: "2vh",
              paddingBottom: "6vh",
            }}
          >
            {medals && medals.length > 0 ? (
              medals.slice().map((row, index) => {
                const {
                  id,
                  name,
                  description,
                  url,
                } = row;
                return (
                  <>
                    <div
                      key={index}
                      style={{
                        height: "Auto",
                        width: mobile ? "80vw" : "50%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "1vh",

                        backgroundColor: "#5E0516",
                        boxShadow: "1px 1px 6px 2px black",
                        border: "solid",
                        borderWidth: "4px",
                        borderColor: "#eed77a",
                      }}
                    >
                      <label
                        style={{
                          color: "#eed77a",
                          fontSize: "1.2rem",
                          fontWeight: 800,
                          marginTop: "1vh",
                        }}
                        value={"Add"}
                        onClick={
                          user.role === "admin"
                            ? (e) => handleOpenMedalModal(e, "Edit", row)
                            : null
                        }
                      >
                        {name}
                      </label>
                      <p
                        style={{
                          height: "2vh",
                          color: "#eed77a",
                          fontSize: ".5remm",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          fontWeight: 500,
                          marginTop: "1vh",
                          textAlign: "center",
                        }}
                      >{`Description:`}</p>
                      <ParagraphComponent text={description} />

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            marginBottom: "1vh",
                            marginRight: "2vw",
                          }}
                        >

                            <img
                              style={{
                                width: mobile ? "25vw" : "6.6vw",
                                height: "auto",
                                padding: mobile ? "" : "1vh",
                              }}
                              src={url}
                              alt={name}
                            /> 
                        </div>
                      </div>
                    </div>
                  </>
                );
              })
            ) : (
              <>CEF Medals</>
            )}


            
          </div>
        </div>
        <Footer />
        {/* {!redirect ? null : <Navigate push to="/login" />} */}
      </div>
    </>
  );
};
