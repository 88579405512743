import React, { useEffect, useContext, useState } from "react";
import { Tooltip } from "@mui/material";
import { DataContext } from "../../common/DataContext";
import useToggle from "../../common/hooks/useToggle";

const chunkArray = (array, chunkSize) => {
  const result = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }
  return result;
};

export const EditExperienceInfoModal = (props) => {
  const [tempYears, setTempYears] = useState(null);
  const [tempTeam, setTempTeam] = useState(null);
  const [tempStyles, setTempStyles] = useState([]);
  const { mobile } = useContext(DataContext);
  useEffect(() => {
    if (props.editExperienceInfoOpen === true) {
      setTempYears(props.years);
      setTempTeam(props.team);
      if (props.styles === "") {
        setTempStyles([]);
      } else {
        setTempStyles(props.styles.split(","));
      }
    }
    // eslint-disable-next-line
  }, [props.editExperienceInfoOpen]);

  const handleUpdateUserInfo = () => {
    props.updateExperienceInfo(tempYears, tempTeam, tempStyles);
    props.handleEditExperienceInfoModal();
    props.fetchUser();
  };

  const handleSetTempPlayTypes = (e) => {
    let tempList = [...tempStyles];
    if (tempList && tempList.includes(e.target.value)) {
      tempList = tempList.filter((type) => type !== e.target.value);
    } else {
      tempList.push(e.target.value);
    }

    setTempStyles(tempList);
  };

  return (
    <>
      {props.editExperienceInfoOpen && props.editExperienceInfoOpen === true ? (
        <>
          <div
            style={{
              position: "fixed",
              top: "20vh",
              left: mobile ? "4vw" : "32vw",
              height: mobile ? "70vh" : "60%",
              width: mobile ? "92vw" : "40vw",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "left",
              marginTop: "1vh",
              backgroundColor: "ghostwhite",
              boxShadow: "2px 2px 9px #000000",
              zIndex: "999",
              flexWrap: "wrap", // Allow items to wrap to the next row
            }}
          >
            <div
              style={{
                height: "5vh",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#5E0516",
                boxShadow: "2px 2px 9px #000000",
              }}
            >
              <h3 style={{ color: "#eed77a" }}>{`Edit Experience Info:`}</h3>
            </div>

            <div
              style={{
                height: "80%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                // backgroundColor: "lightgrey",
              }}
            >
              <h3>Edit any or all of the fields below:</h3>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  width: "100%",
                  alignItems: "center",
                  // justifyContent:'center',
                  // backgroundColor:'red'
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    height: "3vh",
                    width: mobile ? "90%" : "30vw",
                    alignItems: "center",
                    justifyContent: "right",
                    marginBottom: "2vh",
                    // backgroundColor:'red',
                    marginRight: mobile ? "0vw" : "2vw",
                  }}
                >
                  <label
                    style={{
                      height: mobile ? "3vh" : "2vh",
                      marginRight: "2vw",
                      textJustify: "auto",
                      paddingBottom: ".5vh",
                    }}
                  >
                    {mobile ? "Years XP" : "Years Experience:"}
                  </label>

                  <input
                    type="text"
                    style={{
                      width: mobile ? "45vw" : "15vw",
                      height: "2vh",
                      marginRight: mobile ? "4vw" : "",
                    }}
                    value={tempYears}
                    onChange={(e) => setTempYears(e.target.value)}
                    id=""
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    height: "3vh",
                    width: mobile ? "90%" : "30vw",
                    alignItems: "center",
                    justifyContent: "right",
                    marginBottom: "3vh",
                    // backgroundColor:'red',
                    marginRight: mobile ? "0vw" : "2vw",
                  }}
                >
                  <label
                    style={{
                      height: mobile ? "3vh" : "2vh",
                      marginRight: "2vw",
                      textJustify: "auto",
                      paddingBottom: ".5vh",
                    }}
                  >
                    Home Team:
                  </label>

                  <input
                    type="text"
                    style={{
                      width: mobile ? "45vw" : "15vw",
                      height: "2vh",
                      marginRight: mobile ? "4vw" : "",
                    }}
                    value={tempTeam}
                    onChange={(e) => setTempTeam(e.target.value)}
                    id=""
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: mobile ? "column" : "row",
                    height: "3vh",
                    width: mobile ? "90%" : "30vw",
                    alignItems: "center",
                    justifyContent: "right",
                    marginBottom: "2vh",
                    // backgroundColor:'red',
                    marginRight: mobile ? "0vw" : "2vw",
                  }}
                >
                  <label
                    style={{
                      height: mobile ? "3vh" : "2vh",
                      marginRight: "2vw",
                      textJustify: "auto",
                      paddingBottom: ".5vh",
                    }}
                  >
                    Styles:
                  </label>

                  <div
                    style={{
                      width: "auto",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "auto",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "left",
                      }}
                    >
                      <label>Speedball:</label>
                      <input
                        type="checkbox"
                        value="Speedball"
                        checked={tempStyles.includes("Speedball")}
                        onChange={(e) => handleSetTempPlayTypes(e)}
                        id=""
                      />
                      <label style={{ marginLeft: "1vh" }}>Woodsball:</label>
                      <input
                        type="checkbox"
                        value="Woodsball"
                        checked={tempStyles.includes("Woodsball")}
                        onChange={(e) => handleSetTempPlayTypes(e)}
                        id=""
                      />

                      <label style={{ marginLeft: "1vh" }}>Scenario:</label>
                      <input
                        type="checkbox"
                        value="Scenario"
                        checked={tempStyles.includes("Scenario")}
                        onChange={(e) => handleSetTempPlayTypes(e)}
                        id=""
                      />
                    </div>
                    <div
                      style={{
                        width: "auto",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "left",
                      }}
                    >
                      <label>Magfed:</label>
                      <input
                        type="checkbox"
                        value="Magfed"
                        checked={tempStyles.includes("Magfed")}
                        onChange={(e) => handleSetTempPlayTypes(e)}
                        id=""
                      />
                      <label style={{ marginLeft: "1vh" }}>Pump:</label>
                      <input
                        type="checkbox"
                        value="Pump"
                        checked={tempStyles.includes("Pump")}
                        onChange={(e) => handleSetTempPlayTypes(e)}
                        id=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                height: "10%",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                style={{ marginRight: "2vw" }}
                onClick={(e) => props.handleEditExperienceInfoModal(e)}
              >
                Cancel
              </button>
              <button onClick={() => handleUpdateUserInfo()}>Confirm</button>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export const EditOtherInfoModal = (props) => {
  const [tempSkills, setTempSkills] = useState(null);
  const [tempOtherInfo, setTempOtherInfo] = useState(null);
  const { mobile } = useContext(DataContext);
  useEffect(() => {
    if (props.editOtherInfoOpen === true) {
      setTempSkills(props.skills);
      setTempOtherInfo(props.otherInfo);
    }
    // eslint-disable-next-line
  }, [props.editOtherInfoOpen]);

  const handleUpdateUserInfo = () => {
    props.updateOtherInfo(tempSkills, tempOtherInfo);
    props.handleEditOtherInfoModal();
    props.fetchUser();
  };

  return (
    <>
      {props.editOtherInfoOpen && props.editOtherInfoOpen === true ? (
        <>
          <div
            style={{
              position: "fixed",
              top: "20vh",
              left: mobile ? "4vw" : "32vw",
              height: mobile ? "70vh" : "60%",
              width: mobile ? "92vw" : "40vw",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "left",
              marginTop: "1vh",
              backgroundColor: "ghostwhite",
              boxShadow: "2px 2px 9px #000000",
              zIndex: "999",
              flexWrap: "wrap", // Allow items to wrap to the next row
            }}
          >
            <div
              style={{
                height: "5vh",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#5E0516",
                boxShadow: "2px 2px 9px #000000",
              }}
            >
              <h3 style={{ color: "#eed77a" }}>{`Edit Experience Info:`}</h3>
            </div>

            <div
              style={{
                height: "80%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                // backgroundColor: "lightgrey",
              }}
            >
              <h3>Edit any or all of the fields below:</h3>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  width: "100%",
                  alignItems: "center",
                  // justifyContent:'center',
                  // backgroundColor:'red'
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    height: "3vh",
                    width: mobile ? "90%" : "30vw",
                    alignItems: "center",
                    justifyContent: "right",
                    marginBottom: "2vh",
                    // backgroundColor:'red',
                    marginRight: mobile ? "0vw" : "2vw",
                  }}
                >
                  <label
                    style={{
                      height: mobile ? "3vh" : "2vh",
                      marginRight: "2vw",
                      textJustify: "auto",
                      paddingBottom: ".5vh",
                    }}
                  >
                    {"Skills/Trades:"}
                  </label>

                  <input
                    type="text"
                    style={{
                      width: mobile ? "45vw" : "15vw",
                      height: "2vh",
                      marginRight: mobile ? "4vw" : "",
                    }}
                    value={tempSkills}
                    onChange={(e) => setTempSkills(e.target.value)}
                    id=""
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    height: "3vh",
                    width: mobile ? "90%" : "30vw",
                    alignItems: "center",
                    justifyContent: "right",
                    marginBottom: "3vh",
                    // backgroundColor:'red',
                    marginRight: mobile ? "0vw" : "2vw",
                  }}
                >
                  <label
                    style={{
                      height: mobile ? "3vh" : "2vh",
                      marginRight: "2vw",
                      textJustify: "auto",
                      paddingBottom: ".5vh",
                    }}
                  >
                    Other Info:
                  </label>

                  <input
                    type="text"
                    style={{
                      width: mobile ? "45vw" : "15vw",
                      height: "2vh",
                      marginRight: mobile ? "4vw" : "",
                    }}
                    value={tempOtherInfo}
                    onChange={(e) => setTempOtherInfo(e.target.value)}
                    id=""
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                height: "10%",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                style={{ marginRight: "2vw" }}
                onClick={(e) => props.handleEditOtherInfoModal(e)}
              >
                Cancel
              </button>
              <button onClick={() => handleUpdateUserInfo()}>Confirm</button>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export const EditContactInfoModal = (props) => {
  const [tempAddress, setTempAddress] = useState(null);
  const [tempCity, setTempCity] = useState(null);
  const [tempCountry, setTempCountry] = useState(null);
  const [tempState, setTempState] = useState(null);
  const [tempEmail, setTempEmail] = useState(null);
  const [tempPhone, setTempPhone] = useState(null);
  const [tempEmergency, setTempEmergency] = useState(null);
  const [tempShowLocation, toggleTempShowLocation] = useToggle(false);
  const { mobile } = useContext(DataContext);
  useEffect(() => {
    if (props.editContactInfoOpen === true) {
      setTempAddress(props.address);
      setTempCity(props.city);
      setTempCountry(props.country);
      setTempState(props.state);
      setTempEmail(props.email);
      setTempPhone(props.phone);
      setTempEmergency(props.emergency);
      toggleTempShowLocation(props.showLocation);
    }
    // eslint-disable-next-line
  }, [props.editContactInfoOpen]);

  const handleUpdateUserInfo = () => {
    props.updateContactInfo(
      tempAddress,
      tempCity,
      tempCountry,
      tempState,
      tempEmail,
      tempPhone,
      tempEmergency,
      tempShowLocation,
    );
    props.handleEditContactInfoModal();
    props.fetchUser();
  };

  const handleToggleShowLocation = (e) => {
    toggleTempShowLocation();
  };

  return (
    <>
      {props.editContactInfoOpen && props.editContactInfoOpen === true ? (
        <>
          <div
            style={{
              position: "fixed",
              top: "20vh",
              left: mobile ? "4vw" : "32vw",
              height: mobile ? "70vh" : "60%",
              width: mobile ? "92vw" : "40vw",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "left",
              marginTop: "1vh",
              backgroundColor: "ghostwhite",
              boxShadow: "2px 2px 9px #000000",
              zIndex: "999",
              flexWrap: "wrap", // Allow items to wrap to the next row
            }}
          >
            <div
              style={{
                height: "5vh",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#5E0516",
                boxShadow: "2px 2px 9px #000000",
              }}
            >
              <h3 style={{ color: "#eed77a" }}>{`Edit Contact Info:`}</h3>
            </div>

            <div
              style={{
                height: "80%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                // backgroundColor: "lightgrey",
              }}
            >
              <h3>Edit any or all of the fields below:</h3>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  width: "100%",
                  alignItems: "center",
                  // justifyContent:'center',
                  // backgroundColor:'red'
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    height: "3vh",
                    width: mobile ? "90%" : "30vw",
                    alignItems: "center",
                    justifyContent: "right",
                    marginBottom: "2vh",
                    // backgroundColor:'red',
                    marginRight: mobile ? "0vw" : "2vw",
                  }}
                >
                  <label
                    style={{
                      height: mobile ? "3vh" : "2vh",
                      marginRight: "2vw",
                      textJustify: "auto",
                      paddingBottom: ".5vh",
                    }}
                  >
                    Address:
                  </label>

                  <input
                    type="text"
                    style={{
                      width: mobile ? "45vw" : "15vw",
                      height: "2vh",
                      marginRight: mobile ? "4vw" : "",
                    }}
                    value={tempAddress}
                    onChange={(e) => setTempAddress(e.target.value)}
                    id=""
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    height: "3vh",
                    width: mobile ? "90%" : "30vw",
                    alignItems: "center",
                    justifyContent: "right",
                    marginBottom: "2vh",
                    // backgroundColor:'red',
                    marginRight: mobile ? "0vw" : "2vw",
                  }}
                >
                  <label
                    style={{
                      height: mobile ? "3vh" : "2vh",
                      marginRight: "2vw",
                      textJustify: "auto",
                      paddingBottom: ".5vh",
                    }}
                  >
                    City:
                  </label>

                  <input
                    type="text"
                    style={{
                      width: mobile ? "45vw" : "15vw",
                      height: "2vh",
                      marginRight: mobile ? "4vw" : "",
                    }}
                    value={tempCity}
                    onChange={(e) => setTempCity(e.target.value)}
                    id=""
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    height: "3vh",
                    width: mobile ? "90%" : "30vw",
                    alignItems: "center",
                    justifyContent: "right",
                    marginBottom: "2vh",
                    // backgroundColor:'red',
                    marginRight: mobile ? "0vw" : "2vw",
                  }}
                >
                  <label
                    style={{
                      height: mobile ? "3vh" : "2vh",
                      marginRight: "2vw",
                      textJustify: "auto",
                      paddingBottom: ".5vh",
                    }}
                  >
                    State:
                  </label>

                  <input
                    type="text"
                    style={{
                      width: mobile ? "45vw" : "15vw",
                      height: "2vh",
                      marginRight: mobile ? "4vw" : "",
                    }}
                    value={tempState}
                    onChange={(e) => setTempState(e.target.value)}
                    id=""
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    height: "3vh",
                    width: mobile ? "90%" : "30vw",
                    alignItems: "center",
                    justifyContent: "right",
                    marginBottom: "2vh",
                    // backgroundColor:'red',
                    marginRight: mobile ? "0vw" : "2vw",
                  }}
                >
                  <label
                    style={{
                      height: mobile ? "3vh" : "2vh",
                      marginRight: "2vw",
                      textJustify: "auto",
                      paddingBottom: ".5vh",
                    }}
                  >
                    Country:
                  </label>

                  <input
                    type="text"
                    style={{
                      width: mobile ? "45vw" : "15vw",
                      height: "2vh",
                      marginRight: mobile ? "4vw" : "",
                    }}
                    value={tempCountry}
                    onChange={(e) => setTempCountry(e.target.value)}
                    id=""
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    height: "3vh",
                    width: mobile ? "90%" : "30vw",
                    alignItems: "center",
                    justifyContent: "right",
                    marginBottom: "2vh",
                    // backgroundColor:'red',
                    marginRight: mobile ? "0vw" : "2vw",
                  }}
                >
                  <label
                    style={{
                      height: mobile ? "3vh" : "2vh",
                      marginRight: "2vw",
                      textJustify: "auto",
                      paddingBottom: ".5vh",
                    }}
                  >
                    Email:
                  </label>

                  <input
                    type="text"
                    style={{
                      width: mobile ? "45vw" : "15vw",
                      height: "2vh",
                      marginRight: mobile ? "4vw" : "",
                    }}
                    value={tempEmail}
                    onChange={(e) => setTempEmail(e.target.value)}
                    id=""
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    height: "3vh",
                    width: mobile ? "90%" : "30vw",
                    alignItems: "center",
                    justifyContent: "right",
                    marginBottom: "2vh",
                    // backgroundColor:'green',
                    marginRight: mobile ? "0vw" : "2vw",
                  }}
                >
                  <label
                    style={{
                      height: mobile ? "3vh" : "2vh",
                      marginRight: "2vw",
                      textJustify: "auto",
                      paddingBottom: ".5vh",
                    }}
                  >
                    Phone:
                  </label>

                  <input
                    type="text"
                    style={{
                      width: mobile ? "45vw" : "15vw",
                      height: "2vh",
                      marginRight: mobile ? "4vw" : "",
                    }}
                    value={tempPhone}
                    onChange={(e) => setTempPhone(e.target.value)}
                    id=""
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    height: "3vh",
                    width: mobile ? "90%" : "30vw",
                    alignItems: "center",
                    justifyContent: "right",
                    // backgroundColor:'red',
                    marginRight: mobile ? "0vw" : "2vw",
                    marginBottom: "2vh",
                  }}
                >
                  <label
                    style={{
                      height: mobile ? "3vh" : "2vh",
                      marginRight: "2vw",
                      textJustify: "auto",
                      paddingBottom: ".5vh",
                    }}
                  >
                    Emergency:
                  </label>

                  <input
                    type="text"
                    style={{
                      width: mobile ? "45vw" : "15vw",
                      height: "2vh",
                      marginRight: mobile ? "4vw" : "",
                    }}
                    onChange={(e) => setTempEmergency(e.target.value)}
                    value={tempEmergency}
                    id=""
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    height: "3vh",
                    width: mobile ? "90%" : "auto",
                    alignItems: "center",
                    justifyContent: "right",
                    paddingRight: "8vw",
                    // backgroundColor:'blue',
                    marginRight: mobile ? "0vw" : "2vw",
                  }}
                >
                  <label
                    style={{
                      height: mobile ? "3vh" : "2vh",
                      marginRight: "2vw",
                      textJustify: "auto",
                      paddingBottom: ".5vh",
                    }}
                  >
                    Show location on public pages:
                  </label>

                  <label
                    style={{
                      height: mobile ? "3vh" : "2vh",

                      textJustify: "auto",
                      paddingBottom: ".5vh",
                    }}
                  >
                    Yes
                  </label>

                  <input
                    onChange={(e) => handleToggleShowLocation(e)}
                    checked={tempShowLocation === true}
                    style={{
                      marginRight: "1vw",
                    }}
                    type="radio"
                  ></input>

                  <label
                    style={{
                      height: mobile ? "3vh" : "2vh",

                      textJustify: "auto",
                      paddingBottom: ".5vh",
                    }}
                  >
                    No
                  </label>
                  <input
                    onChange={(e) => handleToggleShowLocation(e)}
                    checked={tempShowLocation === false}
                    type="radio"
                  ></input>
                </div>
              </div>
            </div>

            <div
              style={{
                height: "10%",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                style={{ marginRight: "2vw" }}
                onClick={(e) => props.handleEditContactInfoModal(e)}
              >
                Cancel
              </button>
              <button onClick={() => handleUpdateUserInfo()}>Confirm</button>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export const EditPlayerInfoModal = (props) => {
  const [tempFirstName, setTempFirstName] = useState(null);
  const [tempLastName, setTempLastName] = useState(null);
  const [tempMiddleName, setTempMiddleName] = useState(null);
  const [tempCallsign, setTempCallsign] = useState(null);
  const [tempAge, setTempAge] = useState(null);
  const [tempRank, setTempRank] = useState(null);
  const [tempUnit, setTempUnit] = useState(null);
  const [tempCompany, setTempCompany] = useState(null);
  const [tempSection, setTempSection] = useState(null);
  const [tempShowName, toggleTempShowName] = useToggle(false);
  const { mobile } = useContext(DataContext);
  useEffect(() => {
    if (props.editPlayerInfoOpen === true) {
      setTempFirstName(props.firstName);
      setTempMiddleName(props.middleName);
      setTempLastName(props.lastName);
      setTempCallsign(props.callsign);
      setTempAge(props.age);
      setTempRank(props.rank);
      setTempUnit(props.unit);
      setTempCompany(props.company);
      setTempSection(props.section);
      toggleTempShowName(props.showName);
    }
    // eslint-disable-next-line
  }, [props.editPlayerInfoOpen]);

  useEffect(() => {
    console.log(tempShowName);
    // eslint-disable-next-line
  }, [tempShowName]);

  const handleUpdateUserInfo = () => {
    props.updatePlayerInfo(
      tempFirstName,
      tempLastName,
      tempMiddleName,
      tempCallsign,
      tempAge,
      tempRank,
      tempUnit,
      tempCompany,
      tempSection,
      tempShowName,
    );
    props.handleEditPlayerInfoModal();
    props.fetchUser();
  };

  const handleToggleShowName = (e) => {
    toggleTempShowName();
  };

  return (
    <>
      {props.editPlayerInfoOpen && props.editPlayerInfoOpen === true ? (
        <>
          <div
            style={{
              position: "fixed",
              top: "20vh",
              left: mobile ? "4vw" : "32vw",
              height: mobile ? "70vh" : "65%",
              width: mobile ? "92vw" : "40vw",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "left",
              marginTop: "1vh",
              backgroundColor: "ghostwhite",
              boxShadow: "2px 2px 9px #000000",
              zIndex: "999",
              flexWrap: "wrap", // Allow items to wrap to the next row
            }}
          >
            <div
              style={{
                height: "5vh",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#5E0516",
                boxShadow: "2px 2px 9px #000000",
              }}
            >
              <h3 style={{ color: "#eed77a" }}>{`Edit Player Info:`}</h3>
            </div>

            <div
              style={{
                height: mobile ? "80%" : "70%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingBottom: "2vh",
                // backgroundColor: "lightgrey",
                overflowY: mobile ? "scroll" : "",
              }}
            >
              <h3>Edit any or all of the fields below:</h3>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  height: "3vh",
                  width: mobile ? "90%" : "30vw",
                  alignItems: "center",
                  justifyContent: "right",
                  marginBottom: "2vh",
                  // backgroundColor:'red',
                  marginRight: mobile ? "0vw" : "2vw",
                }}
              >
                <label
                  style={{
                    height: mobile ? "3vh" : "2vh",
                    marginRight: "2vw",
                    textJustify: "auto",
                    paddingBottom: ".5vh",
                  }}
                >
                  First Name:
                </label>

                <input
                  type="text"
                  style={{
                    width: mobile ? "45vw" : "15vw",
                    height: "2vh",
                    marginRight: mobile ? "4vw" : "",
                  }}
                  value={tempFirstName}
                  onChange={(e) => setTempFirstName(e.target.value)}
                  id=""
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  height: "3vh",
                  width: mobile ? "90%" : "30vw",
                  alignItems: "center",
                  justifyContent: "right",
                  marginBottom: "2vh",
                  // backgroundColor:'red',
                  marginRight: mobile ? "0vw" : "2vw",
                }}
              >
                <label
                  style={{
                    height: mobile ? "3vh" : "2vh",
                    marginRight: "2vw",
                    textJustify: "auto",
                    paddingBottom: ".5vh",
                  }}
                >
                  Last Name:
                </label>

                <input
                  type="text"
                  style={{
                    width: mobile ? "45vw" : "15vw",
                    height: "2vh",
                    marginRight: mobile ? "4vw" : "",
                  }}
                  value={tempLastName}
                  onChange={(e) => setTempLastName(e.target.value)}
                  id=""
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  height: "3vh",
                  width: mobile ? "90%" : "30vw",
                  alignItems: "center",
                  justifyContent: "right",
                  marginBottom: "2vh",
                  // backgroundColor:'red',
                  marginRight: mobile ? "0vw" : "2vw",
                }}
              >
                <label
                  style={{
                    height: mobile ? "3vh" : "2vh",
                    marginRight: "2vw",
                    textJustify: "auto",
                    paddingBottom: ".5vh",
                  }}
                >
                  Middle Inital:
                </label>

                <input
                  type="text"
                  style={{
                    width: mobile ? "45vw" : "15vw",
                    height: "2vh",
                    marginRight: mobile ? "4vw" : "",
                  }}
                  value={tempMiddleName}
                  onChange={(e) => setTempMiddleName(e.target.value)}
                  id=""
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  height: "3vh",
                  width: mobile ? "90%" : "30vw",
                  alignItems: "center",
                  justifyContent: "right",
                  marginBottom: "2vh",
                  // backgroundColor:'red',
                  marginRight: mobile ? "0vw" : "2vw",
                }}
              >
                <label
                  style={{
                    height: mobile ? "3vh" : "2vh",
                    marginRight: "2vw",
                    textJustify: "auto",
                    paddingBottom: ".5vh",
                  }}
                >
                  Callsign:
                </label>

                <input
                  type="text"
                  style={{
                    width: mobile ? "45vw" : "15vw",
                    height: "2vh",
                    marginRight: mobile ? "4vw" : "",
                  }}
                  value={tempCallsign}
                  onChange={(e) => setTempCallsign(e.target.value)}
                  id=""
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  height: "3vh",
                  width: mobile ? "90%" : "30vw",
                  alignItems: "center",
                  justifyContent: "right",
                  marginBottom: "2vh",
                  // backgroundColor:'red',
                  marginRight: mobile ? "0vw" : "2vw",
                }}
              >
                <label
                  style={{
                    height: mobile ? "3vh" : "2vh",
                    marginRight: "2vw",
                    textJustify: "auto",
                    paddingBottom: ".5vh",
                  }}
                >
                  Rank:
                </label>
                <select
                  type="text"
                  style={{
                    width: mobile ? "47vw" : "15.5vw",
                    height: "2.5vh",
                    marginRight: mobile ? "4vw" : "",
                  }}
                  onChange={(e) => setTempRank(e)}
                >
                  <option value="Unknown" id="Unknown">
                    Unknown
                  </option>
                  <option value="Recruit" id="Recruit">
                    Recruit
                  </option>
                  <option value="Private" id="Private">
                    Private
                  </option>
                  <option value="Lance Corporal" id="Lance Corporal">
                    Lance Corporal
                  </option>
                  <option value="Corporal" id="Corporal">
                    Corporal
                  </option>
                  <option value="Sergeant" id="Sergeant">
                    Sergeant
                  </option>
                  <option
                    value="Force Sergeant Major"
                    id="Force Sergeant Major"
                  >
                    Force Sergeant Major
                  </option>
                  <option value="Warrant Officer" id="Warrant Officer">
                    Warrant Officer
                  </option>
                  <option
                    value="Chief Warrant Officer"
                    id="Chief Warrant Officer"
                  >
                    Chief Warrant Officer
                  </option>
                  <option value="2nd Lieutenant" id="2nd Lieutenant">
                    2nd Lieutenant
                  </option>
                  <option value="Lieutenant" id="Lieutenant">
                    Lieutenant
                  </option>
                  <option value="Captain" id="Captain">
                    Captain
                  </option>
                  <option value="Major" id="Major">
                    Major
                  </option>
                  <option value="Lieutenant Colonel" id="Lieutenant Colonel">
                    Lieutenant Colonel
                  </option>
                  <option value="Colonel" id="Colonel">
                    Colonel
                  </option>
                  <option value="Brigadier" id="Brigadier">
                    Brigadier
                  </option>
                  <option value="Major General" id="Major General">
                    Major General
                  </option>
                  <option value="Lieutenant General" id="Lieutenant General">
                    Lieutenant General
                  </option>
                  <option value="General" id="General">
                    General
                  </option>
                </select>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  height: "3vh",
                  width: mobile ? "90%" : "30vw",
                  alignItems: "center",
                  justifyContent: "right",
                  marginBottom: "2vh",
                  // backgroundColor:'red',
                  marginRight: mobile ? "0vw" : "2vw",
                }}
              >
                <label
                  style={{
                    height: mobile ? "3vh" : "2vh",
                    marginRight: "2vw",
                    textJustify: "auto",
                    paddingBottom: ".5vh",
                  }}
                >
                  Unit:
                </label>
                <select
                  type="text"
                  style={{
                    width: mobile ? "47vw" : "15.5vw",
                    height: "2.5vh",
                    marginRight: mobile ? "4vw" : "",
                  }}
                  onChange={(e) => setTempUnit(e)}
                >
                  <option value="Unknown" id="Unknown">
                    Unknown
                  </option>
                  <option value="13/18 Hussars" id="13/18 Hussars">
                    13/18 Hussars (D-Day)
                  </option>
                  <option
                    value="13/18 Light Dragoons"
                    id="13/18 Light Dragoons"
                  >
                    13/18 Light Dragoons
                  </option>
                  <option
                    value="East Yorkshire Regiment"
                    id="East Yorkshire Regiment"
                  >
                    East Yorkshire Regiment
                  </option>
                  <option
                    value="41 Royal Marines Commando"
                    id="41 Royal Marines Commando"
                  >
                    41 Royal Marines Commando
                  </option>
                  <option
                    value="Royal Winnipeg Rifles"
                    id="Royal Winnipeg Rifles"
                  >
                    Royal Winnipeg Rifles
                  </option>
                  <option
                    value="Canadian Scottish Regiment"
                    id="Canadian Scottish Regiment"
                  >
                    Canadian Scottish Regiment
                  </option>
                  <option
                    value="9th British Parachute Regiment"
                    id="9th British Parachute Regiment"
                  >
                    9th British Parachute Regiment
                  </option>
                  <option
                    value="1st Canadian Parachute Battalion"
                    id="1st Canadian Parachute Battalion"
                  >
                    1st Canadian Parachute Battalion
                  </option>
                  <option value="Royal Air Force" id="Royal Air Force">
                    Royal Air Force
                  </option>
                  <option
                    value="6th Airbourne Division"
                    id="6th Airbourne Division"
                  >
                    6th Airbourne Division
                  </option>
                  <option
                    value="3rd Infantry Division"
                    id="3rd Infantry Division"
                  >
                    3rd Infantry Division
                  </option>
                  <option
                    value="Royal Scots Dragoon Guards (Fulda Gap)"
                    id="Royal Scots Dragoon Guards (Fulda Gap)"
                  >
                    Royal Scots Dragoon Guards (Fulda Gap)
                  </option>
                  <option value="CEF High Command" id="CEF High Command">
                    CEF High Command
                  </option>
                </select>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  height: "3vh",
                  width: mobile ? "90%" : "30vw",
                  alignItems: "center",
                  justifyContent: "right",
                  marginBottom: "2vh",
                  // backgroundColor:'red',
                  marginRight: mobile ? "0vw" : "2vw",
                }}
              >
                <label
                  style={{
                    height: mobile ? "3vh" : "2vh",
                    marginRight: "2vw",
                    textJustify: "auto",
                    paddingBottom: ".5vh",
                  }}
                >
                  Company:
                </label>
                <select
                  type="text"
                  style={{
                    width: mobile ? "47vw" : "15.5vw",
                    height: "2.5vh",
                    marginRight: mobile ? "4vw" : "",
                  }}
                  onChange={(e) => setTempCompany(e)}
                >
                  <option value="N/A" id="Unknown">
                    N/A
                  </option>
                  <option value="ABLE" id="ABLE">
                    ABLE
                  </option>
                  <option value="BAKER" id="BAKER">
                    BAKER
                  </option>
                  <option value="CHARLES" id="CHARLES">
                    CHARLES
                  </option>
                  <option value="DOG" id="DOG">
                    DOG
                  </option>
                  <option value="ZULU" id="ZULU">
                    ZULU
                  </option>
                  <option value="HQ" id="HQ">
                    HQ
                  </option>
                </select>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  height: "3vh",
                  width: mobile ? "90%" : "30vw",
                  alignItems: "center",
                  justifyContent: "right",
                  marginBottom: "2vh",
                  // backgroundColor:'red',
                  marginRight: mobile ? "0vw" : "2vw",
                }}
              >
                <label
                  style={{
                    height: mobile ? "3vh" : "2vh",
                    marginRight: "2vw",
                    textJustify: "auto",
                    paddingBottom: ".5vh",
                  }}
                >
                  Section:
                </label>
                <select
                  type="text"
                  style={{
                    width: mobile ? "47vw" : "15.5vw",
                    height: "2.5vh",
                    marginRight: mobile ? "4vw" : "",
                  }}
                  onChange={(e) => setTempSection(e)}
                >
                  <option value="N/A" id="Unknown">
                    N/A
                  </option>
                  <option value="S1 - Personnel" id="S1 - Personnel">
                    S1- Personnel
                  </option>
                  <option value="S2 - Intellegence" id="S2 - Intellegence">
                    S2 - Intellegence
                  </option>
                  <option value="S3 - Operations" id="S3 - Operations">
                    S3 - Operations
                  </option>
                  <option value="S4 - Logistics" id="S4 - Logistics">
                    S4 - Logistics
                  </option>
                  <option value="S5 - Plans" id="S5 - Plans">
                    S5 - Plans
                  </option>
                  <option value="S6 - Communications" id="S6 - Communications">
                    S6 - Communications
                  </option>
                  <option value="S7 - Training" id="S7 - Training">
                    S7 - Training
                  </option>
                  <option value="S8 - Finance" id="S8 - Finance">
                    S8 - Finance
                  </option>
                  <option value="S9 - Media" id="S9 - Media">
                    S9 - Media
                  </option>
                </select>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  height: "3vh",
                  width: mobile ? "90%" : "auto",
                  alignItems: "center",
                  justifyContent: "right",
                  paddingRight: "8vw",
                  marginRight: mobile ? "0vw" : "2vw",
                }}
              >
                <label
                  style={{
                    height: mobile ? "3vh" : "2vh",
                    marginRight: "2vw",
                    textJustify: "auto",
                    paddingBottom: ".5vh",
                  }}
                >
                  Show name on public pages:
                </label>

                <label
                  style={{
                    height: mobile ? "3vh" : "2vh",

                    textJustify: "auto",
                    paddingBottom: ".5vh",
                  }}
                >
                  Yes
                </label>

                <input
                  onChange={(e) => handleToggleShowName(e)}
                  checked={tempShowName === true}
                  style={{
                    marginRight: "1vw",
                  }}
                  type="radio"
                ></input>

                <label
                  style={{
                    height: mobile ? "3vh" : "2vh",

                    textJustify: "auto",
                    paddingBottom: ".5vh",
                  }}
                >
                  No
                </label>
                <input
                  onChange={(e) => handleToggleShowName(e)}
                  checked={tempShowName === false}
                  type="radio"
                ></input>
              </div>
            </div>

            <div
              style={{
                height: "10%",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                // backgroundColor:'grey'
              }}
            >
              <button
                style={{ marginRight: "2vw" }}
                onClick={(e) => props.handleEditPlayerInfoModal(e)}
              >
                Cancel
              </button>
              <button onClick={() => handleUpdateUserInfo()}>Confirm</button>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export const EventComponent = (props) => {
  const { mobile } = useContext(DataContext);
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // justifyContent: "center",

        marginBottom: "1vh",
        paddingBottom: "2vh",
        overflowY: mobile ? "" : "scroll",
      }}
    >
      {props.playerEvents && props.playerEvents.length > 0
        ? props.playerEvents.slice().map((row, index) => {
            const { name, image_url, years_attended } = row;

            return (
              <>
                <div
                  key={index}
                  style={{
                    height: "Auto",
                    width: "90%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "1vh",
                    backgroundColor: "#5E0516",
                    boxShadow: "1px 1px 6px 2px black",
                    border: "solid",
                    borderWidth: "4px",
                    borderColor: "#eed77a",
                  }}
                >
                  <label
                    style={{
                      color: "#eed77a",
                      fontSize: ".8remm",
                      fontWeight: 700,
                      marginTop: "1vh",
                      textAlign: "center",
                    }}
                  >
                    {name}
                  </label>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {image_url && image_url ? (
                        <img
                          style={{
                            width: mobile ? "35vw" : "8vw",
                            height: "auto",
                            padding: "1vh",
                          }}
                          src={image_url}
                          alt={name} // Add alt text for accessibility
                        />
                      ) : (
                        <div
                          style={{
                            height: "2vh",
                          }}
                        ></div>
                      )}

                      <div>
                        {chunkArray(years_attended, 4).map((row, rowIndex) => (
                          <div
                            key={rowIndex}
                            style={{
                              display: "flex",
                              marginBottom: "10px",
                            }}
                          >
                            {row.map((year) => (
                              <div
                                key={year}
                                style={{
                                  marginRight: "10px",
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <label
                                  style={{
                                    color: "#eed77a",
                                    fontSize: ".8remm",
                                    fontWeight: 700,
                                  }}
                                >
                                  {year}
                                </label>
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })
        : null}
    </div>
  );
};

export const MedalComponent = (props) => {
  const { mobile } = useContext(DataContext);
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: mobile ? "column" : "row",
        alignItems: "center",
        justifyContent: "left",
        flexWrap: "wrap", // Allow items to wrap to the next row
        overflowY: mobile ? null : "scroll",
        overflowX: mobile ? null : "scroll",
      }}
    >
      {props.medalInfo && props.medalInfo.length > 0
        ? props.medalInfo.slice().map((row, index) => {
            const { name, url } = row;

            return (
              <>
                <div
                  key={index}
                  style={{
                    height: mobile ? "25vh" : "18vh",
                    width: mobile ? "55vw" : "auto",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "1vh",
                    marginLeft: "1vw",
                    // backgroundColor:'grey'
                    // You can add more styles here
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "auto",
                      height: "20vh",
                      // backgroundColor:'blue',
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "4.8vw",
                        height: "12vh",
                        // backgroundColor:'yellow',
                        alignItems: "center",
                        WebkitJustifyContent: "center",
                      }}
                    >
                      <img
                        style={{
                          width: mobile ? "25vw" : "4.8vw",
                          height: "auto",
                        }}
                        src={url}
                        alt={name} // Add alt text for accessibility
                      />
                    </div>
                    <h4>{name}</h4>
                  </div>
                </div>
              </>
            );
          })
        : null}
    </div>
  );
};

export const BadgeComponent = (props) => {
  const { mobile } = useContext(DataContext);
  const [selectedBadge,setSelectedBadge]=useState(null)

  const handleSetSelectedBadge=(e,name, url, description, level, id)=>{
    props.handleSetSelectedBadges(name, url, description, level, id)
    setSelectedBadge(id)
  }


  return (
    <div
      style={{
        height: "90%",
        paddingLeft:'1vw',
        width: mobile ? "76%" : "97%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "left",
        marginTop: "1vh",
        overflowY: mobile ? null : "scroll",
        overflowX: mobile ? null : "scroll",
        
        // marginBottom:'1vh',
        flexWrap: "wrap", // Allow items to wrap to the next row
      }}
    >
      {props.badgeInfo && props.badgeInfo.length > 0
        ? props.badgeInfo.slice().map((row, index) => {
            const { name, url, description, level, id} = row;

            return (
              <>
                <div
                  style={{
                    height: mobile ? "10vh" : "auto",
                    width: mobile ? "15vw" : "5vw",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "left",
                    marginRight: mobile ? "2vw" : "0vw",
                    marginBottom: "1vh",
                    padding:'2px',
                    backgroundColor:selectedBadge===id?'lightgrey':''

                  }}
                  onClick={props.mode&&props.mode==='edit'?(e)=>handleSetSelectedBadge(e,name, url, description, level, id):null}
                >
                  <Tooltip
                    placement="top"
                    title={`${name}: ${level} `}
                    enterTouchDelay={0}
                    arrow
                  >
                    <img
                      style={{
                        width: mobile ? "15vw" : "4.8vw",
                        height: "auto",
                      }}
                      src={url}
                      alt={name}
                    />
                  </Tooltip>
                </div>
              </>
            );
          })
        : null}
    </div>
  );
};
