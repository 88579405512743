import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { UserSidebar } from "../../components/common";
import { AuthContext } from "../../common/AuthContext";
import { DataContext } from "../../common/DataContext/index";
import React, { useEffect, useContext, useState } from "react";
import { Navigate } from "react-router-dom";

export const UserHome = () => {
  const [editFeaturesTextOpen, toggleEditFeaturesTextOpen] = useState(null);
  const { user, redirect, toggleRedirect } = useContext(AuthContext);

  const {
    mobile,
    updateFeaturesText,
    editNewFeaturesText,
    fetchNewFeaturesText,
    newFeaturesText,
    setNewFeaturesText,
    editAnnouncmentsText,
    fetchAnnouncmentsText,
    announcmentsText,
    setAnnouncmentsText,
  } = useContext(DataContext);

  useEffect(() => {
    if (!user || user == null) {
      toggleRedirect(true);
    } else {
      toggleRedirect(false);
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    fetchAnnouncmentsText();
    fetchNewFeaturesText();
    // eslint-disable-next-line
  }, []);

  const handleEditFeaturesTextModal = () => {
    if (editFeaturesTextOpen) {
      toggleEditFeaturesTextOpen(false);
    } else {
      toggleEditFeaturesTextOpen(true);
    }
  };

  const EditNewFeaturesModal = (props) => {
    const [tempFeaturesText, setTempFeaturesText] = useState("");
    const [tempAnnouncementsText, setTempAnnouncementsText] = useState("");

    useEffect(() => {
      if (props.editFeaturesTextOpen === true) {
        setTempFeaturesText(props.featuresText);
        setTempAnnouncementsText(props.announcmentsText);
      }
      // eslint-disable-next-line
    }, []);

    const handleFeaturesText = () => {
      editNewFeaturesText(tempFeaturesText);
      editAnnouncmentsText(tempAnnouncementsText);
      toggleEditFeaturesTextOpen(false);
    };

    return (
      <>
        {props.editFeaturesTextOpen && props.editFeaturesTextOpen === true ? (
          <>
            <div
              style={{
                position: "fixed",
                top: "20vh",
                left: mobile ? "4vw" : "32vw",
                height: mobile ? "70vh" : "60%",
                width: mobile ? "92vw" : "40vw",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "left",
                marginTop: "1vh",
                backgroundColor: "ghostwhite",
                boxShadow: "2px 2px 9px #000000",
                zIndex: "999",
                flexWrap: "wrap", // Allow items to wrap to the next row
              }}
            >
              <div
                style={{
                  height: "5vh",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#5E0516",
                  boxShadow: "2px 2px 9px #000000",
                }}
              >
                <h3
                  style={{ color: "#eed77a" }}
                >{`Edit Announcments Text:`}</h3>
              </div>

              <div
                style={{
                  height: "80%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",

                  // backgroundColor: "lightgrey",
                }}
              >
                <label
                  style={{
                    marginTop: "1vh",
                    textDecoration: "none",
                    fontWeight: 500,
                    fontSize: mobile ? ".60rem" : "1rem",
                  }}
                >
                  Announcments:
                </label>
                <textarea
                  style={{
                    height: "90%",
                    width: "90%",
                    marginTop: "2vh",
                  }}
                  value={tempAnnouncementsText}
                  onChange={(e) => setTempAnnouncementsText(e.target.value)}
                />
                <label
                  style={{
                    marginTop: "1vh",
                    textDecoration: "none",
                    fontWeight: 500,
                    fontSize: mobile ? ".60rem" : "1rem",
                  }}
                >
                  New Features:
                </label>
                <textarea
                  style={{
                    height: "90%",
                    width: "90%",
                    marginTop: "2vh",
                  }}
                  value={tempFeaturesText}
                  onChange={(e) => setTempFeaturesText(e.target.value)}
                />
              </div>

              <div
                style={{
                  height: "10%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <button
                  style={{ marginRight: "2vw" }}
                  onClick={(e) => props.handleEditFeaturesTextModal(e)}
                >
                  Cancel
                </button>
                <button onClick={(e) => handleFeaturesText(e)}>Confirm</button>
              </div>
            </div>
          </>
        ) : null}
      </>
    );
  };

  const ParagraphComponent = ({ text }) => {
    return (
      <div
        style={{
          whiteSpace: "pre-line",
          width: "70%",
          color: "#eed77a",
          textDecoration: "none",
          // textAlign: "center",
          marginBottom: "1vh",
          fontWeight: 500,
        }}
      >
        {text}
      </div>
    );
  };

  const AnnouncmentComponent = ({ text }) => {
    return (
      <div
        style={{
          whiteSpace: "pre-line",
          width: "70%",
          color: "#eed77a",
          textDecoration: "none",
          textAlign: "center",
          marginBottom: "1vh",
          fontWeight: 500,
        }}
      >
        {text}
      </div>
    );
  };

  return (
    <>
      <EditNewFeaturesModal
        handleEditFeaturesTextModal={handleEditFeaturesTextModal}
        editFeaturesTextOpen={editFeaturesTextOpen}
        featuresText={newFeaturesText}
        announcementsText={announcmentsText}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          backgroundColor: "#adc8e6",
          minHeight: mobile ? "100vh" : "",
        }}
      >
        <Header />
        <div
          style={{
            height: "88vh",
            width: "100%",
            display: "flex",
            flexDirection: mobile ? "column" : "row",
            alignItems: "center",
            flexGrow: mobile ? 1 : 0,
          }}
        >
          <UserSidebar />
          <div
            style={{
              height: mobile ? "74vh" : "88vh",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              // backgroundColor:'grey'
            }}
          >
            <div
              style={{
                height: mobile ? "38vh" : "38vh",
                width: "90%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                // justifyContent: "center",
                marginTop: "3vh",
                backgroundColor: "#5E0516",
                boxShadow: "1px 1px 6px 2px black",
                border: "solid",
                borderWidth: "4px",
                borderColor: "#eed77a",
              }}
            >
              <label
                style={{
                  textAlign: "center",
                  color: "#eed77a",
                  marginBottom: "0vh",
                  marginTop: "0vh",
                  fontWeight: mobile ? 500 : 700,
                }}
              >
                NEXT EVENT: BONES & ASHES
              </label>

              <div
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: "1vh",
                  // backgroundColor:'grey'
                }}
              >
                <div
                  style={{
                    height: "100%",
                    width: "25vw",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    // backgroundColor:'grey'
                  }}
                >
                  <label
                    style={{
                      width: "80%",
                      color: "#eed77a",
                      textDecoration: "none",
                      marginBottom: "1vh",
                      fontWeight: 500,
                      fontSize: mobile ? ".60rem" : "1rem",
                    }}
                  >
                    <strong>Event Date:</strong> <br /> March 1-3, 2024
                  </label>

                  <label
                    style={{
                      width: "80%",
                      color: "#eed77a",
                      textDecoration: "none",
                      marginBottom: "1vh",
                      fontWeight: 500,
                      fontSize: mobile ? ".60rem" : "1rem",
                    }}
                  >
                    <strong>Venue:</strong> <br /> Black Ops Paintball 2112
                    River Rd, Fayetteville, NC 28312
                  </label>

                  <label
                    style={{
                      width: "80%",
                      color: "#eed77a",
                      textDecoration: "none",
                      marginBottom: "1vh",
                      fontWeight: 500,
                      fontSize: mobile ? ".60rem" : "1rem",
                    }}
                  >
                    <strong>Game Runner:</strong> <br />
                    CPX EVENTS
                  </label>
                </div>

                <div
                  style={{
                    height: "100%",
                    width: "50%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    // overflowY: "scroll",
                  }}
                >
                  <img
                    style={{
                      height: "auto",
                      width: "90%",
                      marginBottom: "1vh",
                    }}
                    src={
                      "https://printsmiths.nyc3.cdn.digitaloceanspaces.com/CEF/Events/eventBanners/bones.png"
                    }
                  />
                  <div
                    style={{
                      height: mobile ? "15vh" : "11vh",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: mobile ? "center" : null,
                      overflowY: mobile ? "scroll" : "",
                      // backgroundColor:'grey'
                    }}
                  >
                    <label
                      style={{
                        width: "100%",
                        color: "#eed77a",
                        textDecoration: "none",
                        textAlign: "center",
                        marginBottom: "1vh",
                        fontWeight: 500,
                        fontSize: mobile ? ".60rem" : "1rem",
                      }}
                    >
                      <strong>Description:</strong> Bones & Ashes, a Legends
                      Event, is a multi-day scenario game featuring detailed
                      storylines and big time tactical action at one of
                      paintball’s most exciting venues; a 50 acre mega park
                      built by some of our Nation’s Heroes.
                    </label>
                  </div>
                </div>

                <div
                  style={{
                    height: "100%",
                    width: mobile ? "25vw" : "20vw",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    paddingLeft: mobile ? "1vw" : "5vw",
                    paddingRight: mobile ? "1vw" : "",
                  }}
                >
                  <label
                    style={{
                      width: "80%",
                      color: "#eed77a",
                      textDecoration: "none",
                      fontWeight: 500,
                      fontSize: mobile ? ".60rem" : "1rem",
                      marginBottom: "1vh",
                    }}
                  >
                    <strong>Website:</strong> <br />
                  </label>
                  <a
                    href="https://cpxtickets.com/collections/bonesandashes"
                    target="_blank"
                    style={{ textDecoration: "none", marginBottom: "2vh" }}
                  >
                    <label
                      style={{
                        color: "#eed77a",
                        textDecoration: "none",
                        marginBottom: "1vh",
                        fontWeight: 500,
                        fontSize: mobile ? ".60rem" : "1rem",
                        textOverflow: "ellipsis",
                        textAlign: "left",
                        // backgroundColor:'grey',
                        marginLeft: mobile ? "" : "2vh",
                      }}
                    >
                      <strong>cpxtickets.com</strong> <br />
                    </label>
                  </a>
                  <label
                    style={{
                      width: "80%",
                      color: "#eed77a",
                      textDecoration: "none",
                      marginBottom: "1vh",
                      fontWeight: 500,
                      fontSize: mobile ? ".60rem" : "1rem",
                    }}
                  >
                    <strong>Email:</strong> <br />
                  </label>
                  <label
                    style={{
                      color: "#eed77a",
                      textDecoration: "none",
                      marginBottom: "1vh",
                      fontWeight: 500,
                      fontSize: mobile ? ".60rem" : "1rem",
                      textOverflow: "ellipsis",
                      marginLeft: mobile ? "" : "2vh",
                    }}
                  >
                    <strong>info@cpxevents.com</strong> <br />
                  </label>
                </div>
                {user && user.role === "admin" && !mobile ? (
                  <>
                    <button
                      style={{
                        position: "relative",
                        right: ".5vw",
                        bottom: "19vh",
                      }}
                      // onClick={(e)=>handleEditMemberModal(e,id,rank,unit,company,section)}
                    >
                      Edit
                    </button>
                  </>
                ) : null}
              </div>
            </div>

            <div
              style={{
                height: "38vh",
                width: "90%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                // justifyContent: "center",
                marginTop: "3vh",
                backgroundColor: "#5E0516",
                boxShadow: "1px 1px 6px 2px black",
                border: "solid",
                borderWidth: "4px",
                borderColor: "#eed77a",
                paddingBottom: "1vh",
                overflowY: "scroll",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  height: "5vh",
                  alignItems: "center",
                  justifyContent: "center",
                  // backgroundColor:'lightgrey'
                }}
              >
                <label
                  style={{
                    textAlign: "center",
                    color: "#eed77a",
                    marginBottom: "0vh",
                    fontWeight: mobile ? 500 : 700,
                  }}
                >
                  ANNOUNCEMENTS:
                </label>
                {user && user.role === "admin" && !mobile ? (
                  <>
                    <button
                      style={{
                        position: "fixed",
                        right: "6vw",
                      }}
                      onClick={(e) => handleEditFeaturesTextModal()}
                    >
                      Edit
                    </button>
                  </>
                ) : null}
              </div>
              {/* <label
                style={{
                  width: "80%",
                  color: "#eed77a",
                  textAlign: "center",
                  textDecoration: "none",
                  marginBottom: "1vh",
                  marginTop: "1vh",
                  fontWeight: 500,
                  fontSize: mobile ? ".60rem" : "1rem",
                }}
              >
                
              </label> */}
              <AnnouncmentComponent text={announcmentsText} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  height: "5vh",
                  alignItems: "center",
                  justifyContent: "center",
                  // backgroundColor:'lightgrey'
                }}
              >
                <label
                  style={{
                    textAlign: "center",
                    color: "#eed77a",
                    marginBottom: "0vh",
                    fontWeight: mobile ? 500 : 700,
                  }}
                >
                  NEW FEATURES:
                </label>
              </div>
              <ParagraphComponent text={newFeaturesText} />
            </div>
          </div>
        </div>
        <Footer />
      </div>
      {/* {redirect && !user ? <Navigate push to="/login" /> : null} */}
    </>
  );
};
