import React, { useContext, useState } from "react";
import logo from "../../static/NavIcon.png";
import { NavLink } from "react-router-dom";
import { DataContext } from "../../common/DataContext";
import mobileMenuIcon from "../../static/images/mobileMenu.png";
import "./styles.css";

const Nav_Link = (props) => {
  return <h6 class="headerlink">{props.text}</h6>;
};

export const Header = () => {
  const { mobile } = useContext(DataContext);

  const [NavModalOpen, setNavModalOpen] = useState(false);

  const handleToggleNavModalOpen = () => {
    if (NavModalOpen) {
      setNavModalOpen(false);
    } else {
      setNavModalOpen(true);
    }
  };

  const NavModal = (props) => {
    return (
      <>
        {props.NavModalOpen && props.NavModalOpen === true ? (
          <>
            <div
              style={{
                width: "26vw",
                height: "40vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                position: "fixed",
                right: "2vw",
                top: "5vh",
                backgroundColor: "#5E0516",
                boxShadow: "2px 2px 9px #000000",
                overflowY: "scroll",
                WebkitOverflowScrolling: "touch",
              }}
            >
              <NavLink to="/" style={{ textDecoration: "none" }}>
                <Nav_Link text={"HOME"} />
              </NavLink>

              <NavLink to="/About" style={{ textDecoration: "none" }}>
                <Nav_Link text={"ABOUT"} />
              </NavLink>

              <NavLink to="/History" style={{ textDecoration: "none" }}>
                <Nav_Link text={"HISTORY"} />
              </NavLink>

              <NavLink to="/Media" style={{ textDecoration: "none" }}>
                <Nav_Link text={"MEDIA"} />
              </NavLink>

              <NavLink to="/Events" style={{ textDecoration: "none" }}>
                <Nav_Link text={"EVENTS"} />
              </NavLink>

              <NavLink to="/Members" style={{ textDecoration: "none" }}>
                <Nav_Link text={"MEMBERS"} />
              </NavLink>

              <NavLink to="/Recruiting" style={{ textDecoration: "none" }}>
                <Nav_Link text={"RECRUITING"} />
              </NavLink>
              {/* 
              <NavLink to="/Memorials" style={{ textDecoration: "none" }}>
                <Nav_Link text={"MEMORIALS"} />
              </NavLink> */}

              <NavLink to="/Map" style={{ textDecoration: "none" }}>
                <Nav_Link text={"MAP"} />
              </NavLink>

              <NavLink
                target="_blank"
                to="https://www.etsy.com/shop/marooncef/?etsrc=sdt&fbclid=IwAR1PkxxxEEd7gw6LKF1wHVUUsi774ocmuCgo-zt7T1C6X6cdJHVjdZ6-_FE"
                style={{ textDecoration: "none" }}
              >
                <Nav_Link text={"STORE"} />
              </NavLink>
            </div>
          </>
        ) : null}
      </>
    );
  };

  const NavImg = () => {
    return (
      <img
        class="navImg"
        style={
          !mobile
            ? {}
            : {
                height: "2.20rem",
                width: "auto",
                marginLeft: "2vw",
                marginTop: "1.25vh",
              }
        }
        src={logo}
      ></img>
    );
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "left",
          height: "6vh",
          width: "100vw",
          backgroundColor: "#5E0516",
          boxShadow: "2px 2px 9px #000000",
          zIndex: "999",
        }}
      >
        <NavImg />

        {!mobile ? (
          <>
            <NavLink
              to="/"
              style={{ textDecoration: "none", marginLeft: "4vw" }}
            >
              <Nav_Link text={"HOME"} />
            </NavLink>

            <NavLink
              to="/About"
              style={{ textDecoration: "none", marginLeft: "4vw" }}
            >
              <Nav_Link text={"ABOUT"} />
            </NavLink>

            <NavLink
              to="/History"
              style={{ textDecoration: "none", marginLeft: "4vw" }}
            >
              <Nav_Link text={"HISTORY"} />
            </NavLink>

            <NavLink
              to="/Media"
              style={{ textDecoration: "none", marginLeft: "4vw" }}
            >
              <Nav_Link text={"MEDIA"} />
            </NavLink>

            <NavLink
              to="/Events"
              style={{ textDecoration: "none", marginLeft: "4vw" }}
            >
              <Nav_Link text={"EVENTS"} />
            </NavLink>

            <NavLink
              to="/Members"
              style={{ textDecoration: "none", marginLeft: "4vw" }}
            >
              <Nav_Link text={"MEMBERS"} />
            </NavLink>

            <NavLink
              to="/Recruiting"
              style={{ textDecoration: "none", marginLeft: "4vw" }}
            >
              <Nav_Link text={"RECRUITING"} />
            </NavLink>

            {/* <NavLink to="/Memorials" 
            style={{ textDecoration: "none" , marginLeft: "4vw"}}>
                <Nav_Link text={"MEMORIALS"} />
              </NavLink> */}

            <NavLink
              to="/Map"
              style={{ textDecoration: "none", marginLeft: "4vw" }}
            >
              <Nav_Link text={"MAP"} />
            </NavLink>

            <NavLink
              target="_blank"
              to="https://www.etsy.com/shop/marooncef/?etsrc=sdt&fbclid=IwAR1PkxxxEEd7gw6LKF1wHVUUsi774ocmuCgo-zt7T1C6X6cdJHVjdZ6-_FE"
              style={{ textDecoration: "none", marginLeft: "4vw" }}
            >
              <Nav_Link text={"STORE"} />
            </NavLink>
          </>
        ) : mobile ? (
          <>
            <label
              style={{
                textAlign: "center",
                color: "#eed77a",
                marginLeft: "0vw",
                fontWeight: "700",
                fontSize: ".80rem",
                width: "80vw",
              }}
            >
              Commonwealth Expeditionary Force
            </label>
            <img
              style={{ height: "2.5vh", width: "auto" }}
              src={mobileMenuIcon}
              onClick={(e) => handleToggleNavModalOpen(e)}
            />
            <NavModal NavModalOpen={NavModalOpen} />
          </>
        ) : null}
      </div>
    </>
  );
};
