import React, { useState, useContext, useEffect } from "react";
import { DataContext } from "../../common/DataContext";
import { AuthContext } from "../../common/AuthContext";
export const HistoryText = () => {
  const [editHistoryOpen, toggleEditHistoryOpen] = useState(null);
  const { mobile, historyText, setHistoryText, updateHistory, fetchHistory } =
    useContext(DataContext);

  const { user } = useContext(AuthContext);

  const [tempText, setTempText] = useState(null);

  const handleEditHistoryModal = () => {
    if (editHistoryOpen) {
      toggleEditHistoryOpen(false);
    } else {
      toggleEditHistoryOpen(true);
    }
  };

  const EditHistoryModal = (props) => {
    const [tempHistoryText, setTempHistoryText] = useState("");

    const { mobile } = useContext(DataContext);

    useEffect(() => {
      if (props.editHistoryOpen === true) {
        fetchHistory();
        setTempHistoryText(props.historyText);
      }
      // eslint-disable-next-line
    }, []);

    const handleHistoryText = () => {
      updateHistory(tempHistoryText);
      toggleEditHistoryOpen(false);
    };

    return (
      <>
        {props.editHistoryOpen && props.editHistoryOpen === true ? (
          <>
            <div
              style={{
                position: "fixed",
                top: "20vh",
                left: mobile ? "4vw" : "32vw",
                height: mobile ? "70vh" : "60%",
                width: mobile ? "92vw" : "40vw",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "left",
                marginTop: "1vh",
                backgroundColor: "ghostwhite",
                boxShadow: "2px 2px 9px #000000",
                zIndex: "999",
                flexWrap: "wrap", // Allow items to wrap to the next row
              }}
            >
              <div
                style={{
                  height: "5vh",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#5E0516",
                  boxShadow: "2px 2px 9px #000000",
                }}
              >
                <h3 style={{ color: "#eed77a" }}>{`Edit CEF HIstory Text:`}</h3>
              </div>

              <div
                style={{
                  height: "80%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  // backgroundColor: "lightgrey",
                }}
              >
                <textarea
                  style={{
                    height: "90%",
                    width: "90%",
                    marginTop: "2vh",
                  }}
                  value={tempHistoryText}
                  onChange={(e) => setTempHistoryText(e.target.value)}
                />
              </div>

              <div
                style={{
                  height: "10%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <button
                  style={{ marginRight: "2vw" }}
                  onClick={(e) => props.handleEditHistoryModal(e)}
                >
                  Cancel
                </button>
                <button onClick={(e) => handleHistoryText(e)}>Confirm</button>
              </div>
            </div>
          </>
        ) : null}
      </>
    );
  };

  const ParagraphComponent = ({ text }) => {
    return (
      <div
        style={{
          whiteSpace: "pre-line",
          color: "#eed77a",
          textDecoration: "none",
          // textAlign: "center",
          marginBottom: "1vh",
          fontWeight: 500,
        }}
      >
        {text}
      </div>
    );
  };

  const handleUpdateHistoryText = () => {
    updateHistory(tempText);
    handleEditHistoryModal();
  };

  return (
    <>
      <EditHistoryModal
        editHistoryOpen={editHistoryOpen}
        handleEditHistoryModal={handleEditHistoryModal}
        historyText={historyText}
        setHistoryText={setHistoryText}
        handleUpdateHistoryText={handleUpdateHistoryText}
      />
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          // justifyContent: "center",
        }}
      >
        <div
          style={{
            height: mobile ? "90%" : "85%",
            width: "90%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: mobile ? "" : "center",
            backgroundColor: "#5E0516",
            boxShadow: "1px 1px 6px 2px black",
            border: "solid",
            borderWidth: "4px",
            borderColor: "#eed77a",
            paddingBottom: "2vh",
            marginTop: mobile ? "5vh" : "3vh",
            // overflowY: mobile ? "scroll" : "scroll",
          }}
        >
          <div
            style={{
              height: mobile ? "80%" : "90%",
              width: mobile ? "80%" : "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: mobile ? "" : "",

              paddingBottom: "2vh",
              marginTop: mobile ? "5vh" : "",
              overflowY: mobile ? "scroll" : "scroll",
            }}
          >
            <h1
              style={{
                textAlign: "center",
                color: "#eed77a",
              }}
            >
              CEF History
            </h1>

            <div>
              <ParagraphComponent text={historyText} />
            </div>
          </div>
          {user && user.role === "admin" ? (
            <>
              <button
                style={{
                  marginTop: "2vh",
                }}
                onClick={(e) => handleEditHistoryModal(e)}
              >
                Edit
              </button>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};
