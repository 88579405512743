import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { poster, formPoster } from "../../calls/calls";
import { AuthContext } from "../AuthContext";
import { API_URL } from "../constants";
import useToggle from "../hooks/useToggle";

export const DataContext = createContext({});
export const DataProvider = ({ children }) => {
  const navigate = useNavigate();
  const [showName, toggleShowName] = useToggle(false);
  const [showLocation, toggleShowLocation] = useToggle(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [currentProducts, setCurrentProducts] = useState([]);
  const [currentCategories, setCurrentCategories] = useState([]);
  const [currentImageURLs, setCurrentImageURLs] = useState([]);
  const [newProductImages, setNewProductImages] = useState([]);
  const [newProfilePic, setNewProfilePic] = useState(null);
  const [newEventPic, setNewEventPic] = useState(null);
  const [selectedProductImages, setSelectedProductImages] = useState([]);
  const [selectedCategoryImage, setSelectedCategoryImage] = useState(null);
  const [newCategoryURL, setNewCategoryURL] = useState(null);
  const [newCategoryImage, setNewCategoryImage] = useState(null);
  const [tempIndex, setTempIndex] = useState(0);
  const [badgeInfo, setBadgeInfo] = useState({});
  const [medalInfo, setMedalInfo] = useState({});
  const [formattedName, setFormatedName] = useState(null);
  const [badges, setBadges] = useState(null);
  const [medals, setMedals] = useState(null);
  const [location, setLocation] = useState("");
  const [tempEventFactions, setTempEventFactions] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [middleName, setMiddleName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [address, setAddress] = useState(null);
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [unitNumber, setUnitNumber] = useState(null);
  const [country, setCountry] = useState(null);
  const [zip, setZip] = useState(null);
  const [phone, setPhone] = useState(null);
  const [EmergencyContact, setEmergencyContact] = useState(null);
  const [age, setAge] = useState(null);
  const [email, setEmail] = useState(null);
  const [loginEmail, setLoginEmail] = useState(null);
  const [callsign, setCallsign] = useState(null);
  const [homeTeam, setHomeTeam] = useState(null);
  const [rank, setRank] = useState("");
  const [unit, setUnit] = useState("");
  const [company, setCompany] = useState("");
  const [section, setSection] = useState("");
  const [years, setYears] = useState(null);
  const [playTypes, setPlayTypes] = useState([]);
  const [registerMedals, setRegisterMedals] = useState({});
  const [registerBadges, setRegisterBadges] = useState({});
  const [playerEvents, setPlayerEvents] = useState(null);
  const [pastEvents, setPastEvents] = useState(null);
  const [skills, setSkills] = useState(null);
  const [otherInfo, setOtherInfo] = useState(null);
  const [eventYears, setEventYears] = useState(null);
  const [password, setPassword] = useState(null);
  const [checkPassword, setCheckPassword] = useState(null);
  const [code, setCode] = useState(null);
  const [page, setPage] = useState(1);
  const [profilePicUrl, setProfilePicUrl] = useState(null);
  const { user, toggleRedirect } = useContext(AuthContext);
  const [members, setMembers] = useState(null);
  const [servicesText, setServicesText] = useState("");

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [mobile, toggleMobile] = useState(false);

  const [aboutUsText, setAboutUsText] = useState(null);
  const [recruitingText, setRecruitingText] = useState(null);
  const [historyText, setHistoryText] = useState(null);
  const [newFeaturesText, setNewFeaturesText] = useState(null);
  const [announcmentsText, setAnnouncmentsText] = useState(null);
  const [tempEventName, setTempEventName] = useState(null);
  const [tempImageUrl, setTempImageUrl] = useState(null);
  const [tempYears, setTempYears] = useState(null);
  const [tempEventVenu, setTempEventVenu] = useState(null);
  const [tempCity, setTempCity] = useState(null);
  const [tempState, setTempState] = useState(null);
  const [tempCOuntry, setTempCountry] = useState(null);
  const [tempAddress, setTempAddress] = useState(null);
  const [tempGameRunner, setTempGameRunner] = useState(null);
  const [tempWebsite, setTempWebsite] = useState(null);
  const [tempEmail, setTempEmail] = useState(null);
  const [tempPhone, setTempPhone] = useState(null);
  const [tempDescription, setTempDescription] = useState(null);
  const [tempUnitId, setTempUnitId] = useState(null);
  const [tempEventScenario, setTempEventScenario] = useState(null);
  const [tempStartDate, setTempStartDate] = useState(null);
  const [tempEndDate, setTempEndDate] = useState(null);
  const [tempEventId, setTempEventId] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedYearId, setSelectedYearId] = useState(null);
  const [eventGenerals, setEventGenerals] = useState(null);
  const [eventScore, setEventScore] = useState(null);
  const [attendingPlayers, setAttendingPlayers] = useState(null);
  const [newGeneralId, setNewGeneralId] = useState(null);
  const [tempUnits, setTempUnits] = useState(null);
  const [tempUnitCOname, setTempUnitCOname] = useState(null);
  const [tempUnitXOname, setTempUnitXOname] = useState(null);

  useEffect(() => {
    document.title = "CEF Paintball";
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    let isMobile;
    windowWidth >= 768 ? (isMobile = false) : (isMobile = true);
    // console.log("mobile device:", mobile);
    toggleMobile(isMobile);
  }, []);

  const handleSetNewProductImages = (e) => {
    setNewProductImages(Array.from(e.target.files));
    const selectedFiles = e.target.files;
    const previews = [];
    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      const reader = new FileReader();
      reader.onload = (e) => {
        const previewUrl = e.target.result;
        previews.push(previewUrl);
        if (previews.length === selectedFiles.length) {
          setCurrentImageURLs(previews);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSetNewProfilePic = (e) => {
    console.log(e.target.files)
    setNewProfilePic(e.target.files);
  };


  const handleSetNewEventPic = (e) => {
    setNewEventPic(e.target.files);
  };

  const updateProfilePic = (text) => {
    let URL = API_URL + "/updateProfilePic";
    let outpack = {
      user_id: user.id,
    };
    var formData = new FormData();
    if (newProfilePic !== null) {
      for (let i = 0; i < newProfilePic.length; i++) {
        formData.append(`file`, newProfilePic[i]);
      }
    }
    formPoster(URL, formData, outpack).then((response) => {
      if (response.status === 200) {
        setProfilePicUrl(null);
        setProfilePicUrl(response.profile_pic_url);
        return;
      }
    });
  };

  const updateGeneralPic = (general_id, image) => {
    let URL = API_URL + "/updateGeneralPic";
    let outpack = {
      general_id: general_id,
    };
    var formData = new FormData();
    if (image !== null) {
      for (let i = 0; i < image.length; i++) {
        formData.append(`file`, image[i]);
      }
    }
    formPoster(URL, formData, outpack).then((response) => {
      if (response.status === 200) {
        // setProfilePicUrl(null);
        // setProfilePicUrl(response.profile_pic_url);
        return;
      }
    });
  };

  const updateEventPic = (event_id, newImage) => {
    let URL = API_URL + "/updateEventPic";
    let outpack = {
      event_id: event_id,
    };
    var formData = new FormData();
    console.log("newImage", newImage);
    if (newImage !== null) {
      for (let i = 0; i < newImage.length; i++) {
        formData.append(`file`, newImage[i]);
      }
    }
    formPoster(URL, formData, outpack).then((response) => {
      if (response.status === 200) {
        alert("Event Image Updated.");
        fetchPastEvents();
        return;
      }
    });
  };

  const updateFactionPic = (event_id, faction_id, newImage) => {
    let URL = API_URL + "/updateFactionPic";
    let outpack = {
      event_id: event_id,
      faction_id: faction_id,
    };
    var formData = new FormData();
    console.log("newImage", newImage);
    if (newImage !== null) {
      for (let i = 0; i < newImage.length; i++) {
        formData.append(`file`, newImage[i]);
      }
    }
    formPoster(URL, formData, outpack).then((response) => {
      if (response.status === 200) {
        alert("Faction Image Updated.");
        // fetchPastEvents();
        return;
      }
    });
  };

  const handleSetContactInfo = (e) => {
    setAddress(e.address);
    setPhone(e.phone);
    setEmail(e.email);
  };

  const handleSetServicesInfo = (response) => {
    setServicesText(response.text);
  };

  const handleAddNewProductImagePreviews = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const newProductImagesArray = [...newProductImages];
    const currentImageURLSArray = [...currentImageURLs];
    const previews = [];
    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      const reader = new FileReader();
      reader.onload = (e) => {
        const previewUrl = e.target.result;
        previews.push(previewUrl);
        setCurrentImageURLs(currentImageURLSArray.concat(previews));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSetNewCategoryImage = (e) => {
    setNewCategoryImage(e.target.files[0]);
    const selectedFile = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const previewUrl = e.target.result;
      setNewCategoryURL(previewUrl);
    };
    reader.readAsDataURL(selectedFile);
  };

  const handlePhoneNumberChange = (event) => {
    const inputValue = event.target.value;
    const formattedNumber = inputValue
      .replace(/\D/g, "") // Remove non-digit characters
      .replace(/^(\d{3})(\d{3})(\d{4})$/, "($1) $2-$3"); // Format as (123) 456-7890

    setPhone(formattedNumber);
  };

  const handleSetAddress = (e) => {
    setAddress(e.target.value);
  };

  const handleEmailChange = (event) => {
    const inputValue = event.target.value;
    setEmail(inputValue);
  };

  const handleSetSelectedRow = (e) => {
    setSelectedRow(e);
  };

  const handleNavigate = (route) => {
    navigate(route);
  };

  const registerUser = async () => {
    let url = API_URL + "/register";
    if (
      firstName &&
      lastName &&
      callsign &&
      country &&
      zip &&
      city &&
      state &&
      email &&
      phone &&
      password &&
      checkPassword &&
      code
    ) {
      if (password == checkPassword) {
        let outpack = {
          firstName: firstName,
          lastName: lastName,
          middleName: middleName,
          callsign: callsign,
          country: country,
          zip: zip,
          city: city,
          state: state,
          address: address,
          phone: phone,
          emergencyContact: EmergencyContact,
          age: age,
          homeTeam: homeTeam,
          rank: rank,
          unit: unit,
          company: company,
          section: section,
          years: years,
          playTypes: playTypes,
          registerMedals: registerMedals,
          registerBadges: registerBadges,
          eventYears: eventYears,
          skills: skills,
          otherInfo: otherInfo,
          loginEmail: loginEmail,
          email: email,
          password: password,
          code: code,
          show_location: showLocation,
          show_name: showName,
        };
        poster(outpack, url).then((response) => {
          if (response.ok) {
            toggleRedirect(true);
          }
        });
      } else {
        alert("Passwords do not match");
      }
    } else {
      alert("Please fill in all of the required fields");
    }
  };

  const editPlayerInfo = (
    selectedMember,
    tempRank,
    tempUnit,
    tempCompany,
    tempSection,
  ) => {
    let url = API_URL + "/updateUser";
    let outpack = {
      id: selectedMember,
      rank: tempRank,
      unit: tempUnit,
      company: tempCompany,
      section: tempSection,
    };
    poster(outpack, url).then((response) => {
      if (response.ok) {
        alert("Player Info Updated");
      }
    });
  };

  const fetchNewFeaturesText = () => {
    let url = API_URL + "/fetchNewFeatures";
    let outpack = {};
    poster(outpack, url).then((response) => {
      if (response.ok) {
        setNewFeaturesText(response.features_text);
      }
    });
  };

  const editNewFeaturesText = (text) => {
    let url = API_URL + "/updateNewFeatures";
    let outpack = {
      text: text,
    };
    poster(outpack, url).then((response) => {
      if (response.ok) {
        // alert("Features Text Updated");
      }
    });
  };

  const fetchAnnouncmentsText = () => {
    let url = API_URL + "/fetchAnnouncments";
    let outpack = {};
    poster(outpack, url).then((response) => {
      if (response.ok) {
        setAnnouncmentsText(response.announcments_text);
      }
    });
  };

  const editAnnouncmentsText = (text) => {
    let url = API_URL + "/updateAnnouncments";
    let outpack = {
      text: text,
    };
    poster(outpack, url).then((response) => {
      if (response.ok) {
        alert("Announcments Updated");
      }
    });
  };

  const updatePlayerInfo = (
    tempFirstName,
    tempLastName,
    tempMiddleName,
    tempCallsign,
    tempAge,
    tempRank,
    tempUnit,
    tempCompany,
    tempSection,
    tempShowName,
  ) => {
    let url = API_URL + "/updateUser";
    let outpack = {
      id: user.id,
      first_name: tempFirstName,
      last_name: tempLastName,
      middle_name: tempMiddleName,
      callsign: tempCallsign,
      age: tempAge,
      rank: tempRank,
      unit: tempUnit,
      company: tempCompany,
      section: tempSection,
      show_name: tempShowName,
    };
    poster(outpack, url).then((response) => {
      if (response.ok) {
        alert("Player Info Updated");
      }
    });
  };

  const updateContactInfo = (
    tempAddress,
    tempCity,
    tempState,
    tempCountry,
    tempEmail,
    tempPhone,
    tempEmergency,
    tempShowLocation,
  ) => {
    let url = API_URL + "/updateUser";
    let outpack = {
      id: user.id,
      address: tempAddress,
      city: tempCity,
      state: tempState,
      country: tempCountry,
      contactEmail: tempEmail,
      phone: tempPhone,
      emergency_contact: tempEmergency,
      show_location: tempShowLocation,
    };
    poster(outpack, url).then((response) => {
      if (response.ok) {
        alert("Contact Info Updated");
      }
    });
  };

  const updateExperienceInfo = (tempYears, tempTeam, tempStyles) => {
    let url = API_URL + "/updateUser";
    let outpack = {
      id: user.id,
      home_team: tempTeam,
      playTypes: tempStyles.toString(),
      yearsXP: tempYears,
    };
    poster(outpack, url).then((response) => {
      if (response.ok) {
        alert("Experience Info Updated");
      }
    });
  };

  const updateOtherInfo = (tempSkills, tempOtherInfo) => {
    let url = API_URL + "/updateUser";
    let outpack = {
      id: user.id,
      skills: tempSkills,
      otherInfo: tempOtherInfo,
    };

    poster(outpack, url).then((response) => {
      if (response.ok) {
        alert("Other Info Updated");
      }
    });
  };

  const createProduct = (
    product_name,
    product_desc,
    product_material,
    product_SKU,
    product_dimensions,
    product_color,
    product_price,
    product_shipping,
    product_quantity,
    product_category,
  ) => {
    var formData = new FormData();
    if (newProductImages !== null) {
      for (let i = 0; i < newProductImages.length; i++) {
        formData.append(`file`, newProductImages[i]);
      }
      let URL = API_URL + "/createProduct";
      let outpack = {
        product_name: product_name,
        product_desc: product_desc,
        product_material: product_material,
        product_SKU: product_SKU,
        product_dimensions: product_dimensions,
        product_color: product_color,
        product_price: product_price,
        product_shipping: product_shipping,
        product_quantity: product_quantity,
        product_category: product_category,
      };
      formPoster(URL, formData, outpack).then((response) => {
        if (response.status_code === 200) {
          fetchProducts();
        }
      });
    }
  };

  const editProduct = (
    product_ID,
    product_name,
    product_desc,
    product_material,
    product_SKU,
    product_dimensions,
    product_color,
    product_price,
    product_shipping,
    product_quantity,
    product_category,
  ) => {
    let URL = API_URL + "/editProduct";
    let outpack = {
      product_ID: product_ID,
      product_name: product_name,
      product_desc: product_desc,
      product_material: product_material,
      product_SKU: product_SKU,
      product_dimensions: product_dimensions,
      product_color: product_color,
      product_price: product_price,
      product_shipping: product_shipping,
      product_quantity: product_quantity,
      product_category: product_category,
      product_images: selectedProductImages,
    };
    var formData = new FormData();
    if (newProductImages !== null) {
      for (let i = 0; i < newProductImages.length; i++) {
        formData.append(`file`, newProductImages[i]);
      }
    }
    formPoster(URL, formData, outpack).then((response) => {
      if (response.status_code === 200) {
        fetchProducts();
      }
    });
  };

  const deleteProduct = (product_ID) => {
    let URL = API_URL + "/deleteProduct";
    let outpack = {
      product_ID: product_ID,
    };
    poster(outpack, URL).then((response) => {
      if (response.status_code === 200) {
        fetchProducts();
      }
    });
  };

  const fetchProducts = () => {
    let URL = API_URL + "/fetchProducts";
    let outpack = {};
    poster(outpack, URL).then((response) => {
      if (response.status_code === 200) {
        setCurrentProducts(response.products);
      }
    });
  };

  const fetchUser = () => {
    let URL = API_URL + "/fetchUser";
    let outpack = { user_id: user.id };
    poster(outpack, URL).then((response) => {
      if (response.status_code === 200) {
        setFirstName(response.first_name);
        setLastName(response.last_name);
        setMiddleName(response.middle_name);
        setAge(response.age);
        setHomeTeam(response.homeTeam);
        setFormatedName(response.name);
        setYears(response.yearsXP);
        setCallsign(response.callsign);
        setRank(response.rank);
        setUnit(response.unit);
        setPlayTypes(response.playTypes);
        setEmergencyContact(response.contact);
        setCompany(response.company);
        setCity(response.city);
        setCountry(response.country);
        setState(response.state);
        setYears(response.yearsXP);
        setAddress(response.address);
        setEmail(response.email);
        setPhone(response.phone);
        setSection(response.section);
        setBadgeInfo(response.badges);
        setMedalInfo(response.medals);
        setSkills(response.skills);
        setOtherInfo(response.other_info);
        setProfilePicUrl(response.profile_pic);
        setPlayerEvents(response.player_events);
        toggleShowName(response.showName);
        toggleShowLocation(response.showLocation);
      }
    });
  };

  const fetchMembers = () => {
    let URL = API_URL + "/fetchMembers";
    let outpack = {};
    poster(outpack, URL).then((response) => {
      if (response.status_code === 200) {
        setMembers(response.members);
      }
    });
  };

  const fetchMedals = () => {
    let URL = API_URL + "/fetchMedals";
    let outpack = {};
    poster(outpack, URL).then((response) => {
      if (response.status_code === 200) {
        setMedals(response.medals);
      }
    });
  };

  const fetchBadges = () => {
    let URL = API_URL + "/fetchBadges";
    let outpack = {};
    poster(outpack, URL).then((response) => {
      if (response.status_code === 200) {
        setBadges(response.badges);
      }
    });
  };

  const fetchPastEvents = () => {
    let URL = API_URL + "/fetchPastEvents";
    let outpack = {};
    poster(outpack, URL).then((response) => {
      if (response.status_code === 200) {
        setPastEvents(null);
        setPastEvents(response.past_events);
      }
    });
  };

  const fetchCategories = () => {
    let URL = API_URL + "/fetchCategories";
    let outpack = {};
    poster(outpack, URL).then((response) => {
      if (response.status_code === 200) {
        setCurrentCategories(response.categories);
      }
    });
  };

  const deleteCategory = (category_ID) => {
    let URL = API_URL + "/deleteCategory";
    let outpack = {
      category_ID: category_ID,
    };
    poster(outpack, URL).then((response) => {
      if (response.status_code === 200) {
        fetchCategories();
      }
    });
  };

  const createCategory = (category_name, category_desc) => {
    var formData = new FormData();
    if (newCategoryImage !== null) {
      formData.append(`file`, newCategoryImage);
    }
    let URL = API_URL + "/createCategory";
    let outpack = {
      category_name: category_name,
      category_desc: category_desc,
    };
    formPoster(URL, formData, outpack).then((response) => {
      if (response.status_code === 200) {
        fetchCategories();
      }
    });
  };

  const editCategory = (category_id, category_name, category_desc) => {
    var formData = new FormData();
    if (newCategoryImage !== null) {
      formData.append(`file`, newCategoryImage);
    }
    let URL = API_URL + "/editCategory";
    let currentImage;
    if (selectedCategoryImage.length < 1) {
      currentImage = null;
    } else {
      currentImage = selectedCategoryImage;
    }
    let outpack = {
      category_id: category_id,
      category_name: category_name,
      category_desc: category_desc,
    };
    formPoster(URL, formData, outpack).then((response) => {
      if (response.status_code === 200) {
        fetchCategories();
      }
    });
  };

  const fetchContactInfo = () => {
    let URL = API_URL + "/fetchContactInfo";
    let outpack = {};
    poster(outpack, URL).then((response) => {
      if (response.status_code === 200) {
        handleSetContactInfo(response);
      }
    });
  };

  const fetchServicesInfo = () => {
    let URL = API_URL + "/fetchServicesInfo";
    let outpack = {};
    poster(outpack, URL).then((response) => {
      if (response.status === 200) {
        handleSetServicesInfo(response);
      }
    });
  };

  const updateServicesInfo = (text) => {
    let URL = API_URL + "/updateServicesInfo";
    let outpack = {
      text: text,
    };
    poster(outpack, URL).then((response) => {
      if (response.status === 200) {
        return;
      }
    });
  };

  const updateAboutUs = (text) => {
    let URL = API_URL + "/updateAboutUs";
    let outpack = {
      text: text,
    };
    poster(outpack, URL).then((response) => {
      if (response.ok) {
        fetchAboutUs();
      }
    });
  };

  const fetchAboutUs = () => {
    let URL = API_URL + "/fetchAboutUs";
    let outpack = {};
    poster(outpack, URL).then((response) => {
      if (response.ok) {
        setAboutUsText(response.about_us_text);
      }
    });
  };

  const updateHistory = (text) => {
    let URL = API_URL + "/updateHistory";
    let outpack = {
      text: text,
    };
    poster(outpack, URL).then((response) => {
      if (response.ok) {
        fetchHistory();
      }
    });
  };

  const fetchHistory = () => {
    let URL = API_URL + "/fetchHistory";
    let outpack = {};
    poster(outpack, URL).then((response) => {
      if (response.ok) {
        console.log(response.history_text);
        setHistoryText(response.history_text);
      }
    });
  };

  const updateRecruiting = (text) => {
    let URL = API_URL + "/updateRecruiting";
    let outpack = {
      text: text,
    };
    poster(outpack, URL).then((response) => {
      if (response.ok) {
        fetchRecruiting();
      }
    });
  };

  const fetchRecruiting = (text) => {
    let URL = API_URL + "/fetchRecruiting";
    let outpack = {};
    poster(outpack, URL).then((response) => {
      if (response.ok) {
        setRecruitingText(response.recruiting_text);
      }
    });
  };



  const updateUserBadges = (
    id,
    new_badges
  ) => {
    let URL = API_URL + "/updateUserBadges";
    let outpack = {
      id: id,
      new_badges:new_badges
    };
    poster(outpack, URL).then((response) => {
      if (response.ok) {
        console.log('response.message')
      }
    });
  };

  const removeUserBadge = (
    user_id,
    badge_id
  ) => {
    let URL = API_URL + "/removeUserBadge";
    let outpack = {
      user_id:user_id,
      badge_id:badge_id
    };
    poster(outpack, URL).then((response) => {
      if (response.ok) {
        console.log('response.message')
      }
    });
  };




  const updateBadges = (
    name,
    description,
    gold_requirements,
    silver_requirements,
    bronze_requirements,
    id = null,
    gold_image = null,
    silver_image = null,
    bronze_image = null,
  ) => {
    let URL = API_URL + "/updateBadges";
    let outpack = {
      id: id,
      name: name,
      description: description,
      gold_requirements: gold_requirements,
      silver_requirements: silver_requirements,
      bronze_requirements: bronze_requirements,
    };
    console.log(outpack);
    poster(outpack, URL).then((response) => {
      if (response.ok) {
        if (!id) {
          let badge_id = response.new_badge_id;
          if (gold_image || silver_image || bronze_image) {
            updateBadgeImages(badge_id, gold_image, silver_image, bronze_image);
          }
        }
        else{
          if (gold_image || silver_image || bronze_image) {
            updateBadgeImages(id, gold_image, silver_image, bronze_image);
          }
        }
      }
    });
  };

  const updateBadgeImages = (
    badge_id,
    gold_image,
    silver_image,
    bronze_image,
  ) => {
    let URL = API_URL + "/updateBadgePic";
    let outpack = {
      badge_id: badge_id,
    };
    var formData = new FormData();

    // Check if gold_image is not null and append to formData
    if (gold_image !== null) {
      formData.append("gold_image", gold_image[0]);
    }

    // Check if silver_image is not null and append to formData
    if (silver_image !== null) {
      formData.append("silver_image", silver_image[0]);
    }

    // Check if bronze_image is not null and append to formData
    if (bronze_image !== null) {
      formData.append("bronze_image", bronze_image[0]);
    }

    formPoster(URL, formData, outpack).then((response) => {
      if (response.status === 200) {
        // Handle success
        return;
      } else {
        // Handle failure
      }
    });
  };


  const updateMedals =(
    name,
    description,
    id = null,
    medal_image = null,
  ) => {
    let URL = API_URL + "/updateMedals";
    let outpack = {
      id: id,
      name: name,
      description: description,
    };
    // console.log(outpack);
    poster(outpack, URL).then((response) => {
      if (response.ok) {
        console.log(response.new_medal_id)
        if (!id) {
          let medal_id = response.new_medal_id;
          console.log('update medal')
          if(medal_image){
            updateMedalImage(medal_id , medal_image);
          }
        }
        else{
          if(medal_image){
            updateMedalImage(id , medal_image);
          }
        }
      }
    });
  }

  const updateMedalImage= (medal_id, image) => {
    let URL = API_URL + "/updateMedalImage";
    let outpack = {
      medal_id: medal_id,
    };
    console.log(outpack);
    var formData = new FormData();
    if (image !== null) {
      for (let i = 0; i < image.length; i++) {
        formData.append(`file`, image[i]);
      }
    }
    formPoster(URL, formData, outpack).then((response) => {
      if (response.status === 200) {
        // setProfilePicUrl(null);
        // setProfilePicUrl(response.profile_pic_url);
        return;
      }
    });
  };

  const deleteMedal = (id)=>{
    let URL = API_URL + "/deleteMedal";
    let outpack = {
      medal_id: id,
    };
    poster(outpack, URL).then((response) => {
      if (response.status_code === 200) {
        fetchMedals();
      }
    });
  }


  const editEvent = (
    id,
    editEventName,
    editEventVenu,
    editCity,
    editState,
    editCOuntry,
    editAddress,
    editGameRunner,
    editWebsite,
    editEmail,
    editPhone,
    editDescription,
    editYears,
  ) => {
    let URL = API_URL + "/updatePastEvent";
    let outpack = {
      event_id: id,
      user_id: user.id,
      event_name: editEventName,
      event_venue: editEventVenu,
      city: editCity,
      state: editState,
      country: editCOuntry,
      street_address: editAddress,
      game_runner: editGameRunner,
      phone: editPhone,
      email: editEmail,
      website: editWebsite,
      description: editDescription,
      event_years: editYears,
    };
    console.log(outpack);
    poster(outpack, URL).then((response) => {
      if (response.ok) {
        setPastEvents(null);
        fetchPastEvents();
      }
    });
  };

  const addEvent = (
    editEventName,
    editEventVenu,
    editCity,
    editState,
    editCOuntry,
    editAddress,
    editGameRunner,
    editWebsite,
    editEmail,
    editPhone,
    editDescription,
    editYears,
    editImage,
  ) => {
    let URL = API_URL + "/addPastEvent";
    let outpack = {
      user_id: user.id,
      event_name: editEventName,
      event_venue: editEventVenu,
      city: editCity,
      state: editState,
      country: editCOuntry,
      street_address: editAddress,
      game_runner: editGameRunner,
      phone: editPhone,
      email: editEmail,
      website: editWebsite,
      description: editDescription,
      event_years: editYears,
    };
    poster(outpack, URL).then((response) => {
      if (response.ok) {
        alert(response.message);
        setPastEvents(null);
        fetchPastEvents();
        updateEventPic(response.new_event_id, editImage);
      }
    });
  };

  const deleteEvent = (event_id) => {
    let URL = API_URL + "/deletePastEvent";
    let outpack = {
      event_id: event_id,
    };
    poster(outpack, URL).then((response) => {
      if (response.ok) {
        alert(response.message);
        setPastEvents(null);
        fetchPastEvents();
        return response.new_event_id;
      }
    });
  };

  const deleteBadge = (badge_id) => {
    let URL = API_URL + "/deleteBadge";
    let outpack = {
      badge_id: badge_id,
    };
    poster(outpack, URL).then((response) => {
      if (response.ok) {
        alert(response.message);
        fetchBadges();
        return;
      }
    });
  };





  const fetchEventDetails = (event_id) => {
    let URL = API_URL + "/fetchEventDetails";
    let outpack = {
      event_id: event_id,
    };
    poster(outpack, URL).then((response) => {
      if (response.ok) {
        console.log(response.years);
        setTempEventVenu(response.venue);
        setTempEventName(response.name);
        setTempYears(response.years);
        setTempCity(response.city);
        setTempState(response.state);
        setTempCountry(response.country);
        setTempAddress(response.street_address);
        setTempGameRunner(response.game_runner);
        setTempWebsite(response.website);
        setTempEmail(response.email);
        setTempDescription(response.description);
        setTempPhone(response.phone);
        setTempImageUrl(response.image_url);
        setTempEventFactions(response.event_factions);
        setTempEventScenario(response.scenario);
        setTempUnits(response.event_units);
      }
    });
  };

  const addNewFaction = (event_id, faction_name, faction_desc) => {
    let URL = API_URL + "/addEventFaction";
    let outpack = {
      event_id: event_id,
      faction_name: faction_name,
      faction_desc: faction_desc,
    };
    poster(outpack, URL).then((response) => {
      if (response.ok) {
        alert(response.message);
        setTempEventFactions(response.event_factions);
      }
    });
  };

  const updateFaction = (faction_id, faction_name, faction_desc) => {
    let URL = API_URL + "/updateFactionDetails";
    let outpack = {
      faction_id: faction_id,
      faction_name: faction_name,
      faction_desc: faction_desc,
    };
    poster(outpack, URL).then((response) => {
      if (response.ok) {
        alert(response.message);
        setTempEventFactions(response.event_factions);
      }
    });
  };

  const fetchEventFactions = (faction_id) => {
    let URL = API_URL + "/fetchEventFactions";
    let outpack = {
      faction_id: faction_id,
    };
    poster(outpack, URL).then((response) => {
      if (response.ok) {
        setTempEventFactions(response.event_factions);
      }
    });
  };

  const deleteFaction = (faction_id) => {
    let URL = API_URL + "/deleteEventFaction";
    let outpack = {
      faction_id: faction_id,
    };
    poster(outpack, URL).then((response) => {
      if (response.ok) {
        alert(response.message);
        setTempEventFactions(response.event_factions);
      }
    });
  };

  const fetchEventYearDetails = (year_id) => {
    let URL = API_URL + "/fetchEventYearDetails";
    let outpack = {
      year_id: year_id,
    };
    poster(outpack, URL).then((response) => {
      if (response.ok) {
        setSelectedYear(response.year);
        setTempStartDate(response.start_date);
        setTempEndDate(response.end_date);
        setAttendingPlayers(response.attending_players);
        setEventGenerals(response.event_generals);
        setEventScore(response.event_scores);
        setTempUnitId(response.event_unit);
      }
    });
  };

  const addEventGeneral = (
    year_id,
    faction_id,
    general_id,
    general_name,
    new_image,
    edit_image,
  ) => {
    let URL = API_URL + "/addEventGeneral";
    let outpack = {
      event_year_id: year_id,
      faction_id: faction_id,
      general_name: general_name,
      general_id: general_id,
    };
    poster(outpack, URL).then((response) => {
      if (response.ok) {
        alert(response.message);
        setNewGeneralId(response.general_id);
        if (new_image) {
          updateGeneralPic(
            general_id !== null ? general_id : response.general_id,
            edit_image,
          );
        }
      }
    });
  };

  const addEventScore = (year_id, faction_id, score) => {
    let URL = API_URL + "/updateEventScore";
    let outpack = {
      event_year_id: year_id,
      faction_id: faction_id,
      score: score,
    };
    poster(outpack, URL).then((response) => {
      if (response.ok) {
        alert(response.message);
      }
    });
  };

  const addEventDates = (year_id) => {
    let URL = API_URL + "/addEventDates";
    let outpack = {
      event_year_id: year_id,
      start_date: tempStartDate,
      end_date: tempEndDate,
    };
    poster(outpack, URL).then((response) => {
      if (response.ok) {
        alert(response.message);
      }
    });
  };

  const updateScenarioText = (event_id, scenario_text) => {
    let URL = API_URL + "/updateScenarioText";
    let outpack = {
      event_id: event_id,
      scenario_text: scenario_text,
    };
    poster(outpack, URL).then((response) => {
      if (response.ok) {
        alert(response.message);
      }
    });
  };

  const addEventUnit = (
    event_id,
    tempFactionId,
    tempUnitName,
    tempUnitDesc,
    new_image,
    edit_image,
    unit_id = null,
  ) => {
    let URL = API_URL + "/addEventUnit";
    let outpack = {
      event_id: event_id,
      faction_id: tempFactionId,
      unit_name: tempUnitName,
      unit_desc: tempUnitDesc,
    };
    poster(outpack, URL).then((response) => {
      if (response.ok) {
        if (new_image) {
          console.log(response.unit_id);
          updateUnitPic(
            unit_id !== null ? unit_id : response.unit_id,
            edit_image,
          );
        }
      }
    });
  };

  const setUnitYear = (unit_id, year) => {
    let URL = API_URL + "/setUnitYear";
    let outpack = {
      unit_id: unit_id,
      year: year,
    };
    console.log(outpack);
    poster(outpack, URL).then((response) => {
      if (response.ok) {
        alert(response.message);
      }
    });
  };

  const updateUnitPic = (unit_id, image) => {
    let URL = API_URL + "/updateUnitPic";
    let outpack = {
      unit_id: unit_id,
    };
    console.log(outpack);
    var formData = new FormData();
    if (image !== null) {
      for (let i = 0; i < image.length; i++) {
        formData.append(`file`, image[i]);
      }
    }
    formPoster(URL, formData, outpack).then((response) => {
      if (response.status === 200) {
        // setProfilePicUrl(null);
        // setProfilePicUrl(response.profile_pic_url);
        return;
      }
    });
  };

  const updateUnitCommand = (role, name, id, year_id) => {
    let URL = API_URL + "/updateUnitCommand";
    let outpack = {
      year_id: year_id,
      name: name,
      user_id: id,
      role: role,
    };

    poster(outpack, URL).then((response) => {
      if (response.ok) {
        alert(response.message);
      }
    });
  };

  const fetchEventUnitORBAT = (year_id) => {
    let URL = API_URL + "/fetchUnitYearORBAT";
    let outpack = {
      year_id: year_id,
    };
    poster(outpack, URL).then((response) => {
      if (response.ok) {
        console.log(response);
        if (response.co_name) {
          setTempUnitCOname(response.co_name);
        }
        if (response.xo_name) {
          setTempUnitXOname(response.xo_name);
        }
      }
    });
  };

  const value = {
    fetchEventUnitORBAT,
    updateUnitCommand,
    handleAddNewProductImagePreviews,
    editProduct,
    handleSetNewProductImages,
    fetchProducts,
    deleteProduct,
    handleNavigate,
    handleSetSelectedRow,
    handleSetNewCategoryImage,
    createCategory,
    fetchCategories,
    deleteCategory,
    editCategory,
    fetchContactInfo,
    fetchServicesInfo,
    updateServicesInfo,
    address,
    setAddress,
    phone,
    setPhone,
    email,
    setEmail,
    handleEmailChange,
    handlePhoneNumberChange,
    handleSetAddress,
    selectedCategoryImage,
    setSelectedCategoryImage,
    selectedRow,
    setSelectedRow,
    currentProducts,
    createProduct,
    newProductImages,
    setNewProductImages,
    currentImageURLs,
    setCurrentImageURLs,
    tempIndex,
    setTempIndex,
    selectedProductImages,
    setSelectedProductImages,
    currentCategories,
    setCurrentCategories,
    newCategoryURL,
    setNewCategoryURL,
    newCategoryImage,
    setNewCategoryImage,
    servicesText,
    setServicesText,
    location,
    setLocation,
    fetchUser,
    badgeInfo,
    setBadgeInfo,
    firstName,
    setFirstName,
    middleName,
    setMiddleName,
    lastName,
    setLastName,
    address,
    setAddress,
    city,
    setCity,
    state,
    setState,
    country,
    setCountry,
    zip,
    setZip,
    unitNumber,
    setUnitNumber,
    phone,
    setPhone,
    EmergencyContact,
    setEmergencyContact,
    age,
    setAge,
    email,
    setEmail,
    callsign,
    setCallsign,
    homeTeam,
    setHomeTeam,
    rank,
    setRank,
    unit,
    setUnit,
    company,
    setCompany,
    section,
    setSection,
    years,
    setYears,
    playTypes,
    setPlayTypes,
    medals,
    setMedals,
    fetchMedals,
    registerMedals,
    setRegisterMedals,
    badges,
    setBadges,
    fetchBadges,
    skills,
    setSkills,
    otherInfo,
    setOtherInfo,
    registerBadges,
    setRegisterBadges,
    pastEvents,
    setPastEvents,
    fetchPastEvents,
    eventYears,
    setEventYears,
    password,
    setPassword,
    checkPassword,
    setCheckPassword,
    loginEmail,
    setLoginEmail,
    playerEvents,
    setPlayerEvents,
    code,
    setCode,
    registerUser,
    setMedalInfo,
    medalInfo,
    formattedName,
    handleSetNewProfilePic,
    profilePicUrl,
    setProfilePicUrl,
    updateProfilePic,
    updatePlayerInfo,
    updateContactInfo,
    updateExperienceInfo,
    windowWidth,
    setWindowWidth,
    toggleMobile,
    mobile,
    members,
    setMembers,
    fetchMembers,
    updateOtherInfo,
    aboutUsText,
    setAboutUsText,
    historyText,
    setHistoryText,
    recruitingText,
    setRecruitingText,
    fetchAboutUs,
    updateAboutUs,
    fetchRecruiting,
    updateRecruiting,
    editPlayerInfo,
    fetchHistory,
    updateHistory,
    editNewFeaturesText,
    fetchNewFeaturesText,
    newFeaturesText,
    setNewFeaturesText,
    editAnnouncmentsText,
    fetchAnnouncmentsText,
    announcmentsText,
    setAnnouncmentsText,
    tempEventVenu,
    setTempEventVenu,
    tempEventName,
    setTempEventName,
    tempImageUrl,
    setTempImageUrl,
    tempYears,
    setTempYears,
    tempCity,
    setTempCity,
    tempState,
    setTempState,
    tempCOuntry,
    setTempCountry,
    tempAddress,
    setTempAddress,
    tempGameRunner,
    setTempGameRunner,
    tempWebsite,
    setTempWebsite,
    tempEmail,
    setTempEmail,
    tempDescription,
    setTempDescription,
    tempStartDate,
    setTempStartDate,
    tempEndDate,
    setTempEndDate,
    tempEventId,
    setTempEventId,
    tempPhone,
    setTempPhone,
    editEvent,
    updateEventPic,
    newEventPic,
    setNewEventPic,
    handleSetNewEventPic,
    addEvent,
    deleteEvent,
    fetchEventDetails,
    addNewFaction,
    tempEventFactions,
    updateFaction,
    fetchEventFactions,
    deleteFaction,
    fetchEventYearDetails,
    selectedYear,
    setSelectedYear,
    selectedYearId,
    setSelectedYearId,
    eventGenerals,
    setEventGenerals,
    attendingPlayers,
    setAttendingPlayers,
    addEventGeneral,
    updateGeneralPic,
    newGeneralId,
    setNewGeneralId,
    addEventScore,
    eventScore,
    setEventScore,
    addEventDates,
    updateFactionPic,
    tempEventScenario,
    setTempEventScenario,
    updateScenarioText,
    addEventUnit,
    tempUnits,
    setTempUnits,
    setUnitYear,
    tempUnitId,
    setTempUnitId,
    updateBadges,
    tempUnitCOname,
    setTempUnitCOname,
    tempUnitXOname,
    setTempUnitXOname,
    showName,
    toggleShowName,
    showLocation,
    toggleShowLocation,
    updateBadges,
    deleteBadge,
    updateMedals,
    deleteMedal ,
    updateUserBadges,
    removeUserBadge
  };

  return value ? (
    <DataContext.Provider value={value}>{children}</DataContext.Provider>
  ) : null;
};
