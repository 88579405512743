import React, { useState, useContext, useEffect } from "react";
import { DataContext } from "../../common/DataContext";
import { AuthContext } from "../../common/AuthContext";
export const AboutUsText = () => {
  const [editAboutUsOpen, toggleEditAboutUsOpen] = useState(null);
  const { mobile, aboutUsText, setAboutUsText, fetchAboutUs, updateAboutUs } =
    useContext(DataContext);

  const { user } = useContext(AuthContext);

  const [tempText, setTempText] = useState(null);

  const handleEditAboutUsModal = () => {
    if (editAboutUsOpen) {
      toggleEditAboutUsOpen(false);
    } else {
      toggleEditAboutUsOpen(true);
    }
  };

  const EditAboutUsModal = (props) => {
    const [tempAboutUsText, setTempAboutUsText] = useState(null);

    const { mobile } = useContext(DataContext);

    useEffect(() => {
      if (props.editAboutUsOpen === true) {
        setTempAboutUsText(props.aboutUsText);
        fetchAboutUs();
      }
      // eslint-disable-next-line
    }, [props.editAboutUsOpen]);

    const handleAboutUsText = () => {
      updateAboutUs(tempAboutUsText);
      toggleEditAboutUsOpen(false);
    };

    return (
      <>
        {props.editAboutUsOpen && props.editAboutUsOpen === true ? (
          <>
            <div
              style={{
                position: "fixed",
                top: "20vh",
                left: mobile ? "4vw" : "32vw",
                height: mobile ? "70vh" : "60%",
                width: mobile ? "92vw" : "40vw",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "left",
                marginTop: "1vh",
                backgroundColor: "ghostwhite",
                boxShadow: "2px 2px 9px #000000",
                zIndex: "999",
                flexWrap: "wrap", // Allow items to wrap to the next row
              }}
            >
              <div
                style={{
                  height: "5vh",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#5E0516",
                  boxShadow: "2px 2px 9px #000000",
                }}
              >
                <h3 style={{ color: "#eed77a" }}>{`Edit About Us Text:`}</h3>
              </div>

              <div
                style={{
                  height: "80%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  // backgroundColor: "lightgrey",
                }}
              >
                <textarea
                  style={{
                    height: "90%",
                    width: "90%",
                    marginTop: "2vh",
                  }}
                  value={tempAboutUsText}
                  onChange={(e) => setTempAboutUsText(e.target.value)}
                />
              </div>
              <div
                style={{
                  height: "10%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <button
                  style={{ marginRight: "2vw" }}
                  onClick={(e) => props.handleEditAboutUsModal(e)}
                >
                  Cancel
                </button>
                <button onClick={() => handleAboutUsText()}>Confirm</button>
              </div>
            </div>
          </>
        ) : null}
      </>
    );
  };

  const ParagraphComponent = ({ text }) => {
    return (
      <div
        style={{
          whiteSpace: "pre-line",
          color: "#eed77a",
          textDecoration: "none",
          // textAlign: "center",
          marginBottom: "1vh",
          fontWeight: 500,
        }}
      >
        {text}
      </div>
    );
  };

  const text = `Welcome to the official website of the Commonwealth Expeditionary
              Force International Scenario Paintball Team.
  
              Established in 2003 on the hallowed grounds of Oklahoma D-Day, a
              renowned World War II Normandy invasion-themed scenario and once
              largest paintball event in the world, our team has proudly embodied
              the role of the Commonwealth Expeditionary Forces from this pivotal
              engagement.
  
              Since our inception, we've been a consistent presence at Oklahoma
              D-Day, participating passionately from 2003 until the final event in
              2021.
  
              Over the years, we've witnessed our ranks flourish, with over 300
              dedicated players hailing from 13 different countries, fostering a
              truly international community.
  
              Our diverse membership includes players from the USA, Canada, the
              UK, France, Belgium, Germany, Australia, New Zealand, and Malaysia.
  
              As a team, we've expanded our footprint beyond the iconic Oklahoma
              D-Day, becoming a fixture at other flagship scenario events such as
              Fulda Gap, Supergame, and various others.
  
              Our commitment to camaraderie, sportsmanship, and a shared passion
              for scenario paintball has solidified our reputation as a formidable
              force on and off the field.
  
              Whether you're a seasoned player or a newcomer to the world of
              scenario paintball, we invite you to explore our website, learn more
              about our rich history, and join us in our continued adventures on
              the paintball battlefield.
  
              Together, we celebrate the spirit of international cooperation,
              historical reenactment, and the thrill of scenario paintball.`;

  const handleUpdateAboutUsText = () => {
    updateAboutUs(tempText);
    handleEditAboutUsModal();
  };

  return (
    <>
      <EditAboutUsModal
        editAboutUsOpen={editAboutUsOpen}
        handleEditAboutUsModal={handleEditAboutUsModal}
        aboutUsText={aboutUsText}
        setAboutUsText={setAboutUsText}
        handleUpdateAboutUsText={handleUpdateAboutUsText}
      />
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          // justifyContent: "center",
        }}
      >
        <div
          style={{
            height: mobile ? "90%" : "85%",
            width: "90%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: mobile ? "" : "center",
            backgroundColor: "#5E0516",
            boxShadow: "1px 1px 6px 2px black",
            border: "solid",
            borderWidth: "4px",
            borderColor: "#eed77a",
            paddingBottom: "2vh",
            marginTop: mobile ? "5vh" : "3vh",
            // overflowY: mobile ? "scroll" : "scroll",
          }}
        >
          <div
            style={{
              height: mobile ? "80%" : "90%",
              width: mobile ? "80%" : "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: mobile ? "" : "",

              paddingBottom: "2vh",
              marginTop: mobile ? "5vh" : "",
              overflowY: mobile ? "scroll" : "scroll",
            }}
          >
            <h1
              style={{
                textAlign: "center",
                color: "#eed77a",
              }}
            >
              About the CEF
            </h1>

            <div>
              <ParagraphComponent text={aboutUsText} />
            </div>
          </div>
          {user && user.role === "admin" ? (
            <>
              <button
                style={{
                  marginTop: "2vh",
                }}
                onClick={(e) => handleEditAboutUsModal(e)}
              >
                Edit
              </button>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};
