import React, { useState, useEffect, useContext } from "react";
import ReactMapGL, { Source, Layer, Marker, Popup } from "react-map-gl";
import mapboxgl from "mapbox-gl";
import { DataContext } from "../../common/DataContext";
import { poster } from "../../calls/calls";
import "./styles.css";
import "./mapbox-gl.css";
// import {
//   SearchWidget,
//   LayerWidget,
//   DrawPolygonWidget,
//   clearPolygonTemplate,

// } from './mapComponents'

///// MAPBOX TOKEN & SETUP /////

/// ! DONT REMOVE THE FOLLOWING COMMENTS ! ///

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass =require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
const MAPBOX_TOKEN =
  "pk.eyJ1IjoicGVuaXRlbnRhbmdlbnQiLCJhIjoiY2tndTR1NmwyMDl0dDJybXdrYzdhenN6bSJ9.ivCCzPQgiBvvPA9zqmhBcA";

///// GLOBAL VARS /////
var searchJson = {};
var propsJson = {};

///// COMPONENT EXPORT /////

export const MapWidget = () => {
  ///// STATES & SETTERS /////
  const { mobile } = useContext(DataContext);

  const [mapRef, setMapRef] = useState(null);
  const [layerOpen, setLayerOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [resultsOpen, setResultsOpen] = useState(false);
  const [drawPolyOpen, setDrawPolyOpen] = useState(false);
  const [address, setAddress] = useState(null);
  const [city, setCity] = useState(null);
  const [postcode, setPostcode] = useState(null);
  const [state, setState] = useState(null);
  const [country, setCountry] = useState(null);
  const [searchResults, setSearchResults] = useState(null);
  const [featureCount, setFeatureCount] = useState(null);
  const [resultSelected, setResultSelected] = useState(null);
  const [selectedLayer, setSelectedLayer] = useState(
    "mapbox://styles/mapbox/satellite-streets-v11?optimize=true",
  );
  const [drawMode, setDrawMode] = useState(null);
  const [polyArray, setPolyArray] = useState([]);
  const [stringArray, setStringArray] = useState([]);
  const [pointArray, setPointArray] = useState([]);
  const [firstFeature, setfirstFeature] = useState(null);
  const [displayGeoJson, setDisplayGeoJson] = useState({});
  const [geoJson, setGeoJson] = useState({});
  // const [clearPolygon,setClearPolygon]=useState(clearPolygonTemplate)
  const [displayBoundary, setDisplayBoundary] = useState(null);
  const [area, setArea] = useState(null);
  const [centroid, setCentroid] = useState(null);
  const [polyWidgetTop, setPolyWidgetTop] = useState("15%");

  const [selectedPlace, setSelectedPlace] = useState(null);
  const [viewport, setViewport] = useState({
    longitude: -94.66757,
    latitude: 36.75996,
    zoom: 4,
  });
  ///// USE EFFECTS /////

  const places = [
    {
      id: 1,
      latitude: 36.75996,
      longitude: -94.66757,
      name: "Oklahoma D-Day",
      image:
        "https://printsmiths.nyc3.cdn.digitaloceanspaces.com/CEF/Events/D-Day_logo.png",
      url: "https://www.ddaypark.com/oklahoma-d-day",
    },
    {
      id: 2,
      latitude: 35.842856,
      longitude: -81.321233,
      name: "Fulda Gap",
      image:
        "https://printsmiths.nyc3.cdn.digitaloceanspaces.com/CEF/Events/Fulda-Gap_logo.png",
      url: "https://www.fuldagap.com/index.html",
    },
    {
      id: 3,
      latitude: 45.22093217801996,
      longitude: -122.92734570000002,
      name: "Supergame Oregon",
      image:
        "https://printsmiths.nyc3.cdn.digitaloceanspaces.com/CEF/Events/Supergame_logo.png",
      url: "https://supergamepaintball.com/oregon-sg",
    },
    {
      id: 4,
      latitude: 39.0702737008007,
      longitude: -108.150968592074,
      name: "The Battle of Powderhorn",
      image:
        "https://printsmiths.nyc3.cdn.digitaloceanspaces.com/CEF/Events/Powderhorn_logo.png",
      url: "https://www.facebook.com/media/set/?set=a.1597231043840530&type=3",
    },
    {
      id: 5,
      latitude: 39.7560464120787,
      longitude: -104.40860364960572,
      name: "DMS Operation Market Garden",
      image:
        "https://printsmiths.nyc3.cdn.digitaloceanspaces.com/CEF/Events/market_garden_logo.png",
      url: "",
    },
    {
      id: 6,
      latitude: 41.659341315174366,
      longitude: -111.82269191719662,
      name: "Aliens in the Rockies",
      image:
        "https://printsmiths.nyc3.cdn.digitaloceanspaces.com/CEF/Events/Aliens_logo.png",
      url: "",
    },
  ];

  useEffect(() => {}, [displayGeoJson]);

  useEffect(() => {
    if (geoJson !== null) {
      setDisplayGeoJson(geoJson);
      setGeoJson(null);
    }
  }, [geoJson]);

  useEffect(() => {
    let geoJson = {
      type: "FeatureCollection",
      features: [],
    };
    if (pointArray !== null) {
      for (let i = 0; i < pointArray.length; i++) {
        geoJson.features.push(pointArray[i]);
      }
    }

    if (polyArray !== null && polyArray.length > 2) {
      let polygonFeature = {
        type: "Feature",
        properties: {
          category: null,
        },
        geometry: {
          type: "Polygon",
          coordinates: null,
        },
      };
      geoJson.features.push(polygonFeature);
      polygonFeature.geometry.coordinates = [polyArray];
    }
    let lineFeature = {
      type: "Feature",
      properties: {
        category: null,
      },
      geometry: {
        type: "LineString",
        coordinates: [stringArray],
      },
    };
    geoJson.features.push(lineFeature);
    setGeoJson(geoJson);
  }, [polyArray]);

  ///// FUNCTIONS /////

  const polyStyle = {
    id: "polygon",
    type: "fill",
    paint: {
      "fill-color": "#55CEFF",
      "fill-opacity": 0.4,
    },
  };

  const lineStyle = {
    id: "line",
    type: "line",
    paint: {
      "line-color": "#FE6E00",
      "line-width": 2,
      "line-dasharray": [2, 4],
    },
  };
  const pointStyle = {
    id: "point",
    type: "circle",
    paint: {
      "circle-color": "#FE6E00",
      "circle-stroke-color": "#55CEFF",
      "circle-radius": 5,
    },
  };

  const drawPolygonPoint = (e) => {
    let lnglat = e.lngLat;
    let cordArray = [];
    let outerCordArray = [];
    let lat = lnglat.lat;
    let lng = lnglat.lng;
    cordArray.push(lng, lat);
    var string = stringArray;
    var joined = polyArray;
    if (firstFeature === null) {
      setfirstFeature(cordArray);
    }
    if (joined.length > 1) {
      joined.splice(joined.length - 1, 1);
    }
    joined.push(cordArray, firstFeature);
    string.push(cordArray);
    let feature = {
      type: "Feature",
      properties: {
        category: null,
      },
      geometry: {
        type: "Point",
        coordinates: null,
      },
    };
    feature.geometry.coordinates = cordArray;

    let tempPointArray = [];
    tempPointArray.push(feature);
    for (let i = 0; i < pointArray.length; i++) {
      tempPointArray.push(pointArray[i]);
    }

    setPointArray(null);
    setPointArray(tempPointArray);
    setStringArray(null);
    setStringArray(string);
    setPolyArray(null);
    setPolyArray(joined);
  };

  const searchProceed = () => {
    let outpack = {
      address: address ? address : "None",
      city: city ? city : "None",
      postcode: postcode ? postcode : "None",
      state: state ? state : "None",
      country: country ? country : "None",
    };
    poster(outpack, "/api/geocoder").then((response) => {
      searchJson = response.results ? response.results : null;
      propsJson = response.properties ? response.properties : null;
      if (searchJson !== null && propsJson !== null) {
        let featureLength = searchJson.length;
        setSearchResults(searchJson);
        setFeatureCount(featureLength);
        var newViewport = {};
        if (featureLength === 1) {
          newViewport = {
            longitude: searchJson[0].coordinates[0],
            latitude: searchJson[0].coordinates[1],
            zoom: 16,
          };
        }
        if (featureLength > 1) {
          newViewport = {
            longitude: propsJson.search_center[0],
            latitude: propsJson.search_center[1],
            zoom: 2,
          };
          handleSetResultsOpen();
        }
        setViewport(newViewport);
      }
    });

    // handleSetSearchOpen()
  };

  ///// COMPONENTS //////

  const resultMarker = (result, index) => {
    return (
      <>
        <Marker
          key={`marker-${result.index}`}
          longitude={result.coordinates[0]}
          latitude={result.coordinates[1]}
        />
        <Popup
          longitude={result.coordinates[0]}
          latitude={result.coordinates[1]}
          anchor="top"
          offset={10}
          closeOnMove={false}
          closeOnClick={false}
        >
          {result.name}
          <br />
          {`Latitude: ${result.coordinates[0]}`}
          <br />
          {`Longitude: ${result.coordinates[1]}`}
        </Popup>
      </>
    );
  };

  ///// HANDLERS /////

  const handleDrawPolygon = () => {
    setDrawMode("polygon");
  };
  const handleClearPolygon = () => {
    setDrawMode(null);
    setPolyArray([]);
    setStringArray([]);
    setPointArray([]);
    setfirstFeature(null);
    setDisplayBoundary("");
    setCentroid("");
    setArea("");
  };

  const handleSetPolygon = () => {
    setDisplayBoundary(polyArray);
    let outpack = {
      polygon: polyArray ? polyArray : "None",
    };

    poster(outpack, "/api/polygonInfo").then((response) => {
      setCentroid(`${response.centroid[0]}, ${response.centroid[1]}`);
      setArea(`${response.area} m²`);
    });
  };
  const handleSetResultSelected = (e) => {
    setResultSelected(e);
  };
  const handleSetResultsOpen = () => {
    setResultsOpen(!resultsOpen);
    if (resultsOpen) {
      setPolyWidgetTop("42%");
    }
    if (!resultsOpen) {
      setPolyWidgetTop("70%");
    }
  };
  const handleSetDrawPolyOpen = () => {
    setDrawPolyOpen(!drawPolyOpen);
  };
  const handleSetLayerOpen = () => {
    setLayerOpen(!layerOpen);
  };
  const handleSetSearchOpen = () => {
    setSearchOpen(!searchOpen);
    if (searchOpen) {
      setPolyWidgetTop("15%");
    }
    if (!searchOpen) {
      setPolyWidgetTop("42%");
    }
  };
  const handleSetAddress = (e) => {
    setAddress(e);
  };
  const handleSetCity = (e) => {
    setCity(e);
  };
  const handleSetPostcode = (e) => {
    setPostcode(e);
  };
  const handleSetState = (e) => {
    setState(e);
  };
  const handleSetCountry = (e) => {
    setCountry(e);
  };

  const handleLayerChange = (e) => {
    setSelectedLayer(e);
  };

  const onViewportChange = (newViewport) => {
    setViewport(newViewport);
  };

  const handleClearSearch = () => {
    setAddress("");
    setCity("");
    setPostcode("");
    setState("");
    setCountry("");
    setSearchResults("");
    setResultsOpen(false);
    setPolyWidgetTop("42%");
    searchJson = {};
    propsJson = {};
  };

  const handleViewResult = () => {
    let newViewport = {
      longitude: searchResults[resultSelected].coordinates[0],
      latitude: searchResults[resultSelected].coordinates[1],
      zoom: 18,
    };
    setViewport(newViewport);
  };

  ///// COMPONENT RENDER /////
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <ReactMapGL
        ref={mapRef}
        {...viewport}
        width={"100%"}
        height={"100%"}
        onMove={onViewportChange}
        mapboxAccessToken={MAPBOX_TOKEN}
        mapboxApiAccessToken={MAPBOX_TOKEN}
        mapStyle={selectedLayer}
        // projection={"globe"}
        // terrain={{ source: "mapbox-dem", exaggeration: 1.5 }}
        onClick={drawMode === "polygon" ? (e) => drawPolygonPoint(e) : null}
      >
        {places.map((place) => (
          <Marker
            // key={place.id}
            latitude={place.latitude}
            longitude={place.longitude}
          >
            <button
              style={{
                width: "auto",
                height: "auto",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "transparent",
                paddingRight: "0vw",
                paddingLeft: "0vw",
                paddingTop: "0vw",
                paddingBottom: "0vw",
              }}
              onClick={(e) => {
                e.preventDefault();
                setSelectedPlace(place);
              }}
            >
              <img
                style={{
                  width: mobile ? "15vw" : "3.5vw",
                  height: "auto",
                }}
                src={place.image}
                alt="Marker"
              />
            </button>
          </Marker>
        ))}
        {selectedPlace && (
          <Popup
            latitude={selectedPlace.latitude}
            longitude={selectedPlace.longitude}
            onClose={() => setSelectedPlace(null)}
          >
            <div>
              <h3>{selectedPlace.name}</h3>
              <p>
                <a
                  href={selectedPlace.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Visit Website
                </a>
              </p>
            </div>
          </Popup>
        )}

        {searchResults && searchResults.map(resultMarker)}
        {/* <SearchWidget
        featureCount={featureCount}
        searchOpen={searchOpen}
        resultsOpen={resultsOpen}
        searchResults={searchResults}
        address={address}
        city={city}
        postcode={postcode}
        state={state}
        country={country}
        resultSelected={resultSelected}
        handleSetResultsOpen={handleSetResultsOpen}
        handleSetSearchOpen={handleSetSearchOpen}
        handleSetAddress={handleSetAddress}
        handleSetCity={handleSetCity}
        handleSetPostcode={handleSetPostcode}
        handleSetState={handleSetState}
        handleSetCountry={handleSetCountry}
        handleSetResultSelected={handleSetResultSelected}
        handleClearSearch={handleClearSearch}
        handleViewResult={handleViewResult}
        action={searchProceed}
      />
      <LayerWidget
        layerOpen={layerOpen}
        handleSetLayerOpen={handleSetLayerOpen}
        handleLayerChange={handleLayerChange}
        selectedLayer={selectedLayer}
      />
      <DrawPolygonWidget
        searchOpen={searchOpen}
        resultsOpen={resultsOpen}
        drawPolyOpen={drawPolyOpen}
        displayBoundary={displayBoundary}
        area={area}
        centroid={centroid}
        polyWidgetTop={polyWidgetTop}
        handleDrawPolygon={handleDrawPolygon}
        handleSetDrawPolyOpen={handleSetDrawPolyOpen}
        handleClearPolygon={handleClearPolygon}
        handleSetPolygon={handleSetPolygon}
      /> */}
        <Source
          id="mapbox-dem"
          type="raster-dem"
          url="mapbox://mapbox.mapbox-terrain-dem-v1"
        />
        <Source type="geojson" data={displayGeoJson && displayGeoJson}>
          <Layer {...polyStyle} />
          <Layer {...lineStyle} />
          <Layer {...pointStyle} />
        </Source>
      </ReactMapGL>
    </div>
  );
};
