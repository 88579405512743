import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../common/DataContext/index";
import { AuthContext } from "../../common/AuthContext";
import { Navigate } from "react-router-dom";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { UserSidebar, ConfirmModal } from "../../components/common";

var newYear;
var newImage = false;
var editImage;
export const Events = () => {
  const {
    pastEvents,
    fetchPastEvents,
    mobile,
    tempEventName,
    setTempEventName,
    tempImageUrl,
    setTempImageUrl,
    tempYears,
    setTempYears,
    tempEventVenu,
    setTempEventVenu,
    tempCity,
    setTempCity,
    tempState,
    setTempState,
    tempCOuntry,
    setTempCountry,
    tempAddress,
    setTempAddress,
    tempGameRunner,
    setTempGameRunner,
    tempWebsite,
    setTempWebsite,
    tempEmail,
    setTempEmail,
    tempDescription,
    setTempDescription,
    tempStartDate,
    setTempStartDate,
    tempEndDate,
    setTempEndDate,
    tempEventId,
    setTempEventId,
    tempPhone,
    setTempPhone,
    addEvent,
    deleteEvent,
  } = useContext(DataContext);
  const { user, redirect } = useContext(AuthContext);
  const [modalMode, setModalMode] = useState(false);
  const [editEventOpen, toggleEditEventOpen] = useState(false);

  useEffect(() => {
    fetchPastEvents();

    // eslint-disable-next-line
  }, []);

  const chunkArray = (array, chunkSize) => {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  };

  const resetTempStates = () => {
    setTempEventId(null);
    setTempEventName(null);
    setTempEventVenu(null);
    setTempCity(null);
    setTempState(null);
    setTempCountry(null);
    setTempAddress(null);
    setTempGameRunner(null);
    setTempWebsite(null);
    setTempEmail(null);
    setTempPhone(null);
    setTempDescription(null);
    setTempStartDate(null);
    setTempEndDate(null);
    setTempYears(null);
    setTempImageUrl(null);
    // Add similar lines for other states
  };

  const handleAddEventModal = () => {
    if (editEventOpen) {
      toggleEditEventOpen(false);
    } else {
      setModalMode("add");
      toggleEditEventOpen(true);
    }
  };

  const handleEditEventModal = (
    id,
    name,
    image_url,
    event_years,
    event_venu,
    city,
    state,
    country,
    street_address,
    start_date,
    end_date,
    game_runner,
    phone,
    email,
    website,
    description,
    years,
  ) => {
    console.log(event_years);
    setTempEventId(id);
    setTempEventName(name);
    setTempImageUrl(image_url);
    setTempYears(event_years);
    setTempEventVenu(event_venu);
    setTempCity(city);
    setTempState(state);
    setTempCountry(country);
    setTempAddress(street_address);
    setTempGameRunner(game_runner);
    setTempWebsite(website);
    setTempEmail(email);
    setTempPhone(phone);
    setTempStartDate(start_date);
    setTempEndDate(end_date);
    setTempDescription(description);
    if (editEventOpen) {
      toggleEditEventOpen(false);
    } else {
      setModalMode("edit");
      toggleEditEventOpen(true);
    }
  };

  const EditEventModal = (props) => {
    const [selectedYear, setSelectedYear] = useState(null);
    const [editEventId, setEditEventId] = useState(null);
    const [editEventName, setEditEventName] = useState(null);
    const [editEventVenu, setEditEventVenu] = useState(null);

    const [editCity, setEditCity] = useState(null);
    const [editState, setEditState] = useState(null);
    const [editCOuntry, setEditCountry] = useState(null);

    const [editAddress, setEditAddress] = useState(null);
    const [editGameRunner, setEditGameRunner] = useState(null);
    const [editWebsite, setEditWebsite] = useState(null);
    const [editEmail, setEditEmail] = useState(null);
    const [editPhone, setEditPhone] = useState(null);
    const [editDescription, setEditDescription] = useState(null);
    const [editStartDate, setEditStartDate] = useState(null);
    const [editEndDate, setEditEndDate] = useState(null);

    const [editYears, setEditYears] = useState([]);
    const [deleteModalOpen, toggleDeleteModalOpen] = useState([]);

    const {
      editEvent,
      tempEventName,
      setTempEventName,
      tempImageUrl,
      setTempImageUrl,
      tempYears,
      setTempYears,
      tempEventVenu,
      setTempEventVenu,
      tempCity,
      setTempCity,
      tempState,
      setTempState,
      tempCOuntry,
      setTempCountry,
      tempAddress,
      setTempAddress,
      tempGameRunner,
      setTempGameRunner,
      tempWebsite,
      setTempWebsite,
      tempEmail,
      setTempEmail,
      tempDescription,
      setTempDescription,
      updateEventPic,
      handleSetNewEventPic,
      newEventPic,
      setNewEventPic,
    } = useContext(DataContext);

    useEffect(() => {
      if (props.editEventOpen === true) {
        if (modalMode === "edit") {
          setEditEventId(tempEventId);
          setEditEventName(tempEventName);
          setEditEventVenu(tempEventVenu);
          setEditCity(tempCity);
          setEditState(tempState);
          setEditCountry(tempCOuntry);
          setEditAddress(tempAddress);
          setEditGameRunner(tempGameRunner);
          setEditWebsite(tempWebsite);
          setEditEmail(tempEmail);
          setEditPhone(tempPhone);
          setEditDescription(tempDescription);
          setEditYears(tempYears);
        } else if (modalMode === "add") {
        }
      }

      // eslint-disable-next-line
    }, [props.editEventOpen]);
    useEffect(() => {
      console.log(editImage);
      // eslint-disable-next-line
    }, [editImage]);

    const shouldEditEvent =
      editEventId !== tempEventId ||
      editEventName !== tempEventName ||
      editEventVenu !== tempEventVenu ||
      editCity !== tempCity ||
      editState !== tempState ||
      editCOuntry !== tempCOuntry ||
      editAddress !== tempAddress ||
      editGameRunner !== tempGameRunner ||
      editWebsite !== tempWebsite ||
      editEmail !== tempEmail ||
      editPhone !== tempPhone ||
      editDescription !== tempDescription ||
      editYears !== tempYears;

    const handleEditEvent = () => {
      if (shouldEditEvent) {
        editEvent(
          editEventId,
          editEventName,
          editEventVenu,
          editCity,
          editState,
          editCOuntry,
          editAddress,
          editGameRunner,
          editWebsite,
          editEmail,
          editPhone,
          editDescription,
          editYears,
        );
      }
      if (newImage === true) {
        updateEventPic(editEventId, editImage);
      }
      setModalMode(null);
      toggleEditEventOpen(false);
      resetTempStates();
    };

    const handleAddEvent = () => {
      addEvent(
        editEventName,
        editEventVenu,
        editCity,
        editState,
        editCOuntry,
        editAddress,
        editGameRunner,
        editWebsite,
        editEmail,
        editPhone,
        editDescription,
        editYears,
        editImage,
      );
      setModalMode(null);
      toggleEditEventOpen(false);
      resetTempStates();
    };

    const handleSetEditEventName = (e) => {
      setEditEventName(e.target.value);
    };
    const handleSetEditEventVenu = (e) => {
      setEditEventVenu(e.target.value);
    };
    const handleSetEditAddress = (e) => {
      setEditAddress(e.target.value);
    };
    const handleSetEditCity = (e) => {
      setEditCity(e.target.value);
    };
    const handleSetEditState = (e) => {
      setEditState(e.target.value);
    };
    const handleSetEditCountry = (e) => {
      setEditCountry(e.target.value);
    };
    const handleSetEditWebsite = (e) => {
      setEditWebsite(e.target.value);
    };

    const handleSetEditEmail = (e) => {
      setEditEmail(e.target.value);
    };
    const handleSetEditDescription = (e) => {
      setEditDescription(e.target.value);
    };

    const handleSetEditStartDate = (e) => {
      setEditStartDate(e.target.value);
    };
    const handleSetEditEndDate = (e) => {
      setEditEndDate(e.target.value);
    };

    const handleSetSelectedYear = (e, year) => {
      e.preventDefault();
      setSelectedYear(year);
    };
    const handleSetNewYear = (e) => {
      e.preventDefault();
      newYear = e.target.value;
    };
    const handleSetEditGameRunner = (e) => {
      setEditGameRunner(e.target.value);
    };
    const handleSetEditPhone = (e) => {
      setEditPhone(e.target.value);
    };

    const handleDeleteYear = () => {
      const updatedYears = editYears.filter((year) => year !== selectedYear);

      // Update the state with the filtered array
      setEditYears(updatedYears);
    };
    const handleAddYear = () => {
      if (/^\d{4}$/.test(newYear) && !editYears.includes(newYear)) {
        setEditYears((prevYears) => [...prevYears, newYear].sort());
      }
    };

    const handleDeleteEventModalOpen = () => {
      if (deleteModalOpen) {
        toggleDeleteModalOpen(false);
      } else {
        toggleDeleteModalOpen(true);
      }
    };

    const handleDeleteEvent = () => {
      deleteEvent(editEventId);
      handleDeleteEventModalOpen();
      toggleEditEventOpen(false);
      resetTempStates();
    };

    const handleSetEventImage = (e) => {
      editImage = e.target.files;
      newImage = true;
      const selectedFile = e.target.files[0];
      console.log(e.target.files);

      const reader = new FileReader();
      reader.onload = (event) => {
        const fileString = event.target.result;
        setTempImageUrl(fileString);
      };
      reader.readAsDataURL(selectedFile);
    };

    return (
      <>
        {props.editEventOpen && props.editEventOpen ? (
          <>
            <ConfirmModal
              question="Are you sure you want to delete this Event?"
              modalState={deleteModalOpen}
              cancelAction={handleDeleteEventModalOpen}
              confirmAction={handleDeleteEvent}
            />
            <div
              style={{
                position: "fixed",
                top: "20vh",
                left: mobile ? "4vw" : "32vw",
                height: mobile ? "70vh" : "60%",
                width: mobile ? "92vw" : "40vw",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "left",
                marginTop: "1vh",
                backgroundColor: "ghostwhite",
                boxShadow: "2px 2px 9px #000000",
                zIndex: "999",
                // flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  height: "5vh",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#5E0516",
                  boxShadow: "2px 2px 9px #000000",
                }}
              >
                <h3 style={{ color: "#eed77a" }}>{`Edit CEF Event:`}</h3>
              </div>

              <div
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  paddingTop: "2vh",
                  paddingBottom: "2vh",
                  // backgroundColor: "grey",
                  overflowY: "scroll",
                }}
              >
                {tempImageUrl && tempImageUrl ? (
                  <img
                    style={{
                      width: "30%",
                      height: "auto",
                      padding: "1vh",
                    }}
                    src={tempImageUrl}
                    alt={`${tempEventName} logo`}
                  />
                ) : (
                  <div
                    style={{
                      height: "2vh",
                    }}
                  ></div>
                )}
                <div>
                  <input
                    type="file"
                    accept=".jpg, .png, .jpeg,"
                    style={{
                      marginLeft: ".5vw",
                      width: "15.25vw",
                      color: "black",
                    }}
                    onChange={(e) => handleSetEventImage(e)}
                    multiple={true}
                  />
                </div>

                <div
                  style={{
                    height: "auto",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "right",
                    marginTop: "2vh",
                    //backgroundColor: "blue",
                  }}
                >
                  <label
                    style={{
                      fontSize: "1rem",
                      color: "black",
                      fontWeight: 600,
                      marginRight: "2vw",
                      marginLeft: "2vw",
                    }}
                  >
                    Years:
                  </label>
                  <div
                    style={{
                      width: "21vw",
                      height: "auto",
                      marginRight: "5vw",
                      backgroundColor: "lightgrey",
                      paddingLeft: "1vw",
                      paddingTop: "1vh",
                      paddingRight: "1vh",
                    }}
                    // value={tempEventName}
                  >
                    {editYears && editYears !== null
                      ? chunkArray(editYears, 8).map((row, rowIndex) => (
                          <div
                            key={rowIndex}
                            style={{
                              display: "flex",
                              marginBottom: "10px",
                              width: "auto",
                              // backgroundColor:'maroon'
                            }}
                          >
                            {row.map((year) => (
                              <YearDiv
                                selectedYear={selectedYear}
                                year={year}
                                handleSetSelectedYear={handleSetSelectedYear}
                              />
                            ))}
                          </div>
                        ))
                      : null}

                    <div
                      style={{
                        width: "100%",
                        height: "2vh",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        // backgroundColor:'lightgreen'
                      }}
                    >
                      <input
                        value={newYear}
                        onChange={(e) => handleSetNewYear(e)}
                        placeholder="2007"
                        style={{
                          width: "3vw",
                          marginRight: ".5vw",
                        }}
                        type="text"
                      />
                      <button
                        style={{
                          width: "3vw",
                          marginRight: ".5vw",
                        }}
                        onClick={(e) => handleAddYear(e)}
                      >
                        Add
                      </button>
                      <button onClick={(e) => handleDeleteYear(e)}>
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    height: "3vh",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "right",
                    marginTop: "2vh",
                  }}
                >
                  <label
                    style={{
                      fontSize: "1rem",
                      color: "black",
                      fontWeight: 600,
                      marginRight: "2vw",
                      marginLeft: "2vw",
                    }}
                  >
                    Event Name:
                  </label>
                  <input
                    style={{
                      width: "22vw",
                      height: "2vh",
                      marginRight: "5vw",
                    }}
                    value={editEventName}
                    onChange={(e) => handleSetEditEventName(e)}
                    type="text"
                  />
                </div>

                <div
                  style={{
                    height: "3vh",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "right",

                    marginTop: "2vh",
                    //backgroundColor: "blue",
                  }}
                >
                  <label
                    style={{
                      fontSize: "1rem",
                      color: "black",
                      fontWeight: 600,
                      marginRight: "2vw",
                      marginLeft: "2vw",
                    }}
                  >
                    Event Venue:
                  </label>
                  <input
                    style={{
                      width: "22vw",
                      height: "2vh",
                      marginRight: "5vw",
                    }}
                    onChange={(e) => handleSetEditEventVenu(e)}
                    value={editEventVenu}
                    type="text"
                  />
                </div>
                <div
                  style={{
                    height: "3vh",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "right",

                    marginTop: "2vh",
                    //backgroundColor: "blue",
                  }}
                >
                  <label
                    style={{
                      fontSize: "1rem",
                      color: "black",
                      fontWeight: 600,
                      marginRight: "2vw",
                      marginLeft: "2vw",
                    }}
                  >
                    Game Runner:
                  </label>
                  <input
                    style={{
                      width: "22vw",
                      height: "2vh",
                      marginRight: "5vw",
                    }}
                    value={editGameRunner}
                    onChange={(e) => handleSetEditGameRunner(e)}
                    type="text"
                  />
                </div>
                <div
                  style={{
                    height: "3vh",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "right",

                    marginTop: "2vh",
                    //backgroundColor: "blue",
                  }}
                >
                  <label
                    style={{
                      fontSize: "1rem",
                      color: "black",
                      fontWeight: 600,
                      marginRight: "2vw",
                      marginLeft: "2vw",
                    }}
                  >
                    Address:
                  </label>
                  <input
                    style={{
                      width: "22vw",
                      height: "2vh",
                      marginRight: "5vw",
                    }}
                    onChange={(e) => handleSetEditAddress(e)}
                    value={editAddress}
                    type="text"
                  />
                </div>
                <div
                  style={{
                    height: "3vh",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "right",

                    marginTop: "2vh",
                    //backgroundColor: "blue",
                  }}
                >
                  <label
                    style={{
                      fontSize: "1rem",
                      color: "black",
                      fontWeight: 600,
                      marginRight: "2vw",
                      marginLeft: "2vw",
                    }}
                  >
                    City:
                  </label>
                  <input
                    style={{
                      width: "22vw",
                      height: "2vh",
                      marginRight: "5vw",
                    }}
                    value={editCity}
                    onChange={(e) => handleSetEditCity(e)}
                    type="text"
                  />
                </div>
                <div
                  style={{
                    height: "3vh",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "right",

                    marginTop: "2vh",
                    //backgroundColor: "blue",
                  }}
                >
                  <label
                    style={{
                      fontSize: "1rem",
                      color: "black",
                      fontWeight: 600,
                      marginRight: "2vw",
                      marginLeft: "2vw",
                    }}
                  >
                    State:
                  </label>
                  <input
                    style={{
                      width: "22vw",
                      height: "2vh",
                      marginRight: "5vw",
                    }}
                    value={editState}
                    placeholder="Abbreviated, i.e. CO"
                    onChange={(e) => handleSetEditState(e)}
                    type="text"
                  />
                </div>
                <div
                  style={{
                    height: "3vh",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "right",

                    marginTop: "2vh",
                    //backgroundColor: "blue",
                  }}
                >
                  <label
                    style={{
                      fontSize: "1rem",
                      color: "black",
                      fontWeight: 600,
                      marginRight: "2vw",
                      marginLeft: "2vw",
                    }}
                  >
                    Country:
                  </label>
                  <input
                    style={{
                      width: "22vw",
                      height: "2vh",
                      marginRight: "5vw",
                    }}
                    value={editCOuntry}
                    onChange={(e) => handleSetEditCountry(e)}
                    type="text"
                  />
                </div>

                <div
                  style={{
                    height: "3vh",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "right",

                    marginTop: "2vh",
                    //backgroundColor: "blue",
                  }}
                >
                  <label
                    style={{
                      fontSize: "1rem",
                      color: "black",
                      fontWeight: 600,
                      marginRight: "2vw",
                      marginLeft: "2vw",
                    }}
                  >
                    Phone:
                  </label>
                  <input
                    style={{
                      width: "22vw",
                      height: "2vh",
                      marginRight: "5vw",
                    }}
                    value={editPhone}
                    onChange={(e) => handleSetEditPhone(e)}
                    type="text"
                  />
                </div>

                <div
                  style={{
                    height: "3vh",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "right",

                    marginTop: "2vh",
                    //backgroundColor: "blue",
                  }}
                >
                  <label
                    style={{
                      fontSize: "1rem",
                      color: "black",
                      fontWeight: 600,
                      marginRight: "2vw",
                      marginLeft: "2vw",
                    }}
                  >
                    Website:
                  </label>
                  <input
                    style={{
                      width: "22vw",
                      height: "2vh",
                      marginRight: "5vw",
                    }}
                    value={editWebsite}
                    onChange={(e) => handleSetEditWebsite(e)}
                    type="text"
                  />
                </div>
                <div
                  style={{
                    height: "3vh",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "right",
                    marginTop: "2vh",
                    //backgroundColor: "blue",
                  }}
                >
                  <label
                    style={{
                      fontSize: "1rem",
                      color: "black",
                      fontWeight: 600,
                      marginRight: "2vw",
                      marginLeft: "2vw",
                    }}
                  >
                    Email:
                  </label>
                  <input
                    style={{
                      width: "22vw",
                      height: "2vh",
                      marginRight: "5vw",
                    }}
                    value={editEmail}
                    onChange={(e) => handleSetEditEmail(e)}
                    type="text"
                  />
                </div>
                {/* <div
                  style={{
                    height: "3vh",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "right",
                    marginTop: "2vh",
                    //backgroundColor: "blue",
                  }}
                >
                  <label
                    style={{
                      fontSize: "1rem",
                      color: "black",
                      fontWeight: 600,
                      marginRight: "2vw",
                      marginLeft: "2vw",
                    }}
                  >
                    Dates:
                  </label>
                  <input
                    style={{
                      width: "10vw",
                      height: "2vh",
                      marginRight: "2vw",
                    }}
                    value={editStartDate}
                    onChange={(e) => handleSetEditStartDate(e)}
                    select="multi"
                    type="date"
                  />
                  <input
                    style={{
                      width: "10vw",
                      height: "2vh",
                      marginRight: "5vw",
                    }}
                    value={editEndDate}
                    onChange={(e) => handleSetEditEndDate(e)}
                    select="multi"
                    type="date"
                  />
                </div> */}

                <div
                  style={{
                    height: "auto",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "right",
                    marginTop: "2vh",
                    //backgroundColor: "blue",
                  }}
                >
                  <label
                    style={{
                      fontSize: "1rem",
                      color: "black",
                      fontWeight: 600,
                      marginRight: "2vw",
                      marginLeft: "2vw",
                    }}
                  >
                    Description:
                  </label>
                  <textarea
                    style={{
                      width: "22.25vw",
                      height: "10vh",
                      marginRight: "5vw",
                    }}
                    value={editDescription}
                    onChange={(e) => handleSetEditDescription(e)}
                  />
                </div>
              </div>
              <div
                style={{
                  height: "10%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <button
                  style={{ marginRight: "2vw" }}
                  onClick={(e) => props.handleEditEventModal(e)}
                >
                  Cancel
                </button>
                <button
                  style={{ marginRight: "2vw" }}
                  onClick={(e) => handleDeleteEventModalOpen(e)}
                >
                  Delete
                </button>
                <button
                  onClick={(e) =>
                    modalMode === "add" ? handleAddEvent(e) : handleEditEvent(e)
                  }
                >
                  Confirm
                </button>
              </div>
            </div>
          </>
        ) : null}
      </>
    );
  };

  const YearDiv = (props) => {
    return (
      <div
        key={props.year}
        style={{
          marginRight: "10px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor:
            props.selectedYear === props.year ? "lightsteelblue" : null,
        }}
        onClick={(e) => props.handleSetSelectedYear(e, props.year)}
      >
        <label
          style={{
            // color: "#eed77a",
            fontSize: "1remm",
            fontWeight: 700,
          }}
        >
          {props.year}
        </label>
      </div>
    );
  };

  return (
    <>
      <EditEventModal
        editEventOpen={editEventOpen}
        handleEditEventModal={handleEditEventModal}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          backgroundColor: "#adc8e6",
          minHeight: mobile ? "100vh" : "",
        }}
      >
        <Header />
        <div
          style={{
            height: "88vh",
            width: "100%",
            display: "flex",
            flexDirection: mobile ? "column" : "row",
            alignItems: "center",
            flexGrow: mobile ? 1 : 0,
          }}
        >
          {user && user !== null ? (
            <>
              <UserSidebar />
            </>
          ) : null}
          <div
            style={{
              height: mobile ? "78vh" : "88vh",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              // alignItems: "center",
            }}
          >
            {user && user.role === "admin" ? (
              <>
                <button
                  style={{
                    position: "fixed",
                    height: "2vh",
                    width: "7vw",
                    left: "10vw",
                    top: "8vh",
                    // bottom:'19vh'
                  }}
                  onClick={(e) => handleAddEventModal(e)}
                >
                  Add Past Event
                </button>
              </>
            ) : null}
            <div
              style={{
                height: "100%",
                width: "97%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                // justifyContent:'top',
                // backgroundColor:'ghostwhite',
                msrginRight: "1vw",
                marginLeft: "1vw",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: mobile ? "75vh" : "100%",
                  display: "flex",
                  flexDirection: "column",
                  overflowY: "auto",
                  // scroll
                  // backgroundColor:'#adc8e6',
                  // boxShadow: "1px 1px 6px 2px black",
                  paddingLeft: "1vw",
                  paddingRight: "1vw",
                  paddingBottom: "3vh",

                  paddingTop: mobile ? "" : "3vh",
                  alignItems: "center",
                }}
              >
                {pastEvents && pastEvents.length > 0
                  ? pastEvents.slice().map((row, index) => {
                      const {
                        id,
                        name,
                        image_url,
                        event_years,
                        event_venu,
                        city,
                        state,
                        country,
                        street_address,
                        start_date,
                        end_date,
                        game_runner,
                        phone,
                        email,
                        website,
                        description,
                      } = row;

                      return (
                        <>
                          <div
                            key={index}
                            style={{
                              height: "Auto",
                              width: mobile ? "70vw" : "30%",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              marginTop: "1vh",

                              backgroundColor: "#5E0516",
                              boxShadow: "1px 1px 6px 2px black",
                              border: "solid",
                              borderWidth: "4px",
                              borderColor: "#eed77a",
                              paddingBottom: "2vh",
                            }}
                          >
                            {user && user.role === "admin" && !mobile ? (
                              <>
                                <button
                                  style={{
                                    position: "relative",
                                    left: "11.5vw",
                                    top: "1.5vh",
                                    // bottom:'19vh'
                                  }}
                                  onClick={(e) =>
                                    handleEditEventModal(
                                      id,
                                      name,
                                      image_url,
                                      event_years,
                                      event_venu,
                                      city,
                                      state,
                                      country,
                                      street_address,
                                      start_date,
                                      end_date,
                                      game_runner,
                                      phone,
                                      email,
                                      website,
                                      description,
                                    )
                                  }
                                >
                                  Edit
                                </button>
                              </>
                            ) : null}
                            <label
                              style={{
                                color: "#eed77a",
                                fontSize: "1.5rem",
                                fontWeight: 700,
                                // marginTop: "1vh",
                              }}
                            >
                              {name}
                            </label>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {image_url && image_url ? (
                                  <img
                                    style={{
                                      width: "40%",
                                      height: "auto",
                                      padding: "1vh",
                                    }}
                                    src={image_url}
                                    alt={name} // Add alt text for accessibility
                                  />
                                ) : (
                                  <div
                                    style={{
                                      height: "2vh",
                                    }}
                                  ></div>
                                )}

                                <div>
                                  {chunkArray(event_years, 4).map(
                                    (row, rowIndex) => (
                                      <div
                                        key={rowIndex}
                                        style={{
                                          display: "flex",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        {row.map((year) => (
                                          <div
                                            key={year}
                                            style={{
                                              marginRight: "10px",
                                              display: "flex",
                                              flexDirection: "row",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <label
                                              style={{
                                                color: "#eed77a",
                                                fontSize: "1remm",
                                                fontWeight: 700,
                                              }}
                                            >
                                              {year}
                                            </label>
                                          </div>
                                        ))}
                                      </div>
                                    ),
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
        </div>
        <Footer />
        {/* {!redirect ? null : <Navigate push to="/login" />} */}
      </div>
    </>
  );
};
