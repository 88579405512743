import React from "react";

export const ShopText = () => {
  return (
    <>
      <h1 style={{ paddingTop: "8vh", textAlign: "center", color: "black" }}>
        Shop
      </h1>
      <p
        style={{
          fontSize: "1em",
          marginLeft: "4vw",
          marginRight: "4vw",
          marginBottom: "8vh",
          padding: "auto",
          paddingBottom: "10vh",
          textAlign: "left",
          color: "black",
        }}
      >
        Loren is some or whatver
      </p>
    </>
  );
};
