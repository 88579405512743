import React, { useContext } from "react";
import { DataContext } from "../../common/DataContext/index";
import { AuthContext } from "../../common/AuthContext";
import { Navigate } from "react-router-dom";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { UserSidebar } from "../../components/common";

export const Memorials = () => {
  const { mobile } = useContext(DataContext);
  const { user, redirect } = useContext(AuthContext);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          backgroundColor: "#adc8e6",
          minHeight: mobile ? "100vh" : "",
        }}
      >
        <Header />
        <div
          style={{
            height: "88vh",
            width: "100%",
            display: "flex",
            flexDirection: mobile ? "column" : "row",
            alignItems: "center",
            flexGrow: mobile ? 1 : 0,
          }}
        >
          {user && user !== null ? (
            <>
              <UserSidebar />
            </>
          ) : null}

          <div
            style={{
              height: "88vh",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              flexGrow: mobile ? 1 : 0,
              overflowY: "scroll",
              paddingTop: "4vh",
            }}
          >
            <div
              style={{
                height: mobile ? "15vh" : "20vh",
                width: mobile ? "90vw" : "50vw",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "left",
                marginTop: "1vh",
                backgroundColor: "#5E0516",
                boxShadow: "1px 1px 6px 2px black",
                border: "solid",
                borderWidth: "4px",
                borderColor: "#eed77a",
              }}
            >
              <img
                style={{
                  width: mobile ? "auto" : "auto",
                  height: mobile ? "12vh" : "15vh",
                  boxShadow: "2px 2px 9px #000000",
                  border: "solid",
                  borderWidth: "4px",
                  borderColor: "#eed77a",
                  marginLeft: "1vw",
                  marginRight: "1vw",
                }}
                src={
                  "https://printsmiths.nyc3.cdn.digitaloceanspaces.com/CEF/memorials/russ_1.png"
                }
              />

              <div
                style={{
                  height: mobile ? "15vh" : "20vh",
                  width: mobile ? "90vw" : "50vw",

                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "left",

                  // backgroundColor:'blue',
                }}
              >
                <label
                  style={{
                    fontSize: "4rem",
                    color: "#eed77a",
                    fontWeight: 600,
                  }}
                >
                  {`Russ "Jester" Storing`}
                </label>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        {/* {!redirect ? null : <Navigate push to="/login" />} */}
      </div>
    </>
  );
};
