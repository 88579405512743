import React, { useContext, useEffect } from "react";
import { DataContext } from "../../common/DataContext/index";
import { AuthContext } from "../../common/AuthContext";
import "./styles.css";
import { Navigate } from "react-router-dom";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { poster } from "../../calls/calls";
import { UserSidebar } from "../../components/common";
import { HistoryText } from "./HistoryText";

export const History = () => {
  const { user, redirect } = useContext(AuthContext);

  const { mobile, fetchHistory } = useContext(DataContext);

  useEffect(() => {
    fetchHistory();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          backgroundColor: "#adc8e6",
          minHeight: mobile ? "100vh" : "",
        }}
      >
        <Header />
        <div
          style={{
            height: "88vh",
            width: "100%",
            display: "flex",
            flexDirection: mobile ? "column" : "row",
            alignItems: "center",
            flexGrow: mobile ? 1 : 0,
          }}
        >
          {user && user !== null ? (
            <>
              <UserSidebar />
            </>
          ) : null}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: mobile ? "60vh" : "100%",
              alignItems: "center",
            }}
          >
            <HistoryText />
          </div>
        </div>
        <Footer />
        {/* {!redirect ? null : <Navigate push to="/login" />} */}
      </div>
    </>
  );
};
