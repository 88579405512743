// import { API_URL } from "./components/constants";
// import Cookie from "js-cookie";
var obj;

//--------------poster----------
export async function poster(jsonData, route) {
  const response = await fetch(route, {
    method: "POST",
    body: JSON.stringify(jsonData),
    headers: {
      "Content-Type": "application/json",
      mode: "cors",
    },
  });
  if (response.ok) {
    obj = await response.json();
    obj.ok = true;
  } else if (!response.ok) {
    obj = { response: "error", ok: false };
  }
  return obj;
}

export async function formPoster(route, info = null, jsonData = null) {
  let requestBody;
  if (info && jsonData) {
    info.append("json_data", JSON.stringify(jsonData));
    requestBody = info;
  } else if (info === null && jsonData !== null) {
    requestBody = jsonData;
  } else {
    requestBody = info;
  }
  const response = await fetch(route, {
    method: "POST",
    body: requestBody,
    headers: {
      mode: "cors",
    },
  });

  if (response.ok) {
    return await response.json();
  } else {
    return { response: "error" };
  }
}

//--------------fetcher---------
export async function fetcher(route) {
  const response = await fetch(route, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      mode: "cors",
    },
  });
  if (response.ok) {
    obj = await response.json();
  } else if (!response.ok) {
    obj = { response: "Error" };
  }
  return obj;
}

// "X-CSRF-TOKEN": `${Cookie.get("csrf_access_token")}`,

// "X-CSRF-TOKEN": `${Cookie.get("csrf_access_token")}`,
