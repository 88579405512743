import React, { useContext, useEffect, useState, useRef } from "react";
import { DataContext } from "../../common/DataContext/index";
import { AuthContext } from "../../common/AuthContext";
import { Navigate } from "react-router-dom";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { UserSidebar, ConfirmModal } from "../../components/common";
import { Tooltip } from "@mui/material";
import logo from "../../static/NavIcon.png";
import expand_icon from "../../static/expand_icon.png";
import collapse_icon from "../../static/collapse_icon.png";
import { Register_page_2 } from "../Register/register_components";
var newImage = false;
var editImage;
export const EventProfile = () => {
  const {
    mobile,
    tempEventVenu,
    tempEventName,
    tempImageUrl,
    tempYears,
    tempCity,
    tempState,
    tempCOuntry,
    tempAddress,
    tempGameRunner,
    tempWebsite,
    tempEmail,
    tempDescription,
    tempStartDate,
    tempEndDate,
    tempEventId,
    tempEventFactions,
    tempPhone,
    fetchEventDetails,
    addNewFaction,
    updateFaction,
    fetchEventFactions,
    deleteFaction,
    selectedYearId,
    selectedYear,
    setSelectedYearId,
    fetchEventYearDetails,
    eventGenerals,
    setEventGenerals,
    attendingPlayers,
    setAttendingPlayers,
    addEventGeneral,
    addEventDates,
    tempEventScenario,
    updateGeneralPic,
    newGeneralId,
    eventScore,
    setEventScore,
    addEventScore,
    setTempStartDate,
    setTempEndDate,
    updateFactionPic,
    updateScenarioText,
    addEventUnit,
    tempUnits,
    setUnitYear,
    fetchMembers,
    // members,
    tempUnitId,
    updateUnitCommand,
    fetchEventUnitORBAT,
    tempUnitCOname,
    tempUnitXOname,
  } = useContext(DataContext);
  const inputRefs = useRef([]);

  const initialIndexes = {};
  const { user, redirect } = useContext(AuthContext);
  const [addFactionOpen, toggleAddFactionOpen] = useState(false);
  const [editGeneralOpen, toggleEditGeneralOpen] = useState(false);
  const [editScoreOpen, toggleEditScoreOpen] = useState(false);
  const [editDatesOpen, toggleEditDatesOpen] = useState(false);
  const [editUnitsOpen, toggleEditUnitsOpen] = useState(false);
  const [editScenarioOpen, toggleEditScenarioOpen] = useState(false);
  const [locationOpen, toggleLocationOpen] = useState(true);
  const [scenarioOpen, toggleScenarioOpen] = useState(true);
  const [factionsOpen, toggleFactionsOpen] = useState(true);
  const [unitsOpen, toggleUnitsOpen] = useState(true);
  const [unitYearOpen, toggleUnitYearOpen] = useState(false);
  const [unitORBATOpen, toggleUnitORBATOpen] = useState(false);

  const [selectedFaction, setSelectedFaction] = useState(null);
  const [selectedFactionId, setSelectedFactionId] = useState(null);

  const [generalName, setGeneralName] = useState(null);

  const [generalId, setGeneralId] = useState(null);

  const [factionText, setFactionText] = useState(null);
  const [factions, setFactions] = useState(null);
  const [selectedScore, setSelectedScore] = useState(null);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [currentIndexes, setCurrentIndexes] = useState(initialIndexes);
  const [unitImage, setUnitImage] = useState(null);
  const [unitDesc, setUnitDesc] = useState(null);
  const [unitName, setUnitName] = useState(null);
  const [unitFactionName, setUnitFactionName] = useState(null);

  useEffect(() => {
    let startdate = new Date(tempStartDate);
    startdate.setMinutes(
      startdate.getMinutes() + startdate.getTimezoneOffset(),
    );

    setStartDate(startdate.toLocaleDateString());
  }, [tempStartDate]);

  useEffect(() => {
    if (!tempUnitCOname && !tempUnitXOname) {
      if (selectedYearId !== null) {
        fetchEventUnitORBAT(selectedYearId);
      }
    }
  }, [selectedYearId]);

  useEffect(() => {
    if (tempUnits !== null) {
      Object.values(tempUnits).forEach((units) => {
        const foundUnit = units.find((unit) => unit.unit_id === tempUnitId);
        if (foundUnit) {
          console.log(foundUnit.unit_image);
          setUnitImage(foundUnit.unit_image);
          setUnitDesc(foundUnit.unit_description);
          setUnitName(foundUnit.unit_name);
          const foundFaction = tempEventFactions.find(
            ([factionId]) => factionId === foundUnit.faction_id,
          );
          if (foundFaction) {
            setUnitFactionName(foundFaction[1]);
          }
        }
      });
    }
  }, [tempUnits, tempUnitId]);

  useEffect(() => {
    let enddate = new Date(tempEndDate);
    enddate.setMinutes(enddate.getMinutes() + enddate.getTimezoneOffset());
    setEndDate(enddate.toLocaleDateString());
  }, [tempEndDate]);

  useEffect(() => {
    setFactionText(
      tempEventFactions &&
        tempEventFactions.map(([id, name]) => name).join(" vs "),
    );
    if (tempEventFactions && tempEventFactions.length > 0) {
      tempEventFactions.forEach((faction) => {
        initialIndexes[faction[0]] = 0;
      });
      console.log(initialIndexes);
      setCurrentIndexes(initialIndexes);
    }

    // eslint-disable-next-line
  }, [tempEventFactions]);

  useEffect(() => {
    fetchEventDetails(2);
    setSelectedYearId(30);
    fetchEventYearDetails(30);
  }, []);

  useEffect(() => {
    setFactions(null);
    setFactions(tempEventFactions);
    // eslint-disable-next-line
  }, [tempEventFactions]);

  const handleToggleUnitORBATOpen = () => {
    if (unitORBATOpen) {
      toggleUnitORBATOpen(false);
    } else {
      toggleUnitORBATOpen(true);
    }
  };
  const handleToggleAddFactionOpen = () => {
    if (addFactionOpen) {
      toggleAddFactionOpen(false);
    } else {
      toggleAddFactionOpen(true);
    }
  };

  const handleToggleUnitYearOpen = (e) => {
    if (unitYearOpen) {
      toggleUnitYearOpen(false);
    } else {
      toggleUnitYearOpen(true);
    }
  };

  const handleToggleEditDatesOpen = (e) => {
    if (editDatesOpen) {
      toggleEditDatesOpen(false);
    } else {
      toggleEditDatesOpen(true);
    }
  };

  const handleToggleEditUnitsOpen = (e) => {
    if (editUnitsOpen) {
      toggleEditUnitsOpen(false);
    } else {
      toggleEditUnitsOpen(true);
    }
  };

  const handleToggleEditSceanrioOpen = (e) => {
    if (editScenarioOpen) {
      toggleEditScenarioOpen(false);
    } else {
      toggleEditScenarioOpen(true);
    }
  };

  const handleToggleLocationOpen = (e) => {
    if (locationOpen) {
      toggleLocationOpen(false);
    } else {
      toggleLocationOpen(true);
    }
  };

  const handleToggleScenarioOpen = (e) => {
    if (scenarioOpen) {
      toggleScenarioOpen(false);
    } else {
      toggleScenarioOpen(true);
    }
  };

  const handleToggleFactionsOpen = (e) => {
    if (factionsOpen) {
      toggleFactionsOpen(false);
    } else {
      toggleFactionsOpen(true);
    }
  };

  const handleToggleUnitsOpen = (e) => {
    if (unitsOpen) {
      toggleUnitsOpen(false);
    } else {
      toggleUnitsOpen(true);
    }
  };

  const handleToggleEditScoreOpen = (e, faction, factionId, score) => {
    setSelectedFaction(faction);
    setSelectedFactionId(factionId);
    setSelectedScore(score);
    if (editScoreOpen) {
      toggleEditScoreOpen(false);
    } else {
      toggleEditScoreOpen(true);
    }
  };

  const handleToggleEditGeneralOpen = (
    e,
    factionId,
    faction,
    generalId,
    generalName,
  ) => {
    if (editGeneralOpen) {
      toggleEditGeneralOpen(false);
    } else {
      toggleEditGeneralOpen(true);
      console.log(factionId, faction, generalId, generalName);
      setSelectedFactionId(factionId);
      setSelectedFaction(faction);
      setGeneralName(generalName);
      setGeneralId(generalId);
    }
  };

  const handleSetSelectedYear = (e) => {
    setAttendingPlayers(null);
    setSelectedYearId(e.target.value);
    fetchEventYearDetails(e.target.value);
  };

  const handlePrevUnit = (factionName) => {
    setCurrentIndexes((prevIndexes) => ({
      ...prevIndexes,
      [factionName]:
        prevIndexes[factionName] === 0
          ? tempUnits[factionName].length
          : prevIndexes[factionName] - 1,
    }));
  };

  const handleNextUnit = (factionName) => {
    setCurrentIndexes((prevIndexes) => ({
      ...prevIndexes,
      [factionName]:
        prevIndexes[factionName] === tempUnits[factionName].length - 1
          ? 0
          : prevIndexes[factionName] + 1,
    }));
  };

  const EditUnitYearModal = (props) => {
    const [selectedUnit, setSelectedUnit] = useState(null);

    const handleSetSelectedUnit = (e) => {
      setSelectedUnit(e.target.value);
    };

    const handleSetUnitYear = () => {
      setUnitYear(selectedUnit, selectedYear);
      handleToggleUnitYearOpen();
    };

    return (
      <>
        {unitYearOpen ? (
          <>
            <div
              style={{
                position: "fixed",
                top: "20vh",
                left: mobile ? "4vw" : "32vw",
                height: mobile ? "70vh" : "auto",
                width: mobile ? "92vw" : "40vw",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "1vh",
                backgroundColor: "ghostwhite",
                boxShadow: "2px 2px 9px #000000",
                zIndex: "999",
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  height: "5vh",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#5E0516",
                  boxShadow: "2px 2px 9px #000000",
                  marginBottom: "1vh",
                }}
              >
                <h3
                  style={{ color: "#eed77a" }}
                >{`Edit CEF unit for ${tempEventName} ${selectedYear}:`}</h3>
              </div>

              <div
                style={{
                  height: "auto",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  // backgroundColor:'lightblue',
                  marginBottom: "1vh",
                }}
              >
                {tempEventFactions &&
                  tempEventFactions.map(([factionId, factionName]) => (
                    <div
                      key={factionId}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h2>{factionName}</h2>
                      <select
                        style={{
                          width: "30vw",
                          marginBottom: "1vh",
                        }}
                        onChange={(e) => handleSetSelectedUnit(e)}
                      >
                        <option>{""}</option>
                        {tempUnits &&
                          tempUnits[factionId].map((unit) => (
                            <option key={unit.unit_id} value={unit.unit_id}>
                              {unit.unit_name}
                            </option>
                          ))}
                      </select>
                    </div>
                  ))}

                <div
                  style={{
                    height: "auto",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "1vh",
                    marginTop: "1vh",
                    // backgroundColor:'lightgreen'
                  }}
                >
                  <button
                    style={{ width: "3vw" }}
                    onClick={(e) => handleToggleUnitYearOpen(e)}
                  >
                    Cancel
                  </button>
                  <span style={{ width: "2vw" }} />
                  <button
                    style={{ width: "3vw" }}
                    onClick={(e) => handleSetUnitYear(e)}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </>
    );
  };

  //ORBAT MODAL
  const EditORBATModal = (props) => {
    const [tempCOname, setTempCOname] = useState(null);
    const [tempXOname, setTempXOname] = useState(null);
    const [tempCOid, setTempCOid] = useState(null);
    const [tempXOid, setTempXOid] = useState(null);
    const [squads, setSquads] = useState([]);
    const [tempMemberName, setTempMemberName] = useState(null);
    const [selectMode, setSelectMode] = useState(null);
    const [selectModeXO, setSelectModeXO] = useState(true);
    const [selectModeCO, setSelectModeCO] = useState(true);
    useEffect(() => {
      console.log(squads);
    }, [squads]);

    // useEffect(() => {
    //   if (unitORBATOpen === true && members === null) {
    //     fetchMembers();
    //   }
    // }, [unitORBATOpen]);

    // useEffect(() => {
    //   console.log(squ)
    // }, [squads]);

    const handleSetSelectMode = (e) => {
      console.log(e);
      setSelectMode(e);
    };

    const handleSetSelectedXO = (e) => {
      let value = e.target.value.split(",");
      setTempXOid(value[0]);
      setTempXOname(value[1] + "," + value[2]);
    };
    const handleSetSelectedCO = (e) => {
      let value = e.target.value.split(",");
      setTempCOid(value[0]);
      setTempCOname(value[1] + "," + value[2]);
    };

    const handleSetCOname = (e) => {
      setTempCOid(null);
      setTempCOname(e.target.value);
    };

    const handleUpdateCommand = (role) => {
      if (role === "CO") {
        updateUnitCommand(role, tempCOname, tempCOid, selectedYearId);
      } else if (role === "XO") {
        updateUnitCommand(role, tempXOname, tempXOid, selectedYearId);
      }
    };

    const handleSetXOname = (e) => {
      setTempXOid(null);
      setTempXOname(e.target.value);
    };

    const handleAddUnit = (e) => {
      let new_team = { name: "New Unit", members: [] };
      try {
        setSquads((prevValues) => [...prevValues, new_team]);
      } catch {
        return;
      }
    };

    const handleChangeSquadName = (e, index) => {
      const updatedSquads = [...squads];
      updatedSquads[index] = { ...updatedSquads[index], name: e.target.value };
      setSquads(updatedSquads);
    };

    const handleChangeSquadRole = (e, index) => {
      const updatedSquads = [...squads];
      const squadToUpdate = { ...updatedSquads[index] };
      squadToUpdate.role = e.target.value;

      updatedSquads[index] = squadToUpdate;
      setSquads(updatedSquads);
    };

    const handleChangeSquadAOR = (e, index) => {
      const updatedSquads = [...squads];
      const squadToUpdate = { ...updatedSquads[index] };
      squadToUpdate.aor = e.target.value;
      updatedSquads[index] = squadToUpdate;
      setSquads(updatedSquads);
    };

    const handleChangeSquadCO = (e, index) => {
      if (selectMode === false) {
        const updatedSquads = [...squads];
        const squadToUpdate = { ...updatedSquads[index] };
        squadToUpdate.co = e.target.value;
        updatedSquads[index] = squadToUpdate;
        setSquads(updatedSquads);
      }
      if (selectMode === true) {
        // const updatedSquads = [...squads];
        // const squadToUpdate = { ...updatedSquads[index] };
        // squadToUpdate.co = e.target.value;
        // updatedSquads[index] = squadToUpdate;
        // setSquads(updatedSquads);
      }
    };

    const handleDeleteTempUnit = (e, indexToRemove) => {
      setSquads((prevItems) => {
        return prevItems.filter((item, index) => index !== indexToRemove);
      });
    };

    const handleSetTempMemberName = (e, id) => {
      console.log(e.target.value);
      setTempMemberName(e.target.value);
    };

    const handleAddTempMembers = (e, index) => {
      const updatedSquads = [...squads];
      console.log(index);
      const squadToUpdate = { ...updatedSquads[index] };
      console.log(squadToUpdate);
      squadToUpdate.members && squadToUpdate.members.push(tempMemberName);
      console.log(squadToUpdate.members);
      updatedSquads[index] = squadToUpdate;
      setSquads(updatedSquads);
    };
    return (
      <>
        {unitORBATOpen ? (
          <>
            <div
              style={{
                position: "fixed",
                top: "9vh",
                left: mobile ? "4vw" : "32vw",
                height: mobile ? "70vh" : "auto",
                width: mobile ? "92vw" : "40vw",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "1vh",
                backgroundColor: "ghostwhite",
                boxShadow: "2px 2px 9px #000000",
                zIndex: "999",
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  height: "5vh",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#5E0516",
                  boxShadow: "2px 2px 9px #000000",
                  marginBottom: "1vh",
                }}
              >
                <h3 style={{ color: "#eed77a" }}>{`Edit ORBAT:`}</h3>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  // justifyContent:'center',
                  // backgroundColor:'red',
                  // overflowY:'scroll',
                  height: "70vh",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "white",
                    boxShadow: "2px 2px 9px #000000",
                    padding: "1vw",
                    marginBottom: "1vh",
                    marginTop: "1vh",
                    height: "10vh",
                    width: "20vw",
                    // overflowY: "scroll",
                  }}
                >
                  <label
                    style={{
                      fontSize: "1rem",
                      color: "black",
                      fontWeight: 600,
                      marginBottom: ".5vw",
                    }}
                  >
                    Edit Commanding Officer:
                  </label>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: "1vh",
                    }}
                  >
                    <>
                      <label
                        style={{
                          fontSize: "1rem",
                          color: "black",
                          fontWeight: 600,
                        }}
                      >
                        Select:
                      </label>
                      <input
                        checked={selectModeCO}
                        type="radio"
                        onClick={(e) => setSelectModeCO(true)}
                      ></input>

                      <label
                        style={{
                          fontSize: "1rem",
                          color: "black",
                          fontWeight: 600,
                          marginLeft: "1vw",
                        }}
                      >
                        Write in:
                      </label>
                      <input
                        checked={!selectModeCO}
                        type="radio"
                        onClick={(e) => setSelectModeCO(false)}
                      ></input>
                    </>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {selectModeCO === true ? (
                      <>
                        <select
                          type="text"
                          style={{
                            width: mobile ? "47vw" : "15.5vw",
                            height: "2.5vh",
                            marginRight: mobile ? "4vw" : "",
                            marginBottom: "1vh",
                          }}
                          onChange={(e) => handleSetSelectedCO(e)}
                        >
                          <option value={null} selected={tempXOid === null}>
                            Not Listed
                          </option>
                          {attendingPlayers &&
                            attendingPlayers.slice().map((row) => {
                              const {
                                id,
                                callsign,
                                name,
                                rank,
                                section,
                                state,
                                unit,
                                country,
                                city,
                                company,
                                profilePicUrl,
                              } = row;
                              return (
                                <>
                                  <option value={[id, name]}>{name}</option>
                                </>
                              );
                            })}
                        </select>
                      </>
                    ) : selectModeCO === false ? (
                      <>
                        <input
                          type="text"
                          style={{
                            width: mobile ? "47vw" : "15.5vw",
                            height: "2.5vh",
                            marginRight: mobile ? "4vw" : "",
                            marginBottom: "1vh",
                          }}
                          value={tempCOname && tempCOname ? tempCOname : null}
                          onChange={(e) => handleSetCOname(e)}
                        ></input>
                      </>
                    ) : null}
                  </div>

                  <button onClick={(e) => handleUpdateCommand("CO")}>
                    Update
                  </button>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "white",
                    boxShadow: "2px 2px 9px #000000",
                    padding: "1vw",
                    marginBottom: "1vh",
                    marginTop: "1vh",
                    height: "10vh",
                    width: "20vw",
                  }}
                >
                  <label
                    style={{
                      fontSize: "1rem",
                      color: "black",
                      fontWeight: 600,
                      marginBottom: ".5vw",
                    }}
                  >
                    Edit Executive Officer:
                  </label>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: "1vh",
                    }}
                  >
                    <>
                      <label
                        style={{
                          fontSize: "1rem",
                          color: "black",
                          fontWeight: 600,
                        }}
                      >
                        Select:
                      </label>
                      <input
                        checked={selectModeXO}
                        type="radio"
                        onClick={(e) => setSelectModeXO(true)}
                      ></input>

                      <label
                        style={{
                          fontSize: "1rem",
                          color: "black",
                          fontWeight: 600,
                          marginLeft: "1vw",
                        }}
                      >
                        Write in:
                      </label>
                      <input
                        checked={!selectModeXO}
                        type="radio"
                        onClick={(e) => setSelectModeXO(false)}
                      ></input>
                    </>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {selectModeXO === true ? (
                      <>
                        <select
                          type="text"
                          style={{
                            width: mobile ? "47vw" : "15.5vw",
                            height: "2.5vh",
                            marginRight: mobile ? "4vw" : "",
                            marginBottom: "1vh",
                          }}
                          onChange={(e) => handleSetSelectedXO(e)}
                        >
                          <option value={null} selected={tempXOid === null}>
                            Not Listed
                          </option>
                          {attendingPlayers &&
                            attendingPlayers.slice().map((row) => {
                              const {
                                id,
                                callsign,
                                name,
                                rank,
                                section,
                                state,
                                unit,
                                country,
                                city,
                                company,
                                profilePicUrl,
                              } = row;
                              return (
                                <>
                                  <option value={[id, name]}>{name}</option>
                                </>
                              );
                            })}
                        </select>
                      </>
                    ) : selectModeXO === false ? (
                      <>
                        <input
                          type="text"
                          style={{
                            width: mobile ? "47vw" : "15.5vw",
                            height: "2.5vh",
                            marginRight: mobile ? "4vw" : "",
                            marginBottom: "1vh",
                          }}
                          value={tempXOname && tempXOname ? tempXOname : null}
                          onChange={(e) => handleSetXOname(e)}
                        ></input>
                      </>
                    ) : null}
                  </div>

                  <button onClick={(e) => handleUpdateCommand("XO")}>
                    Update
                  </button>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "lightgreen",

                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "lightyellow",
                    }}
                  >
                    <label
                      style={{
                        fontSize: "1rem",
                        color: "black",
                        fontWeight: 600,
                        marginRight: ".5vw",
                      }}
                    >
                      Edit Units:
                    </label>

                    <button onClick={(e) => handleAddUnit(e)}>Add Unit</button>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "40vw",
                    }}
                  >
                    {squads && squads.length > 0 ? (
                      <div
                        style={{
                          width: "40vw",
                          height: "auto",
                          backgroundColor: "white",
                          display: "flex",
                          flexDirection: "row",
                          // alignItems: "center",
                          // justifyContent: "center",
                          overflowY: "scroll",
                          padding: "1vw",
                        }}
                      >
                        {squads.slice().map((row, index) => {
                          const { name, role, aor, co, members } = row;

                          return (
                            <div
                              style={{
                                width: "auto",
                                height: "auto",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                // justifyContent:'center',
                                boxShadow: "2px 2px 9px #000000",
                                marginRight: "1vw",
                                // paddingLeft: "1vw",
                                // paddingRight: "1vw",
                                paddingBottom: "1vw",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "right",
                                  width: "90%",
                                  // backgroundColor:'grey',
                                  height: "2vh",
                                  // paddingRight:'.5vw'
                                }}
                              >
                                <label
                                  onClick={(e) =>
                                    handleDeleteTempUnit(e, index)
                                  }
                                  style={{
                                    fontSize: "1rem",
                                    color: "black",
                                    fontWeight: 600,
                                  }}
                                >
                                  <strong>X</strong>
                                </label>
                              </div>

                              <label
                                style={{
                                  fontSize: "1rem",
                                  color: "black",
                                  fontWeight: 600,
                                }}
                              >
                                Unit Name:
                              </label>

                              <input
                                ref={(el) => (inputRefs.current[index] = el)}
                                type="text"
                                value={name}
                                style={{
                                  width: "80%",
                                  marginTop: ".5vh",
                                }}
                                onChange={(e) =>
                                  handleChangeSquadName(e, index)
                                }
                              ></input>

                              <label
                                style={{
                                  fontSize: "1rem",
                                  color: "black",
                                  fontWeight: 600,
                                }}
                              >
                                Unit Commander:
                              </label>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: "90%",
                                  height: "3vh",
                                }}
                              >
                                {selectMode === null ? (
                                  <>
                                    <label
                                      style={{
                                        fontSize: "1rem",
                                        color: "black",
                                        fontWeight: 600,
                                      }}
                                    >
                                      Select:
                                    </label>
                                    <input
                                      type="radio"
                                      onClick={(e) => handleSetSelectMode(true)}
                                    ></input>

                                    <label
                                      style={{
                                        fontSize: "1rem",
                                        color: "black",
                                        fontWeight: 600,
                                        marginLeft: "1vw",
                                      }}
                                    >
                                      Write:
                                    </label>
                                    <input
                                      type="radio"
                                      onClick={(e) =>
                                        handleSetSelectMode(false)
                                      }
                                    ></input>
                                  </>
                                ) : selectMode === true ? (
                                  <>
                                    <select
                                      type="text"
                                      style={{
                                        width: mobile ? "47vw" : "15.5vw",
                                        height: "2.5vh",
                                        marginRight: mobile ? "4vw" : "",
                                      }}
                                      onChange={(e) => handleSetSelectedXO(e)}
                                    >
                                      <option
                                        value={null}
                                        selected={tempXOid === null}
                                      >
                                        Not Listed
                                      </option>
                                      {attendingPlayers &&
                                        attendingPlayers.slice().map((row) => {
                                          const {
                                            id,
                                            callsign,
                                            name,
                                            rank,
                                            section,
                                            state,
                                            unit,
                                            country,
                                            city,
                                            company,
                                            profilePicUrl,
                                          } = row;
                                          return (
                                            <>
                                              <option value={[id, name]}>
                                                {name}
                                              </option>
                                            </>
                                          );
                                        })}
                                    </select>
                                  </>
                                ) : selectMode === false ? (
                                  <>
                                    <input
                                      type="text"
                                      value={co !== null ? co : ""}
                                      onChange={(e) => handleChangeSquadCO(e)}
                                      style={{
                                        width: "80%",
                                        marginTop: ".5vh",
                                      }}
                                    ></input>
                                  </>
                                ) : null}
                              </div>

                              {/*  */}
                              <label
                                style={{
                                  fontSize: "1rem",
                                  color: "black",
                                  fontWeight: 600,
                                }}
                              >
                                Role:
                              </label>

                              <input
                                type="text"
                                value={role !== null ? role : ""}
                                onChange={(e) =>
                                  handleChangeSquadRole(e, index)
                                }
                                style={{
                                  width: "80%",
                                  marginTop: ".5vh",
                                }}
                              ></input>

                              <label
                                style={{
                                  fontSize: "1rem",
                                  color: "black",
                                  fontWeight: 600,
                                }}
                              >
                                AOR:
                              </label>

                              <input
                                type="text"
                                value={aor !== null ? aor : ""}
                                onChange={(e) => handleChangeSquadAOR(e, index)}
                                style={{
                                  width: "80%",
                                  marginTop: ".5vh",
                                }}
                              ></input>

                              <label
                                style={{
                                  fontSize: "1rem",
                                  color: "black",
                                  fontWeight: 600,
                                }}
                              >
                                Add Members:
                              </label>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: "90%",
                                  height: "2vh",
                                  // backgroundColor:'blue',
                                  marginBottom: ".5vh",
                                }}
                              >
                                <input
                                  type="text"
                                  value={
                                    tempMemberName !== null
                                      ? tempMemberName
                                      : ""
                                  }
                                  onChange={(e) => handleSetTempMemberName(e)}
                                  style={{
                                    width: "70%",
                                  }}
                                ></input>
                                <button
                                  onClick={(e) =>
                                    handleAddTempMembers(e, index)
                                  }
                                >
                                  Add
                                </button>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: "90%",
                                  height: "2vh",
                                  // backgroundColor:'blue',
                                  marginBottom: ".5vh",
                                }}
                              >
                                <select
                                  style={{
                                    width: "93%",
                                    height: "2vh",
                                  }}
                                  onChange={(e) => handleSetTempMemberName(e)}
                                >
                                  <option
                                    value={null}
                                    selected={tempMemberName === null}
                                  ></option>
                                  {attendingPlayers &&
                                    attendingPlayers.slice().map((row) => {
                                      const {
                                        id,
                                        callsign,
                                        name,
                                        rank,
                                        section,
                                        state,
                                        unit,
                                        country,
                                        city,
                                        company,
                                        profilePicUrl,
                                      } = row;
                                      return (
                                        <>
                                          <option value={[id, name]}>
                                            {name}
                                          </option>
                                        </>
                                      );
                                    })}
                                </select>
                              </div>

                              <div
                                style={{
                                  width: "84%",
                                  height: "8vh",
                                  display: "flex",
                                  flexDirection: "column",
                                  backgroundColor: "lightseagreen",
                                  marginTop: "1vh",
                                  overflowY: "scroll",
                                  border: "solid",
                                  borderWidth: "1px",
                                  borderColor: "black",
                                }}
                              >
                                {members &&
                                  members.map((member, memberIndex) => (
                                    <label key={memberIndex}>{member[0]}</label>
                                  ))}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </>
    );
  };

  const EditUnitsModal = (props) => {
    const [tempUnitName, setTempUnitName] = useState(null);
    const [tempUnitDesc, setTempUnitDesc] = useState(null);
    const [tempUnitPic, setTempUnitPic] = useState(null);
    const [tempFactionId, setTempFactionId] = useState(null);

    const handleSetUnitName = (e) => {
      setTempUnitName(e.target.value);
    };
    const handleSetUnitDesc = (e) => {
      setTempUnitDesc(e.target.value);
    };

    const handleSetTempFactionId = (e) => {
      console.log(e.target.value);
      setTempFactionId(e.target.value);
    };

    const handleAddUnit = () => {
      if (!tempFactionId) {
        alert("Select a Faction");
      } else {
        addEventUnit(
          2,
          tempFactionId,
          tempUnitName,
          tempUnitDesc,
          newImage,
          editImage,
        );
      }
      toggleEditUnitsOpen();
    };

    const handleSetNewUnitPic = (e) => {
      editImage = e.target.files;
      newImage = true;
      const selectedFile = e.target.files[0];
      console.log(e.target.files);

      const reader = new FileReader();
      reader.onload = (event) => {
        const fileString = event.target.result;
        setTempUnitPic(fileString);
      };
      reader.readAsDataURL(selectedFile);
    };

    return (
      <>
        {editUnitsOpen ? (
          <>
            <div
              style={{
                position: "fixed",
                top: "20vh",
                left: mobile ? "4vw" : "32vw",
                height: mobile ? "70vh" : "auto",
                width: mobile ? "92vw" : "40vw",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "1vh",
                backgroundColor: "ghostwhite",
                boxShadow: "2px 2px 9px #000000",
                zIndex: "999",
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  height: "5vh",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#5E0516",
                  boxShadow: "2px 2px 9px #000000",
                  marginBottom: "1vh",
                }}
              >
                <h3 style={{ color: "#eed77a" }}>{`Add / Edit Units:`}</h3>
              </div>

              <div
                style={{
                  height: "auto",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  // backgroundColor:'lightblue',
                  marginBottom: "1vh",
                }}
              >
                <label
                  style={{
                    fontSize: "1rem",
                    color: "black",
                    fontWeight: 600,
                    marginRight: "2vw",
                    marginLeft: "2vw",
                  }}
                >
                  Select Unit Logo:
                </label>

                <img
                  style={{
                    width: "8vw",
                    height: "auto",
                    marginBottom: "1vh",
                  }}
                  src={tempUnitPic}
                  // src={tempProfilePic !== null ? tempProfilePic : logo}
                />

                <input type="file" onChange={(e) => handleSetNewUnitPic(e)} />
              </div>

              <div
                style={{
                  height: "auto",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  // backgroundColor:'darkcyan',
                  marginBottom: "2vh",
                }}
              >
                <label
                  style={{
                    fontSize: "1rem",
                    color: "black",
                    fontWeight: 600,
                    marginRight: "2vw",
                    marginLeft: "2vw",
                  }}
                >
                  Faction:
                </label>
                <select
                  type="text"
                  style={{
                    width: mobile ? "47vw" : "15.5vw",
                    height: "2.5vh",
                    marginRight: mobile ? "4vw" : "",
                  }}
                  onChange={(e) => handleSetTempFactionId(e)}
                >
                  <option
                    value={"none"}
                    // selected={tempFactionId == id}
                    // id="S1 - Personnel"
                  >
                    {}
                  </option>
                  {tempEventFactions &&
                    tempEventFactions.map(([id, name, url, desc]) => (
                      <option
                        value={id}
                        selected={tempFactionId == id}
                        // id="S1 - Personnel"
                      >
                        {name}
                      </option>

                      // <label
                      //   onClick={(e) => handleSetSelectedFaction(e, id, name,url,desc)}
                      //   style={{
                      //     width: "80%",
                      //     textAlign: "center",
                      //     marginBottom: "1vh",
                      //     backgroundColor:
                      //       selectedFaction === id ? "orange" : null,
                      //   }}
                      //   key={id}
                      // >
                      //   {name}
                      // </label>
                    ))}
                </select>
              </div>

              <div
                style={{
                  height: "auto",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  // backgroundColor:'darkcyan',
                  marginBottom: "2vh",
                }}
              >
                <label
                  style={{
                    fontSize: "1rem",
                    color: "black",
                    fontWeight: 600,
                    marginRight: "2vw",
                    marginLeft: "2vw",
                  }}
                >
                  Unit Name:
                </label>
                <input
                  type="text"
                  value={tempUnitName}
                  onChange={(e) => handleSetUnitName(e)}
                  style={{}}
                />
              </div>
              <div
                style={{
                  height: "auto",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  // backgroundColor:'darkcyan',
                  marginBottom: "2vh",
                }}
              >
                <label
                  style={{
                    fontSize: "1rem",
                    color: "black",
                    fontWeight: 600,
                    marginRight: "2vw",
                    marginLeft: "2vw",
                  }}
                >
                  Description:
                </label>
                <textarea
                  value={tempUnitDesc}
                  onChange={(e) => handleSetUnitDesc(e)}
                />
              </div>
              <div
                style={{
                  height: "auto",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "1vh",
                  // backgroundColor:'lightgreen'
                }}
              >
                <button
                  style={{ width: "3vw" }}
                  onClick={(e) => handleToggleEditUnitsOpen(e)}
                >
                  Cancel
                </button>
                <span style={{ width: "2vw" }} />
                <button
                  style={{ width: "3vw" }}
                  onClick={(e) => handleAddUnit(e)}
                >
                  Update
                </button>
              </div>
            </div>
          </>
        ) : null}
      </>
    );
  };

  const EditScenarioModal = (props) => {
    const [newEventScenarioText, setNewEventScenarioText] = useState(null);

    useEffect(() => {
      if (editScenarioOpen === true) {
        setNewEventScenarioText(props.scenarioText);
      }
    }, [editScenarioOpen]);

    const handleSetScenarioText = (e) => {
      setNewEventScenarioText(e.target.value);
    };

    const handleUpdateScenarioText = (e) => {
      updateScenarioText(2, newEventScenarioText);
    };

    return (
      <>
        {editScenarioOpen ? (
          <>
            <div
              style={{
                position: "fixed",
                top: "20vh",
                left: mobile ? "4vw" : "32vw",
                height: mobile ? "70vh" : "auto",
                width: mobile ? "92vw" : "40vw",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "1vh",
                backgroundColor: "ghostwhite",
                boxShadow: "2px 2px 9px #000000",
                zIndex: "999",
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  height: "5vh",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#5E0516",
                  boxShadow: "2px 2px 9px #000000",
                  marginBottom: "1vh",
                }}
              >
                <h3
                  style={{ color: "#eed77a" }}
                >{`Edit Event Scenario / History:`}</h3>
              </div>

              <label
                style={{
                  marginRight: "1vw",
                  fontSize: "1.25rem",
                }}
              >
                Sceanrio / History text:
              </label>

              <textarea
                style={{
                  width: "50%",
                  height: "10vh",
                  marginBottom: "1vh",
                }}
                onChange={(e) => handleSetScenarioText(e)}
                value={newEventScenarioText}
              />
              <div
                style={{
                  height: "auto",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "1vh",
                  // backgroundColor:'lightgreen'
                }}
              >
                <button
                  style={{ width: "3vw" }}
                  onClick={(e) => handleToggleEditSceanrioOpen(e)}
                >
                  Cancel
                </button>
                <span style={{ width: "2vw" }} />
                <button
                  style={{ width: "3vw" }}
                  onClick={(e) => handleUpdateScenarioText(e)}
                >
                  Add
                </button>
              </div>
            </div>
          </>
        ) : null}
      </>
    );
  };

  const EditDatesModal = (props) => {
    const handleAddEventDates = () => {
      addEventDates(selectedYearId);
    };

    return (
      <>
        {editDatesOpen ? (
          <>
            <div
              style={{
                position: "fixed",
                top: "20vh",
                left: mobile ? "4vw" : "32vw",
                height: mobile ? "70vh" : "auto",
                width: mobile ? "92vw" : "40vw",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "1vh",
                backgroundColor: "ghostwhite",
                boxShadow: "2px 2px 9px #000000",
                zIndex: "999",
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  height: "5vh",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#5E0516",
                  boxShadow: "2px 2px 9px #000000",
                }}
              >
                <h3 style={{ color: "#eed77a" }}>{`Edit Event Dates:`}</h3>
              </div>
              <div
                style={{
                  height: "auto",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  paddingTop: "2vh",
                  paddingBottom: "2vh",
                  // backgroundColor: "grey",
                  overflowY: "scroll",
                }}
              >
                <div
                  style={{
                    height: "auto",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "1vh",
                    // backgroundColor:'lightgreen'
                  }}
                >
                  <label
                    style={{
                      marginRight: "1vw",
                      fontSize: "1.25rem",
                    }}
                  >
                    Start Date:
                  </label>

                  <input
                    type="date"
                    value={tempStartDate}
                    onChange={(e) => setTempStartDate(e.target.value)}
                    style={{
                      marginRight: "2vw",
                    }}
                  />
                  <label
                    style={{
                      marginRight: "1vw",
                      fontSize: "1.25rem",
                    }}
                  >
                    End Date:
                  </label>

                  <input
                    type="date"
                    value={tempEndDate}
                    onChange={(e) => setTempEndDate(e.target.value)}
                    style={{}}
                  />
                </div>
                <div
                  style={{
                    height: "auto",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    // backgroundColor:'lightgreen'
                  }}
                >
                  <button
                    style={{ width: "3vw" }}
                    onClick={(e) => handleToggleEditDatesOpen(e)}
                  >
                    Cancel
                  </button>
                  <span style={{ width: "2vw" }} />
                  <button
                    style={{ width: "3vw" }}
                    onClick={(e) => handleAddEventDates(e)}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </>
    );
  };

  const EditScoreModal = (props) => {
    const [tempScore, setTempScore] = useState(null);

    useEffect(() => {
      setTempScore(selectedScore);
    }, [selectedScore]);

    const handleSetTempScore = (e) => {
      setTempScore(e.target.value);
    };

    const handleAddEventScore = (e) => {
      addEventScore(selectedYearId, selectedFactionId, tempScore);
    };

    return (
      <>
        {editScoreOpen ? (
          <>
            <div
              style={{
                position: "fixed",
                top: "20vh",
                left: mobile ? "4vw" : "32vw",
                height: mobile ? "70vh" : "auto",
                width: mobile ? "92vw" : "40vw",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "1vh",
                backgroundColor: "ghostwhite",
                boxShadow: "2px 2px 9px #000000",
                zIndex: "999",
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  height: "5vh",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#5E0516",
                  boxShadow: "2px 2px 9px #000000",
                }}
              >
                <h3
                  style={{ color: "#eed77a" }}
                >{`Edit ${selectedFaction} Score:`}</h3>
              </div>
              <div
                style={{
                  height: "auto",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  paddingTop: "2vh",
                  paddingBottom: "2vh",
                  // backgroundColor: "grey",
                  overflowY: "scroll",
                }}
              >
                <div
                  style={{
                    height: "auto",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "1vh",
                    // backgroundColor:'lightgreen'
                  }}
                >
                  <label
                    style={{
                      marginRight: "1vw",
                      fontSize: "1.25rem",
                    }}
                  >
                    Score:
                  </label>
                  <input
                    type="number"
                    value={tempScore}
                    onChange={(e) => handleSetTempScore(e)}
                    style={{}}
                  />
                </div>

                <div
                  style={{
                    height: "auto",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    // backgroundColor:'lightgreen'
                  }}
                >
                  <button
                    style={{ width: "3vw" }}
                    onClick={(e) => handleToggleEditScoreOpen(e)}
                  >
                    Cancel
                  </button>
                  <span style={{ width: "2vw" }} />
                  <button
                    style={{ width: "3vw" }}
                    onClick={(e) => handleAddEventScore(e)}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </>
    );
  };

  const EditGeneralModal = (props) => {
    const [tempGeneralName, setTempGeneralName] = useState(null);
    const [tempGeneralId, setTempGeneralId] = useState(null);
    const [tempProfilePic, setTempProfilePic] = useState(null);

    useEffect(() => {
      if (editGeneralOpen === true) {
        setTempGeneralName(props.generalName);
        setTempGeneralId(props.generalId);
      }
      // eslint-disable-next-line
    }, [editGeneralOpen]);

    const handleSetTempGeneralName = (e) => {
      console.log(e.target.value);
      setTempGeneralName(e.target.value);
    };

    const handleUpdateEventGeneral = (e) => {
      addEventGeneral(
        selectedYearId,
        selectedFactionId,
        tempGeneralId,
        tempGeneralName,
        newImage,
        editImage,
      );
      // if (newImage){
      //   if(tempGeneralId !== null){
      //     updateGeneralPic(tempGeneralId,editImage)
      //   }
      //   else{
      //     updateGeneralPic(newGeneralId,editImage)
      //   }

      // }
    };

    const handleSetNewGeneralPic = (e) => {
      editImage = e.target.files;
      newImage = true;
      const selectedFile = e.target.files[0];
      console.log(e.target.files);

      const reader = new FileReader();
      reader.onload = (event) => {
        const fileString = event.target.result;
        setTempProfilePic(fileString);
      };
      reader.readAsDataURL(selectedFile);
    };

    return (
      <>
        {editGeneralOpen === true ? (
          <>
            <div
              style={{
                position: "fixed",
                top: "20vh",
                left: mobile ? "4vw" : "32vw",
                height: mobile ? "70vh" : "auto",
                width: mobile ? "92vw" : "40vw",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                // justifyContent: "left",
                marginTop: "1vh",
                backgroundColor: "ghostwhite",
                boxShadow: "2px 2px 9px #000000",
                zIndex: "999",
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  height: "5vh",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#5E0516",
                  boxShadow: "2px 2px 9px #000000",
                }}
              >
                <h3
                  style={{ color: "#eed77a" }}
                >{`Edit ${selectedFaction} General:`}</h3>
              </div>
              <div
                style={{
                  height: "auto",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",

                  paddingTop: "2vh",
                  paddingBottom: "2vh",
                  // backgroundColor: "grey",
                  overflowY: "scroll",
                }}
              >
                <div
                  style={{
                    height: "auto",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    // backgroundColor:'lightblue',
                    marginBottom: "1vh",
                  }}
                >
                  <label
                    style={{
                      fontSize: "1rem",
                      color: "black",
                      fontWeight: 600,
                      marginRight: "2vw",
                      marginLeft: "2vw",
                    }}
                  >
                    Select Profile Pic:
                  </label>

                  <img
                    style={{
                      width: "8vw",
                      height: "auto",
                      marginBottom: "1vh",
                    }}
                    // src={logo}
                    src={tempProfilePic !== null ? tempProfilePic : logo}
                  />

                  <input
                    type="file"
                    onChange={(e) => handleSetNewGeneralPic(e)}
                  />
                </div>

                <div
                  style={{
                    height: "auto",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    // backgroundColor:'darkcyan',
                    marginBottom: "2vh",
                  }}
                >
                  <label
                    style={{
                      fontSize: "1rem",
                      color: "black",
                      fontWeight: 600,
                      marginRight: "2vw",
                      marginLeft: "2vw",
                    }}
                  >
                    Name:
                  </label>
                  <input
                    type="text"
                    value={tempGeneralName}
                    onChange={(e) => handleSetTempGeneralName(e)}
                    style={{}}
                  />
                </div>

                <div
                  style={{
                    height: "auto",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    // backgroundColor:'lightgreen'
                  }}
                >
                  <button
                    style={{ width: "3vw" }}
                    onClick={(e) => handleToggleEditGeneralOpen(e)}
                  >
                    Cancel
                  </button>
                  <span style={{ width: "2vw" }} />
                  <button
                    style={{ width: "3vw" }}
                    onClick={(e) => handleUpdateEventGeneral(e)}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </>
    );
  };

  const AddFactionModal = (props) => {
    const [tempFactionPic, setTempFactionPic] = useState(null);
    const [newFactionName, setNewFactionName] = useState(null);
    const [newFactionDesc, setNewFactionDesc] = useState(null);
    const [selectedFaction, setSelectedFaction] = useState(null);

    const handleSetNewFactionName = (e) => {
      setNewFactionName(e.target.value);
    };

    const handleAddNewFaction = () => {
      addNewFaction(2, newFactionName, newFactionDesc);
      toggleAddFactionOpen();
    };

    const handleUpdateFaction = () => {
      updateFaction(selectedFaction, newFactionName, newFactionDesc);

      if (newImage) {
        handleSetNewFectionImage();
        newImage = false;
      }
      toggleAddFactionOpen();
    };

    const handleSetSelectedFaction = (e, id, name, url, description) => {
      console.log(id);
      setSelectedFaction(id);
      setNewFactionName(name);
      setTempFactionPic(url);
      setNewFactionDesc(description);
    };

    const handleDeleteFaction = () => {
      deleteFaction(selectedFaction);
      toggleAddFactionOpen();
    };

    const handleSetNewFectionImage = (e) => {
      updateFactionPic(2, selectedFaction, editImage);
    };
    const handleSetNewFactionDesc = (e) => {
      setNewFactionDesc(e.target.value);
    };

    const handleSetNewFactionPic = (e) => {
      editImage = e.target.files;
      newImage = true;
      const selectedFile = e.target.files[0];
      console.log(e.target.files);

      const reader = new FileReader();
      reader.onload = (event) => {
        const fileString = event.target.result;
        setTempFactionPic(fileString);
      };
      reader.readAsDataURL(selectedFile);
    };

    return (
      <>
        {props.addFactionOpen === true ? (
          <>
            <div
              style={{
                position: "fixed",
                top: "20vh",
                left: mobile ? "4vw" : "32vw",
                height: mobile ? "70vh" : "auto",
                width: mobile ? "92vw" : "40vw",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                // justifyContent: "left",
                marginTop: "1vh",
                backgroundColor: "ghostwhite",
                boxShadow: "2px 2px 9px #000000",
                zIndex: "999",
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  height: "5vh",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#5E0516",
                  boxShadow: "2px 2px 9px #000000",
                }}
              >
                <h3 style={{ color: "#eed77a" }}>{`Edit Event Factions:`}</h3>
              </div>
              <div
                style={{
                  height: "80%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",

                  paddingTop: "2vh",
                  paddingBottom: "2vh",
                  // backgroundColor: "grey",
                  overflowY: "scroll",
                }}
              >
                <div
                  style={{
                    width: "80%",
                    height: "70%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    // backgroundColor: "lightgrey",
                    paddingLeft: "1vw",
                    paddingTop: "1vh",
                    paddingRight: "1vh",
                  }}
                  // value={tempEventName}
                >
                  {tempEventFactions &&
                    tempEventFactions.map(([id, name, url, desc]) => (
                      <label
                        onClick={(e) =>
                          handleSetSelectedFaction(e, id, name, url, desc)
                        }
                        style={{
                          width: "80%",
                          textAlign: "center",
                          marginBottom: "1vh",
                          backgroundColor:
                            selectedFaction === id ? "orange" : null,
                        }}
                        key={id}
                      >
                        {name}
                      </label>
                    ))}

                  <div
                    style={{
                      height: "auto",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      // backgroundColor:'lightblue',
                      marginBottom: "1vh",
                    }}
                  >
                    <label
                      style={{
                        fontSize: "1rem",
                        color: "black",
                        fontWeight: 600,
                        marginRight: "2vw",
                        marginLeft: "2vw",
                      }}
                    >
                      Select Profile Pic:
                    </label>

                    <img
                      style={{
                        width: "8vw",
                        height: "auto",
                        marginBottom: "1vh",
                      }}
                      // src={logo}
                      src={tempFactionPic !== null ? tempFactionPic : logo}
                    />

                    <input
                      type="file"
                      onChange={(e) => handleSetNewFactionPic(e)}
                    />
                  </div>

                  <div
                    style={{
                      width: "16vw",
                      height: "2vh",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "right",
                      marginBottom: "2vh",
                      // backgroundColor:'lightgreen'
                    }}
                  >
                    <label
                      style={{
                        textJustify: "center",
                        marginRight: "1vw",
                        fontSize: "1.1rem",
                      }}
                    >
                      <strong>Faction Name:</strong>
                    </label>
                    <input
                      value={newFactionName}
                      onChange={(e) => handleSetNewFactionName(e)}
                      placeholder="i.e. Warsaw"
                      style={{
                        width: "8vw",
                      }}
                      type="text"
                    />
                  </div>

                  <div
                    style={{
                      width: "16vw",
                      height: "2vh",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "right",
                      marginBottom: "2vh",
                      // backgroundColor:'grey'
                    }}
                  >
                    <label
                      style={{
                        textJustify: "center",
                        marginRight: "1vw",
                        fontSize: "1.1rem",
                      }}
                    >
                      <strong>Description:</strong>
                    </label>
                    <textarea
                      value={newFactionDesc !== null ? newFactionDesc : "N/A"}
                      onChange={(e) => handleSetNewFactionDesc(e)}
                      placeholder="i.e. Warsaw"
                      style={{
                        width: "8vw",
                      }}
                      type="text"
                    />
                  </div>

                  <div
                    style={{
                      width: "100%",
                      height: "2vh",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "1vh",
                      // backgroundColor:'lightgreen'
                    }}
                  >
                    <button
                      style={{
                        width: "3vw",
                        marginRight: ".5vw",
                      }}
                      onClick={
                        selectedFaction === null
                          ? (e) => handleAddNewFaction(e)
                          : (e) => handleUpdateFaction(e)
                      }
                    >
                      {selectedFaction === null ? "Add" : "Update"}
                    </button>

                    <button
                      style={{
                        width: "3vw",
                        marginRight: ".5vw",
                      }}
                      onClick={(e) => handleDeleteFaction(e)}
                    >
                      Delete
                    </button>

                    <button
                      style={{
                        width: "3vw",
                        marginRight: ".5vw",
                      }}
                      onClick={(e) => toggleAddFactionOpen(e)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </>
    );
  };

  const ParagraphComponent = (props) => {
    return (
      <div
        style={{
          width: "90%",
          whiteSpace: "pre-line",
          color:
            props.textColor !== undefined && props.textColor !== ""
              ? props.textColor
              : "#eed77a",
          textDecoration: "none",
          textAlign: "center",
          // marginBottom: "1vh",
          fontWeight: 500,
        }}
      >
        {props.text}
      </div>
    );
  };

  const chunkArray = (array, chunkSize) => {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  };
  return (
    <>
      <EditScoreModal />
      <EditDatesModal />
      <EditGeneralModal generalName={generalName} generalId={generalId} />
      <AddFactionModal addFactionOpen={addFactionOpen} />
      <EditScenarioModal scenarioText={tempEventScenario} />
      <EditUnitsModal />
      <EditUnitYearModal />
      <EditORBATModal />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          backgroundColor: "#adc8e6",
          minHeight: mobile ? "100vh" : "",
        }}
      >
        <Header />
        <div
          style={{
            height: "88vh",
            width: "100%",
            display: "flex",
            flexDirection: mobile ? "column" : "row",
            alignItems: "center",
            flexGrow: mobile ? 1 : 0,
          }}
        >
          {user && user !== null ? (
            <>
              <UserSidebar />
            </>
          ) : null}
          <div
            style={{
              height: mobile ? "78vh" : "88vh",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              // alignItems: "center",
            }}
          >
            <div
              style={{
                height: "100%",
                width: "31vw",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                overflow: "hidden",
                // // marginLeft:'1vh',
                // backgroundColor:'lightgray',
                paddingTop: "1vh",
                marginTop: "1vh",
              }}
            >
              <div
                style={{
                  height: "84vh",
                  width: "94%",
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "ghostwhite",
                  boxShadow: "2px 2px 9px #000000",
                  marginLeft: "1vw",
                }}
              >
                <div
                  style={{
                    height: "5vh",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#5E0516",
                    boxShadow: "2px 2px 9px #000000",
                  }}
                >
                  <h3
                    style={{
                      color: "#eed77a",
                    }}
                  >
                    <strong>Event:</strong>
                  </h3>
                </div>

                <div
                  style={{
                    height: "100%",
                    width: "auto",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    overflowY: "scroll",
                    marginTop: ".25vh",
                    paddingTop: "1vh",
                    paddingBottom: "1vh",
                  }}
                >
                  <div
                    style={{
                      height: "auto",
                      width: "90%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#5E0516",
                      boxShadow: "2px 2px 9px #000000",
                      paddingTop: "4vh",
                      paddingBottom: "4vh",
                      border: "solid",
                      borderWidth: "6px",
                      borderColor: "#eed77a",
                    }}
                  >
                    <img
                      style={{
                        width: "70%",
                        height: "auto",
                        maxHeight: "100%",
                        marginBottom: "1vh",
                      }}
                      src={tempImageUrl}
                    />

                    <label
                      style={{
                        color: "#eed77a",
                        fontSize: "1.5rem",
                        color: "#eed77a",
                        marginBottom: ".25vw",
                      }}
                    >
                      <strong>{tempEventName}</strong>
                    </label>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        // backgroundColor:'grey',
                        height: "2vh",
                        marginBottom: ".5vw",
                      }}
                    >
                      <label
                        style={{
                          color: "#eed77a",
                          fontSize: "1.25rem",
                        }}
                      >
                        {factionText}
                      </label>

                      {/* {user && user.role === "admin" && !mobile ? (
                    <>
                      <button
                        style={
                          {
                            // position: "relative",
                            // left: "11.5vw",
                            // top: "1.5vh",
                            // // bottom:'19vh'
                          }
                        }
                        onClick={(e) => handleToggleAddFactionOpen(e)}
                      >
                        Edit
                      </button>
                    </>
                  ) : null} */}
                    </div>

                    <label
                      style={{
                        marginBottom: "1vh",
                        color: "#eed77a",
                      }}
                    >
                      {/* <strong>Description:</strong> */}
                    </label>

                    <ParagraphComponent text={tempDescription} />
                  </div>

                  <div
                    style={{
                      height: "auto",
                      width: "92%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      // justifyContent: "center",
                      // backgroundColor: "#5E0516",
                      marginTop: "5vh",
                      // boxShadow: "2px 2px 9px #000000",
                    }}
                  >
                    <h3
                      style={
                        {
                          // color: "#eed77a",
                        }
                      }
                      onClick={(e) => handleToggleLocationOpen(e)}
                    >
                      <strong>{"Location and Contact:"}</strong>
                      <img
                        style={{
                          height: "1vh",
                          width: "auto",
                          marginLeft: "1vw",
                        }}
                        src={locationOpen ? collapse_icon : expand_icon}
                      />
                    </h3>
                  </div>
                  {locationOpen ? (
                    <>
                      <div
                        style={{
                          width: "92%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          marginBottom: "2vh",
                          boxShadow: "2px 2px 9px #000000",
                          paddingLeft: "1vw",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginRight: "1vw",
                            marginTop: "2vh",
                          }}
                        >
                          <label
                            style={{
                              marginBottom: "2vh",
                              textAlign: "right",
                              fontSize: "1.1rem",
                              height: "5vh",
                            }}
                          >
                            <strong>Location:</strong>
                          </label>

                          <label
                            style={{
                              marginBottom: "2vh",
                              textAlign: "right",
                              fontSize: "1.1rem",
                            }}
                          >
                            <strong>Venue:</strong>
                          </label>
                          <label
                            style={{
                              marginBottom: "2vh",
                              textAlign: "right",
                              fontSize: "1.1rem",
                            }}
                          >
                            <strong>Email:</strong>
                          </label>
                          <label
                            style={{
                              marginBottom: "2vh",
                              textAlign: "right",
                              fontSize: "1.1rem",
                            }}
                          >
                            <strong>Phone:</strong>
                          </label>
                          <label
                            style={{
                              marginBottom: "2vh",
                              textAlign: "right",
                              fontSize: "1.1rem",
                            }}
                          >
                            <strong>Website:</strong>
                          </label>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: "2vh",
                            marginTop: "2vh",
                          }}
                        >
                          <Tooltip
                            placement="top"
                            title={"Click to view on map"}
                            arrow
                          >
                            <label
                              style={{
                                textJustify: "top",
                                marginBottom: "2vh",
                                fontSize: "1.1rem",
                                height: "5vh",
                              }}
                            >
                              {`${tempAddress}, ${tempCity} ${tempState} - ${tempCOuntry}`}
                            </label>
                          </Tooltip>

                          <Tooltip
                            placement="top"
                            title={"Click to view on map"}
                            arrow
                          >
                            <label
                              style={{
                                marginBottom: "2vh",
                                fontSize: "1.1rem",
                              }}
                            >
                              {tempEventVenu}
                            </label>
                          </Tooltip>

                          <label
                            style={{
                              marginBottom: "2vh",
                              fontSize: "1.1rem",
                            }}
                          >
                            {tempEmail}
                          </label>
                          <label
                            style={{
                              marginBottom: "2vh",
                              fontSize: "1.1rem",
                            }}
                          >
                            {tempPhone}
                          </label>

                          <a
                            href={tempWebsite}
                            style={{
                              textDecoration: "none",
                              fontSize: "1.1rem",
                              // marginBottom: "2vh",
                            }}
                            target="_blank"
                          >
                            <label
                              style={
                                {
                                  // marginBottom: "2vh",
                                }
                              }
                            >
                              {tempWebsite}
                            </label>
                          </a>
                        </div>
                      </div>
                    </>
                  ) : null}

                  <div
                    style={{
                      height: "auto",
                      width: "92%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      // justifyContent: "center",
                      // marginBottom: "1vh",
                      // marginTop: "2vh",
                      // boxShadow: "2px 2px 9px #000000",
                    }}
                  >
                    <h3
                      onClick={(e) => handleToggleEditSceanrioOpen(e)}
                      style={
                        {
                          // color: "#eed77a",
                        }
                      }
                    >
                      <strong>{"Scenario / History:"}</strong>
                    </h3>
                    <img
                      onClick={(e) => handleToggleScenarioOpen(e)}
                      style={{
                        height: "1vh",
                        width: "auto",
                        marginLeft: "1vw",
                      }}
                      src={scenarioOpen ? collapse_icon : expand_icon}
                    />
                  </div>
                  {scenarioOpen ? (
                    <>
                      <div
                        style={{
                          width: "92%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          marginBottom: "2vh",
                          boxShadow: "2px 2px 9px #000000",
                          paddingLeft: "1vw",
                          paddingTop: "1vh",
                          paddingBottom: "1vh",
                        }}
                      >
                        <ParagraphComponent
                          text={tempEventScenario}
                          textColor={"black"}
                        />
                      </div>
                    </>
                  ) : null}

                  <div
                    style={{
                      height: "auto",
                      width: "92%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      // justifyContent: "center",
                      // marginTop:'2vh',
                      marginBottom: "1vh",
                      // marginTop: "2vh",
                      // boxShadow: "2px 2px 9px #000000",
                    }}
                  >
                    <h3
                      onClick={(e) => handleToggleAddFactionOpen(e)}
                      style={
                        {
                          // color: "#eed77a",
                        }
                      }
                    >
                      <strong>{"Factions:"}</strong>
                    </h3>
                    <img
                      style={{
                        height: "1vh",
                        width: "auto",
                        marginLeft: "1vw",
                      }}
                      src={factionsOpen ? collapse_icon : expand_icon}
                      onClick={(e) => handleToggleFactionsOpen(e)}
                    />
                  </div>
                  {factionsOpen ? (
                    <>
                      {tempEventFactions &&
                        tempEventFactions.map(
                          ([id, label, imageUrl, description]) => (
                            <div
                              key={id}
                              className="faction-column"
                              style={{
                                display: "flex",
                                width: "95%",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                boxShadow: "2px 2px 9px #000000",
                                marginBottom: "1vh",
                                paddingBottom: "1vh",
                                paddingTop: "1vh",
                              }}
                            >
                              <label
                                style={{
                                  marginBottom: "1vh",
                                  fontSize: "1.5rem",
                                  fontWeight: 600,
                                }}
                              >
                                {label}
                              </label>
                              <img
                                src={imageUrl !== null ? imageUrl : logo}
                                alt={label}
                                style={{
                                  width: "80%",
                                  height: "auto",
                                  border: "solid",
                                  borderWidth: "4px",
                                  borderColor: "black",
                                  marginBottom: "1vh",
                                }}
                                className="faction-image"
                              />
                              {description !== null ? (
                                <>
                                  <ParagraphComponent
                                    text={description}
                                    textColor={"black"}
                                  />
                                </>
                              ) : null}
                            </div>
                          ),
                        )}
                    </>
                  ) : null}

                  <div
                    style={{
                      height: "auto",
                      width: "92%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <h3
                      style={
                        {
                          // color: "#eed77a",
                        }
                      }
                      onClick={(e) => handleToggleEditUnitsOpen(e)}
                    >
                      <strong>{"Units:"}</strong>
                    </h3>
                    <img
                      style={{
                        height: "1vh",
                        width: "auto",
                        marginLeft: "1vw",
                      }}
                      src={unitsOpen ? collapse_icon : expand_icon}
                      onClick={(e) => handleToggleUnitsOpen(e)}
                    />
                  </div>
                  {unitsOpen ? (
                    <>
                      <div
                        style={{
                          height: "auto",
                          width: "94%",
                          display: "flex",
                          flexDirection: "column",
                          // justifyContent: "flex-start",
                          // alignItems: "flex-start",
                          marginBottom: "2vh",

                          paddingTop: "1vh",
                          paddingBottom: "2vh",
                        }}
                      >
                        <div>
                          {tempEventFactions &&
                            tempEventFactions.map((faction) => (
                              <div
                                key={faction[0]}
                                style={{
                                  display: "flex",
                                  height: "auto",
                                  minHeight: "44vh",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  // justifyContent:'center',
                                  boxShadow: "2px 2px 9px #000000",
                                  marginBottom: "2vh",
                                }}
                              >
                                <label
                                  style={{
                                    fontSize: "1.35rem",
                                    fontWeight: 700,
                                    marginTop: "1vh",
                                  }}
                                >
                                  {faction[1]}
                                </label>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <button
                                    style={{
                                      backgroundColor: "transparent",
                                      border: "none",
                                      cursor: "pointer",
                                      fontSize: "16px",
                                      outline: "none",
                                      height: "100%",
                                    }}
                                    onClick={() => handlePrevUnit(faction[0])}
                                  >
                                    &lt;
                                  </button>
                                  <div
                                    style={{
                                      display: "flex",
                                      width: "22vw",
                                      flexDirection: "column",
                                      paddingLeft: ".5vw",
                                      paddingRight: ".5vw",
                                      paddingTop: "1vw",
                                      paddingBottom: "1vw",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      // backgroundColor:'grey'
                                    }}
                                  >
                                    {/* {tempUnits&&tempUnits[faction[0].toString()][0]?.unit_name } */}
                                    <img
                                      src={
                                        tempUnits &&
                                        tempUnits[faction[0].toString()][
                                          currentIndexes[faction[0]]
                                        ]?.unit_image
                                      }
                                      alt={
                                        tempUnits &&
                                        tempUnits[faction[0].toString()][
                                          currentIndexes[faction[0]]
                                        ]?.unit_name
                                      }
                                      style={{ height: "10vh", width: "auto" }}
                                    />
                                    <label
                                      style={{
                                        fontSize: "1.1rem",
                                        fontWeight: 700,
                                        marginBottom: "1vh",
                                        textAlign: "center",
                                        marginTop: "1vh",
                                      }}
                                    >
                                      {tempUnits &&
                                        tempUnits[faction[0].toString()][
                                          currentIndexes[faction[0]]
                                        ]?.unit_name}
                                    </label>
                                    <ParagraphComponent
                                      text={
                                        tempUnits &&
                                        tempUnits[faction[0].toString()][
                                          currentIndexes[faction[0]]
                                        ]?.unit_description
                                      }
                                      textColor={"black"}
                                    />
                                  </div>
                                  <button
                                    style={{
                                      backgroundColor: "transparent",
                                      border: "none",
                                      cursor: "pointer",
                                      fontSize: "16px",
                                      outline: "none",
                                      height: "100%",
                                    }}
                                    onClick={() => handleNextUnit(faction[0])}
                                  >
                                    &gt;
                                  </button>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>

            <div
              style={{
                height: "100%",
                width: "60vw",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingTop: "1vh",
              }}
            >
              <div
                style={{
                  height: "82vh",
                  width: "96%",
                  marginTop: "1vh",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  backgroundColor: "ghostwhite",
                  boxShadow: "2px 2px 9px #000000",
                  // paddingTop: "4vh",
                  paddingBottom: "2vh",
                }}
              >
                <div
                  style={{
                    height: "5vh",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#5E0516",
                    boxShadow: "2px 2px 9px #000000",
                  }}
                >
                  <h3
                    style={{
                      color: "#eed77a",
                    }}
                  >{`Details By Year:`}</h3>

                  <div style={{ position: "fixed", right: "5vw" }}>
                    <label
                      style={{
                        height: mobile ? "3vh" : "2vh",
                        marginRight: "1vw",
                        textJustify: "auto",
                        paddingBottom: ".5vh",
                        color: "#eed77a",
                      }}
                    >
                      Select Year:
                    </label>
                    <select
                      style={{ width: "4vw" }}
                      onChange={(e) => handleSetSelectedYear(e)}
                    >
                      {tempYears &&
                        tempYears.map(([id, year]) => (
                          <option
                            key={id}
                            value={id}
                            selected={selectedYearId === id}
                          >
                            {year}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>

                <div
                  style={{
                    height: "80vh",
                    width: "98%",
                    overflowY: "scroll",
                    // display:'flex',
                    // flexDirection:'column',
                    alignItems: "center",
                    // paddingTop:'1vh',
                    paddingLeft: "1vw",
                    marginTop: ".25vh",
                  }}
                >
                  <div
                    style={{
                      height: "auto",
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      // backgroundColor: "lightgrey",
                    }}
                  >
                    <label
                      style={{
                        // color: "#eed77a",
                        fontSize: "3rem",
                        marginBottom: "0vh",
                      }}
                    >
                      <strong>{`${tempEventName} ${selectedYear}`}</strong>
                    </label>
                  </div>

                  <div
                    style={{
                      height: "5vh",
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "1vh",
                      // backgroundColor:'lightcoral'
                    }}
                  >
                    <label
                      onClick={(e) => handleToggleEditDatesOpen(e)}
                      style={{
                        // height: mobile ? "3vh" : "2vh",
                        fontSize: "1.25rem",
                      }}
                    >
                      {"Dates:"}
                    </label>
                    <span style={{ width: "2vw" }} />
                    <label
                      style={{
                        height: mobile ? "3vh" : "2vh",
                        width: "7vw",
                        // backgroundColor:'grey'
                      }}
                    >
                      {startDate && startDate ? startDate : "unknown"}
                    </label>
                    <span style={{ width: "3vw" }} />
                    <label
                      style={{
                        height: mobile ? "3vh" : "2vh",
                        width: "7vw",
                        // backgroundColor:'grey'
                      }}
                    >
                      {endDate && endDate ? endDate : "unknown"}
                    </label>
                  </div>

                  <div
                    style={{
                      height: "auto",
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      // backgroundColor: "lightgrey",
                    }}
                  >
                    {tempEventFactions &&
                      tempEventFactions.map(([id, name, image_url]) => {
                        const [foundGeneral] =
                          (eventGenerals &&
                            eventGenerals.filter(
                              ({ faction }) => parseInt(faction) === id,
                            )) ||
                          [];
                        const [foundScore] =
                          (eventScore &&
                            eventScore.filter(
                              ({ faction }) => parseInt(faction) === id,
                            )) ||
                          [];
                        console.log(foundScore);
                        const factionScore =
                          foundScore && foundScore
                            ? `${foundScore.score} points`
                            : "unknown";

                        const generalId = foundGeneral?.id || null;
                        const generalName = foundGeneral?.name || "";
                        const generalPic = foundGeneral?.profile_pic_url || "";
                        console.log(image_url);
                        return (
                          <div
                            key={id}
                            style={{
                              height: "100%",
                              width: "40%",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              backgroundColor: "#5E0516",

                              boxShadow: "2px 2px 9px #000000",
                              border: "solid",
                              borderWidth: "6px",
                              borderColor: "#eed77a",
                              marginRight: "1vw",
                              paddingTop: "1vh",
                            }}
                          >
                            <label
                              style={{
                                color: "#eed77a",
                                fontSize: "1.25rem",
                                fontWeight: "500",
                              }}
                            >
                              {name}
                            </label>
                            <div
                              style={{
                                height: "100%",
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                marginTop: "1vh",
                              }}
                            >
                              <div
                                style={{
                                  height: "100%",
                                  width: "50%",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  style={{
                                    height: "10vh",
                                    width: "10vh",
                                    marginBottom: "1.25vh",
                                    border: "solid",
                                    borderWidth: "4px",
                                    borderColor: "#eed77a",
                                  }}
                                  src={generalPic !== "" ? generalPic : logo}
                                />

                                <label
                                  key={id}
                                  onClick={(e) =>
                                    handleToggleEditGeneralOpen(
                                      e,
                                      id,
                                      name,
                                      generalId,
                                      generalName,
                                    )
                                  }
                                  style={{
                                    color: "#eed77a",
                                    fontSize: "1.25rem",
                                    fontWeight: "500",
                                  }}
                                >
                                  {"General:"}
                                </label>
                                {/* Use generalName instead of name */}
                                <label
                                  key={id}
                                  style={{
                                    color: "#eed77a",
                                    fontSize: "1rem",
                                    fontWeight: "500",
                                  }}
                                >
                                  {generalName}
                                </label>
                              </div>

                              <div
                                style={{
                                  height: "100%",
                                  width: "50%",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  style={{
                                    height: "10vh",
                                    width: "auto",
                                    border: "solid",
                                    borderWidth: "4px",
                                    borderColor: "#eed77a",
                                    // marginBottom:'1vh'
                                  }}
                                  src={image_url}
                                />
                                {/* <label
                                  key={id}
                                  style={{
                                    color: "#eed77a",
                                    fontSize: "1.25rem",
                                    fontWeight: "500",
                                    marginBottom: "1vh",
                                  }}
                                >
                                  {"Player Count:"}
                                </label>

                                <label
                                  key={id}
                                  style={{
                                    color: "#eed77a",
                                    fontSize: "1rem",
                                    fontWeight: "500",
                                    marginBottom: "5vh",
                                  }}
                                >
                                  {"1,000"}
                                </label> */}

                                <label
                                  key={id}
                                  style={{
                                    color: "#eed77a",
                                    fontSize: "1.25rem",
                                    fontWeight: "500",
                                    marginTop: "1vh",
                                  }}
                                  onClick={(e) =>
                                    handleToggleEditScoreOpen(
                                      e,
                                      name,
                                      id,
                                      factionScore,
                                    )
                                  }
                                >
                                  {"Final Score:"}
                                </label>
                                <label
                                  key={id}
                                  style={{
                                    color: "#eed77a",
                                    fontSize: "1rem",
                                    fontWeight: "500",
                                    marginBottom: "1vh",
                                  }}
                                >
                                  {factionScore}
                                </label>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>

                  <div
                    style={{
                      height: "auto",
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      // backgroundColor: "lightgrey",
                      marginTop: "2vh",
                    }}
                  >
                    <div
                      style={{
                        height: "80%",
                        width: "83%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        // justifyContent: "center",
                        backgroundColor: "#5E0516",
                        boxShadow: "2px 2px 9px #000000",
                        border: "solid",
                        borderWidth: "6px",
                        borderColor: "#eed77a",
                        marginRight: "1vw",
                        paddingTop: "1vh",
                      }}
                    >
                      <label
                        style={{
                          color: "#eed77a",
                          fontSize: "1rem",
                          fontWeight: "500",
                        }}
                      >
                        {"Winner:"}
                      </label>
                      <label
                        style={{
                          color: "#eed77a",
                          fontSize: "1.5rem",
                          fontWeight: "500",
                          marginBottom: "1vh",
                        }}
                      >
                        {"WARSAW"}
                      </label>
                    </div>
                  </div>

                  <div
                    style={{
                      height: "auto",
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      // alignItems: "center",
                      justifyContent: "center",
                      // backgroundColor: "lightgrey",
                      marginTop: "2vh",
                    }}
                  >
                    <div
                      style={{
                        height: "80%",
                        width: "83%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        // justifyContent: "center",
                        backgroundColor: "#5E0516",
                        boxShadow: "2px 2px 9px #000000",
                        border: "solid",
                        borderWidth: "6px",
                        borderColor: "#eed77a",
                        marginRight: "1vw",
                        paddingTop: "1vh",
                      }}
                    >
                      <label
                        style={{
                          color: "#eed77a",
                          fontSize: "1.25rem",
                          fontWeight: "500",
                        }}
                      >
                        {"Attendance:"}
                      </label>
                      {/* <label  style={{color: "#eed77a", fontSize:'3rem',fontWeight:'500',marginBottom:'1vh'}}>{"WARSAW"}</label> */}

                      <div
                        style={{
                          overflowX: "scroll",
                          display: "flex",
                          width: "100%",
                          height: "100%",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        {attendingPlayers &&
                          attendingPlayers.map((item, index) => (
                            <div
                              key={index}
                              style={{
                                width: "auto",
                                height: "15vh", // Set your desired height
                                margin: "10px",
                                // backgroundColor: 'lightgrey',
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                // justifyContent:'center',
                                // paddingTop:'1vh'
                              }}
                            >
                              {item.profile_pic_url ? (
                                <img
                                  src={item.profile_pic_url}
                                  alt="Profile Pic"
                                  style={{
                                    height: "10vh",
                                    width: "auto",
                                    objectFit: "cover",
                                    border: "solid",
                                    borderWidth: "4px",
                                    borderColor: "#eed77a",
                                  }}
                                />
                              ) : (
                                <img
                                  src={logo}
                                  alt="Default Logo"
                                  style={{
                                    height: "10vh",
                                    width: "auto",
                                    objectFit: "cover",
                                    border: "solid",
                                    borderWidth: "4px",
                                    borderColor: "#eed77a",
                                  }}
                                />
                              )}
                              <label
                                style={{
                                  color: "#eed77a",
                                  fontSize: "1remm",
                                  fontWeight: 700,
                                }}
                              >
                                {item.callsign}
                              </label>
                              {/* You can add additional labels or content here */}
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      height: "40vh",
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      // alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "lightgrey",
                      marginTop: "2vh",
                    }}
                  >
                    <div
                      style={{
                        height: "40vh",
                        width: "83%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#5E0516",
                        boxShadow: "2px 2px 9px #000000",
                        border: "solid",
                        borderWidth: "6px",
                        borderColor: "#eed77a",
                        marginRight: "1vw",
                        marginBottom: "1vh",
                        paddingTop: "1vh",
                      }}
                    >
                      <label
                        style={{
                          color: "#eed77a",
                          fontSize: "1.25rem",
                          fontWeight: "500",
                        }}
                        onClick={(e) => handleToggleUnitYearOpen(e)}
                      >
                        {"Event Faction & Unit:"}
                      </label>

                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          height: "80%",
                          flexDirection: "row",
                          // justifyContent: "center",
                          // backgroundColor:'lightgreen'
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "50%",
                            height: "100%",
                            flexDirection: "column",
                            // justifyContent: "center",
                            alignItems: "center",
                            // backgroundColor:'lightblue'
                          }}
                        >
                          <label
                            style={{
                              color: "#eed77a",
                              fontSize: "1.25rem",
                              fontWeight: "500",
                              marginBottom: "1vh",
                            }}
                          >
                            {"Unit Logo:"}
                          </label>

                          <img
                            style={{
                              height: "80%",
                              width: "auto",
                            }}
                            src={unitImage}
                            alt={`Unit ${tempUnitId}`}
                          ></img>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            width: "50%",
                            height: "100%",
                            flexDirection: "column",
                            alignItems: "center",
                            // justifyContent: "center",
                            // backgroundColor:'lightblue'
                          }}
                        >
                          <label
                            style={{
                              color: "#eed77a",
                              fontSize: "1.25rem",
                              fontWeight: "500",
                              marginBottom: "1vh",
                            }}
                          >
                            {"Description:"}
                          </label>

                          <ParagraphComponent
                            text={unitDesc}
                            color={"#eed77a"}
                          />
                        </div>
                      </div>

                      <label
                        style={{
                          color: "#eed77a",
                          fontSize: "2rem",
                          fontWeight: "500",
                          marginBottom: "1vh",
                        }}
                      >
                        {`${unitFactionName}: ${unitName}`}
                      </label>
                    </div>
                  </div>

                  <div
                    style={{
                      height: "40vh",
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      // alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "lightgrey",
                      marginTop: "4.5vh",
                    }}
                  >
                    <div
                      style={{
                        height: "40vh",
                        width: "83%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        // justifyContent: "center",
                        backgroundColor: "#5E0516",
                        boxShadow: "2px 2px 9px #000000",
                        border: "solid",
                        borderWidth: "6px",
                        borderColor: "#eed77a",
                        marginRight: "1vw",
                        paddingTop: "1vh",
                      }}
                    >
                      <label
                        style={{
                          color: "#eed77a",
                          fontSize: "1.25rem",
                          fontWeight: "500",
                        }}
                        onClick={(e) => handleToggleUnitORBATOpen(e)}
                      >
                        {"ORBAT:"}
                      </label>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "80%",
                          height: "5vh",
                          // backgroundColor:'lightgrey',
                          marginTop: "1vh",
                        }}
                      >
                        <label
                          style={{
                            color: "#eed77a",
                            fontSize: "1.25rem",
                            fontWeight: "500",
                          }}
                        >
                          {`Commanding Officer: ${tempUnitCOname}`}
                        </label>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "80%",
                          height: "5vh",
                          // backgroundColor:'lightgrey',
                          marginTop: "1vh",
                        }}
                      >
                        <label
                          style={{
                            color: "#eed77a",
                            fontSize: "1.25rem",
                            fontWeight: "500",
                          }}
                        >
                          {`Executive Officer: ${tempUnitXOname}`}
                        </label>
                      </div>
                      <label
                        style={{
                          color: "#eed77a",
                          fontSize: "1.25rem",
                          fontWeight: "500",
                          marginTop: "1vh",
                        }}
                      >
                        {`Units:`}
                      </label>
                    </div>
                  </div>

                  <div
                    style={{
                      height: "40vh",
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      // alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "lightgrey",
                      marginTop: "4.5vh",
                    }}
                  >
                    <div
                      style={{
                        height: "40vh",
                        width: "83%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        // justifyContent: "center",
                        backgroundColor: "#5E0516",
                        boxShadow: "2px 2px 9px #000000",
                        border: "solid",
                        borderWidth: "6px",
                        borderColor: "#eed77a",
                        marginRight: "1vw",
                        paddingTop: "1vh",
                      }}
                    >
                      <label
                        style={{
                          color: "#eed77a",
                          fontSize: "1.25rem",
                          fontWeight: "500",
                        }}
                        onClick={(e) => handleToggleUnitYearOpen(e)}
                      >
                        {"AAR:"}
                      </label>
                    </div>
                  </div>

                  <div
                    style={{
                      height: "40vh",
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      // alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "lightgrey",
                      marginTop: "4.5vh",
                    }}
                  >
                    <div
                      style={{
                        height: "40vh",
                        width: "83%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        // justifyContent: "center",
                        backgroundColor: "#5E0516",
                        boxShadow: "2px 2px 9px #000000",
                        border: "solid",
                        borderWidth: "6px",
                        borderColor: "#eed77a",
                        marginRight: "1vw",
                        paddingTop: "1vh",
                      }}
                    >
                      <label
                        style={{
                          color: "#eed77a",
                          fontSize: "1.25rem",
                          fontWeight: "500",
                        }}
                        onClick={(e) => handleToggleUnitYearOpen(e)}
                      >
                        {"Unit Awards:"}
                      </label>
                    </div>
                  </div>

                  <div
                    style={{
                      height: "40vh",
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      // alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "lightgrey",
                      marginTop: "4.5vh",
                    }}
                  >
                    <div
                      style={{
                        height: "40vh",
                        width: "83%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        // justifyContent: "center",
                        backgroundColor: "#5E0516",
                        boxShadow: "2px 2px 9px #000000",
                        border: "solid",
                        borderWidth: "6px",
                        borderColor: "#eed77a",
                        marginRight: "1vw",
                        paddingTop: "1vh",
                      }}
                    >
                      <label
                        style={{
                          color: "#eed77a",
                          fontSize: "1.25rem",
                          fontWeight: "500",
                        }}
                        onClick={(e) => handleToggleUnitYearOpen(e)}
                      >
                        {"Individual Awards:"}
                      </label>
                    </div>
                  </div>

                  <div
                    style={{
                      height: "40vh",
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      // alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "lightgrey",
                      marginTop: "4.5vh",
                    }}
                  >
                    <div
                      style={{
                        height: "40vh",
                        width: "83%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        // justifyContent: "center",
                        backgroundColor: "#5E0516",
                        boxShadow: "2px 2px 9px #000000",
                        border: "solid",
                        borderWidth: "6px",
                        borderColor: "#eed77a",
                        marginRight: "1vw",
                        paddingTop: "1vh",
                      }}
                    >
                      <label
                        style={{
                          color: "#eed77a",
                          fontSize: "1.25rem",
                          fontWeight: "500",
                        }}
                        onClick={(e) => handleToggleUnitYearOpen(e)}
                      >
                        {"Photo & Video Gallery:"}
                      </label>
                    </div>
                  </div>

                  <div
                    style={{
                      height: "40vh",
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      // alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "lightgrey",
                      marginTop: "4.5vh",
                    }}
                  >
                    <div
                      style={{
                        height: "30vh",
                        width: "83%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        // justifyContent: "center",
                        backgroundColor: "#5E0516",
                        boxShadow: "2px 2px 9px #000000",
                        border: "solid",
                        borderWidth: "6px",
                        borderColor: "#eed77a",
                        marginRight: "1vw",
                        paddingTop: "1vh",
                      }}
                    >
                      <label
                        style={{
                          color: "#eed77a",
                          fontSize: "1.25rem",
                          fontWeight: "500",
                        }}
                        onClick={(e) => handleToggleUnitYearOpen(e)}
                      >
                        {"Event Swag:"}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        {!redirect ? null : <Navigate push to="/login" />}
      </div>
    </>
  );
};
