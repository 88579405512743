import React, { useState, useContext, useEffect } from "react";
import { DataContext } from "../../common/DataContext";
import { AuthContext } from "../../common/AuthContext";
export const RecruitingText = () => {
  const [editRecruitingOpen, toggleEditRecruitingOpen] = useState(null);
  const {
    mobile,
    setRecruitingText,
    updateRecruiting,
    fetchRecruiting,
    recruitingText,
  } = useContext(DataContext);

  const { user } = useContext(AuthContext);

  const [tempText, setTempText] = useState(null);

  const handleEditRecruitingModal = () => {
    if (editRecruitingOpen) {
      toggleEditRecruitingOpen(false);
    } else {
      toggleEditRecruitingOpen(true);
    }
  };

  const EditRecruitingModal = (props) => {
    const [tempRecruitingText, setTempRecruitingText] = useState("");

    const { mobile } = useContext(DataContext);

    useEffect(() => {
      fetchRecruiting();
      if (props.editRecruitingOpen === true) {
        setTempRecruitingText(props.RecruitingText);
      }
      // eslint-disable-next-line
    }, []);

    const handleRecruitingText = () => {
      updateRecruiting(tempRecruitingText);
      toggleEditRecruitingOpen(false);
    };

    return (
      <>
        {props.editRecruitingOpen && props.editRecruitingOpen === true ? (
          <>
            <div
              style={{
                position: "fixed",
                top: "20vh",
                left: mobile ? "4vw" : "32vw",
                height: mobile ? "70vh" : "60%",
                width: mobile ? "92vw" : "40vw",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "left",
                marginTop: "1vh",
                backgroundColor: "ghostwhite",
                boxShadow: "2px 2px 9px #000000",
                zIndex: "999",
                flexWrap: "wrap", // Allow items to wrap to the next row
              }}
            >
              <div
                style={{
                  height: "5vh",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#5E0516",
                  boxShadow: "2px 2px 9px #000000",
                }}
              >
                <h3
                  style={{ color: "#eed77a" }}
                >{`Edit CEF Recruiting Text:`}</h3>
              </div>

              <div
                style={{
                  height: "80%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  // backgroundColor: "lightgrey",
                }}
              >
                <textarea
                  style={{
                    height: "90%",
                    width: "90%",
                    marginTop: "2vh",
                  }}
                  value={tempRecruitingText}
                  onChange={(e) => setTempRecruitingText(e.target.value)}
                />
              </div>

              <div
                style={{
                  height: "10%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <button
                  style={{ marginRight: "2vw" }}
                  onClick={(e) => props.handleEditRecruitingModal(e)}
                >
                  Cancel
                </button>
                <button onClick={(e) => handleRecruitingText(e)}>
                  Confirm
                </button>
              </div>
            </div>
          </>
        ) : null}
      </>
    );
  };

  const ParagraphComponent = ({ text }) => {
    return (
      <div
        style={{
          whiteSpace: "pre-line",
          color: "#eed77a",
          textDecoration: "none",
          // textAlign: "center",
          marginBottom: "1vh",
          fontWeight: 500,
        }}
      >
        {text}
      </div>
    );
  };

  const handleUpdateRecruitingText = () => {
    updateRecruiting(tempText);
    handleEditRecruitingModal();
  };

  return (
    <>
      <EditRecruitingModal
        editRecruitingOpen={editRecruitingOpen}
        handleEditRecruitingModal={handleEditRecruitingModal}
        RecruitingText={recruitingText}
        setRecruitingText={setRecruitingText}
        handleUpdateRecruitingText={handleUpdateRecruitingText}
      />
      <div
        style={{
          height: "75vh",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          // justifyContent: "center",
        }}
      >
        <div
          style={{
            height: mobile ? "80%" : "85%",
            width: "90%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: mobile ? "" : "center",
            backgroundColor: "#5E0516",
            boxShadow: "1px 1px 6px 2px black",
            border: "solid",
            borderWidth: "4px",
            borderColor: "#eed77a",
            paddingBottom: "2vh",
            marginTop: mobile ? "5vh" : "3vh",
            // overflowY: mobile ? "scroll" : "scroll",
          }}
        >
          <div
            style={{
              height: mobile ? "80%" : "90%",
              width: mobile ? "80%" : "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: mobile ? "" : "",

              paddingBottom: "2vh",
              marginTop: mobile ? "5vh" : "",
              overflowY: mobile ? "scroll" : "scroll",
            }}
          >
            <h1
              style={{
                textAlign: "center",
                color: "#eed77a",
              }}
            >
              Join the CEF
            </h1>

            <div>
              <ParagraphComponent text={recruitingText} />
            </div>
          </div>
          {user && user.role === "admin" ? (
            <>
              <button
                style={{
                  marginTop: "2vh",
                }}
                onClick={(e) => handleEditRecruitingModal(e)}
              >
                Edit
              </button>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};
