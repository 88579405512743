import React from "react";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { ShopText } from "./shopText";
import "./styles.css";

export const Shop = () => {
  return (
    <>
      <Header />
      <div>
        <div>
          <ShopText />
        </div>
      </div>
      <Footer />
    </>
  );
};
