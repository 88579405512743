// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.appDiv {
  /* position: fixed;
  width:100%; */
  height: 100%;
  position: fixed;
  width: auto;
  /* height:auto; */
  margin: 0%;
  padding: 0px;
  /* background-color: rgb(79, 207, 207); */

  /* background: url('./static/hexmap_background.png') no-repeat ; */
  /* center center fixed;  */
  /* -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-attachment: fixed; */
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE;eACa;EACb,YAAY;EACZ,eAAe;EACf,WAAW;EACX,iBAAiB;EACjB,UAAU;EACV,YAAY;EACZ,yCAAyC;;EAEzC,kEAAkE;EAClE,0BAA0B;EAC1B;;;;mCAIiC;AACnC","sourcesContent":[".appDiv {\n  /* position: fixed;\n  width:100%; */\n  height: 100%;\n  position: fixed;\n  width: auto;\n  /* height:auto; */\n  margin: 0%;\n  padding: 0px;\n  /* background-color: rgb(79, 207, 207); */\n\n  /* background: url('./static/hexmap_background.png') no-repeat ; */\n  /* center center fixed;  */\n  /* -webkit-background-size: cover;\n    -moz-background-size: cover;\n    -o-background-size: cover;\n    background-size: cover;\n    background-attachment: fixed; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
